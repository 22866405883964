import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tag, Typography, Spin, Form, DatePicker, Button } from "antd";
import { useIntl } from "react-intl";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import dayjs from "dayjs";
import {
  getPendingPayments,
  verifyPayment,
  verifyPaymentReservation,
  verifyPrivatePayment,
  verifyPrivatePaymentReservation,
  verifyMyFatoorahPayment,
  verifyMyFatoorahPaymentReservation,
} from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import { customerExport } from "../../helpers";

const { RangePicker } = DatePicker;

const { Text, Title } = Typography;
const PendingPayments = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formattedPayments, setFormattedPayments] = useState([]);
  const [filterRest, setFilterRest] = useState([]);
  const getSettlementData = async (values) => {
    try {
      setLoading(true);
      let dateStart = dayjs(values.date[0]).format("YYYY-MM-DD");
      let dateEnd = dayjs(values.date[1]).format("YYYY-MM-DD");
      const query = `?dateStart=${dateStart}&dateEnd=${dateEnd}`;
      const res = await getPendingPayments(query);
      if (res?.data?.status === "success") {
        setFormatPayments({ payments: res.data.payments });
        setLoading(false);
        openNotification({
          title: "success",
          description: "payments loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      // dataIndex: "number",
      key: "index",
      width: 80,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "orderDate" }),
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: intl.formatMessage({ id: "restaurantName" }),
      dataIndex: "name",
      key: "name",
      // width: 160,
      render: (name, record) => {
        return <Text>{name ? name : record?.namePickup}</Text>;
      },
      filters: filterRest,
      onFilter: (value, record) => {
        return record.name === value || record.namePickup === value;
      },
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "total",
      key: "total",
    },
    {
      title: "iwaiter ID",
      dataIndex: "orderId",
      key: "orderId",
      render: (orderID, record) => {
        return (
          <Text
            copyable={{
              text: orderID,
            }}
          >
            {" "}
            {orderID === "Reservation"
              ? "Reservation"
              : `${orderID}`?.substring(orderID?.length - 3)}
          </Text>
        );
      },
    },
    {
      title: "payment ID",
      dataIndex: "chargeId",
      key: "chargeId",
      render: (orderID) => {
        return (
          <Text
            copyable={{
              text: orderID,
            }}
          >
            {`${orderID}`}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "paymentCompanyName" }),
      dataIndex: "paymentCompanyName",
      key: "paymentCompanyName",
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return <Tag className='my-font'>{status}</Tag>;
      },
    },
    {
      title: intl.formatMessage({ id: "type" }),
      dataIndex: "type",
      key: "type",
      filters: [
        {
          text: "DineIn",
          value: "DineIn",
        },
        {
          text: "Pickup",
          value: "Pickup",
        },
        {
          text: "Reservation",
          value: "Reservation",
        },
      ],
      onFilter: (value, record) => record.type === value,
    },
    // add action buttons;
    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "actions",
      key: "actions",
      render: (actions, record) => {
        return (
          <div>
            <Button
              type='primary'
              disabled={
                !record.chargeId || record.chargeId === "undefined" || loading
              }
              onClick={() => {
                checkPayment(
                  record.chargeId,
                  record.restaurantId,
                  record.destination_id,
                  record.orderId,
                  record.reservationId,
                  record.paymentCompanyName,
                  record.type
                );
              }}
            >
              check
            </Button>
          </div>
        );
      },
    },
  ];

  const checkPayment = async (
    chargeId,
    restaurantId,
    destination_id,
    orderId,
    reservationId,
    paymentCompanyName,
    type
  ) => {
    try {
      setLoading(true);
      let res;
      if (type === "Reservation") {
        // check paymentCompanyName;
        if (paymentCompanyName === "MY_FATOORAH") {
          res = await verifyMyFatoorahPaymentReservation(
            chargeId,
            restaurantId,
            reservationId
          );
        } else {
          if (destination_id === "WALLET" && false) {
            res = await verifyPrivatePaymentReservation(
              chargeId,
              restaurantId,
              reservationId
            );
          } else {
            res = await verifyPaymentReservation(chargeId, reservationId);
          }
        }
      } else {
        if (paymentCompanyName === "MY_FATOORAH") {
          res = await verifyMyFatoorahPayment(chargeId, restaurantId);
        } else {
          if (destination_id === "WALLET" && false) {
            res = await verifyPrivatePayment(chargeId, restaurantId);
          } else {
            res = await verifyPayment(chargeId);
          }
        }
      }
      if (res.data.status === "notPaid") {
        openNotification({
          title: "Not paid invoice",
          description: res?.data?.response?.message,
          type: "error",
          rtl: true,
        });
      } else {
        openNotification({
          title: "success",
          description: res?.data?.response?.message,
          type: "success",
          rtl: true,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const setFormatPayments = ({ payments }) => {
    let filter = [];
    const formatted = payments.map((payment) => {
      let name = payment.name || payment.namePickup;
      let j = filter.findIndex((a) => a.text === name);
      if (j === -1) {
        filter.push({
          text: name,
          value: name,
        });
      }
      return {
        key: payment._id,
        name: name,
        orderId: payment.orderId || payment._id,
        paymentMethod: payment.paymentMethod,
        total: payment.totalNumber,
        chargeId: payment.chargeId,
        paymentCompanyName: payment.paymentCompanyName,
        createdAt: new Date(payment.createdAt).toLocaleString(),
        status: payment?.status,
        destination_id: payment?.destination_id || payment.destination_idPickup,
        restaurantId: payment?.restaurantId || payment.restaurantIdPickup,
        reservationId: payment?._id,
        paymentObject: { ...payment },
        type: payment?.type
          ? payment?.type
          : payment.name
          ? "DineIn"
          : "Pickup",
      };
    });
    setFilterRest(filter);
    setFormattedPayments(formatted);
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "pendingPayments" })}
          </Title>

          <Form
            name='payment-data'
            form={form}
            preserve={false}
            className='form-container'
            layout='inline'
            onFinish={getSettlementData}
          >
            <Form.Item
              name='date'
              label={intl.formatMessage({ id: "dateRange" })}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <RangePicker />
            </Form.Item>
            <Form.Item>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "send" })}
                className={`btnRegister btn-text border-8`}
                loading={loading}
                disabled={loading}
                type='primary'
              />
            </Form.Item>
          </Form>
        </div>

        <Table
          searchable
          fuzzySearch
          exportable
          exportableProps={{
            showColumnPicker: true,
            disabled: loading || formattedPayments?.length === 0,
            btnProps: {
              icon: <FileExcelOutlined />,
              type: "primary",
              style: { borderRadius: 7 },
              onClick: () =>
                customerExport({
                  data: formattedPayments,
                  columns,
                  fileName: "Payments Report",
                  loadingSetter: setLoading,
                }),
            },
            children: (
              <Text
                className='my-font'
                style={{
                  color:
                    formattedPayments?.length === 0 ? "#dddddd" : COLORS.white,
                }}
              >
                {intl.formatMessage({ id: "export" })}
              </Text>
            ),
          }}
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedPayments?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedPayments}
          scroll={{ x: "max-content" }}
          //   summary={(pageData) => {
          //     let total = 0;
          //     let totalPaid = 0;
          //     let remain = 0;
          //     pageData.forEach(({ paymentObject }) => {
          //       if (typeof +paymentObject?.total === "number") {
          //         if (paymentObject.didReceiveSettlement) {
          //           totalPaid += +paymentObject.total;
          //         }
          //         total += +paymentObject?.total;
          //       }
          //     });
          //     remain = total - totalPaid;
          //     return (
          //       <MYT.Summary>
          //         <MYT.Summary.Row>
          //           <MYT.Summary.Cell className='summary-bg-gray' colSpan={2}>
          //             {intl.formatMessage({ id: "total" })}
          //           </MYT.Summary.Cell>
          //           <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
          //             <Text style={{ color: COLORS.primary }}>
          //               {total?.toFixed(2)}
          //             </Text>
          //           </MYT.Summary.Cell>
          //           <MYT.Summary.Cell className='summary-bg-gray' colSpan={2}>
          //             {intl.formatMessage({ id: "settlAmount" })}
          //           </MYT.Summary.Cell>
          //           <MYT.Summary.Cell
          //             colSpan={2}
          //             style={{ backgroundColor: "red" }}
          //           >
          //             <Text type='success'>{totalPaid?.toFixed(2)}</Text>
          //           </MYT.Summary.Cell>
          //         </MYT.Summary.Row>
          //         <MYT.Summary.Row>
          //           <MYT.Summary.Cell className='summary-bg-gray' colSpan={2}>
          //             {intl.formatMessage({ id: "remainAmount" })}
          //           </MYT.Summary.Cell>
          //           <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
          //             <Text type='danger'>{remain?.toFixed(2)}</Text>
          //           </MYT.Summary.Cell>
          //         </MYT.Summary.Row>
          //       </MYT.Summary>
          //     );
          //   }}
        />
      </Spin>
    </div>
  );
};

export default PendingPayments;
