import React, { useEffect, useState } from "react";
import COLORS from "../../Style/colors";
import { Table, Typography, Tag } from "antd";
import openNotification from "../../Components/Notifications";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { IoRefreshOutline } from "react-icons/io5";
import CustomButton from "../../Components/CustomButton";
import { BiPlus } from "react-icons/bi";
import dayjs from "dayjs";
import { getAllChallenges } from "../../API/fetch";
import { Link, useNavigate } from "react-router-dom";
import { setChallengesAction } from "../../redux/reducers/appReducer";

const { Title, Text } = Typography;
function GameMarketing() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { restaurants } = useSelector((state) => state.restaurants);
  const { challenges } = useSelector((state) => state.app);
  const [loading, setLoading] = useState(false);
  const [formattedChallenges, setFormattedChallenges] = useState([]);
  const [allPrizes, setAllPrizes] = useState(0);
  const [systemPrizes, setSystemPrizes] = useState(0);

  useEffect(() => {
    if (challenges?.length > 0) {
      let allP = 0;
      let sysP = 0;
      let formatted = challenges.map((item) => {
        let isSystem = false;
        if (item?.creator?.phone.startsWith("111")) {
          isSystem = true;
        }
        let winner = "--";
        if (item?.winner) {
          if (item?.winner?._id === item?.creator?._id) {
            winner = intl.formatMessage({ id: "creator" });
          } else {
            winner = `${item?.winner?.name} - ${item?.winner?.phone}`;
          }
        }
        allP += item?.prize;
        if (isSystem) {
          sysP += item?.prize;
        }
        let game = "XO";
        if (item?.gameType?.includes("Quiz")) {
          game = "Quiz";
        }
        return {
          key: item._id,
          _id: item._id,
          isSystem,
          creator: isSystem ? "System" : item?.creator?.name,
          prize: item?.prize,
          players: item?.allPlayers?.length,
          status: item?.status,
          createdAt: item?.createdAt,
          winner,
          game,
        };
      });
      setFormattedChallenges(formatted);
      setAllPrizes(allP);
      setSystemPrizes(sysP);
    } else {
      setFormattedChallenges([]);
    }
  }, [challenges]);

  const getChallenges = async () => {
    try {
      setLoading(true);
      const res = await getAllChallenges();
      if (res?.data?.challenges) {
        dispatch(setChallengesAction(res?.data?.challenges));
        openNotification({
          title: "ناجح",
          description: "تم تحميل البيانات بنجاح",
          type: "success",
          rtl: true,
        });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: "فشل",
        description: "حدث خطأ أثناء تحميل البيانات",
        type: "danger",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      render: (text, record, index) => {
        return <Link to={`${record._id}`}>{index + 1}</Link>;
      },
    },
    {
      title: intl.formatMessage({ id: "creator" }),
      dataIndex: "creator",
      key: "creator",
      render: (text) => <Text>{text}</Text>,
      filters: [
        {
          text: "System",
          value: "System",
        },
        {
          text: "User",
          value: "User",
        },
      ],
      onFilter: (value, record) => {
        if (value === "System") {
          return record?.isSystem === true;
        } else if (value === "User") {
          return record?.isSystem === false;
        }
      },
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <Text>{dayjs(text).format("YYYY-MM-DD")}</Text>,
      sorter: (a, b) => {
        return dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix();
      },
      filters: [
        {
          text: "Today",
          value: "today",
        },
        {
          text: "Yesterday",
          value: "yesterday",
        },
        {
          text: "This Week",
          value: "thisWeek",
        },
        {
          text: "This Month",
          value: "thisMonth",
        },
      ],
      onFilter: (value, record) => {
        if (value === "today") {
          return dayjs(record.createdAt).isSame(dayjs(), "day");
        } else if (value === "yesterday") {
          return dayjs(record.createdAt).isSame(
            dayjs().subtract(1, "day"),
            "day"
          );
        } else if (value === "thisWeek") {
          return dayjs(record.createdAt).isSame(dayjs(), "week");
        } else if (value === "thisMonth") {
          return dayjs(record.createdAt).isSame(dayjs(), "month");
        }
      },
    },
    {
      title: intl.formatMessage({ id: "prize" }),
      dataIndex: "prize",
      key: "prize",
      render: (text) => <Text>{text}</Text>,
      sorter: (a, b) => a.prize - b.prize,
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (text) => {
        let isExpired = false;
        let isActive = false;
        if (text === "ended" || text === "deleted") {
          isExpired = true;
        }
        if (text === "started" || text === "accepted") {
          isActive = true;
        }

        return (
          <div
            style={{
              backgroundColor: isExpired
                ? COLORS.danger
                : isActive
                ? COLORS.warning
                : COLORS.success,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              borderRadius: 5,
            }}
          >
            <Text
              className='my-font'
              style={{
                color: "white",
                fontWeight: "bold",
              }}
            >
              {text}
            </Text>
          </div>
        );
      },
      filters: [
        {
          text: "Started",
          value: "started",
        },
        {
          text: "Ended",
          value: "ended",
        },
        {
          text: "Deleted",
          value: "deleted",
        },
        {
          text: "Ready",
          value: "waiting",
        },
        {
          text: "Accepted",
          value: "accepted",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: intl.formatMessage({ id: "winner" }),
      dataIndex: "winner",
      key: "winner",
    },
    {
      title: intl.formatMessage({ id: "game" }),
      dataIndex: "game",
      key: "game",
      filters: [
        {
          text: "XO",
          value: "XO",
        },
        {
          text: "Quiz",
          value: "Quiz",
        },
      ],
      onFilter: (value, record) => record.game.indexOf(value) === 0,
    },
    {
      title: intl.formatMessage({ id: "players" }),
      dataIndex: "players",
      key: "players",
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "appGames" })}
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getChallenges}
          />
        </div>
        <CustomButton
          text={intl.formatMessage({ id: "add" })}
          icon={<BiPlus />}
          type='primary'
          onClick={() => {
            navigate("/addChallenges");
          }}
        />
      </div>

      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 10,
          width: "92%",
          minHeight: "70vh",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 20,
        }}
        className='light-shadow'
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Tag
            style={{
              minWidth: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 5,
              marginRight: 10,
              padding: "5px 10px",
            }}
          >
            {intl.formatMessage({ id: "allPrizes" })}: {allPrizes?.toFixed(2)}
          </Tag>
          <Tag
            style={{
              minWidth: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 5,
              marginRight: 10,
              padding: "5px 10px",
            }}
            color='red'
          >
            {intl.formatMessage({ id: "systemPrize" })}:{" "}
            {systemPrizes?.toFixed(2)}
          </Tag>
        </div>
        <Table
          pagination={{
            position: ["topLeft"],
            pageSizeOptions: [10, 25, 50, 100, 200, 500, 1000, 2000],
            pageSize: 500,
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedChallenges}
          scroll={loading ? undefined : { x: "max-content" }}
        />
      </div>
    </div>
  );
}

export default GameMarketing;
