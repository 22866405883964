import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Typography, Spin } from "antd";
import { useIntl } from "react-intl";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  FileExcelOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { getRestaurants, sendWhatsAppMessage } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { setRestaurantsAction } from "../../redux/reducers/restaurantsReducer";
import { PAYONLINEIN, SERVICES_TYPES } from "../../Types";
import { MdVerified } from "react-icons/md";
import { Link } from "react-router-dom";
import { Table } from "ant-table-extensions";
import { IoRefreshOutline } from "react-icons/io5";
import { useRef } from "react";
import { getCities, getPayOnlineIn } from "../../helpers";
import { formatServices } from "../../helpers/restaurants";

export const CLINT_URL = "https://app.iwaiter.club";
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
const { Text, Title } = Typography;
const Restaurants = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { restaurants } = useSelector((state) => state.restaurants);
  const { rtl } = useSelector((state) => state.settings);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formattedRestaurants, setFormattedRestaurants] = useState([]);
  const [searchedResults, setSearchedResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const DATA = useRef(null);

  useEffect(() => {
    if (!restaurants?.length) {
      getRestaurantsData();
    } else {
      setLoading(false);
    }
  }, [restaurants]);

  const getRestaurantsData = async () => {
    try {
      console.log("Start");
      setLoading(true);
      const res = await getRestaurants();
      if (res?.data?.restaurants) {
        dispatch(setRestaurantsAction(res.data.restaurants));
        setLoading(false);
        openNotification({
          title: "success",
          description: "restaurants loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  useEffect(() => {
    if (searchValue && searchValue?.trim()?.length > 0) {
      let copy = [...formattedRestaurants];
      copy = copy.filter((el) => {
        return (
          el?.name?.includes(searchValue) ||
          el?.name_en?.includes(searchValue) ||
          el?.phone?.includes(searchValue)
        );
      });
      setSearchedResults(copy);
    } else {
      setSearchedResults([]);
    }
  }, [searchValue]);

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record, index) => {
        return <Link to={`${record._id}`}>{index + 1}</Link>;
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      width: 160,
      render: (name, record) => {
        return (
          <Link to={`${record._id}`}>
            {record.didPaid ? (
              <MdVerified
                style={{ marginLeft: 10 }}
                size={18}
                color={COLORS.primary}
              />
            ) : null}
            {name}
          </Link>
        );
      },
      onFilter: (value, record) => {
        return record?.didPaid === value;
      },
      filters: [
        { text: intl.formatMessage({ id: "paid" }), value: true },
        { text: intl.formatMessage({ id: "notPaid" }), value: false },
      ],
    },
    {
      title: intl.formatMessage({ id: "userName" }),
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      width: 150,
      key: "phone",
      render: (phone) => {
        return (
          <Text
            copyable={{
              // remove the first three digits from phone;
              text: phone ? phone.slice(3) : "",
            }}
          >
            {phone}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "cities" }),
      dataIndex: "cities",
      key: "cities",
      render: (cities) => {
        return cities?.map((city, i) => {
          return <Tag key={i}>{city}</Tag>;
        });
      },
      onFilter: (value, record) =>
        record?.cities?.map((el) => el?.toLowerCase()).includes(value),
      filterSearch: true,
      filters: getCities(restaurants),
    },
    {
      title: intl.formatMessage({ id: "JUST_MENU" }),
      dataIndex: "menuLink",
      key: "menuLink",
      render: (menuLink) => {
        return (
          <Tag color={COLORS.primary}>
            <a href={`${menuLink}`} target="_blank">
              {intl.formatMessage({ id: "JUST_MENU" })}
            </a>
          </Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "isOnlineActive" }),
      dataIndex: "isPayOnline",
      key: "isPayOnline",
      render: (isPayOnline, record) => {
        const data = [
          {
            text: "dine_in",
            value: record?.isOnlineDineInActive,
          },
          {
            text: "delivery",
            value: record?.isOnlineDeliveryActive,
          },
          {
            text: "pickup",
            value: record?.isOnlinePickupActive,
          },
        ];
        return (
          <div>
            {data?.map((element, i) => {
              if (element?.value) {
                return (
                  <Tag key={i}>
                    <Text>{intl.formatMessage({ id: element?.text })}</Text>
                    <CheckCircleFilled
                      style={{ fontSize: 15, color: COLORS.success }}
                    />
                  </Tag>
                );
              } else {
                return (
                  <Tag key={i}>
                    <Text>{intl.formatMessage({ id: element?.text })}</Text>
                    <CloseCircleFilled
                      style={{ fontSize: 15, color: COLORS.danger }}
                    />
                  </Tag>
                );
              }
            })}
          </div>
        );
      },
      onFilter: (value, record) => {
        return record?.payOnlineTypes?.includes(value);
      },
      filterSearch: true,
      filters: [
        {
          text: `${intl.formatMessage({ id: "enableIn" })} ${intl.formatMessage(
            { id: "All" }
          )} `,
          value: PAYONLINEIN.ALL,
        },
        {
          text: `${intl.formatMessage({ id: "enableIn" })} ${intl.formatMessage(
            { id: "dine_in" }
          )} `,
          value: PAYONLINEIN.DINE_IN,
        },
        {
          text: `${intl.formatMessage({ id: "enableIn" })} ${intl.formatMessage(
            { id: "pickup" }
          )} `,
          value: PAYONLINEIN.PICKUP,
        },
        {
          text: `${intl.formatMessage({ id: "enableIn" })} ${intl.formatMessage(
            { id: "delivery" }
          )} `,
          value: PAYONLINEIN.DELIVERY,
        },
        {
          text: `${intl.formatMessage({
            id: "disabledIn",
          })} ${intl.formatMessage({ id: "All" })} `,
          value: PAYONLINEIN.NONE,
        },
      ],
    },

    {
      title: intl.formatMessage({ id: "subscription" }),
      dataIndex: "services",
      key: "services",
      width: 120,
      render: (services) => {
        return services?.map((service) => {
          return <Tag key={service}>{service}</Tag>;
        });
      },
      onFilter: (value, record) => {
        return record.serviceType === value || record.services?.includes(value);
      },
      filters: Object.keys(SERVICES_TYPES).flatMap((type) => {
        return { text: type, value: type };
      }),
    },
    {
      title: intl.formatMessage({ id: "integration" }),
      dataIndex: "integration",
      key: "integration",
      render: (company) => {
        return (
          <Tag
            color={
              company === "foodics"
                ? "purple"
                : company === "Marn"
                ? "blue"
                : ""
            }
          >
            {company === "NO_INTEGRATION" ? "N/A" : company}
          </Tag>
        );
      },
      onFilter: (value, record) => {
        return record?.integration === value;
      },
      filters: [
        { text: "Foodics", value: "foodics" },
        { text: "Marn", value: "Marn" },
        { text: "No integration", value: "NO_INTEGRATION" },
      ],
      width: 90,
    },
    // remove dubelcated values;
    // {
    //   title: intl.formatMessage({ id: "branches" }),
    //   dataIndex: "branches",
    //   key: "branches",
    //   sorter: (a, b) => a.branches - b.branches,
    //   showSorterTooltip: false,
    // },
    // {
    //   title: intl.formatMessage({ id: "waiters" }),
    //   dataIndex: "waiters",
    //   key: "waiters",
    //   sorter: (a, b) => a.waiters - b.waiters,
    //   showSorterTooltip: false,
    // },
    // add start subscription date;
    {
      title: intl.formatMessage({ id: "sendSubscriptionEnd" }),
      dataIndex: "sendSubscriptionEnd",
      key: "sendSubscriptionEnd",
      width: 120,
      render: (_, record) => {
        return (
          <CustomButton
            text={intl.formatMessage({ id: "send" })}
            type="danger"
            className={"px-5"}
            style={{
              borderRadius: 10,
            }}
            onClick={() => {
              sendWhatSappReminder(
                record,
                record.isLastService,
                record?.serviceType
              );
            }}
          />
        );
      },
    },
    {
      title: intl.formatMessage({ id: "startSubscriptionDate" }),
      dataIndex: "startSubscriptionDate",
      key: "startSubscriptionDate",
      render: (startSubscriptionDate, record) => {
        return (
          <Text>
            {dayjs(record?.restaurantObject?.userInfo?.createdAt).format(
              "DD/MM/YYYY"
            )}
          </Text>
        );
      },
      sorter: (a, b) =>
        // sort by data;
        +dayjs(a?.restaurantObject?.userInfo?.createdAt) -
        +dayjs(b?.restaurantObject?.userInfo?.createdAt),
      // showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "subscriptionEndDate" }),
      dataIndex: "endSubscription",
      key: "endSubscription",
      fixed: "right",
      width: 140,
      style: {
        backgroundColor: "red",
      },
      render: (dateString, record) => {
        let background = COLORS.success;
        let color = COLORS.white;
        // check if date is less than now;
        let date = record.endSubscriptionDate;
        if (dayjs(date).isBefore(dayjs())) {
          background = COLORS.danger;
        } // check if date is within the next 8 days;
        else if (dayjs(date).isBetween(dayjs(), dayjs().add(8, "days"))) {
          background = COLORS.warning;
        }
        return {
          props: {
            style: { background },
          },
          children: (
            <Text style={{ color }}>{dayjs(date).format("DD/MM/YYYY")}</Text>
          ),
        };
      },
      sorter: (a, b) =>
        new Date(a.endSubscriptionDate) - new Date(b.endSubscriptionDate),
      showSorterTooltip: false,
      onFilter: (value, record) => {
        let date = record.endSubscriptionDate;
        if (value === "active") {
          return dayjs(date).isAfter(dayjs().add(8, "days"));
        } else if (value === "lessThanWeek") {
          return dayjs(date).isBetween(dayjs(), dayjs().add(8, "days"));
        } else if (value === "expired") {
          return dayjs(date).isBefore(dayjs());
        }

        return false;
      },
      filters: [
        {
          text: intl.formatMessage({ id: "active" }),
          value: "active",
        },
        {
          text: intl.formatMessage({ id: "lessThanWeek" }),
          value: "lessThanWeek",
        },
        {
          text: intl.formatMessage({ id: "expired" }),
          value: "expired",
        },
      ],
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (restaurants?.length) {
      const formatted = restaurants.map((restaurant, i) => {
        const menuLink = `${CLINT_URL}/${restaurant.slug}`;
        // services is an array;
        // get last service ended date;
        let selectedService = {};
        let didPaid = restaurant.didPaid;
        restaurant.services.forEach((service) => {
          if (service?.amountPaid > 0) {
            didPaid = true;
          }
          if (!selectedService?.serviceEnd) {
            selectedService = service;
          } else {
            // check if the current service end date is greater than the selected service end date;
            if (
              dayjs(service.serviceEnd).startOf('day').isAfter(
                dayjs(selectedService.serviceEnd).endOf('day')
              )
            ) {
              selectedService = service;
            }
          }
        });
        const filteredServices = restaurant.services.filter(
          (service) =>
            dayjs().isBetween(
              dayjs(service.serviceStart).startOf('day'),
              dayjs(service.serviceEnd).endOf('day')
            ) 
            && service.status === "Active"
        );
        const services = formatServices(filteredServices);
        if (services.length > 0) {
          const today = dayjs();
          let closestDifference = Infinity;
          services?.forEach((service) => {
            const serviceEnd = dayjs(service?.serviceEnd);
            const difference = Math.abs(serviceEnd.diff(today));
            if (difference < closestDifference) {
              selectedService = service;
              closestDifference = difference;
            }
          });
        }
        // get all services white the same service;
        const allServicesSameType = restaurant.services.filter(
          (service) => {
            let s = selectedService?.services; // [pickup, dleivey, xxx]
            let s2 = service?.services; // [pickup, dleivey, xxx]
            // return all restaurant.services that includes any of s;
            return s.some((el) => s2.includes(el)); 
          }
        );

        let endTime = selectedService?.serviceEnd;
        allServicesSameType.forEach((service) => {
          if(dayjs(service?.serviceEnd).isAfter(dayjs(endTime))){
            // check if the start time of the service is before or same the end time of the selectedService;
            if(dayjs(service?.serviceStart).isSameOrBefore(dayjs(endTime))){
            endTime = service?.serviceEnd;
            }
          }
        });
        selectedService = {
          ...selectedService,
          serviceEnd: dayjs(endTime).toISOString(),
        }
       
        return {
          key: restaurant._id,
          number: i + 1,
          _id: restaurant._id,
          name: restaurant.name,
          name_en: restaurant.name_en,
          phone: restaurant?.userInfo?.phone,
          branches: restaurant.branches,
          didPaid,
          userName: restaurant?.userInfo?.name,
          integration: restaurant?.integration
            ? restaurant.integration
            : "NO_INTEGRATION",
          startSubscriptionDate: dayjs(restaurant?.userInfo?.createdAt).format(
            "DD/MM/YYYY"
          ),
          cities: restaurant?.cities,
          menuLink: menuLink,
          isPayOnline: restaurant?.isPayOnline,
          isOnlineDineInActive: restaurant?.isOnlineDineInActive,
          isOnlinePickupActive: restaurant?.isOnlinePickupActive,
          isOnlineDeliveryActive: restaurant?.isOnlineDeliveryActive,
          payOnlineTypes: getPayOnlineIn({
            isPickup: restaurant?.isOnlinePickupActive,
            isDelivery: restaurant?.isOnlineDeliveryActive,
            isDineIn: restaurant?.isOnlineDineInActive,
          }),
          isLastService: services.length > 1 ? false : true,
          services: SERVICES_TYPES[selectedService?.serviceType],
          serviceType: selectedService?.serviceType,
          refer: restaurant?.refer?.name ?? "N/A",
          waiters: restaurant.waiters,
          selectedService: selectedService,
          endSubscription: dayjs(selectedService?.serviceEnd).format(
            "DD-MM-YYYY"
          ),
          endSubscriptionDate: dayjs(selectedService?.serviceEnd),
          restaurantObject: { ...restaurant },
        };
      });
      setFormattedRestaurants(formatted);
    }
    setLoading(false);
  }, [rtl, restaurants]);
  const sendWhatSappReminder = async (user, isLastService, type) => {
    try {
      setLoading(true);
      let name = user.name;
      let phone = user.phone;
      let endDate = user?.selectedService?.serviceEnd;
      let date = "";
      let time = "";
      if (dayjs(endDate).isSameOrBefore(dayjs().add(8, "days"))) {
        date = dayjs(endDate).format("DD/MM/YYYY");
        // get the difference between now and the end date in days;
        time = dayjs(endDate).diff(dayjs(), "days");
      } else {
        openNotification({
          title: "error",
          description: "تبقى على اشتراك العميل اكثر من ٧ ايام",
          type: "error",
          rtl: true,
        });
        setLoading(false);
        return;
      }
      let globalMessage = `السلام عليكم ${name}.
      أسعد الله أوقاتكم
      للتذكير اشتراككم في اي ويتر ينتهي خلال ${time} أيام في تاريخ
      
      ${date}
      
      تستطيع التجديد من خلال الداشبورد مباشرة في اي ويتر
      https://board.iwaiter.net
      
      
      إذا كان لديك اي سؤال لا تتردد بالتواصل معنا`;

      let serviceMessage = `السلام عليكم ${name}.
      أسعد الله أوقاتكم
      للتذكير اشتراككم في خدمة ${intl.formatMessage({
        id: type,
      })} في اي ويتر ينتهي خلال ${time} أيام في تاريخ
      
      ${date}
      
      تستطيع التجديد من خلال الداشبورد مباشرة في اي ويتر
      https://board.iwaiter.net
      
      
      إذا كان لديك اي سؤال لا تتردد بالتواصل معنا`;
      const data = {
        phone,
        message:  serviceMessage,
      };
      const res = await sendWhatsAppMessage(data);
      if (res.status === 200) {
        openNotification({
          title: "success",
          description: "تم ارسال الرسالة بنجاح",
          type: "success",
          rtl: true,
        });
      } else {
        // console.log("herer one", res.data)
        openNotification({
          title: "error",
          description: "حدث خطأ ما",
          type: "error",
          rtl: true,
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };
  const handleOnExport = () => {
    setFormattedRestaurants(DATA.current);
    setLoadingExport(true);
    const btn = document.getElementById("exportBTN");
    setTimeout(() => {
      btn?.click();
      setLoadingExport(false);
      setFormattedRestaurants(formattedRestaurants);
    }, 2000);
  };
  return (
    <div className="tables-card-container" style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "restaurants" })}
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type="text"
            icon={<IoRefreshOutline />}
            onClick={getRestaurantsData}
          />
        </div>
        <CustomButton
          text={intl.formatMessage({ id: "export" })}
          onClick={handleOnExport}
          disabled={loadingExport}
          loading={loadingExport}
          type="primary"
          className={"border-8"}
          icon={<FileExcelOutlined />}
        />
        <Table
          exportable
          exportableProps={{
            disabled: loading || formattedRestaurants?.length === 0,
            btnProps: {
              icon: <FileExcelOutlined />,
              type: "primary",
              style: { borderRadius: 7, position: "relative", zIndex: -20 },
              id: "exportBTN",
            },
            children: (
              <Text
                className="my-font"
                style={{
                  color:
                    formattedRestaurants?.length === 0
                      ? "#dddddd"
                      : COLORS.white,
                }}
              >
                {intl.formatMessage({ id: "export" })}
              </Text>
            ),
          }}
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table",
              prefix: <SearchOutlined />,
              disabled: loading || formattedRestaurants?.length === 0,
              onChange: (e) => {
                setSearchValue(e.target.value);
              },
              value: searchValue,
            },
          }}
          pagination={{
            position: ["topLeft"],
            pageSizeOptions: [10, 25, 50, 100, 200, 500],
          }}
          columns={columns}
          className="light-shadow"
          loading={loading}
          dataSource={
            searchValue?.trim()?.length > 0
              ? searchedResults
              : formattedRestaurants
          }
          scroll={{ x: "max-content" }}
          footer={(currentPageData) => {
            DATA.current = currentPageData;
            return null;
          }}
        />
      </Spin>
    </div>
  );
};

export default Restaurants;
