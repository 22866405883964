import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Spin, Form, DatePicker } from "antd";
import { useIntl } from "react-intl";
import { SearchOutlined } from "@ant-design/icons";
import { getRestaurantsWithWallets } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import { useNavigate } from "react-router-dom";
const { RangePicker } = DatePicker;
const { Text, Link } = Typography;
const AccountStatement = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(true);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [showOne, setShowOne] = useState(false)
  useEffect(() => {
    getRestaurantsWithWallet();
  }, []);

  const getRestaurantsWithWallet = async () => {
    try {
      setLoading(true);
      const res = await getRestaurantsWithWallets();
      if (res?.data?.restaurants) {
        setRestaurants(res.data.restaurants);
        setLoading(false);
        openNotification({
          title: "success",
          description: "restaurants loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: rtl,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: rtl,
      });
    }
  };


  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "restaurantName",
      key: "restaurantName",
    },
    {
      title: intl.formatMessage({ id: "walletBalance" }),
      dataIndex: "walletBalance",
      key: "walletBalance",
      render: (text, record) => {
        return (
          <Link href=""
            onClick={() => {
              navigate(`/accountStatement/${record?.restaurantId}`, {
                state: { restaurant: record },
              })
              // setSelectedRestaurant(record);
              // setShowOne(true)
            }}
            className={"border-8 px-5"}
          >
{intl.formatMessage({ id: "view" })}
          </Link>
        );
      },
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
          }}
        ></div>
            {/* <OneRestaurantAccount
                show={showOne}
                hide={() => {
                    setShowOne(false);
                    setSelectedRestaurant(null);
                }}
                data={selectedRestaurant}
                
            /> */}
        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || restaurants?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={restaurants}
          scroll={{ x: 'max-content' }}
        />
      </Spin>
    </div>
  );
};
export default AccountStatement;
