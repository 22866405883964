let apiUrl, payAppUrl;
const expressPort = 3003;
let payAppPort = 3007;

export const streamUrl = "https://stream.iwaiter.club/v1/stream";
const apiUrls = {
  production: "https://api.iwaiter.club/api/v1",
  development: `http://localhost:${expressPort}/api/v1`,
};

if (
  window.location.hostname === "localhost" ||
  window.location.hostname.includes("192")
) {
  apiUrl = apiUrls.development;
  payAppUrl = `http://localhost:${payAppPort}/api/v1`;
} else {
  apiUrl = apiUrls.production;
  payAppUrl = "https://api.pay.iwaiter.club/api/v1";
}

export const apiUrlAppPay = payAppUrl;
export default apiUrl;
