import React from "react";
import { Menu, Typography } from "antd";

import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { logOutAction } from "../../redux/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  IoHomeOutline,
  IoLanguageOutline,
  IoLogOut,
  IoPeopleOutline,
  IoLinkOutline,
  IoBusinessOutline,
  IoCardOutline,
  IoChatboxEllipsesOutline,
  IoReceiptOutline,
  IoCarOutline,
  IoNotificationsOutline,
  IoBarcodeOutline,
} from "react-icons/io5";
import { BiError, BiGift, BiNotification } from "react-icons/bi";
import {
  RiCoupon2Fill,
  RiSecurePaymentFill,
  RiSecurePaymentLine,
  RiUserSearchFill,
} from "react-icons/ri";
import { GiReceiveMoney } from "react-icons/gi";
import { changeLanguageAction } from "../../redux/reducers/settingsReducer";
import { BsCardChecklist, BsPercent } from "react-icons/bs";
import {
  MdAlternateEmail,
  MdGames,
  MdSettingsApplications,
} from "react-icons/md";
import {
  AppstoreAddOutlined,
  BarChartOutlined,
  WalletFilled,
  WhatsAppOutlined,
} from "@ant-design/icons";
import CustomButton from "../CustomButton";
import COLORS from "../../Style/colors";

const { Text } = Typography;
const SuperUserSide = ({ toggleSideBar }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const items = [
    {
      key: "home",
      icon: <IoHomeOutline size={20} />,
      label: <Link to='/'>{intl.formatMessage({ id: "home" })}</Link>,
    },
    {
      key: "payApp",
      icon: <RiSecurePaymentLine size={20} />,
      label: <Link to='/payApp'>{intl.formatMessage({ id: "payApp" })}</Link>,
    },
    {
      key: "restaurants",
      icon: <IoBusinessOutline size={20} />,
      label: (
        <Link to='/restaurants'>
          {" "}
          {intl.formatMessage({ id: "restaurants" })}{" "}
        </Link>
      ),
    },
    {
      key: "users",
      icon: <IoPeopleOutline size={20} />,
      label: <Link to='/users'>{intl.formatMessage({ id: "users" })}</Link>,
    },
    {
      key: "superUsers",
      icon: <IoBusinessOutline size={20} />,
      label: (
        <Link to='/super-users'>
          {intl.formatMessage({ id: "superUsers" })}
        </Link>
      ),
    },
    {
      key: "integrations",
      icon: <IoLinkOutline size={20} />,
      label: (
        <Link to='/integrations'>
          {intl.formatMessage({ id: "posRequsets" })}
        </Link>
      ),
    },
    {
      key: "tap-wallets",
      icon: <WalletFilled size={20} />,
      label: <Link to='/tap'>{intl.formatMessage({ id: "tapWallets" })}</Link>,
    },
    {
      key: "refer",
      icon: <BsPercent size={20} />,
      label: <Link to='/refer'>{intl.formatMessage({ id: "refer" })}</Link>,
    },
    {
      key: "sms",
      icon: <IoChatboxEllipsesOutline size={20} />,
      label: <Link to='/sms'>{intl.formatMessage({ id: "sms" })}</Link>,
    },
    {
      key: "marketing",
      icon: <IoNotificationsOutline size={20} />,
      label: (
        <Link to='/marketing'>{intl.formatMessage({ id: "marketing" })}</Link>
      ),
    },
    {
      key: "montlyReports",
      icon: <BarChartOutlined size={20} />,
      label: (
        <Link to='/activityReports'>
          {intl.formatMessage({ id: "activityReports" })}
        </Link>
      ),
    },
    {
      key: "marketingEmails",
      icon: <MdAlternateEmail size={20} />,
      label: (
        <Link to='/marketing-emails'>
          {intl.formatMessage({ id: "marketingEmail" })}
        </Link>
      ),
    },
    {
      key: "myFatoorah",
      icon: <IoChatboxEllipsesOutline size={20} />,
      label: <Link to='/myFatoorah'>My Fatoorah</Link>,
    },
    {
      key: "application",
      icon: <AppstoreAddOutlined size={20} />,
      label: (
        <Link to='/application'>
          {intl.formatMessage({ id: "application" })}
        </Link>
      ),
    },
    {
      key: "notifications",
      icon: <BiNotification size={20} color='blue' />,
      label: (
        <Link to='/notifications'>
          {intl.formatMessage({ id: "notifications" })}
        </Link>
      ),
    },
    {
      key: "users-application",
      icon: <RiUserSearchFill size={20} />,
      label: (
        <Link to='/users-application'>
          {intl.formatMessage({ id: "usersApplication" })}
        </Link>
      ),
    },
    {
      key: "settlement",
      icon: <RiSecurePaymentFill size={20} />,
      label: (
        <Link to='/settlement'>{intl.formatMessage({ id: "settlement" })}</Link>
      ),
    },
    {
      key: "new-payments",
      icon: <BsCardChecklist size={20} />,
      label: (
        <Link to='/newPayments'>
          {intl.formatMessage({ id: "newPayments" })}
        </Link>
      ),
    },
    {
      key: "settlementInfo",
      icon: <RiSecurePaymentFill size={20} />,
      label: (
        <Link to='/settlementInfo'>
          {intl.formatMessage({ id: "settlementInfo" })}
        </Link>
      ),
    },
    {
      key: "payments",
      icon: <IoReceiptOutline size={20} />,
      label: (
        <Link to='/payments'>
          {intl.formatMessage({ id: "paymentsTransactions" })}
        </Link>
      ),
    },
    {
      key: "tips",
      icon: <GiReceiveMoney size={20} />,
      label: (
        <Link to='/tips'>{intl.formatMessage({ id: "tipsRecords" })}</Link>
      ),
    },
    {
      key: "games-wallet",
      icon: <RiCoupon2Fill size={20} color={COLORS.danger} />,
      label: (
        <Link to='/games-wallet'>
          {intl.formatMessage({ id: "gamesWallet" })}
        </Link>
      ),
    },
    {
      key: "accountStatement",
      icon: <IoBarcodeOutline size={20} />,
      label: (
        <Link to='/accountStatement'>
          {intl.formatMessage({ id: "accountStatement" })}
        </Link>
      ),
    },
    {
      key: "pendingPayments",
      icon: <BiError size={20} />,
      label: (
        <Link to='/pendingPayments'>
          {intl.formatMessage({ id: "pendingPayments" })}
        </Link>
      ),
    },
    {
      key: "allEmails",
      icon: <MdAlternateEmail size={20} />,
      label: <Link to='/allEmails'>{intl.formatMessage({ id: "email" })}</Link>,
    },
    {
      key: "whatsapp-setting",
      icon: <WhatsAppOutlined size={20} />,
      label: (
        <Link to='/whatsapp-setting'>
          {intl.formatMessage({ id: "whatsappSetting" })}
        </Link>
      ),
    },
    {
      key: "delivery-requests",
      icon: <IoCarOutline size={20} />,
      label: (
        <Link to='/delivery-requests'>
          {intl.formatMessage({ id: "deliveryRequests" })}
        </Link>
      ),
    },
    {
      key: "app-marketing",
      icon: <BiGift size={20} color={COLORS.danger} />,
      label: (
        <Link
          to='/app-marketing'
          style={{
            color: COLORS.danger,
          }}
        >
          {intl.formatMessage({ id: "appMarketing" })}
        </Link>
      ),
    },
    {
      key: "app-games",
      icon: <MdGames size={20} color={COLORS.danger} />,
      label: (
        <Link
          to='/app-games'
          style={{
            color: COLORS.danger,
          }}
        >
          {intl.formatMessage({ id: "appGames" })}
        </Link>
      ),
    },
    {
      key: "language",
      icon: <IoLanguageOutline size={20} />,
      label: (
        <CustomButton
          onClick={() => dispatch(changeLanguageAction())}
          text={intl.formatMessage({ id: "oppLanguage" })}
          type='text'
        />
      ),
    },
    {
      key: "signOut",
      icon: <IoLogOut size={20} />,
      label: (
        <CustomButton
          onClick={() => dispatch(logOutAction())}
          text={intl.formatMessage({ id: "signout" })}
          type='text'
        />
      ),
    },
  ];

  return (
    <Menu
      items={items}
      theme='light'
      mode='inline'
      defaultSelectedKeys={["1"]}
    />
  );
};

export default SuperUserSide;
