import { Col, Divider, Form, Input, Modal, Row, Select, Space } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import phoneCodes from "../../../helpers/phoneCodes.json";
import { useSelector } from "react-redux";
import { checkIfValidPhone } from "../../../helpers";
import openNotification from "../../../Components/Notifications";
import { createSuperUser, updateSuperUser } from "../../../API/fetch";

const { Option } = Select;

const prefixSelector = (
  <Form.Item name='prefix' noStyle>
    <Select
      style={{
        width: 150,
      }}
    >
      {phoneCodes.map((info) => {
        return (
          <Option key={`${info.dial_code}-${info.name}`} value={info.dial_code}>
            {info.dial_code} {info.name}
          </Option>
        );
      })}
    </Select>
  </Form.Item>
);

function SuperUserModal({
  showSuperUser,
  setShowSuperUser,
  loading,
  setLoading,
  getUsersData,
  isEdit,
  selectedUser,
}) {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { restaurants } = useSelector((state) => state.restaurants);
  const [form] = Form.useForm();

  const addSuperUser = async (values) => {
    try {
      setLoading(true);
      const fullPhone = `${values.prefix.substring(1)}${values.phone}`;
      const data = {
        name: values.name,
        password: values.password,
        phone: fullPhone,
        restaurantIds: values.restaurants,
      };
      const res = await createSuperUser(data);
      openNotification({
        title: "success",
        description: "updated successfully",
        type: "success",
        rtl: true,
      });
      form.resetFields();
      getUsersData();
      setShowSuperUser(false);
    } catch (error) {
      let message = "";
      if (error?.response?.data?.error === "phone number already used") {
        message = intl.formatMessage({ id: "phonealreadyused" });
      }
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: message
          ? message
          : intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl: true,
      });
      setLoading(false);
    }
  };
  const editSuperUser = async (values) => {
    try {
      let data;
      const fullPhone = `${values.prefix.substring(1)}${values.phone}`;
      if (values.password.trim() !== "") {
        data = {
          user: {
            name: values.name,
            password: values.password,
            phone: fullPhone,
            restaurantIds: values.restaurants,
          },
        };
      } else {
        data = {
          user: {
            name: values.name,
            phone: fullPhone,
            restaurantIds: values.restaurants,
          },
        };
      }
      const res = await updateSuperUser(data, selectedUser._id);
      openNotification({
        title: "success",
        description: "updated successfully",
        type: "success",
        rtl: true,
      });

      form.resetFields();
      getUsersData();
      setShowSuperUser(false);
    } catch (error) {
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl: true,
      });
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <div>
      <CustomButton
        className={`btn-text border-8 px-5 pb-5`}
        type='primary'
        text={intl.formatMessage({ id: "addSuperUser" })}
        onClick={() => {
          setShowSuperUser(true);
        }}
      />
      <Modal
        title={intl.formatMessage({ id: "addSuperUser" })}
        open={showSuperUser}
        destroyOnClose={true}
        maskClosable={true}
        onCancel={() => {
          setShowSuperUser(false);
        }}
        cancelText={intl.formatMessage({ id: "cancel" })}
        okText={intl.formatMessage({ id: "save" })}
        footer={null}
      >
        <Form
          name='Add-reservation'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={isEdit ? editSuperUser : addSuperUser}
          initialValues={{
            name: isEdit ? selectedUser?.name : "",
            phone: isEdit ? selectedUser?.phone.substring(3) : "",
            prefix: isEdit
              ? phoneCodes.find(
                  (c) =>
                    c?.dial_code.substring(1) ===
                    selectedUser?.phone?.substring(0, 3)
                )?.dial_code
              : phoneCodes[0].dial_code,
            password: "",
            restaurants: isEdit
              ? selectedUser?.restaurants?.map((el) => el?._id)
              : [],
          }}
        >
          {() => (
            <>
              <Row type='flex' justify='space-between'>
                <Col md={23} sm={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "superUserName" })}
                    className='mb-1'
                    name='name'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Input className={"input"} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={23} sm={24}>
                  <Form.Item
                    name='phone'
                    label={intl.formatMessage({ id: "phone" })}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorphone" }),
                      },
                      {
                        validator: (_, value) => {
                          const prefix = form.getFieldValue("prefix");
                          if (!prefix) {
                            return Promise.reject(
                              intl.formatMessage({ id: "errorphone" })
                            );
                          }
                          let valid = checkIfValidPhone(`${prefix}${value}`);
                          if (valid) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              intl.formatMessage({ id: "errorphone" })
                            );
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      addonBefore={rtl ? null : prefixSelector}
                      addonAfter={rtl ? prefixSelector : null}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row type='flex' justify='space-between'>
                <Col md={23} sm={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "password" })}
                    className='mb-1'
                    name='password'
                    rules={[
                      {
                        required: isEdit ? false : true,
                        message: intl.formatMessage({ id: "errorpassword" }),
                      },
                    ]}
                  >
                    <Input className={"input"} type='text' />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row type='flex' justify='space-between'>
                <Col md={23} sm={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "superUserRestaurants" })}
                    className='mb-1'
                    name='restaurants'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Select
                      mode='multiple'
                      style={{ width: "100%" }}
                      placeholder=' select restaurants'
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={restaurants.map((el) => {
                        return {
                          label: el?.name,
                          value: el._id,
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />
              <Space direction='horizontal'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    type='text'
                    text={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                    onClick={() => {
                      setShowSuperUser(false);
                      form.resetFields();
                    }}
                  />
                </Form.Item>
              </Space>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
}

export default SuperUserModal;
