import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Typography, Spin, Popconfirm } from "antd";
import { useIntl } from "react-intl";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { deleteUserFromDB, getUsers } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { setUsersAction } from "../../redux/reducers/restaurantsReducer";
import {
  SUBSCRIPTION_TYPES,
  TAG_COLOR_BY_SUBSCRIPTION_TYPES,
} from "../../Types";
import { MdVerified } from "react-icons/md";
import { Link } from "react-router-dom";
import { Table } from "ant-table-extensions";
import { IoRefreshOutline } from "react-icons/io5";
import { customerExport } from "../../helpers";


dayjs.extend(isBetween);
const { Text, Title } = Typography;
const Users = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.restaurants);
  const { rtl } = useSelector((state) => state.settings);

  const [loading, setLoading] = useState(true);
  const [formattedUsers, setFormattedUsers] = useState([]);

  useEffect(() => {
    if (!users?.length) {
      getUsersData();
    } else {
      setLoading(false);
    }
  }, [users]);

  const getUsersData = async () => {
    try {
      setLoading(true);
      const res = await getUsers();
      if (res?.data?.users) {
        dispatch(setUsersAction(res.data.users));
        setLoading(false);
        openNotification({
          title: "success",
          description: "users loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const deleteUser = async (id) => {
    try {
      setLoading(true);
      const res = await deleteUserFromDB(id);
      if (res?.data?.status === "success") {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        getUsersData();
        return;
      }
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });

      setLoading(false);
    } catch (error) {
      console.log("Errror", error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record) => {
        return (
          <Link to={`/restaurants//${record.restaurantId}`}>{number}</Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      width: 160,
      render: (name, record) => {
        return (
          <Link
            to={
              record.restaurantId ? `/restaurants/${record.restaurantId}` : ""
            }
          >
            {record.didPaid ? (
              <MdVerified
                style={{ marginLeft: 10 }}
                size={18}
                color={COLORS.primary}
              />
            ) : null}
            {name}
          </Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      width: 150,
      key: "phone",
      render: (phone) => {
        return (
          <Text
            copyable={{
              // remove the first three digits from phone;
              text: phone ? phone.slice(3) : "",
            }}
          >
            {phone}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "restaurantName" }),
      dataIndex: "restaurantName",
      key: "restaurantName",
      render: (name) => {
        return (
          <Text type={name !== "N/A" ? "" : "danger"} className='my-font'>
            {name}
          </Text>
        );
      },
      onFilter: (value, record) => {
        if (value === "N/A") {
          return record.restaurantName === "N/A";
        } else {
          return record.restaurantName !== "N/A";
        }
      },
      filters: [
        {
          text: "N/A",
          value: "N/A",
        },
        {
          text: "Other",
          value: "Other",
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "subscription" }),
      dataIndex: "subscriptionType",
      key: "subscriptionType",
      render: (subscriptionType) => {
        return (
          <Tag color={TAG_COLOR_BY_SUBSCRIPTION_TYPES[subscriptionType]}>
            {intl.formatMessage({
              id: subscriptionType ? subscriptionType : "N/A",
            })}
          </Tag>
        );
      },
      onFilter: (value, record) =>
        record?.subscriptionType?.indexOf(value) === 0,
      filters: [
        {
          text: intl.formatMessage({ id: "MENU" }),
          value: "MENU",
        },
        {
          text: intl.formatMessage({ id: "STANDER" }),
          value: "STANDER",
        },
        {
          text: intl.formatMessage({ id: "SILVER" }),
          value: "SILVER",
        },
        {
          text: intl.formatMessage({ id: "GOLD" }),
          value: "GOLD",
        },
      ],
    },
    // {
    //   title: intl.formatMessage({ id: "referCode" }),
    //   dataIndex: "refer",
    //   key: "refer",
    // },
    // add action delete;
    {
      title: intl.formatMessage({ id: "actions" }),
      dataIndex: "actions",
      key: "actions",
      render: (actions, record) => {
        return (
          <Popconfirm
            title={
              <Text className='my-font'>
                {intl.formatMessage({ id: "confirmDelete" })}
              </Text>
            }
            okText={intl.formatMessage({ id: "delete" })}
            okButtonProps={{
              style: { color: COLORS.danger, fontFamily: "Cairo" },
            }}
            okType='text'
            cancelText={intl.formatMessage({ id: "cancel" })}
            cancelButtonProps={{
              style: { border: "none", fontFamily: "Cairo" },
            }}
            onConfirm={() => deleteUser(record?._id)}
            disabled={
              record?.didPaid ||
              loading ||
              record.restaurantId ||
              record?.phone?.includes("509000036")
            }
            loading={loading}
          >
            <Text
              type='danger'
              className='pointer my-font'
              disabled={
                record?.didPaid ||
                loading ||
                record.restaurantId ||
                record?.phone?.includes("509000036")
              }
            >
              {intl.formatMessage({ id: "delete" })}
            </Text>
          </Popconfirm>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "startSubscriptionDate" }),
      dataIndex: "startSubscriptionDate",
      key: "startSubscriptionDate",
      sorter: (a, b) =>
        new Date(a.startSubscriptionDate) - new Date(b.startSubscriptionDate),
      showSorterTooltip: false,
    },
    {
      title: 'isActive',
      dataIndex: "isActive",
      key: "isActive",
      render: (dateString, record) => {
        let background = COLORS.success;
        let color = COLORS.white;
        let text = "Active";
        // check if date is less than now;
        let date = record.endSubscriptionDate;
        if (dayjs(date).isBefore(dayjs())) {
          background = COLORS.danger;
          text = "Not Active";
        } // check if date is within the next 8 days;
        else if (dayjs(date).isBetween(dayjs(), dayjs().add(8, "days"))) {
          background = COLORS.warning;
          text = "Soon Will End";
        }
        return {
          props: {
            style: { background },
          },
          children: <Text style={{ color }}>{text}</Text>,
        };
      },
      sorter: (a, b) =>
        new Date(a.endSubscriptionDate) - new Date(b.endSubscriptionDate),
      showSorterTooltip: false,
      onFilter: (value, record) => {
        let date = record.endSubscriptionDate;
        if (value === "active") {
          return dayjs(date).isAfter(dayjs().add(8, "days"));
        } else if (value === "lessThanWeek") {
          return dayjs(date).isBetween(dayjs(), dayjs().add(8, "days"));
        } else if (value === "expired") {
          return dayjs(date).isBefore(dayjs());
        }

        return false;
      },
      filters: [
        {
          text: intl.formatMessage({ id: "active" }),
          value: "active",
        },
        {
          text: intl.formatMessage({ id: "lessThanWeek" }),
          value: "lessThanWeek",
        },
        {
          text: intl.formatMessage({ id: "expired" }),
          value: "expired",
        },
      ],
    },
    // remove dubelcated values;
    {
      title: intl.formatMessage({ id: "subscriptionEndDate" }),
      dataIndex: "endSubscription",
      key: "endSubscription",
      fixed: "right",
      width: 140,
      style: {
        backgroundColor: "red",
      },
      render: (dateString, record) => {
        let background = COLORS.success;
        let color = COLORS.white;
        // check if date is less than now;
        let date = record.endSubscriptionDate;
        if (dayjs(date).isBefore(dayjs())) {
          background = COLORS.danger;
        } // check if date is within the next 8 days;
        else if (dayjs(date).isBetween(dayjs(), dayjs().add(8, "days"))) {
          background = COLORS.warning;
        }
        return {
          props: {
            style: { background },
          },
          children: (
            <Text style={{ color }}>{dayjs(date).format("DD/MM/YYYY")}</Text>
          ),
        };
      },
      sorter: (a, b) =>
        new Date(a.endSubscriptionDate) - new Date(b.endSubscriptionDate),
      showSorterTooltip: false,
      onFilter: (value, record) => {
        let date = record.endSubscriptionDate;
        if (value === "active") {
          return dayjs(date).isAfter(dayjs().add(8, "days"));
        } else if (value === "lessThanWeek") {
          return dayjs(date).isBetween(dayjs(), dayjs().add(8, "days"));
        } else if (value === "expired") {
          return dayjs(date).isBefore(dayjs());
        }

        return false;
      },
      filters: [
        {
          text: intl.formatMessage({ id: "active" }),
          value: "active",
        },
        {
          text: intl.formatMessage({ id: "lessThanWeek" }),
          value: "lessThanWeek",
        },
        {
          text: intl.formatMessage({ id: "expired" }),
          value: "expired",
        },
      ],
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (users?.length) {
      const formatted = users.map((user, i) => {
        let textIsActive = 'Active';
        let end = dayjs(user?.subscription?.subscriptionEnd).format(
          "DD-MM-YYYY"
        );
        let date = end;
        if (dayjs(date).isBefore(dayjs())) {
          textIsActive = "Not Active";
        } // check if date is within the next 8 days;
        else if (dayjs(date).isBetween(dayjs(), dayjs().add(8, "days"))) {
          textIsActive = "Soon Will End";
        }
        return {
          key: user._id,
          number: i + 1,
          _id: user._id,
          isActive: textIsActive,
          name: user.name,
          phone: user?.phone,
          didPaid: user?.restaurant?.didPaid,
          restaurantId: user?.restaurant?._id,
          startSubscriptionDate: dayjs(
            user?.subscription?.subscriptionEnd
          ).format("DD-MM-YYYY"),
          restaurantName: user?.restaurant?.name
            ? user?.restaurant?.name
            : "N/A",
          subscriptionType:
            SUBSCRIPTION_TYPES[`${user?.subscription?.subscriptionType}`],
          refer: user?.refer?.name ?? "N/A",
          endSubscription: dayjs(user?.subscription?.subscriptionEnd).format(
            "DD-MM-YYYY"
          ),
          endSubscriptionDate: dayjs(user?.subscription?.subscriptionEnd),
          userObject: { ...user },
        };
      });
      setFormattedUsers(formatted);
    }
    setLoading(false);
  }, [rtl, users]);
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "users" })}
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getUsersData}
          />
        </div>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedUsers?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          exportable
          exportableProps={{
            showColumnPicker: true,
            disabled: loading || formattedUsers?.length === 0,
            btnProps: {
              icon: <FileExcelOutlined />,
              type: "primary",
              style: { borderRadius: 7 },
              onClick: () =>
              customerExport({
                data: formattedUsers,
                columns,
                fileName: "Users Report",
                loadingSetter: setLoading,
              }),
            },
            children: (
              <Text
                className='my-font'
                style={{
                  color:
                    formattedUsers?.length === 0 ? "#dddddd" : COLORS.white,
                }}
              >
                {intl.formatMessage({ id: "export" })}
              </Text>
            ),
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedUsers}
          scroll={{ x: 'max-content' }}
        />
      </Spin>
    </div>
  );
};

export default Users;
