import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { useIntl } from "react-intl";

const OneTableRow = ({
  table,
  posTables,
  linkTableLocal,
  setLinkTableLocal,
}) => {
  const intl = useIntl();
  const [foodicsId, setFoodicsId] = useState("");
  useEffect(() => {
    if (linkTableLocal?.length) {
      let a = linkTableLocal?.find((b) => b?._id === table._id)?.posTableId;
      setFoodicsId(a);
    }
  }, [linkTableLocal, table, posTables]);
  const selectTable = (id) => {
    if (!id) return;
    // check if id === 'NON;
    if (id === "NON") {
      // filter link tablees and remove this table;
      let newLinkTables = linkTableLocal.map((b) => {
        if (table._id === b._id) {
          return {
            ...b,
            posTableId: "",
            posTableName: "",
            posSectionId: "",
            posSectionName: "",
            posTableStatus: "",
          };
        }
        return b;
      });
      setLinkTableLocal(newLinkTables);
      return;
    }
    let otherTableInfo = posTables?.find((t) => t?.id === id);

    let index = linkTableLocal?.findIndex((b) => b?._id === table._id);
    if (index === -1 || !otherTableInfo) {
      throw new Error("table not found");
    } else {
      let newLinkTableLocal = [...linkTableLocal];
      newLinkTableLocal[index].posTableId = id;
      newLinkTableLocal[index].posTableName = otherTableInfo.name;
      newLinkTableLocal[index].posSectionId = otherTableInfo.sectionId;
      newLinkTableLocal[index].posSectionName = otherTableInfo.sectionName;
      setLinkTableLocal(newLinkTableLocal);
    }
  };
  return (
    <Select
      showSearch
      placeholder='Select a person'
      optionFilterProp='children'
      className={"my-font"}
      style={{
        width: "100%",
      }}
      value={foodicsId}
      onChange={selectTable}
    >
      <Select.Option
        value={"NON"}
        style={{
          width: "100%",
        }}
      >
        <span className='my-font'>{intl.formatMessage({ id: "choose" })}</span>
      </Select.Option>
      {posTables?.map((posTable, i) => {
        return (
          <Select.Option
            style={{
              width: "100%",
            }}
            className='my-font'
            value={posTable.id}
            key={posTable.id}
          >
            {posTable.name} - {posTable?.sectionName}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default OneTableRow;
