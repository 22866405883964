import { UploadOutlined } from "@ant-design/icons";
import {
    Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import apiUrl from "../../../API/config";
import { editWalletRestaurant, getBanks, uploadToMyFatoorahAPI } from "../../../API/fetch";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import { BANKS } from "./helpers";

const acceptedTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "application/pdf",
  ];
const { Title, Text } = Typography;
const PaymentInformation = ({ restaurant, setRestaurant }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  // const [banks, setBanks] = useState();
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [savedWallet, setSavedWallet] = useState({});
  const [CommercialRegister, setCommercialRegister] = useState([]);
  const [CommercialLicense, setCommercialLicense] = useState([]);
  const [ArticlesOfAssociation, setArticlesOfAssociation] = useState([]);
  const [ids, setIds] = useState([]);
  const [BankAccountLetter, setBankAccountLetter] = useState([]);

  // useEffect(() => {
  //   // if bank is empty, fetch banks
  //   if (banks.length === 0) {
  //     fetchBanks();
  //   }
  // }, []);
  useEffect(() => {
    if (restaurant?.wallet) {
        getWalletInfo();
      }
  }, [restaurant])
  // console.log(restaurant?.wallet)

  // const fetchBanks = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await getBanks();
  //     if (res.data?.banks?.length > 0) {
  //       setBanks(res.data.banks);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     openNotification({
  //       type: "error",
  //       description: intl.formatMessage({ id: "error" }),
  //       rtl,
  //       title: "Error",
  //     });
  //     console.log(error);
  //   }
  // };

  const getWalletInfo = async () => {
    setLoading(true);
    try {
        console.log("get wallet info")
      setSavedWallet(restaurant?.wallet);
      form.setFieldsValue({
        BankId: +restaurant?.wallet.BankId,
        BankAccountHolderName: restaurant?.wallet.BankAccountHolderName,
        // BankAccount: restaurant?.wallet.BankAccount,
        Iban: restaurant?.wallet.Iban,
        Email: restaurant?.email,
      });
      if (restaurant?.wallet?.CommercialRegister) {
        setCommercialRegister([
          {
            // uid: restaurant?.wallet.CommercialRegister.id,
            name:
              intl.formatMessage({ id: "CommercialRegister" }) +
              " " +
              restaurant?.name,
            status: "done",
            url: restaurant?.wallet.CommercialRegister,
          },
        ]);
      }
      if (restaurant?.wallet?.CommercialLicense) {
        setCommercialLicense([
          {
            // uid: restaurant?.wallet.CommercialLicense.id,
            name:
              intl.formatMessage({ id: "CommercialLicense" }) +
              " " +
              restaurant?.name,
            status: "done",
            url: restaurant?.wallet.CommercialLicense,
          },
        ]);
      }
      if (restaurant?.wallet?.ArticlesOfAssociation) {
        setArticlesOfAssociation([
          {
            // uid: restaurant?.wallet.ArticlesOfAssociation.id,
            name:
              intl.formatMessage({ id: "ArticlesOfAssociation" }) +
              " " +
              restaurant?.name,
            status: "done",
            url: restaurant?.wallet.ArticlesOfAssociation,
          },
        ]);
      }
      if (restaurant?.wallet?.BankAccountLetter) {
        setBankAccountLetter([
          {
            // uid: restaurant?.wallet.BankAccountLetter.id,
            name:
              intl.formatMessage({ id: "BankAccountLetter" }) +
              " " +
              restaurant?.name,
            status: "done",
            url: restaurant?.wallet.BankAccountLetter,
          },
        ]);
      }
      if (restaurant?.wallet?.ids) {
        setIds([
          {
            // uid: restaurant?.wallet.ids.id,
            name: intl.formatMessage({ id: "ids" }) + " " + restaurant?.name,
            status: "done",
            url: restaurant?.wallet.ids,
          },
        ]);
      }

      setLoading(false);
    } catch (error) {
      console.log("Error getting wallet info", error);
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      const data = {
        ...values,
      };
      setLoading(true);
      const res = await editWalletRestaurant(data, restaurant?._id);
      if (res.data?.wallet) {
        const copy = { ...restaurant };
        copy.wallet = res.data.wallet;
        setRestaurant(copy);
        openNotification({
          type: "success",
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  const beforeUpload = (file) => {
    if (!acceptedTypes.includes(file?.type) || !file) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    if (file.type?.includes("image")) {
      if (file.size / 1000000 > 2.2) {
        message.error(intl.formatMessage({ id: "limit2Mb" }));
        return false;
      }
    }

    return true;
  };
  const handleChangeImages = ({ fileList, file }, type) => {
    if (file.status === "uploading") {
      setLoading(true); // show loading;
    } else if (file.status === "done") {
      // upload success;
      setLoading(false); // hide loading;
      if (file.response) {
        if (file?.response) {
          openNotification({
            title: intl.formatMessage({ id: "savedSuccessfully" }),
            type: "success",
            rtl,
          });
        }
      }
    } else if (file.status === "error") {
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
    if (type === "CommercialRegister") {
      setCommercialRegister([file]);
    } else if (type === "CommercialLicense") {
      setCommercialLicense([file]);
    } else if (type === "ArticlesOfAssociation") {
      setArticlesOfAssociation([file]);
    } else if (type === "BankAccountLetter") {
      setBankAccountLetter([file]);
    } else if (type === "ids") {
      setIds([file]);
    }
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const uploadToMyFatoorah = async (file) => {
      try {
        setLoading(true);
        const res = await uploadToMyFatoorahAPI(restaurant?._id, file);
        if (res.data?.status === "success") {
          setLoading(false);
          openNotification({
            title: intl.formatMessage({ id: "savedSuccessfully" }),
            type: "success",
            rtl,
          });
        }
      }catch(error){
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
  }
  return (
    <div
      style={{
        paddingRight: 20,
        paddingLeft: 20,
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <Modal
        open={previewVisible}
        title={rtl ? restaurant?.name : restaurant?.name_en}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img
          alt={restaurant?.name}
          style={{ width: "100%" }}
          src={previewImage}
        />
      </Modal>
      <Form
        name='edit-add-my-fatoorah'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          email: restaurant?.email
        }}
      >
        {() => {
          return (
            <>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Date and payment */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "bankAccountName" })}
                    tooltip={
                      <Text className='my-font'>
                        {intl.formatMessage({ id: "bankAccountNameTool" })}
                      </Text>
                    }
                    className='mb-1'
                    name='BankAccountHolderName'
                    style={{ borderRadius: 20 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input  />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                <Form.Item
                    label={intl.formatMessage({ id: "email" })}
                    className='mb-1'
                    name='email'
                    style={{ borderRadius: 20 }}
                    rules={[
                      // {
                      //   required: true,
                      // },
                      {
                        // validate email
                        type: "email",
                        message: "Check you email",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Date and payment */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "iban" })}
                    className='mb-1'
                    name='Iban'
                    style={{ borderRadius: 20 }}
                    rules={[
                      {
                        required: true,
                      },
                      {
                        // check if the first two letters are SA
                        pattern: /^SA/,
                        message: 'IBAN must start with "SA"',
                      },
                      {
                        // check if the length is 24
                        pattern: /^.{24}$/,
                        message: "IBAN must be 24 characters long",
                      },
                    ]}
                  >
                    <Input  />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name='BankId'
                    label={intl.formatMessage({ id: "chooseBank" })}
                    style={{ borderRadius: 20 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select  className='my-font'>
                      {BANKS?.map((bank) => (
                        <Select.Option
                          className='my-font'
                          key={bank.value}
                          value={bank.value}
                        >
                          {rtl ? bank?.text : bank.text_en}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
             
              <Row className='mt-4'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                    text={intl.formatMessage({ id: "save" })}
                    style={{ minWidth: 100 }}
                  />
                </Form.Item>
              </Row>
              <Divider />
            </>
          );
        }}
      </Form>
      <Title level={5}>{intl.formatMessage({ id: "files" })}</Title>
      {!restaurant?.wallet ? (
        <Text className='my-font' type='secondary'>
          {intl.formatMessage({ id: "youCanUploadFilesAfterSavePayment" })}
        </Text>
      ) : null}
      <Row className='mt-2' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
        <Col md={12} sm={24} xs={24}>
          <Text
            className='my-font'
            type={savedWallet?.CommercialRegister ? "primary" : "danger"}
          >
            {intl.formatMessage({ id: "CommercialRegister" })}
          </Text>
          <CustomButton 
            text={"Upload to MyFatoorah"}
            style={{
              backgroundColor: "#ffffff",
            }}
            disabled={loading || !restaurant?.wallet || !savedWallet?.CommercialRegister}
            onClick={() => uploadToMyFatoorah("CommercialRegister")}
          />
          <br />
          <Upload 
            action={`${apiUrl}/wallets/document/admin?restaurantId=${restaurant?._id}&name=CommercialRegister`}
            headers={{ authorization: "Bearer " + user.token }}
            name='file'
            fileList={CommercialRegister}
            listType='file'
            onChange={(info) => handleChangeImages(info, "CommercialRegister")}
            onPreview={onPreview}
            beforeUpload={beforeUpload}
            multiple={false}
            maxCount={0}
            accept='image/jpg,image/jpeg,image/png,application/pdf'
            disabled={loading || !restaurant?.wallet}
          >
            <Button
              type='primary'
              className='border-8 mt-2'
              icon={<UploadOutlined />}
              disabled={
                loading || !restaurant?.wallet
              }
            >
              {intl.formatMessage({ id: "clickToUpload" })}
            </Button>
          </Upload>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Text
            className='my-font'
            type={savedWallet?.CommercialLicense ? "primary" : "danger"}
          >
            {intl.formatMessage({ id: "CommercialLicense" })}
          </Text>
          <CustomButton 
            text={"Upload to MyFatoorah"}
            style={{
              backgroundColor: "#ffffff",
            }}
            disabled={loading || !restaurant?.wallet || !savedWallet?.CommercialLicense}
            onClick={() => uploadToMyFatoorah("CommercialLicense")}
          />
          <br />
          <Upload
            action={`${apiUrl}/wallets/document/admin?restaurantId=${restaurant?._id}&name=CommercialLicense`}
            headers={{ authorization: "Bearer " + user.token }}
            name='file'
            fileList={CommercialLicense}
            listType='file'
            onChange={(info) => handleChangeImages(info, "CommercialLicense")}
            onPreview={onPreview}
            beforeUpload={beforeUpload}
            multiple={false}
            maxCount={0}
            accept='image/jpg,image/jpeg,image/png,application/pdf'
            disabled={loading || !restaurant?.wallet}
          >
            <Button
              type='primary'
              className='border-8 mt-2'
              disabled={
                loading || !restaurant?.wallet
              }
              icon={<UploadOutlined />}
            >
              {intl.formatMessage({ id: "clickToUpload" })}
            </Button>
          </Upload>
        </Col>
      </Row>
      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }} className='mt-5'>
        <Col md={12} sm={24} xs={24}>
          <Text
            className='my-font'
            type={savedWallet?.ArticlesOfAssociation ? "primary" : "danger"}
          >
            {intl.formatMessage({ id: "ArticlesOfAssociation" })}
          </Text>
          <CustomButton 
            text={"Upload to MyFatoorah"}
            style={{
              backgroundColor: "#ffffff",
            }}
            disabled={loading || !restaurant?.wallet || !savedWallet?.ArticlesOfAssociation}
            onClick={() => uploadToMyFatoorah("ArticlesOfAssociation")}
          />
          <br />
          <Upload
            action={`${apiUrl}/wallets/document/admin?restaurantId=${restaurant?._id}&name=ArticlesOfAssociation`}
            headers={{ authorization: "Bearer " + user.token }}
            name='file'
            fileList={ArticlesOfAssociation}
            listType='file'
            onChange={(info) =>
              handleChangeImages(info, "ArticlesOfAssociation")
            }
            onPreview={onPreview}
            beforeUpload={beforeUpload}
            multiple={false}
            maxCount={0}
            accept='image/jpg,image/jpeg,image/png,application/pdf'
            disabled={loading || !restaurant?.wallet}
          >
            <Button
              type='primary'
              className='border-8 mt-2'
              disabled={
                loading || !restaurant?.wallet
              }
              icon={<UploadOutlined />}
            >
              {intl.formatMessage({ id: "clickToUpload" })}
            </Button>
          </Upload>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Text
            className='my-font'
            type={savedWallet?.ids ? "primary" : "danger"}
          >
            {intl.formatMessage({ id: "ids" })}
          </Text>
          <CustomButton 
            text={"Upload to MyFatoorah"}
            style={{
              backgroundColor: "#ffffff",
            }}
            disabled={loading || !restaurant?.wallet || !savedWallet?.ids}
            onClick={() => uploadToMyFatoorah("ids")}
          />
          <br />
          <Upload
            action={`${apiUrl}/wallets/document/admin?restaurantId=${restaurant?._id}&name=ids`}
            headers={{ authorization: "Bearer " + user.token }}
            name='file'
            fileList={ids}
            listType='file'
            onChange={(info) => handleChangeImages(info, "ids")}
            onPreview={onPreview}
            beforeUpload={beforeUpload}
            multiple={false}
            maxCount={0}
            accept='image/jpg,image/jpeg,image/png,application/pdf'
            disabled={loading || !restaurant?.wallet}
          >
            <Button
              type='primary'
              disabled={
                loading || !restaurant?.wallet
              }
              className='border-8 mt-2'
              icon={<UploadOutlined />}
            >
              {intl.formatMessage({ id: "clickToUpload" })}
            </Button>
          </Upload>
        </Col>
      </Row>
      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }} className='mt-5'>
        <Col md={12} sm={24} xs={24}>
          <Text
            className='my-font'
            type={savedWallet?.BankAccountLetter ? "primary" : "danger"}
          >
            {intl.formatMessage({ id: "BankAccountLetter" })}
          </Text>
          <CustomButton 
            text={"Upload to MyFatoorah"}
            style={{
              backgroundColor: "#ffffff",
            }}
            disabled={loading || !restaurant?.wallet || !savedWallet?.BankAccountLetter}
            onClick={() => uploadToMyFatoorah("BankAccountLetter")}
          />
          <br />
          <Upload
            action={`${apiUrl}/wallets/document/admin?restaurantId=${restaurant?._id}&name=BankAccountLetter`}
            headers={{ authorization: "Bearer " + user.token }}
            name='file'
            fileList={BankAccountLetter}
            listType='file'
            onChange={(info) => handleChangeImages(info, "BankAccountLetter")}
            onPreview={onPreview}
            beforeUpload={beforeUpload}
            multiple={false}
            maxCount={0}
            accept='image/jpg,image/jpeg,image/png,application/pdf'
            disabled={loading || !restaurant?.wallet}
          >
            <Button
              type='primary'
              disabled={
                loading || !restaurant?.wallet
              }
              className='border-8 mt-2'
              icon={<UploadOutlined />}
            >
              {intl.formatMessage({ id: "clickToUpload" })}
            </Button>
          </Upload>
        </Col>
        <Col md={12} sm={24} xs={24}></Col>
      </Row>
    </div>
  );
};

export default PaymentInformation;
