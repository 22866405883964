import React, { useState, useEffect } from "react";
import { Select, Tag, Typography } from "antd";
import { useIntl } from "react-intl";

const { Text } = Typography;
const OneBranchRow = ({
  posBranches,
  branch,
  linkedBranches,
  setLinkBranchedLocal,
}) => {
  const intl = useIntl();
  const [marnId, setMarnId] = useState("");
  useEffect(() => {
    if (linkedBranches?.length && !marnId) {
      let a = branch?.posBranchId;
      if (!a) {
        a = "NON";
      }
      setMarnId(a);
    }
  }, [linkedBranches, branch, posBranches]);

  const selectBranch = (id) => {
    // console.log(linkedBranches);
    // setMarnId(id);
    if (!id) return;
    if (id === "NON") {
      let newLinkedBranches = linkedBranches.map((b) => {
        if (b._id === branch._id) {
          return {
            ...b,
            posBranchId: "",
            posBranchName: "",
          };
        }
        return b;
      });
      setLinkBranchedLocal(newLinkedBranches);
      setMarnId(id);
      return;
    }
    // // check if the link branch does exist in the array;
    let index = linkedBranches?.findIndex((b) => b._id === branch._id);
    if (index === -1) {
      throw new Error("branch not found");
    } else {
      const otherBranchInfo = posBranches?.find(
        (t) => t?.marnLocationKey === id
      );
      let newLinkedBranches = [...linkedBranches];
      newLinkedBranches[index].posBranchId = id;
      newLinkedBranches[index].posBranchName = otherBranchInfo.marnName;
      setLinkBranchedLocal(newLinkedBranches);
      setMarnId(id);
    }
  };
  return (
    <>
      <Select
        className={"my-font"}
        style={{
          width: "100%",
        }}
        value={marnId}
        onChange={(id) => {
          selectBranch(id);
        }}
      >
        <Select.Option
          value={"NON"}
          style={{
            width: "100%",
          }}
        >
          <span className='my-font'>
            {intl.formatMessage({ id: "choose" })}
          </span>
        </Select.Option>
        {posBranches?.map((posBranch, i) => {
          return (
            <Select.Option
              style={{
                width: "100%",
              }}
              className='my-font'
              value={posBranch.marnLocationKey}
              key={posBranch.marnLocationKey}
            >
              {posBranch.marnName}
            </Select.Option>
          );
        })}
      </Select>
      <Text type='secondary'>
        Tables in Marn :{" "}
        <Tag color='blue'>
          {
            posBranches?.find((t) => t?.marnLocationKey === marnId)?.tables
              ?.length
          }{" "}
          {intl.formatMessage({ id: "tables" })}
        </Tag>
      </Text>
    </>
  );
};

export default OneBranchRow;
