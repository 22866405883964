import React, { useState, useEffect } from "react";
import { Form, Row, Col, Spin, Checkbox, InputNumber, Divider } from "antd";
import openNotification from "../../../Components/Notifications";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import { updateRestaurant } from "../../../API/fetch";

const EditRestaurantForm = ({ restaurant, setRestaurant }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const data = {
        restaurant: {
          didPaid: values.didPaid,
          percentageForIwaiter: values.percentageForIwaiter,
          percentageForIWaiterMADA: values.percentageForIWaiterMADA,
          percentageForIWaiterTabby: values.percentageForIWaiterTabby,
          iwaiterTipPercentage: values.iwaiterTipPercentage,
          DoesTipActive: values.DoesTipActive,
          totalAmount: values.totalAmount,
          canEditPaymentType: values?.canEditPaymentType,
          isWithApp: values?.isWithApp,
          appPercentage: values?.appPercentage,
        },
      };
      if (!values?.canEditPaymentType) {
        // disabled payment cash;
        // data.restaurant.payCashDelivery = false;
        // data.restaurant.payOnlineDelivery = true;
        // data.restaurant.payCashPickup = false;
        // data.restaurant.payOnlinePickup = true;
        // data.restaurant.payCashDineIn = false;
        // data.restaurant.payOnlineDineIn = true;
      }
      const res = await updateRestaurant(restaurant._id, data);
      if (res?.data?.restaurant) {
        setRestaurant(res.data.restaurant?.restaurant);
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 500);
  }, [restaurant?.percentageForIwaiter]);

  return (
    <Spin spinning={loading}>
      <Form
        name='edit-rest-form'
        form={form}
        className='form-container'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          didPaid: restaurant?.didPaid,
          percentageForIwaiter: restaurant?.percentageForIwaiter,
          percentageForIWaiterMADA: restaurant?.percentageForIWaiterMADA,
          percentageForIWaiterTabby: restaurant?.percentageForIWaiterTabby,
          iwaiterTipPercentage: restaurant?.iwaiterTipPercentage,
          DoesTipActive: restaurant?.DoesTipActive,
          totalAmount: restaurant?.totalAmount,
          canEditPaymentType: restaurant?.canEditPaymentType,
          isWithApp: restaurant?.isWithApp,
          appPercentage: restaurant?.appPercentage,
        }}
      >
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}></Row>
        <Row className='mt-4' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          {/* iwaiter percentage */}

          <Col md={9} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "percentageForIwaiter" })}
              className='mb-1'
              name='percentageForIwaiter'
              style={{ borderRadius: 20 }}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col md={9} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "percentageForIWaiterMADA" })}
              className='mb-1'
              name='percentageForIWaiterMADA'
              style={{ borderRadius: 20 }}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <Form.Item
              style={{
                position: "relative",
                top: 25,
              }}
              name='canEditPaymentType'
              valuePropName='checked'
            >
              <Checkbox disabled={!restaurant?.wallet?._id}>
                {intl.formatMessage({ id: "canEditPaymentType" })}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col md={9} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "percentageForIWaiterTabby" })}
              className='mb-1'
              name='percentageForIWaiterTabby'
              style={{ borderRadius: 20 }}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          {/* total amount */}
          <Col md={9} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "totalAmount" })}
              className='mb-1'
              name='totalAmount'
              style={{ borderRadius: 20 }}
            >
              <InputNumber style={{ width: "100%" }} disabled={true} />
            </Form.Item>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <Form.Item
              style={{
                position: "relative",
                top: 25,
              }}
              name='didPaid'
              valuePropName='checked'
            >
              <Checkbox>{intl.formatMessage({ id: "paidStatus" })}</Checkbox>
            </Form.Item>
          </Col>
          <Col md={9} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "iwaiterTipPercentage" })}
              className='mb-1'
              name='iwaiterTipPercentage'
              style={{ borderRadius: 20 }}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <Form.Item
              style={{
                position: "relative",
                top: 25,
              }}
              name='DoesTipActive'
              valuePropName='checked'
            >
              <Checkbox>{intl.formatMessage({ id: "DoesTipActive" })}</Checkbox>
            </Form.Item>
          </Col>
          <Divider />
          <Col md={6} sm={24} xs={24}>
            <Form.Item
              style={{
                position: "relative",
                top: 25,
              }}
              name='isWithApp'
              valuePropName='checked'
            >
              <Checkbox>{intl.formatMessage({ id: "isWithApp" })}</Checkbox>
            </Form.Item>
          </Col>
          <Col md={9} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "appPercentage" })}
              className='mb-1'
              name='appPercentage'
              style={{ borderRadius: 20 }}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Form.Item>
            <CustomButton
              htmlType='submit'
              className={`btnRegister btn-text border-8`}
              loading={loading}
              type='primary'
              text={intl.formatMessage({ id: "save" })}
              style={{ minWidth: 100 }}
            />
          </Form.Item>
        </Row>
      </Form>
    </Spin>
  );
};

export default EditRestaurantForm;
