import React from "react";
import { Descriptions,  Typography } from "antd";
import { useIntl } from "react-intl";

const { Title,  } = Typography;
const GeneralInfo = ({ information }) => {
  const intl = useIntl();
  return (
    <Descriptions
      title={intl.formatMessage({ id: "marnInfo" })}
      layout='vertical'
      bordered
      className='my-font'
    >
      <Descriptions.Item label={intl.formatMessage({ id: "totalOrders" })}>
        {information?.orders?.total}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "sentSuccessful" })}>
        {information?.orders?.sent}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "successfulRate" })}>
        <Title level={4}> % {information?.orders?.successRate}</Title>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default GeneralInfo;
