import React, { useState, useEffect } from "react";
import { Descriptions, Select } from "antd";
import { useIntl } from "react-intl";

const OneBranchRow = ({
  posBranches,
  branch,
  linkedBranches,
  setLinkBranchedLocal,
}) => {
  const intl = useIntl();
  useEffect(() => {
    if (linkedBranches?.length) {
      let a = linkedBranches.find((b) => b.localId === branch._id)?.otherId;
      setFoodicsId(a);
    }
  }, [linkedBranches, branch, posBranches]);
  const [foodicsId, setFoodicsId] = useState("");

  const selectBranch = (id) => {
    if (!id) return;
    // check if id === 'NON;
    if (id === "NON") {
      // filter link branches and remove this branch;
      let newLinkBranches = linkedBranches.filter(
        (b) => b.localId !== branch._id
      );
      setLinkBranchedLocal(newLinkBranches);
      return;
    }
    // check if the link branch does exist in the array;
    let index = linkedBranches.findIndex((b) => b.localId === branch._id);
    if (index === -1) {
      setLinkBranchedLocal([
        ...linkedBranches,
        { localId: branch._id, otherId: id, tables: [] },
      ]);
    } else {
      let newLinkedBranches = [...linkedBranches];
      newLinkedBranches[index].otherId = id;
      newLinkedBranches[index].tables = [];
      setLinkBranchedLocal(newLinkedBranches);
    }
  };
  return (
    <Select
      className={"my-font"}
      style={{
        width: "100%",
      }}
      value={foodicsId}
      onChange={selectBranch}
    >
      <Select.Option
        value={"NON"}
        style={{
          width: "100%",
        }}
      >
        <span className='my-font'>{intl.formatMessage({ id: "choose" })}</span>
      </Select.Option>
      {posBranches?.map((posBranch, i) => {
        return (
          <Select.Option
            style={{
              width: "100%",
            }}
            className='my-font'
            value={posBranch.foodicsBranchId}
            key={posBranch.foodicsBranchId}
          >
            {posBranch.foodicsBranchName}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default OneBranchRow;
