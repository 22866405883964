import { Col, Divider, Form, Input, Modal, Radio, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import openNotification from "../../../Components/Notifications";
import CustomButton from "../../../Components/CustomButton";
import { useIntl } from "react-intl";
import { checkIfValidPhone } from "../../../helpers";
import { createPayRestaurant } from "../../../API/payAppFetch";
import { addPayAppRestaurantAction } from "../../../redux/reducers/payAppReducer";

function AddRestaurant({ open, setOpen }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      console.log(values);
      setLoading(true);
      const data = {
        userData: {
          name: values.userName,
          phone: `966${values.phone}`,
          email: values.email,
          password: values.password,
        },
        restaurantData: {
          name: values.restaurantNameAr,
          name_en: values.restaurantNameEn,
          tablesNumber: values.tablesNumber,
        },
      };
      const res = await createPayRestaurant(data);
      if (res?.data?.restaurant) {
        openNotification({
          type: "success",
          title: "Success",
          message: "Restaurant added successfully",
          rtl,
        });
        dispatch(addPayAppRestaurantAction(res.data.restaurant));
        form.resetFields();
        setOpen(false);
      }
    } catch (error) {
      let message = error?.message;
      if (error.response?.data?.message) {
        message = error.response.data.message;
      }
      openNotification({
        type: "error",
        title: "Error",
        description: message,
        rtl,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      title='إضافة مطعم'
      width={"80%"}
      footer={null}
      onCancel={() => setOpen(false)}
    >
      <Form form={form} layout='vertical' onFinish={onFinish}>
        {() => {
          return (
            <>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label='اسم الشخص'
                    name='userName'
                    rules={[
                      {
                        required: true,
                        message: "Please input the title of collection!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='phone'
                    label='رقم الجوال'
                    rules={[
                      {
                        required: true,
                        message: "Please input the title of collection!",
                      },
                      {
                        validator: (_, value) => {
                          const prefix = "+966";
                          if (!prefix) {
                            return Promise.reject(
                              intl.formatMessage({ id: "errorphone" })
                            );
                          }
                          let valid = checkIfValidPhone(`${prefix}${value}`);
                          if (valid) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              intl.formatMessage({ id: "errorphone" })
                            );
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      type='number'
                      addonBefore={rtl ? "" : "966"}
                      addonAfter={!rtl ? "" : "966"}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                {/* Email */}
                <Col span={12}>
                  <Form.Item
                    name='email'
                    label='البريد الإلكتروني'
                    rules={[
                      {
                        required: true,
                        message: "Please input the title of collection!",
                      },
                    ]}
                  >
                    <Input type='email' />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='password'
                    label='كلمة المرور'
                    rules={[
                      {
                        required: true,
                        message: "Please input the title of collection!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[16, 16]}>
                {/* restaurant name in ar and en */}
                <Col span={12}>
                  <Form.Item
                    name='restaurantNameAr'
                    label='اسم المطعم بالعربي'
                    rules={[
                      {
                        required: true,
                        message: "Please input the title of collection!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='restaurantNameEn'
                    label='اسم المطعم بالإنجليزي'
                    rules={[
                      {
                        required: true,
                        message: "Please input the title of collection!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label='عدد الطاولات'
                    name='tablesNumber'
                    rules={[
                      {
                        required: true,
                        message: "Please input the title of collection!",
                      },
                    ]}
                  >
                    <Input type='number' />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <CustomButton
                  text={"إضافة"}
                  loading={loading}
                  htmlType='submit'
                  type='primary'
                  disabled={loading}
                />
              </Form.Item>
            </>
          );
        }}
      </Form>
    </Modal>
  );
}

export default AddRestaurant;
