import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Divider,
  Space,
  DatePicker,
  TimePicker,
  Upload,
  Button,
  message,
  Row,
  Col,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import openNotification from "../../Components/Notifications";
import CustomButton from "../../Components/CustomButton";
import dayjs from "dayjs";
import { patchCompetition, postCompetition } from "../../API/fetch";
import { UploadOutlined } from "@ant-design/icons";
import apiUrl from "../../API/config";

const { Option } = Select;
const { Dragger } = Upload;
const { Title, Text } = Typography;
const acceptedTypes = ["image/png", "image/jpg", "image/jpeg"];
const EditCompetition = ({ show, hide, competition, restaurants }) => {
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const { rtl } = useSelector((state) => state.settings);
  const [isNew, setIsNew] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [filterRestaurants, setFilterRestaurants] = useState(restaurants);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [wideImage, setWideImage] = useState([]);
  const [fullImage, setFullImage] = useState([]);
  const [wideImage_en, setWideImage_en] = useState([]);
  const [fullImage_en, setFullImage_en] = useState([]);

  useEffect(() => {
    if (competition?._id) {
      setIsNew(false);
      form.resetFields();
      if (competition?.wideImage) {
        setWideImage([
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: competition?.wideImage,
          },
        ]);
      }
      if (competition?.fullImage) {
        setFullImage([
          {
            uid: "-11",
            name: "image.png",
            status: "done",
            url: competition?.fullImage,
          },
        ]);
      }
      if (competition?.wideImage_en) {
        setWideImage_en([
          {
            uid: "-12",
            name: "image.png",
            status: "done",
            url: competition?.wideImage_en,
          },
        ]);
      }
      if (competition?.fullImage_en) {
        setFullImage_en([
          {
            uid: "-13",
            name: "image.png",
            status: "done",
            url: competition?.fullImage_en,
          },
        ]);
      }

      setTimeout(() => {
        form.resetFields();
      }, 300);
    } else {
      setIsNew(true);
      form.resetFields();
      setTimeout(() => {
        form.resetFields();
      }, 300);
    }
  }, [competition, show, hide]);

  const editCompetition = async (values) => {
    try {
      setLoading(true);
      const data = {
        competition: {
          ...values,
          endDate: dayjs(values.endDate).toDate(),
          startDate: dayjs(values.startDate).toDate(),
          timeToExecute: dayjs(values.timeToExecute).format("HH:mm"),
        },
      };
      const res = await patchCompetition(competition._id, data);

      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  const addCompetition = async (values) => {
    try {
      setLoading(true);
      const data = {
        competition: {
          ...values,
          endDate: dayjs(values.endDate).toDate(),
          startDate: dayjs(values.startDate).toDate(),
          timeToExecute: dayjs(values.timeToExecute).format("HH:mm"),
        },
      };
      const res = await postCompetition(data);

      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const beforeUpload = (file) => {
    if (!acceptedTypes.includes(file?.type) || !file) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    if (file.type?.includes("image")) {
      if (file.size / 1000000 > 2.2) {
        message.error(intl.formatMessage({ id: "limit2Mb" }));
        return false;
      }
    }

    return true;
  };
  const handleChangeImages = ({ fileList, file }, type) => {
    if (file.status === "uploading") {
      setLoading(true); // show loading;
    } else if (file.status === "done") {
      // upload success;
      setLoading(false); // hide loading;
      if (file.response) {
        if (file?.response) {
          openNotification({
            title: intl.formatMessage({ id: "savedSuccessfully" }),
            type: "success",
            rtl,
          });
        }
      }
    } else if (file.status === "error") {
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
    if (type === "wideImage") {
      setWideImage([file]);
      //   setCommercialRegister([file]);
    } else if (type === "fullImage") {
      setFullImage([file]);
    } else if (type === "wideImage_en") {
      setWideImage_en([file]);
      //   setCommercialRegister([file]);
    } else if (type === "fullImage_en") {
      setFullImage_en([file]);
    }
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {intl.formatMessage({ id: isNew ? "addCompetition" : "edit" })}
        </Title>
      }
      centered
      open={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => {
        setIsNew(true);
        hide();
      }}
      footer={null}
      width={"90vw"}
    >
      <Modal
        open={previewVisible}
        title={competition?.name}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img
          alt={competition?.name}
          style={{ width: "100%" }}
          src={previewImage}
        />
      </Modal>
      <Form
        name='editCompetition'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={isNew ? addCompetition : editCompetition}
        initialValues={
          isNew
            ? {
                restaurantIds: [],
                countOfWinners: 0,
                startDate: dayjs(),
                endDate: dayjs(),
                timeToExecute: dayjs(),
                runningType: "DAILY",
                name: "",
                name_en: "",
                wideImage_en: "",
                fullImage_en: "",
                conditions_en: "",
                wideImage: "",
                fullImage: "",
                conditions: "",
                status: "Active",
                prizeType: "CASH",
                prizeAmount: 0,
              }
            : {
                restaurantIds: competition?.restaurantIds?.map((v) => v?._id),
                countOfWinners: competition?.countOfWinners,
                startDate: dayjs(competition?.startDate),
                endDate: dayjs(competition?.endDate),
                timeToExecute: dayjs(competition?.timeToExecute, "HH:mm"),
                runningType: competition?.runningType,
                status: competition?.status,
                name_en: competition?.name_en,
                name: competition?.name,
                wideImage: competition?.wideImage,
                wideImage_en: competition?.wideImage_en,
                fullImage: competition?.fullImage,
                fullImage_en: competition?.fullImage_en,
                conditions: competition?.conditions,
                conditions_en: competition?.conditions_en,
                prizeType: competition?.prizeType,
                prizeAmount: competition?.prizeAmount,
              }
        }
      >
        {() => {
          return (
            <>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "justName" })}
                    className='mb-1'
                    name='name'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Input className={"input"} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "nameEn" })}
                    className='mb-1'
                    name='name_en'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Input className={"input"} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "conditions" })}
                    tooltip='يجب أن يكون نهاية كل سطر نقطة'
                    className='mb-1'
                    name='conditions'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={"الشروط باللغة الانجليزية"}
                    tooltip='يجب أن يكون نهاية كل سطر نقطة'
                    className='mb-1'
                    name='conditions_en'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label={intl.formatMessage({ id: "restaurants" })}
                className='mb-1'
                name='restaurantIds'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Select
                  onSearch={(e) => {
                    if (e?.length) {
                      let copy = [...restaurants];
                      copy = copy.filter((el) => {
                        return (
                          el?.name
                            ?.toLocaleLowerCase()
                            ?.includes(e?.toLocaleLowerCase()) ||
                          el?.name_en
                            ?.toLocaleLowerCase()
                            ?.includes(e?.toLocaleLowerCase()) ||
                          el?.phone?.includes(e)
                        );
                      });
                      setFilterRestaurants(copy);
                    } else {
                      setFilterRestaurants(restaurants);
                    }
                  }}
                  filterOption={false}
                  allowClear
                  className='my-font'
                  showSearch
                  mode='multiple'
                >
                  {filterRestaurants?.map((restaurant) => (
                    <Option
                      key={restaurant?._id}
                      value={restaurant._id}
                      className='my-font'
                    >
                      {restaurant.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "countOfWinners" })}
                    className='mb-1'
                    name='countOfWinners'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      className={"input"}
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "status" })}
                    className='mb-1'
                    name='status'
                  >
                    <Select className='my-font'>
                      <Option value='Active' className='my-font'>
                        {intl.formatMessage({ id: "Active" })}
                      </Option>
                      <Option value='disabled' className='my-font'>
                        {intl.formatMessage({ id: "disabled" })}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "dateStart" })}
                    className='mb-1'
                    name='startDate'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "dateEnd" })}
                    className='mb-1'
                    name='endDate'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "timeToWin" })}
                    className='mb-1'
                    name='timeToExecute'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <TimePicker
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "runningType" })}
                    className='mb-1'
                    name='runningType'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Select className='my-font'>
                      <Option value='DAILY' className='my-font'>
                        {intl.formatMessage({ id: "daily" })}
                      </Option>
                      <Option value='WEEKLY' className='my-font'>
                        {intl.formatMessage({ id: "weekly" })}
                      </Option>
                      <Option value='MONTHLY' className='my-font'>
                        {intl.formatMessage({ id: "monthly" })}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "prizeType" })}
                    className='mb-1'
                    name='prizeType'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <Select className='my-font'>
                      <Option value='CASH' className='my-font'>
                        مبالغ نقدية
                      </Option>
                      <Option disabled value='FREE_ITEM' className='my-font'>
                        مشتريات مجانية
                      </Option>
                      <Option
                        disabled
                        value='CASH_AND_FREE_ITEM'
                        className='my-font'
                      >
                        مشتريات مجانية وكاش
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "prizeAmount" })}
                    className='mb-1'
                    name='prizeAmount'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorname" }),
                      },
                    ]}
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      className={"input"}
                      min={0}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Text
                    className='my-font'
                    type={competition?.wideImage ? "primary" : "danger"}
                  >
                    صورة عريضة
                  </Text>
                  <br />
                  <Upload
                    action={`${apiUrl}/competitions/image/admin?competitionId=${competition?._id}&name=wideImage`}
                    headers={{ authorization: "Bearer " + user.token }}
                    name='file'
                    fileList={wideImage}
                    listType='file'
                    onChange={(info) => handleChangeImages(info, "wideImage")}
                    onPreview={onPreview}
                    beforeUpload={beforeUpload}
                    multiple={false}
                    maxCount={0}
                    accept='image/jpg,image/jpeg,image/png'
                    disabled={loading || !competition?._id}
                  >
                    <Button
                      type='primary'
                      className='border-8 mt-2'
                      icon={<UploadOutlined />}
                      disabled={loading || !competition?._id}
                    >
                      {intl.formatMessage({ id: "clickToUpload" })}
                    </Button>
                  </Upload>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Text
                    className='my-font'
                    type={competition?.fullImage ? "primary" : "danger"}
                  >
                    صورة طويلة
                  </Text>
                  <br />
                  <Upload
                    action={`${apiUrl}/competitions/image/admin?competitionId=${competition?._id}&name=fullImage`}
                    headers={{ authorization: "Bearer " + user.token }}
                    name='file'
                    fileList={fullImage}
                    listType='file'
                    onChange={(info) => handleChangeImages(info, "fullImage")}
                    onPreview={onPreview}
                    beforeUpload={beforeUpload}
                    multiple={false}
                    maxCount={0}
                    accept='image/jpg,image/jpeg,image/png'
                    disabled={loading || !competition?._id}
                  >
                    <Button
                      type='primary'
                      className='border-8 mt-2'
                      icon={<UploadOutlined />}
                      disabled={loading || !competition?._id}
                    >
                      {intl.formatMessage({ id: "clickToUpload" })}
                    </Button>
                  </Upload>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Text
                    className='my-font'
                    type={competition?.wideImage_en ? "primary" : "danger"}
                  >
                    صورة عريضة للانجليزي
                  </Text>
                  <br />
                  <Upload
                    action={`${apiUrl}/competitions/image/admin?competitionId=${competition?._id}&name=wideImage_en`}
                    headers={{ authorization: "Bearer " + user.token }}
                    name='file'
                    fileList={wideImage_en}
                    listType='file'
                    onChange={(info) =>
                      handleChangeImages(info, "wideImage_en")
                    }
                    onPreview={onPreview}
                    beforeUpload={beforeUpload}
                    multiple={false}
                    maxCount={0}
                    accept='image/jpg,image/jpeg,image/png'
                    disabled={loading || !competition?._id}
                  >
                    <Button
                      type='primary'
                      className='border-8 mt-2'
                      icon={<UploadOutlined />}
                      disabled={loading || !competition?._id}
                    >
                      {intl.formatMessage({ id: "clickToUpload" })}
                    </Button>
                  </Upload>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Text
                    className='my-font'
                    type={competition?.fullImage_en ? "primary" : "danger"}
                  >
                    صورة طويلة للغة الانجليزية
                  </Text>
                  <br />
                  <Upload
                    action={`${apiUrl}/competitions/image/admin?competitionId=${competition?._id}&name=fullImage_en`}
                    headers={{ authorization: "Bearer " + user.token }}
                    name='file'
                    fileList={fullImage_en}
                    listType='file'
                    onChange={(info) =>
                      handleChangeImages(info, "fullImage_en")
                    }
                    onPreview={onPreview}
                    beforeUpload={beforeUpload}
                    multiple={false}
                    maxCount={0}
                    accept='image/jpg,image/jpeg,image/png'
                    disabled={loading || !competition?._id}
                  >
                    <Button
                      type='primary'
                      className='border-8 mt-2'
                      icon={<UploadOutlined />}
                      disabled={loading || !competition?._id}
                    >
                      {intl.formatMessage({ id: "clickToUpload" })}
                    </Button>
                  </Upload>
                </Col>
              </Row>
              <Divider />
              <Space direction='horizontal'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    type='text'
                    text={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                    onClick={() => {
                      setIsNew(true);
                      hide();
                    }}
                  />
                </Form.Item>
              </Space>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default EditCompetition;
