import { Descriptions, Typography, Badge, Avatar } from "antd";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import React, {useState, useEffect} from "react";
import { MdVerified } from "react-icons/md";
import { useIntl } from "react-intl";
import COLORS from "../../../Style/colors";
import { CLINT_URL } from "../../Restaurants";

dayjs.extend(isBetween);
const { Text, Title } = Typography;
const RestaurantInfo = ({ restaurant }) => {
  const [service, setService] = useState({});
  useEffect(() => {
    if(restaurant?.services?.length){
      let selected = restaurant.services[0];
     const a = restaurant.services.forEach((service) => {
      if(dayjs(service.serviceEnd).isAfter(dayjs(selected.serviceEnd))){
        selected = service;
      }
      });
      setService(selected);
    }
  }, [restaurant]);
  const intl = useIntl();
  return (
    <Descriptions
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title level={4}>
            {restaurant?.didPaid ? (
              <MdVerified
                style={{ marginLeft: 10, position: "relative", top: 7 }}
                size={24}
                color={COLORS.primary}
              />
            ) : null}
            {restaurant?.name}
          </Title>
          <Avatar
            src={restaurant?.logo?.length ? restaurant?.logo[0]?.url : null}
          />
        </div>
      }
      className='my-font'
      bordered
      column={{
        xxl: 4,
        xl: 3,
        lg: 2,
        md: 2,
        sm: 1,
        xs: 1,
      }}
    >
      <Descriptions.Item
        label={intl.formatMessage({ id: "subscriptionEndDate" })}
        style={{
          backgroundColor: dayjs(
            service.serviceEnd
          ).isBefore(dayjs())
            ? COLORS.danger
            : dayjs(service.serviceEnd).isBetween(
                dayjs(),
                dayjs().add(7, "days")
              )
            ? COLORS.warning
            : COLORS.success,
        }}
      >
        {dayjs(service.serviceEnd).format("DD/MM/YYYY")}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "justName" })}>
        {restaurant?.userInfo?.name}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "phone" })}>
        <Text copyable={{
           text: restaurant?.userInfo?.phone ? restaurant?.userInfo?.phone.slice(3) : "",
        }}>{restaurant?.userInfo?.phone}</Text>
      </Descriptions.Item>
      <Descriptions.Item
        label={`${intl.formatMessage({
          id: "phone",
        })} - ${intl.formatMessage({ id: "restaurant" })}`}
      >
        <Text copyable={{
           text: restaurant?.phone ? restaurant?.phone.slice(3) : "",
        }}>{restaurant?.phone}</Text>
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "email" })}>
        {restaurant?.email}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "branches" })}>
        {restaurant?.branches?.length}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "waiters" })}>
        {restaurant?.waiters}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "cityName" })}>
        {restaurant?.branches?.map(b => b?.cityName || 'N/A')?.join(', ')}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "paidStatus" })}>
        {restaurant?.didPaid ? (
          <Badge status='success' text='Paid' />
        ) : (
          <Badge status='error' text='Not Paid' />
        )}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "menu" })}>
        <a
          href={`${CLINT_URL}/${restaurant?.slug}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {intl.formatMessage({ id: "menu" })}
        </a>
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "tablesCount" })}>
        {restaurant?.tables}
      </Descriptions.Item>
      <Descriptions.Item
        label={`${intl.formatMessage({
          id: "ordersTotal",
        })} - ${intl.formatMessage({ id: "inHouse" })}`}
      >
        {restaurant?.ordersDinein}
      </Descriptions.Item>
      <Descriptions.Item
        label={`${intl.formatMessage({
          id: "amount",
        })} - ${intl.formatMessage({ id: "inHouse" })}`}
      >
        {restaurant?.totalDinin?.toFixed(2)}
      </Descriptions.Item>
      <Descriptions.Item
        label={`${intl.formatMessage({
          id: "ordersTotal",
        })} - ${intl.formatMessage({ id: "takeAway" })}`}
      >
        {restaurant?.ordersPickup}
      </Descriptions.Item>
      <Descriptions.Item
        label={`${intl.formatMessage({
          id: "amount",
        })} - ${intl.formatMessage({ id: "takeAway" })}`}
      >
        {restaurant?.totalPickup?.toFixed(2)}
      </Descriptions.Item>
      <Descriptions.Item
        label={intl.formatMessage({ id: "percentageForIwaiter" })}
      >
        {restaurant?.percentageForIwaiter}
      </Descriptions.Item>
      <Descriptions.Item
        label={intl.formatMessage({ id: "percentageForIWaiterMADA" })}
      >
        {restaurant?.percentageForIWaiterMADA}
      </Descriptions.Item>
      <Descriptions.Item
        label={intl.formatMessage({ id: "percentageForIWaiterTabby" })}
      >
        {restaurant?.percentageForIWaiterTabby}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "totalAmount" })}>
        {restaurant?.totalAmount}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default RestaurantInfo;
