import {Descriptions, Result, Select, Space, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import LinkedOption from "./LinkedOption";

const { Text, Title } = Typography;
const LinkedModifiers = ({
  foodicsModifiers,
  localFood,
  linkedArray,
  setLinkedArray,
}) => {
  const intl = useIntl();

  // create state array that contain each local modifier id and it is POS integration object;
  useEffect(() => {
    if (localFood?.modifiers?.length) {
      let initals = localFood.modifiers.map((m) => {
        return {
          _id: m._id,
          name: m.name,
          POSIntegration: m.POSIntegration?.find(
            (intg) => intg?.name === "foodics"
          ),
          options: m.options?.map((opt) => {
            return {
              ...opt,
              POSIntegration: opt.POSIntegration?.find(
                (intg) => intg?.name === "foodics"
              ),
            };
          }),
        };
      });
      setLinkedArray(initals);
    }
  }, [localFood]);

  return (
    <Descriptions
      title='IWAITER DATA'
      bordered
      column={1}
      style={{ marginTop: 25 }}
    >
      {localFood?.modifiers?.length ? (
        localFood?.modifiers?.map((modifier) => {
          return (
            <Descriptions.Item
              key={modifier._id}
              label={
                <Space direction='vertical'>
                  <Title level={5}>{modifier?.privateName}</Title>
                  <SelectFoodicsModifier
                    localModId={modifier._id}
                    foodicsModifiers={foodicsModifiers}
                    linkedArray={linkedArray}
                    setLinkedArray={setLinkedArray}
                  />
                  <Text
                    className='my-font'
                    type={modifier.minSelection > 0 ? "danger" : ""}
                  >
                    {intl.formatMessage({ id: "minSelection" })} -{" "}
                    {modifier.minSelection}
                  </Text>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "maxSelection" })} -{" "}
                    {modifier.maxSelection}
                  </Text>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "freeSelection" })} -{" "}
                    {modifier.numberOfFreeChoices}
                  </Text>
                </Space>
              }
            >
              {modifier.options?.map((option) => {
                return (
                  <LinkedOption
                    key={option._id}
                    localOption={option}
                    foodicsModifiers={foodicsModifiers}
                    localModifier={modifier}
                    localModId={modifier._id}
                    linkedArray={linkedArray}
                    setLinkedArray={setLinkedArray}
                  />
                );
              })}
            </Descriptions.Item>
          );
        })
      ) : (
        <Descriptions.Item>
          <Result
            status={"404"}
            title={intl.formatMessage({ id: "noModifiers" })}
            className='my-font'
          />
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

const SelectFoodicsModifier = ({
  localModId,
  foodicsModifiers,
  linkedArray,
  setLinkedArray,
}) => {
  const intl = useIntl();
  const [foodicsId, setFoodicsId] = useState("");

  useEffect(() => {
    if (linkedArray?.length) {
      const foodicsMod = linkedArray.find((mod) => mod._id === localModId);
      setFoodicsId(foodicsMod?.POSIntegration?.otherModifierId);
    }
  }, [localModId, linkedArray]);

  const onChange = (id) => {
    if (!id) return;
    // check if id === 'NON;
    if (id === "NON") {
      const newLinkedArray = linkedArray.map((mod) => {
        if (mod._id === localModId) {
          mod.POSIntegration = null;
          mod.options = mod.options.map((option) => {
            option.POSIntegration = [];
            return option;
          });
        }
        return mod;
      });
      setLinkedArray(newLinkedArray);
      return;
    }
    // get the full object from foodicsModifiers;
    const foodicsMod = foodicsModifiers.find((mod) => mod.foodicsModId === id);
    // update the linked array where is the _id === localModId;
    // set the full foodics object in POSIntegration;
    const newLinkedArray = linkedArray.map((mod) => {
      if (mod._id === localModId) {
        mod.POSIntegration = {
          name: "foodics",
          otherFreeSelection: foodicsMod.foodicsFreeOptions,
          otherMaxSelection: foodicsMod.foodicsMaxOptions,
          otherModifierId: foodicsMod.foodicsModId,
          otherMinSelection: foodicsMod.foodicsMinOptions,
        };
        mod.options = mod.options.map((option) => {
          option.POSIntegration = [];
          return option;
        });
      } 
      return mod;
    });
    setLinkedArray(newLinkedArray);
  };

  return (
    <>
      <Text className='my-font'>
        {intl.formatMessage({ id: "modifierNameInFoodics" })}
      </Text>
      <Select
        className={"my-font"}
        style={{
          width: "80%",
        }}
        value={foodicsId}
        onChange={onChange}
      >
        <Select.Option
          value={"NON"}
          style={{
            width: "100%",
          }}
        >
          <span className='my-font'>choose</span>
        </Select.Option>
        {foodicsModifiers?.map((foodicsModi) => {
          return (
            <Select.Option
              key={foodicsModi?.foodicsModId}
              value={foodicsModi?.foodicsModId}
              style={{
                width: "100%",
              }}
              className='my-font'
            >
              {foodicsModi?.foodicsModName}
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
};

export default LinkedModifiers;
