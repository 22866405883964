import React, { useState, useEffect } from "react";
import {  Select } from "antd";
import { useIntl } from "react-intl";

const OneTableRow = ({
  table,
  posTables,
  linkTableLocal,
  setLinkTableLocal,
}) => {
  const intl = useIntl();

  useEffect(() => {
    if (linkTableLocal?.length) {
      let a = linkTableLocal.find(
        (b) => b.localTableId === table._id
      )?.otherTableId;
      setFoodicsId(a);
    }
  }, [linkTableLocal, table, posTables]);
  const [foodicsId, setFoodicsId] = useState("");

  const selectTable = (id) => {
    if (!id) return;
    // check if id === 'NON;
    if (id === "NON") {
      // filter link tablees and remove this table;
      let newLinkTables = linkTableLocal.filter(
        (b) => b.localTableId !== table._id
      );
      setLinkTableLocal(newLinkTables);
      return;
    }
    let otherSectionId = posTables?.find((t) => t?.id === id)?.sectionId;
    // check if the link table does exist in the array;
    let index = linkTableLocal.findIndex((b) => b.localTableId === table._id);
    if (index === -1) {
      setLinkTableLocal([
        ...linkTableLocal,
        { localTableId: table._id, otherTableId: id, otherSectionId },
      ]);
    } else {
      let newLinkTableLocal = [...linkTableLocal];
      newLinkTableLocal[index].otherTableId = id;
      newLinkTableLocal[index].otherSectionId = otherSectionId;
      newLinkTableLocal[index].localTableId = table._id;
      setLinkTableLocal(newLinkTableLocal);
    }
  };
  return (
    <Select
      showSearch
      placeholder='Select a person'
      optionFilterProp='children'
      className={"my-font"}
      style={{
        width: "100%",
      }}
      value={foodicsId}
      onChange={selectTable}
    >
      <Select.Option
        value={"NON"}
        style={{
          width: "100%",
        }}
      >
        <span className='my-font'>{intl.formatMessage({ id: "choose" })}</span>
      </Select.Option>
      {posTables?.map((posTable, i) => {
        return (
          <Select.Option
            style={{
              width: "100%",
            }}
            className='my-font'
            value={posTable.id}
            key={posTable.id}
          >
            {posTable.name} - {posTable?.sectionName}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default OneTableRow;
