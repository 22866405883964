import {
  Descriptions,
  Divider,
  Modal,
  Popconfirm,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import openNotification from "../../../../Components/Notifications";
import {
  getIntegrationInformationApi,
  removeIntegrationPay,
  updateBranches,
} from "../../../../API/payAppFetch";
import { useIntl } from "react-intl";
import CustomButton from "../../../../Components/CustomButton";
import { useSelector } from "react-redux";
import COLORS from "../../../../Style/colors";
import OneBranchRow from "./Marn/OneBranchRow";
import { hasDuplicates } from "../../../../helpers";
import TablesMarn from "./Marn/TablesMarn";
import GeneralInfo from "./Foodics/GeneralInfo";
import BranchesFoodics from "./Foodics/Branches";
import TablesFoodics from "./Foodics/Tables";

const { Title, Text } = Typography;
function EditIntegrationPayAtTableModal({ open, setOpen, restaurant }) {
  const [loading, setLoading] = useState(false);
  const [integrationInformation, setIntegrationInformation] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    if (
      integrationInformation?.branches?.message ===
      "Request failed with status code 401"
    ) {
      setIsDeleted(true);
    }
  }, [restaurant, integrationInformation]);

  useEffect(() => {
    getIntegrationInformation();
  }, [open]);
  const getIntegrationInformation = async () => {
    try {
      setLoading(true);
      const res = await getIntegrationInformationApi(restaurant._id);
      setIntegrationInformation(res.data?.integration);
      openNotification({
        title: "success",
        description: "integration information",
        type: "success",
        rtl: true,
      });
    } catch (error) {
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      width={"90%"}
    >
      <Spin spinning={loading}>
        {restaurant?.integration?.posName === "Marn" ? (
          <MarnView
            restaurant={restaurant}
            integration={integrationInformation}
          />
        ) : restaurant?.integration?.posName === "Foodics" ? (
          <FoodicsView
            restaurant={restaurant}
            integration={integrationInformation}
            isDeleted={isDeleted}
          />
        ) : null}
      </Spin>
    </Modal>
  );
}

export default EditIntegrationPayAtTableModal;

const MarnView = ({ restaurant, integration }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [linkBranchedLocal, setLinkBranchedLocal] = useState(
    restaurant?.branches
  );

  const updateInformation = async ({ type }) => {
    try {
      if (!type) return;
      setLoading(true);
      let sendData = {};
      if (type === "tax") {
        sendData = {
          integration: {
            posTaxInfo: {
              token: restaurant.integration.posToken,
              restaurantId: restaurant?.integration.restaurantId,
              brandKey: restaurant?.integration.restaurantOtherId,
              locationKey: restaurant?.integration.posBranches[0].otherId,
            },
          },
          type: "posTaxInfo",
          company: "marn",
        };
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
      let res = {};
      //   const res = await updateIntegration(restaurant.integration._id, sendData);
      if (res?.data?.integration) {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const deleteIntegration = async () => {
    setLoading(true);
    try {
      const res = await removeIntegrationPay(restaurant._id);
      if (res?.data?.status === "success") {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        // navigate(-1);
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const updatePOSBranches = async () => {
    // check if we have dubelcated branches;

    const localIds = linkBranchedLocal.map((b) => b.localId);
    if (hasDuplicates(localIds)) {
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: "كل فرع يجب أن يرتبط بفرع واحد فقط",
        type: "error",
        rtl,
      });
      return;
    }
    const otherIds = linkBranchedLocal.map((b) => b.otherId);
    if (hasDuplicates(otherIds)) {
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: "كل فرع يجب أن يرتبط بفرع واحد فقط",
        type: "error",
        rtl,
      });
      return;
    }
    try {
      let branches = restaurant?.branches.map((branch) => {
        const linkedBranch = linkBranchedLocal?.find(
          (b) => b._id === branch._id
        );
        if (linkedBranch) {
          return {
            ...branch,
            posBranchId: linkedBranch.posBranchId,
            posBranchName: linkedBranch.posBranchName,
          };
        }
        return branch;
      });
      const data = {
        type: "branches",
        branches,
      };
      setLoading(true);
      const res = await updateBranches(restaurant._id, data);
      if (res?.data?.status === "success") {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div>
      <div>
        <Descriptions
          title={intl.formatMessage({ id: "branches" })}
          bordered
          className='my-font'
          style={{
            textAlign: "center",
          }}
          column={1}
        >
          <Descriptions.Item
            label='iwaiter'
            labelStyle={{ backgroundColor: COLORS.gray }}
            contentStyle={{ backgroundColor: COLORS.gray }}
          >
            Marn
          </Descriptions.Item>

          {restaurant?.branches?.map((branch, i) => {
            return (
              <Descriptions.Item label={branch.name} key={branch._id}>
                <OneBranchRow
                  branch={branch}
                  posBranches={integration?.branches}
                  linkedBranches={linkBranchedLocal}
                  setLinkBranchedLocal={setLinkBranchedLocal}
                />
              </Descriptions.Item>
            );
          })}
        </Descriptions>
        <CustomButton
          text={intl.formatMessage({ id: "save" })}
          className={`btn-text border-8 px-5 pb-5 mt-4`}
          loading={loading}
          disabled={loading}
          type='primary'
          onClick={updatePOSBranches}
        />
      </div>
      <Divider />
      <div>
        {restaurant?.branches.map((branch) => {
          const localTables = branch.tables;
          const branchName = branch.name;
          const localBranchId = branch._id;
          const posTables = integration?.branches?.find(
            (one) => one?.marnLocationKey === branch?.posBranchId
          )?.tables;
          return (
            <div
              key={branch?._id}
              style={{
                backgroundColor: COLORS.white,
                marginTop: 10,
                width: "100%",
                marginRight: "auto",
                marginLeft: "auto",
                borderRadius: 10,
                padding: 20,
              }}
              className='light-shadow'
            >
              <TablesMarn
                localTables={localTables}
                posTables={posTables}
                linkedTables={branch?.tables}
                loading={loading}
                branchName={branchName}
                updatePOSBranches={updatePOSBranches}
                linkedBranches={linkBranchedLocal}
                localBranchId={localBranchId}
                restaurantId={restaurant._id}
                restaurant={restaurant}
                setLoading={setLoading}
              />
            </div>
          );
        })}
        {/* <CustomButton
          text={intl.formatMessage({ id: "save" })}
          className={`btn-text border-8 px-5 pb-5 mt-4`}
          loading={loading}
          disabled={loading}
          type='primary'
          onClick={updatePOSBranches}
        /> */}
      </div>

      <Space
        direction='horizontal'
        style={{
          width: "100%",
          justifyContent: "space-around",
          marginTop: 20,
        }}
      >
        <CustomButton
          type='primary'
          loading={loading}
          onClick={() => updateInformation({ type: "tax", value: "" })}
          text={intl.formatMessage({ id: "grabTax" })}
          disabled={true}
        />
        <Popconfirm
          title={
            <Text className='my-font'>
              {intl.formatMessage({ id: "confirmDelete" })}
            </Text>
          }
          okText={intl.formatMessage({ id: "delete" })}
          okButtonProps={{
            style: { color: COLORS.danger, fontFamily: "Cairo" },
          }}
          okType='text'
          cancelText={intl.formatMessage({ id: "cancel" })}
          cancelButtonProps={{
            style: { border: "none", fontFamily: "Cairo" },
          }}
          onConfirm={deleteIntegration}
          disabled={!restaurant?.integration || loading}
          loading={loading}
        >
          <Text
            type='danger'
            className='pointer my-font'
            disabled={!restaurant?.integration || loading}
          >
            {intl.formatMessage({ id: "deleteIntegration" })}
          </Text>
        </Popconfirm>
      </Space>
    </div>
  );
};

const FoodicsView = ({ restaurant, integration, isDeleted }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [linkBranchedLocal, setLinkBranchedLocal] = useState(
    restaurant?.branches
  );

  const updateInformation = async ({ type }) => {
    try {
      if (!type) return;
      setLoading(true);
      let sendData = {};
      if (type === "tax") {
        sendData = {
          integration: {
            posTaxInfo: {
              token: restaurant.integration.posToken,
              restaurantId: restaurant?.integration.restaurantId,
              brandKey: restaurant?.integration.restaurantOtherId,
              locationKey: restaurant?.integration.posBranches[0].otherId,
            },
          },
          type: "posTaxInfo",
          company: "marn",
        };
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
      let res = {};
      //   const res = await updateIntegration(restaurant.integration._id, sendData);
      if (res?.data?.integration) {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const deleteIntegration = async () => {
    setLoading(true);
    try {
      const res = await removeIntegrationPay(restaurant._id);
      if (res?.data?.status === "success") {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        // navigate(-1);
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const updatePOSBranches = async () => {
    // check if we have dubelcated branches;

    const localIds = linkBranchedLocal.map((b) => b.localId);
    if (hasDuplicates(localIds)) {
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: "كل فرع يجب أن يرتبط بفرع واحد فقط",
        type: "error",
        rtl,
      });
      return;
    }
    const otherIds = linkBranchedLocal.map((b) => b.otherId);
    if (hasDuplicates(otherIds)) {
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: "كل فرع يجب أن يرتبط بفرع واحد فقط",
        type: "error",
        rtl,
      });
      return;
    }
    try {
      let branches = restaurant?.branches.map((branch) => {
        const linkedBranch = linkBranchedLocal?.find(
          (b) => b._id === branch._id
        );
        if (linkedBranch) {
          return {
            ...branch,
            posBranchId: linkedBranch.posBranchId,
            posBranchName: linkedBranch.posBranchName,
          };
        }
        return branch;
      });
      const data = {
        type: "branches",
        branches,
      };
      setLoading(true);
      const res = await updateBranches(restaurant._id, data);
      if (res?.data?.status === "success") {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div>
      {isDeleted ? (
        <Title level={3} type='danger'>
          تم حذف الربط من فودكس
        </Title>
      ) : null}

      <GeneralInfo
        information={integration?.information}
        integration={restaurant?.integration}
      />
      <BranchesFoodics
        linkedBranches={restaurant?.branches}
        posBranches={integration?.branches}
        localBranches={restaurant?.branches}
        updatePOSBranches={updatePOSBranches}
        loading={loading}
        setLoading={setLoading}
      />

      {isDeleted ||
      integration?.branches?.message === "Request failed with status code 401"
        ? null
        : restaurant?.branches.map((branch) => {
            const localTables = branch.tables;
            const branchName = branch.name;
            const localBranchId = branch._id;
            const posSections = integration?.branches?.find(
              (b) => b?.foodicsBranchId === branch?.posBranchId
            )?.foodicsBranchSections;
            const posTables = posSections?.flatMap((s) => {
              return s.tables?.map((table) => {
                return {
                  sectionId: s.sectionId,
                  sectionName: s.sectionName,
                  sectionNameAr: s?.sectionNameAr,
                  ...table,
                };
              });
            });
            return (
              <div
                key={branch?._id}
                style={{
                  backgroundColor: COLORS.white,
                  marginTop: 10,
                  width: "100%",
                  marginRight: "auto",
                  marginLeft: "auto",
                  borderRadius: 10,
                  padding: 20,
                }}
                className='light-shadow'
              >
                <TablesFoodics
                  localTables={localTables}
                  posTables={posTables}
                  linkedTables={branch?.tables}
                  loading={loading}
                  branchName={branchName}
                  updatePOSBranches={updatePOSBranches}
                  linkedBranches={restaurant?.integration?.posBranches}
                  localBranchId={localBranchId}
                  restaurantId={restaurant._id}
                  // setRestaurant={setRestaurant}
                  restaurant={restaurant}
                  setLoading={setLoading}
                />
              </div>
            );
          })}

      <Space
        direction='horizontal'
        style={{
          width: "100%",
          justifyContent: "space-around",
          marginTop: 20,
          flexWrap: "wrap",
        }}
      >
        <CustomButton
          type='primary'
          loading={loading}
          disabled={true}
          onClick={() => updateInformation({ type: "payment", value: "" })}
          text={intl.formatMessage({ id: "createPayment" })}
        />
        <Popconfirm
          title={
            <Text className='my-font'>
              {intl.formatMessage({ id: "confirmDelete" })}
            </Text>
          }
          okText={intl.formatMessage({ id: "delete" })}
          okButtonProps={{
            style: { color: COLORS.danger, fontFamily: "Cairo" },
          }}
          okType='text'
          cancelText={intl.formatMessage({ id: "cancel" })}
          cancelButtonProps={{
            style: { border: "none", fontFamily: "Cairo" },
          }}
          onConfirm={deleteIntegration}
          disabled={!restaurant?.integration || loading}
          loading={loading}
        >
          <Text
            type='danger'
            className='pointer my-font'
            disabled={!restaurant?.integration || loading}
          >
            {intl.formatMessage({ id: "deleteIntegration" })}
          </Text>
        </Popconfirm>
      </Space>
    </div>
  );
};
