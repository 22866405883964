import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Divider,
  Space,
  DatePicker,
  Row,
  Col,
  Typography,
  Tag,
  TimePicker,
  Popconfirm,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import openNotification from "../../../Components/Notifications";
import CustomButton from "../../../Components/CustomButton";
import { updateService } from "../../../API/fetch";
const { Option } = Select;
const { Text, Title, Link } = Typography;
const EditServiceModal = ({ show, hide, service, setRestaurant }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setTimeout(() => {
      form?.resetFields();
    }, 100);
  }, [service]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let end = dayjs(values.serviceEnd).endOf("day").toDate();
      let start = dayjs(values.serviceStart).startOf("day").toDate();
      const data = {
        ...values,
        serviceEnd: end,
        serviceStart: start,
      };
      const res = await updateService(service._id, data);
      if (res?.data?.restaurant) {
        // update services
        setRestaurant(res.data.restaurant);
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }

      setLoading(false);
      hide();
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        type: "error",
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        rtl,
      });
    }
  };

  return (
    <Modal
      title={
        <>
          <Title level={5} className='my-font'>
            {intl.formatMessage({ id: "edit" })}
          </Title>
        </>
      }
      centered
      open={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => {
        hide();
      }}
      footer={null}
    >
      <Form
        name='editService'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          price: service?.price,
          amountPaid: service?.amountPaid,
          serviceStart: dayjs(service?.serviceStart),
          serviceEnd: dayjs(service?.serviceEnd),
        }}
      >
        {() => {
          return (
            <>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Persons Count */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "amountPaid" })}
                    className='mb-1'
                    name='amountPaid'
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      className={"input"}
                      min={0}
                    />
                  </Form.Item>
                </Col>
                {/* Reservation Type */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "amount" })}
                    className='mb-1'
                    name='price'
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      className={"input"}
                      min={0}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "dateStart" })}
                    className='mb-1'
                    name='serviceStart'
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "dateEnd" })}
                    className='mb-1'
                    name='serviceEnd'
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Space direction='horizontal'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    type='text'
                    text={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                    onClick={() => {
                      hide();
                    }}
                  />
                </Form.Item>
              </Space>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default EditServiceModal;
