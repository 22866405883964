import { Select, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const {  Text } = Typography;

const LinkedOption = ({
  localOption,
  marnModifiers,
  localModifier,
  localModId,
  linkedArray,
  setLinkedArray,
}) => {
  const [options, setOptions] = useState([]);
  const [marnId, setMarnId] = useState("");
  const { rtl } = useSelector((state) => state.settings);

  useEffect(() => {
    // get marn modifier id from the link array; otherModifierId
    const linkedMod = linkedArray.find((mod) => mod._id === localModId);
    // get otherModifierId from linkedMod;
    const otherModifierId = linkedMod?.POSIntegration?.otherModifierId;
    // get the full object from marnModifiers;
    const marnMod = marnModifiers?.find(
      (mod) => mod?.marnModifierId === otherModifierId
    );
    setOptions(marnMod?.options ?? []);
    // id , name, nameAr, price,

    // check the value selected from linkedArray options where option id === localOption._id;
    const selectedOption = linkedMod?.options?.find(
      (option) => option?._id === localOption?._id
    );
    // get the POSIntegration from selectedOption;
    const POSIntegration = selectedOption?.POSIntegration;
    // get the marnId from POSIntegration;
    //     otherOptionId
    // otherTaxOptionId
    setMarnId(POSIntegration?.otherOptionId ?? "NON");
  }, [localModId, linkedArray]);

  const onChange = (id) => {
    // get marn modifier id from the link array; otherModifierId
    const linkedMod = linkedArray.find((mod) => mod._id === localModId);
    // get otherModifierId from linkedMod;
    const otherModifierId = linkedMod?.POSIntegration?.otherModifierId;
    // get the full object from marnModifiers;
    const marnMod = marnModifiers?.find(
      (mod) => mod?.marnModifierId === otherModifierId
    );
    // get marn full option object from marnMod;
    const marnOption = marnMod?.options?.find((option) => option?.id === id);
    // check the value selected from linkedArray options where option id === localOption._id;
    const selectedOption = linkedMod?.options?.find(
      (option) => option?._id === localOption?._id
    );
    let newPOSIntegrationOption = {
      ...selectedOption,
      name: "Marn",
      otherOptionId: id,
      otherTaxOptionId: "",
    };
    // create a new Linked modifier object;
    const newLinkedModifier = {
      ...linkedMod,
      options: linkedMod.options.map((option) => {
        if (option._id === localOption._id) {
          option.POSIntegration = newPOSIntegrationOption;
        }
        return option;
      }),
    };
    // update the inked array;
    const newLinkedArray = linkedArray.map((mod) => {
      if (mod._id === localModId) {
        return newLinkedModifier;
      }
      return mod;
    });
    setLinkedArray(newLinkedArray);
  };
  return (
    <div>
      <Text className='my-font'>
        {rtl ? localOption.name : localOption.name_en} - {localOption?.price}{" "}
        SAR
      </Text>
      <Select
        className={"my-font"}
        style={{
          width: "100%",
        }}
        value={marnId}
        onChange={onChange}
      >
        <Select.Option
          value={"NON"}
          style={{
            width: "100%",
          }}
        >
          <span className='my-font'>choose</span>
        </Select.Option>
        {options?.map((marnOption) => {
          return (
            <Select.Option
              key={marnOption?.id}
              value={marnOption?.id}
              style={{
                width: "100%",
              }}
              className='my-font'
            >
              {marnOption?.name} - {marnOption?.nameAR} - {marnOption?.price}{" "}
              SAR
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default LinkedOption;
