import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Typography,
  Divider,
  Space,
  Tag,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import openNotification from "../../../Components/Notifications";
import CustomButton from "../../../Components/CustomButton";
import dayjs from "dayjs";
import { createTransaction } from "../../../API/fetch";
const { Option } = Select;
const { Title, Text } = Typography;

export const TransactionsTypes = [
  {
    name: "Bank Al Riyadh",
    value: "Bank-AlRiyadh",
  },
  {
    name: "Bank AlBilad",
    value: "Bank-Albilad",
  },
  {
    name: "STC Pay",
    value: "STC-PAY",
  },
];

const EditPaymentModal = ({ show, hide, payment, transactionNumbers }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  // const [isNew, setIsNew] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 300);
  }, [payment, show, hide]);

  console.log(payment);

  const addTransaction = async (values) => {
    try {
      setLoading(true);

      let MADATransferTotal = 0;
      let TABBYTransferTotal = 0;
      let VISATransferTotal = 0;
      if (payment?.paymentObject?.madaTotal) {
        let percentage = 1.9;
        if (payment?.paymentObject?.percentageForIWaiterMADA) {
          percentage = payment?.paymentObject?.percentageForIWaiterMADA;
        }

        MADATransferTotal =
          payment?.paymentObject?.madaTotal -
          ((payment?.paymentObject?.madaTotal * percentage) / 100) * 1.15;
      }
      if (payment?.paymentObject?.tabbyTotal) {
        let percentage = 9;
        if (payment?.paymentObject?.percentageForIWaiterTabby) {
          percentage = payment?.paymentObject?.percentageForIWaiterTabby;
        }
        TABBYTransferTotal =
          payment?.paymentObject?.tabbyTotal -
          ((payment?.paymentObject?.tabbyTotal * percentage) / 100) * 1.15;
      }
      if (payment?.paymentObject?.visaTotal) {
        let percentage = 2.4;
        if (payment?.paymentObject?.percentageForIwaiter) {
          percentage = payment?.paymentObject?.percentageForIwaiter;
        }
        VISATransferTotal =
          payment?.paymentObject?.visaTotal -
          ((payment?.paymentObject?.visaTotal * percentage) / 100) * 1.15;
      }
      const data = {
        paymentTransaction: {
          restaurantId: payment?.restaurantId,
          source: values.source,
          isNewTransaction: true,
          transactionNumber: values.transactionNumber,
          transactionNumbers: transactionNumbers,
          amount: payment?.amountToTransfer,
          profit: payment?.iwaiterFeesWithVat,
          status: "Success",
          MADATotal: payment?.paymentObject?.madaTotal,
          TABBYTotal: payment?.paymentObject?.tabbyTotal,
          VISATotal: payment?.paymentObject?.visaTotal,
          MADATransferTotal: MADATransferTotal,
          TABBYTransferTotal: TABBYTransferTotal,
          VISATransferTotal: VISATransferTotal,
          total:
            payment?.paymentObject?.madaTotal +
            payment?.paymentObject?.tabbyTotal +
            payment?.paymentObject?.visaTotal,
          totalToTransfer:
            payment.amountToTransfer -
            (payment?.refundAmount + payment?.refundFees),
          VATFees: payment?.iwaiterFeesWithVat,
          refundFees: payment?.refundFees,
          totalRefundAmount: payment?.refundAmount,
          refundFeesWithoutVAT: payment?.refundFees / 1.15,
          // deliveryTotal: payment?.totalDeliveryAmount,
          // deliveryTotalToTransfer:
          //   payment?.deliveryAmountToTransferWithoutVat -
          // payment?.deliveryAmountVat,
          restaurantPaidForDelivery: payment?.deliveryRestaurantPaidAmount,
          // deliveryAmountVat: payment?.deliveryAmountVat,
          // deliveryCountOrders: payment?.iwaiterCommission / 2,
          subscriptionUnpaidFees: payment?.subscriptionUnpaidFees,
          totalSubscriptionFees: payment?.totalSubscriptionFees,
          totalSubscriptionPaidFees: payment?.totalSubscriptionPaidFees,
        },
      };
      const res = await createTransaction(data);
      if (res?.data?.transaction) {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setLoading(false);
        hide();
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    } catch (error) {
      console.log(error?.response?.data);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {payment?.name}
        </Title>
      }
      centered
      open={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => {
        hide();
      }}
      footer={null}
    >
      <Form
        name='addTransaction'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={addTransaction}
        initialValues={{
          name: payment?.name,
          // source: payment?.source,
          transactionNumber: payment?.transactions?.[0]?.transactionNumber,
          source:
            payment?.transactions?.[0]?.source || TransactionsTypes[0].value,
        }}
      >
        {() => {
          return (
            <>
              <Form.Item
                label={intl.formatMessage({ id: "transactionNumber" })}
                className='mb-1'
                name='transactionNumber'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Input
                  disabled={payment?.transactions?.[0]?.transactionNumber}
                  className={"input"}
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "transferType" })}
                className='mb-1'
                name='source'
              >
                <Select
                  className='my-font'
                  disabled={payment?.transactions?.[0]?.transactionNumber}
                >
                  {TransactionsTypes.map((item) => {
                    return (
                      <Option
                        className='my-font'
                        key={item.value}
                        value={item.value}
                      >
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Title className='mt-2' level={5}>
                {intl.formatMessage({ id: "amount" })} :{" "}
                {payment?.amountToTransfer} SAR
              </Title>
              <Title className='mt-2' level={5}>
                {intl.formatMessage({ id: "ordersTotal" })} : {payment?.count}
              </Title>
              <Text className='my-font'>
                {intl.formatMessage({ id: "settlements" })}
                {": "}
                {transactionNumbers?.map((a) => (
                  <Tag key={a}>{a}</Tag>
                ))}
              </Text>

              <Divider />
              <Space direction='horizontal'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={payment?.transactions?.length || loading}
                    type='primary'
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    type='text'
                    text={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                    onClick={() => {
                      hide();
                    }}
                  />
                </Form.Item>
              </Space>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default EditPaymentModal;
