import { Collapse, Empty, Space, Typography, Progress } from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import CustomButton from "../../../../../Components/CustomButton";
import OneCategoryCard from "./OneCategoryCard";
import COLORS from "../../../../../Style/colors";

const { Title, Text } = Typography;
const { Panel } = Collapse;
const MenuFoodics = ({ restaurant, updateMenu, posData, addFoodicsMenu, loading }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (restaurant?.menu?.length) {
      // get all foods count;
      let allFoodsCount = 0;
      // get all food that have POSIntegration array in it;
      let allFoodsWithIntegration = 0;
      restaurant.menu.forEach((cat) => {
        cat.foods.forEach((food) => {
          allFoodsCount++;
          if (food.POSIntegration?.length) {
            allFoodsWithIntegration++;
          }
        });
      });
      setPercentage(
        Math.round((allFoodsWithIntegration / allFoodsCount) * 100)
      );
    }
  }, [restaurant]);

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Space direction='vertical'>
        <Title level={3}>{intl.formatMessage({ id: "menu" })}</Title>
        <Text type='secondary'>
          {restaurant?.integration?.posTaxInfo?.status === "active"
            ? restaurant?.integration?.posTaxInfo?.taxInclusive === true
              ? "الأسعار شاملة الضريبة"
              : "الأسعار غير شاملة الضريبة"
            : "لا توجد بيانات الضربية فضلاً قم بجلبها"}
        </Text>
      </Space>
      {/*  */}
      <CustomButton
          text={intl.formatMessage({ id: "addFoodicsMenu" })}
          className={`btn-text border-8 px-5 pb-5 mt-4`}
          style={{
            border: `1px ${COLORS.primary} solid`
          }}
          loading={loading}
          disabled={loading}
          type='danger'
          onClick={addFoodicsMenu}
        />
      <Progress percent={percentage} />
      {restaurant?.menu?.length ? (
        <div className='mb-3 mt-5'>
          <Collapse
            bordered={false}
            accordion={true}
            className='border-8'
            style={{ backgroundColor: "#ffffff" }}
            destroyInactivePanel={false}
          >
            {restaurant?.menu.map((category) => {
              return (
                <Panel
                  style={{ marginTop: 10 }}
                  header={
                    <Title level={5} className='my-font'>
                      {rtl ? category.name : category.name_en}
                    </Title>
                  }
                  key={category.categoryId}
                >
                  <OneCategoryCard
                    category={category}
                    posFoods={posData?.foods}
                    updateMenu={updateMenu}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>
      ) : (
        <div className='mt-4'>
          <Empty
            image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
            imageStyle={{
              height: 250,
            }}
            description={<h2 className='my-font'>No Menu in iwaiter</h2>}
          ></Empty>
        </div>
      )}
    </div>
  );
};

export default MenuFoodics;
