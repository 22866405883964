import { Divider, Select, Spin, Tag, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { updateIntegrationForFood } from "../../../../../API/fetch";
import CustomButton from "../../../../../Components/CustomButton";
import openNotification from "../../../../../Components/Notifications";
import MarnModifiers from "./Components/MarnModifiers";
import LinkedModifiers from "./Components/LinkedModifiers";

const { Text } = Typography;
const OneFoodCard = ({ food, posFoods, updateMenu }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [localFood, setLocalFood] = useState(food);
  const [otherFood, setOtherFood] = useState(null);
  const [marnId, setMarnId] = useState("");
  const [loading, setLoading] = useState(false);
  const [linkedArray, setLinkedArray] = useState([]);
  useEffect(() => {
    if (food) {
      setLocalFood(food);
      const otherId = food?.POSIntegration?.find(
        (f) => f.name === "Marn"
      )?.otherFoodId;
      setMarnId(otherId);
      const otherFood = posFoods?.find((f) => `${f?.marnId}` === otherId);
      setOtherFood(otherFood);
    }
  }, [food]);

  const selectMarnFood = (id) => {
    // get the food object form posFoods;
    const otherFood = posFoods?.find((f) => `${f?.marnId}` === `${id}`);
    // update POSIntegration array where name === marn inside localFood object with the new marnId;
    const newLocalFood = { ...localFood };
    if(newLocalFood.POSIntegration && newLocalFood.POSIntegration.length > 0 && newLocalFood.POSIntegration?.some(intg => intg.name === 'Marn')) {
    newLocalFood.POSIntegration = newLocalFood.POSIntegration.map((f) => {
      if (f.name === "Marn") {
        f.otherFoodId = `${id}`;
        f.otherTaxFoodId = otherFood?.marnTaxId;
      }
      return f;
    });
  }else {
    newLocalFood.POSIntegration = [{
      name: "Marn",
      otherFoodId: `${id}`,
      otherTaxFoodId: otherFood?.marnTaxId
    }]
  }
    setLocalFood(newLocalFood);
  };

  useEffect(() => {
    if (localFood?.POSIntegration?.find((f) => f?.name === "Marn")) {
      const otherId = localFood?.POSIntegration?.find(
        (f) => f.name === "Marn"
      )?.otherFoodId;
      setMarnId(otherId);
      const otherFood = posFoods?.find((f) => `${f?.marnId}` === otherId);
      setOtherFood(otherFood);
    }
  }, [localFood]);

  const handleSaveIntegration = async () => {
    try {
      setLoading(true);
      // create the modifiers array;
      const modifiers = localFood.modifiers.map((modifier) => {
        const newModifier = { ...modifier };
        // get the POSIntegration from the linked array;
        const linkedModifier = linkedArray.find(
          (mod) => mod._id === modifier._id
        );
        // if the modifier has a POSIntegration, then update the modifier with the POSIntegration;
        if (linkedModifier) {
          if (linkedModifier.POSIntegration) {
            newModifier.POSIntegration = [linkedModifier.POSIntegration];
          } else {
            newModifier.POSIntegration = [];
          }
          newModifier.options = newModifier.options.map((option) => {
            const linkedOption = linkedModifier.options.find(
              (opt) => opt._id === option._id
            );
            if (linkedOption) {
              if (linkedOption.POSIntegration) {
                option.POSIntegration = [linkedOption.POSIntegration];
              } else {
                option.POSIntegration = [];
              }
            }
            return option;
          });
        }
        return newModifier;
      });

      // update the food object;
      const data = {
        food: {
          POSIntegration: localFood.POSIntegration,
        },
        modifiers,
      };

      // send request;
      const res = await updateIntegrationForFood(localFood.foodId, data);

      updateMenu(res.data);

      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div style={{ paddingBottom: 20 }}>
      <Spin spinning={loading}>
        <Text type='secondary' className='my-font mt-2' level={3}>
          {intl.formatMessage({ id: "marnFood" })}
        </Text>
        <Select
          showSearch
          placeholder='Select a person'
          optionFilterProp='children'
          className={"my-font mt-2 "}
          style={{
            width: "100%",
          }}
          value={marnId}
          onChange={selectMarnFood}
        >
          <Select.Option
            value={"NON"}
            style={{
              width: "100%",
            }}
          >
            <span className='my-font'>
              {intl.formatMessage({ id: "choose" })}
            </span>
          </Select.Option>
          {posFoods?.map((marnFood) => {
            return (
              <Select.Option
                key={marnFood?.marnId}
                value={marnFood?.marnId}
                className='my-font'
              >
                {marnFood?.marnName} -{" "}
                <Tag color='green'> {marnFood?.marnCategoryName}</Tag>
              </Select.Option>
            );
          })}
        </Select>
        <MarnModifiers modifiers={otherFood?.marnModifiers} />
        <LinkedModifiers
          marnModifiers={otherFood?.marnModifiers}
          localFood={localFood}
          setLocalFood={setLocalFood}
          linkedArray={linkedArray}
          setLinkedArray={setLinkedArray}
        />
        <CustomButton
          text={intl.formatMessage({ id: "save" })}
          className={`btn-text border-8 px-5 pb-5`}
          type='primary'
          disabled={loading}
          loading={loading}
          onClick={handleSaveIntegration}
        />
        <Divider />
      </Spin>
    </div>
  );
};

export default OneFoodCard;
