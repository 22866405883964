import React from "react";
import { Col, Row, Tag, Typography } from "antd";
import { useIntl } from "react-intl";
import { getReportTotal } from "../../../helpers";
import { TAG_COLOR_BY_USER_TYPE } from "../../../Types";

// 9i6b85p5h8

const { Text } = Typography;
const boxStyle = {
  display: "flex",
  justifyContent: "space-around",
  backgroundColor: "white",
  padding: "8px",
  boxShadow: "1px 2px 3px lightgray",
};
function ReportSummary({ data }) {
  const intl = useIntl();
  // console.log(data);
  return (
    <Row
      style={{ backgroundColor: "#fafafa", padding: "10px 20px" }}
      gutter={16}
    >
      <Col className="gutter-row" span={5}>
        <div style={boxStyle}>
          <Text className="my-font">
            {intl.formatMessage({ id: "dine_in" })}
          </Text>
          <Tag color="blue">{data?.dineIn?.highestDineIn}</Tag>
        </div>
        <Text className="my-font">
          {data?.dineIn?.restaurantWithHighestDineIn?.name}
        </Text>
      </Col>
      <Col className="gutter-row" span={4}>
        <div style={boxStyle}>
          <Text className="my-font">
            {intl.formatMessage({ id: "pickup" })}
          </Text>
          <Tag color="blue">{data?.pickUp?.highestPickUp}</Tag>
        </div>
        <Text className="my-font">
          {data?.pickUp?.restaurantWithHighestPickUp?.name}
        </Text>
      </Col>
      <Col className="gutter-row" span={5}>
        <div style={boxStyle}>
          <Text className="my-font">
            {intl.formatMessage({ id: "delivery" })}
          </Text>
          <Tag color="blue">{data?.delivery?.highestDelivery}</Tag>
        </div>
        <Text className="my-font">
          {data?.delivery?.restaurantWithHighestDelivery?.name}
        </Text>
      </Col>
      <Col className="gutter-row" span={5}>
        <div style={boxStyle}>
          <Text className="my-font">
            {intl.formatMessage({ id: "reservations" })}
          </Text>
          <Tag color="blue">{data?.reservation?.highestReservation}</Tag>
        </div>
        <Text className="my-font">
          {data?.reservation?.restaurantWithHighestReservation?.name}
        </Text>
      </Col>
      <Col className="gutter-row" span={5}>
        <div style={boxStyle}>
          <Text className="my-font">
            {intl.formatMessage({ id: "waiting" })}
          </Text>
          <Tag color="blue">{data?.waiting?.highestWaiting}</Tag>
        </div>
        <Text className="my-font">
          {data?.waiting?.restaurantWithHighestWaiting?.name}
        </Text>
      </Col>
    </Row>
  );
}

export default ReportSummary;
