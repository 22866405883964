import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Divider,
  Space,
  DatePicker,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import openNotification from "../../Components/Notifications";
import CustomButton from "../../Components/CustomButton";
import dayjs from "dayjs";
import { patchRefer, postRefer } from "../../API/fetch";
import {
  editReferAction,
  addReferAction,
} from "../../redux/reducers/restaurantsReducer";
const { Option } = Select;
const { Title } = Typography;
const EditReferModal = ({ show, hide, refer }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { rtl } = useSelector((state) => state.settings);
  const [isNew, setIsNew] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    if (refer?._id) {
      setIsNew(false);
      form.resetFields();
      setTimeout(() => {
        form.resetFields();
      }, 300);
    } else {
      setIsNew(true);
      form.resetFields();
      setTimeout(() => {
        form.resetFields();
      }, 300);
    }
  }, [refer, show, hide]);

  const editRefer = async (values) => {
    try {
      setLoading(true);
      const data = {
        refer: {
          ...values,
          endDate: dayjs(values.endDate).toDate(),
          startDate: dayjs(values.startDate).toDate(),
          percentage: 0,
        },
      };
      const res = await patchRefer(refer._id, data);
      if (res?.data?.refer) {
        dispatch(editReferAction(res.data.refer));
      }
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  const addRefer = async (values) => {
    try {
      setLoading(true);
      const data = {
        refer: {
          ...values,
          endDate: dayjs(values.endDate).toDate(),
          startDate: dayjs(values.startDate).toDate(),
          percentage: 0,
        },
      };
      const res = await postRefer(data);
      if (res?.data?.refer) {
        dispatch(addReferAction(res.data.refer));
      }
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {intl.formatMessage({ id: isNew ? "addRefer" : "edit" })}
        </Title>
      }
      centered
      open={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => {
        setIsNew(true);
        hide();
      }}
      footer={null}
    >
      <Form
        name='editRefer'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={isNew ? addRefer : editRefer}
        initialValues={
          isNew
            ? {
                name: "",
                code: "",
                endDate: dayjs(),
                startDate: dayjs(),
                percentage: 0,
                numberOfFreeWeeks: 2,
                status: "Active",
              }
            : {
                name: refer?.name,
                code: refer?.code,
                endDate: dayjs(refer?.endDate),
                startDate: dayjs(refer?.startDate),
                percentage: refer?.percentage,
                numberOfFreeWeeks: refer?.numberOfFreeWeeks,
                status: refer?.status,
              }
        }
      >
        {() => {
          return (
            <>
              <Form.Item
                label={intl.formatMessage({ id: "couponName" })}
                className='mb-1'
                name='name'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Input className={"input"} />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "couponCode" })}
                className='mb-1'
                name='code'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Input disabled={!isNew} className={"input"} />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "numberOfFreeWeeks" })}
                className='mb-1'
                name='numberOfFreeWeeks'
              >
                <InputNumber
                  style={{
                    width: "100%",
                  }}
                  className={"input"}
                  min={0}
                />
              </Form.Item>

              <Form.Item
                label={intl.formatMessage({ id: "startDate" })}
                className='mb-1'
                name='startDate'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "endDate" })}
                className='mb-1'
                name='endDate'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "status" })}
                className='mb-1'
                name='status'
              >
                <Select className='my-font'>
                  <Option value='Active' className='my-font'>
                    {intl.formatMessage({ id: "Active" })}
                  </Option>
                  <Option value='disabled' className='my-font'>
                    {intl.formatMessage({ id: "disabled" })}
                  </Option>
                </Select>
              </Form.Item>

              <Divider />
              <Space direction='horizontal'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    type='text'
                    text={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                    onClick={() => {
                      setIsNew(true);
                      hide();
                    }}
                  />
                </Form.Item>
              </Space>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default EditReferModal;
