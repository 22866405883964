import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Typography, Spin, Form, DatePicker, Space } from "antd";
import { useIntl } from "react-intl";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import { getGameWalletAmount } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { setWalletAmountsAction } from "../../redux/reducers/restaurantsReducer";
import { Link } from "react-router-dom";
import { Table } from "ant-table-extensions";
import EditWaletAmountModal from "./Components/EditWaletAmountModal";
import dayjs from "dayjs";

const { Text, Title } = Typography;
const GamesWallet = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { walletAmounts } = useSelector((state) => state.restaurants);
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formattedPayments, setFormattedPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loadingTotal, setLoadingTotal] = useState(false);
  const [totalTillNow, setTotalTillNow] = useState(0);

  const getPaymentsData = async (values) => {
    try {
      setLoading(true);
      if (!values?.date) {
        return openNotification({
          title: "error",
          description: "please select date",
          type: "error",
          rtl: true,
        });
      }
      let endDate = dayjs(values?.date).subtract(3, "days").endOf("day");
      let startDate = dayjs(endDate).subtract(6, "days").startOf("day");
      startDate = startDate.format("YYYY-MM-DD");
      endDate = endDate.format("YYYY-MM-DD");
      const query = `?dateStart=${startDate}&dateEnd=${endDate}`;
      const res = await getGameWalletAmount(query);

      // console.log(res?.data)
      if (res?.data?.status === "success") {
        dispatch(setWalletAmountsAction(res.data.restaurants));
        setLoading(false);
        openNotification({
          title: "success",
          description: "payments loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record) => {
        return <Link to={`/restaurants/${record.restaurantId}`}>{number}</Link>;
      },
      fixed: "left",
      index: 0,
    },
    {
      title: intl.formatMessage({ id: "restaurantName" }),
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (name, record) => {
        return (
          <Space direction='vertical'>
            <Link
              to={
                record.restaurantId ? `/restaurants/${record.restaurantId}` : ""
              }
            >
              {rtl ? name : record?.name_en}
            </Link>
            <Text type='secondary'>{record?.officialName}</Text>
            <Text copyable type='secondary'>
              {record?.Iban}
            </Text>
          </Space>
        );
      },
      fixed: "left",
      index: 1,
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "totalWalletAmount",
      key: "totalWalletAmount",
      render: (amount) => {
        return <Text>{amount?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "iwaierFees" }),
      dataIndex: "iwaiterShare",
      key: "iwaiterShare",
      render: (VATFees) => {
        return <Text>{VATFees?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "VATFees" }),
      dataIndex: "vat",
      key: "vat",
      render: (VATFees) => {
        return <Text>{VATFees?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "amountToTransfer" }),
      dataIndex: "restaurantAmountToTransfer",
      key: "restaurantAmountToTransfer",
      render: (restaurantAmountToTransfer, record) => {
        return (
          <Text
            copyable={{
              text: restaurantAmountToTransfer?.toFixed(2),
            }}
            type={"primary"}
          >
            {restaurantAmountToTransfer?.toFixed(2)}
          </Text>
        );
      },
    },

    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag
            color={status === "Success" ? "success" : "red"}
            className='my-font'
          >
            {status}
          </Tag>
        );
      },
      onFilter: (value, record) => {
        if (value === "Success") {
          return record.status === "Success";
        } else {
          return record.status !== "Success";
        }
      },
      filters: [
        {
          text: "Success",
          value: "Success",
        },
        {
          text: "Pending",
          value: "Pending",
        },
      ],
    },

    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "edit",
      key: "edit",
      render: (actions, record) => {
        return (
          <CustomButton
            onClick={() => {
              setSelectedPayment(record?.tipObject);
              setShowModal(true);
            }}
            type='primary'
            className='border-8'
            icon={<EditOutlined />}
            text={intl.formatMessage({ id: "edit" })}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (walletAmounts?.length) {
      const formatted = walletAmounts.map((payment, i) => {
        return {
          key: payment.restaurantId,
          restaurantId: payment.restaurantId,
          name: payment.name,
          name_en: payment.name_en,
          status: payment.status,
          start: payment.start,
          end: payment.end,
          iwaiterShare: payment.iwaiterShare,
          totalWalletAmount: payment.totalWalletAmount,
          restaurantAmountToTransfer: payment.restaurantAmount,
          vat: payment.vat,
          officialName: payment.officialName,
          Iban: payment.Iban,
          tipObject: {
            ...payment,
          },
        };
      });
      let sorted = formatted
        .sort((a, b) => {
          return b.totalToTransfer - a.totalToTransfer;
        })
        .map((item, i) => {
          return {
            ...item,
            number: i + 1,
          };
        });
      setFormattedPayments(sorted);
    }
    setLoading(false);
  }, [rtl, walletAmounts]);

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <EditWaletAmountModal
          show={showModal}
          hide={() => {
            setShowModal(false);
            setSelectedPayment(null);
          }}
          payment={selectedPayment}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "gamesWallet" })}
          </Title>
          <Spin spinning={loadingTotal} />
          <Text>{totalTillNow && !loadingTotal ? totalTillNow : ""}</Text>
          <Form
            name='payment-data'
            form={form}
            preserve={false}
            className='form-container'
            layout='inline'
            onFinish={getPaymentsData}
          >
            <Form.Item
              name='date'
              label={intl.formatMessage({ id: "transferTime" })}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                disabledDate={(currentDate) => {
                  // if the day is not Tuseday, return false;
                  return currentDate.day() !== 2;
                }}
              />
            </Form.Item>
            <Form.Item>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "send" })}
                className={`btnRegister btn-text border-8`}
                loading={loading}
                disabled={loading}
                type='primary'
              />
            </Form.Item>
          </Form>
        </div>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedPayments?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedPayments}
          scroll={{ x: "max-content" }}
        />
      </Spin>
    </div>
  );
};

export default GamesWallet;
