import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Spin, Form, Input, Tag } from "antd";
import { useIntl } from "react-intl";
import { SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import {
  getUsersApplication,
  sendApplicationNotification,
} from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { setAppUsersAction } from "../../redux/reducers/restaurantsReducer";
import { Table } from "ant-table-extensions";
import { IoRefreshOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const { Text, Title } = Typography;
const UserApplication = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const { appUsers } = useSelector((state) => state.restaurants);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [length, setLength] = useState(0);
  const [users, setUsers] = useState([]);
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    if (!appUsers?.length) {
      getUsersApplicationLength();
    } else {
      setLength(appUsers?.length);
      setLoading(false);
      let formatted = appUsers?.map((user) => {
        return {
          key: user?._id,
          name: user?.name,
          phone: user?.phone,
          ordersCount: user?.orders?.length,
          pickupCount: user?.pickupOrders?.length,
          reservationCount: user?.reservations?.length,
          waitingCount: user?.waiting?.length,
          fcmNotificationToken: user?.fcmNotificationToken,
          eWallet: user?.eWallet,
          userObject: { ...user },
        };
      });
      setUsers(formatted);
    }
  }, [appUsers]);

  const getUsersApplicationLength = async () => {
    try {
      setLoading(true);
      const res = await getUsersApplication();
      if (res?.data?.length > -1) {
        setLength(res.data.length);
        dispatch(setAppUsersAction(res.data.users));
        let formatted = res?.data?.users?.map((user) => {
          return {
            key: user?._id,
            name: user?.name,
            phone: user?.phone,
            ordersCount: user?.orders?.length,
            pickupCount: user?.pickupOrders?.length,
            reservationCount: user?.reservations?.length,
            waitingCount: user?.waiting?.length,
            fcmNotificationToken: user?.fcmNotificationToken,
            eWallet: user?.eWallet,
            userObject: { ...user },
          };
        });
        setUsers(formatted);
        setLoading(false);
        openNotification({
          title: "success",
          description: "users loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const sendNotifications = async (values) => {
    try {
      setLoading(true);
      const data = {
        ...values,
        tokens,
      };
      if (tokens?.length === 0) {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "selectUsers" }),
          type: "error",
          rtl,
        });
        setLoading(false);
        return;
      }
      const res = await sendApplicationNotification(data);
      if (res.data.status === "success") {
        form.resetFields();
      }

      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const phones = selectedRows.map((row) => row.fcmNotificationToken);
      setTokens(phones);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: !record.fcmNotificationToken ? true : false,
        // Column configuration not to be checked
        name: record.fcmNotificationToken,
      };
    },
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      render: (number, record, index) => {
        return <Text>{index + 1}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: intl.formatMessage({ id: "inHouse" }),
      dataIndex: "ordersCount",
      key: "ordersCount",
      sorter: (a, b) => new Date(a.ordersCount) - new Date(b.ordersCount),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "pickupD" }),
      dataIndex: "pickupCount",
      key: "pickupCount",
      sorter: (a, b) => new Date(a.pickupCount) - new Date(b.pickupCount),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "waiting" }),
      dataIndex: "waitingCount",
      key: "waitingCount",
      sorter: (a, b) => new Date(a.waitingCount) - new Date(b.waitingCount),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "reservations" }),
      dataIndex: "reservationCount",
      key: "reservationCount",
      sorter: (a, b) =>
        new Date(a.reservationCount) - new Date(b.reservationCount),
      showSorterTooltip: false,
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "users" })}
            <Tag color='green' className='mx-3'>
              {length} users
            </Tag>
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getUsersApplicationLength}
          />
        </div>
        <Form
          name='send-sms'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={sendNotifications}
        >
          {() => {
            return (
              <>
                <Form.Item
                  name='title'
                  label={intl.formatMessage({ id: "title" })}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input showCount />
                </Form.Item>
                <Form.Item
                  name='body'
                  label={intl.formatMessage({ id: "body" })}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea rows={2} showCount />
                </Form.Item>
               

                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "send" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                  />
                </Form.Item>
              </>
            );
          }}
        </Form>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || users?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
            pageSizeOptions: [10, 25, 50, 100, 200, 500],
          }}
          columns={columns}
          className='light-shadow pointer'
          loading={loading}
          dataSource={users}
          scroll={{ x: 'max-content' }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                navigate(`/users-application/${record?.userObject?._id}`);
              }, // click row
            };
          }}
        />
      </Spin>
    </div>
  );
};

export default UserApplication;
