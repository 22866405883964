import React, { useState, useEffect } from "react";

import { Tag, Typography, Form, DatePicker } from "antd";
import { useIntl } from "react-intl";
import {
  CheckCircleOutlined,
  FileExcelOutlined,
  MinusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
// import add from 'dayjs/plugin/add'
import { getActivityReports } from "../../API/fetch";
import { SERVICES_TYPES } from "../../Types";
import { Table } from "ant-table-extensions";
import { useRef } from "react";
import openNotification from "../../Components/Notifications";
import { getRestaurantStats } from "../../helpers/reportActivity";
import ReportSummary from "./components/ReportSummary";
import { MdVerified } from "react-icons/md";

dayjs.extend(isBetween);
// dayjs.extend(add)
export const CLINT_URL = "https://app.iwaiter.club";
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;

function ActivityReports() {
  const intl = useIntl();
  const [loadingExport, setLoadingExport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formattedRestaurants, setFormattedRestaurants] = useState([]);
  const [stats, setStats] = useState(null);

  // const [dates, setDates] = useState(null);
  const DATA = useRef(null);

  const onFinish = async (values) => {
    const [start, end] = values.date;
    const startDate = dayjs(start)
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0);
    const endDate = dayjs(end)
      .set("hour", 23)
      .set("minute", 59)
      .set("second", 59);
      // check the difference between the two dates;
      const diff = endDate.diff(startDate, "days");
      if(diff > 31){
        openNotification({
          title: "error",
          description: "you can't select more than 31 days",
          type: "error",
          rtl: true,
        });
        return;
      }
    fetchActivityReports(startDate, endDate);
  };

  const fetchActivityReports = async (startDate, endDate, page, size) => {
    try {
      setLoading(true);
      const data = {
        start: startDate,
        end: endDate,
        size: size,
        page: page,
        isPagination: false,
      };

      const res = await getActivityReports(data);
      if (res.data?.restaurants?.length) {
        const restaurants = [...res.data.restaurants];
        const formatted = restaurants.map((restaurant, i) => {
          // services is an array;
          // get last service ended date;
          let selectedService = {};
          let didPaid = restaurant.didPaid;
          restaurant.services.forEach((service) => {
            if (service?.amountPaid > 0) {
              didPaid = true;
            }
            if (!selectedService?.serviceEnd) {
              selectedService = service;
            } else {
              // check if the current service end date is greater than the selected service end date;
              if (
                dayjs(service.serviceEnd).isAfter(
                  dayjs(selectedService.serviceEnd)
                )
              ) {
                selectedService = service;
              }
            }
          });
          return {
            key: restaurant._id,
            number: i + 1,
            _id: restaurant._id,
            didPaid,
            name: restaurant.name,
            phone: restaurant?.userInfo?.phone,
            userName: restaurant?.userInfo?.name,
            services: SERVICES_TYPES[selectedService?.serviceType],
            serviceType: selectedService?.serviceType,
            selectedService: selectedService,
            startSubscriptionDate: dayjs(
              restaurant?.userInfo?.createdAt
            ).format("DD/MM/YYYY"),
            endSubscription: dayjs(selectedService?.serviceEnd).format(
              "DD-MM-YYYY"
            ),
            endSubscriptionDate: dayjs(selectedService?.serviceEnd),
            dineIn: restaurant.totalInHouse,
            pickUp: restaurant.totalPickUp,
            delivery: restaurant.totalDelivery,
            reservations: restaurant.totalReservation,
            waiting: restaurant.totalWaiting,
            createdAt: restaurant?.createdAt,
            restaurantObject: { ...restaurant },
          };
        });
        setFormattedRestaurants(formatted);
      } else {
        setFormattedRestaurants([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      width: 160,
      render: (name, record) => {
        return (
          <div>
            {record.didPaid ? (
              <MdVerified
                style={{ marginLeft: 10 }}
                size={18}
                color={COLORS.primary}
              />
            ) : null}
            {name}
          </div>
        );
      },
      onFilter: (value, record) => {
        return record?.didPaid === value;
      },
      filters: [
        { text: intl.formatMessage({ id: "paid" }), value: true },
        { text: intl.formatMessage({ id: "notPaid" }), value: false },
      ],
    },
    {
      title: intl.formatMessage({ id: "subscription" }),
      dataIndex: "services",
      key: "services",
      width: 200,
      render: (services) => {
        return services?.map((service) => {
          return <Tag key={service}>{service}</Tag>;
        });
      },
      onFilter: (value, record) => {
        return record.serviceType === value || record.services?.includes(value);
      },
      filters: Object.keys(SERVICES_TYPES).flatMap((type) => {
        return { text: type, value: type };
      }),
    },
    // reservations
    {
      title: intl.formatMessage({ id: "reservations" }),
      dataIndex: "reservations",
      key: "reservations",
      render: (reservations, record) => {
        return <Tag>{reservations ? reservations : 0}</Tag>;
      },
    },
    // DineIn
    {
      title: intl.formatMessage({ id: "dine_in" }),
      dataIndex: "dineIn",
      key: "dineIn",
      render: (dineIn, record) => {
        return <Tag>{dineIn ? dineIn : 0}</Tag>;
      },
    },
    //pickup
    {
      title: intl.formatMessage({ id: "pickup" }),
      dataIndex: "pickUp",
      key: "pickUp",
      render: (pickUp, record) => {
        return <Tag>{pickUp ? pickUp : 0}</Tag>;
      },
    },
    //delivery
    {
      title: intl.formatMessage({ id: "delivery" }),
      dataIndex: "delivery",
      key: "delivery",
      render: (waiting, record) => {
        return <Tag>{waiting ? waiting : 0}</Tag>;
      },
    },
    {
      title: intl.formatMessage({ id: "waiting" }),
      dataIndex: "waiting",
      key: "waiting",
      render: (waiting, record) => {
        return <Tag>{waiting ? waiting : 0}</Tag>;
      },
    },
    {
      title: intl.formatMessage({ id: "newClient" }),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => {
        const created = dayjs(createdAt);
        const toDay = dayjs(new Date());
        const diffDays = toDay.diff(created, "days");
        if (diffDays <= 31) {
          return (
            <CheckCircleOutlined
              style={{ color: COLORS.success, fontSize: 20 }}
            />
          );
        } else {
          return <MinusOutlined />;
        }
      },
    },
    {
      title: intl.formatMessage({ id: "startSubscriptionDate" }),
      dataIndex: "startSubscriptionDate",
      key: "startSubscriptionDate",
      sorter: (a, b) =>
        new Date(a.startSubscriptionDate) - new Date(b.startSubscriptionDate),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "subscriptionEndDate" }),
      dataIndex: "endSubscription",
      key: "endSubscription",
      fixed: "right",
      width: 140,
      style: {
        backgroundColor: "red",
      },
      render: (dateString, record) => {
        let background = COLORS.success;
        let color = COLORS.white;
        // check if date is less than now;
        let date = record.endSubscriptionDate;
        if (dayjs(date).isBefore(dayjs())) {
          background = COLORS.danger;
        } // check if date is within the next 8 days;
        else if (dayjs(date).isBetween(dayjs(), dayjs().add(8, "days"))) {
          background = COLORS.warning;
        }
        return {
          props: {
            style: { background },
          },
          children: (
            <Text style={{ color }}>{dayjs(date).format("DD/MM/YYYY")}</Text>
          ),
        };
      },
      sorter: (a, b) =>
        new Date(a.endSubscriptionDate) - new Date(b.endSubscriptionDate),
      showSorterTooltip: false,
      onFilter: (value, record) => {
        let date = record.endSubscriptionDate;
        if (value === "active") {
          return dayjs(date).isAfter(dayjs().add(8, "days"));
        } else if (value === "lessThanWeek") {
          return dayjs(date).isBetween(dayjs(), dayjs().add(8, "days"));
        } else if (value === "expired") {
          return dayjs(date).isBefore(dayjs());
        }

        return false;
      },
      filters: [
        {
          text: intl.formatMessage({ id: "active" }),
          value: "active",
        },
        {
          text: intl.formatMessage({ id: "lessThanWeek" }),
          value: "lessThanWeek",
        },
        {
          text: intl.formatMessage({ id: "expired" }),
          value: "expired",
        },
      ],
    },
  ];

  const handleOnExport = () => {
    setFormattedRestaurants(DATA.current);
    setLoadingExport(true);
    const btn = document.getElementById("exportBTN");
    setTimeout(() => {
      btn?.click();
      setLoadingExport(false);
      setFormattedRestaurants(formattedRestaurants);
    }, 2000);
  };

  useEffect(() => {
    setStats(getRestaurantStats(formattedRestaurants));
  }, [formattedRestaurants]);

  return (
    <div className="tables-card-container" style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title
          level={4}
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {intl.formatMessage({ id: "activityReports" })}
        </Title>
        <Form
          name="food-form"
          form={form}
          className="form-container"
          layout="inline"
          onFinish={onFinish}
          initialValues={{ type: "all", paymentMethod: "all" }}
        >
          <Form.Item
            rules={[{ required: true, message: "Please select Date" }]}
            name="date"
            label={intl.formatMessage({ id: "date" })}
          >
            <RangePicker 
            
            />
          </Form.Item>
          <Form.Item>
            <CustomButton
              htmlType="submit"
              text={intl.formatMessage({ id: "search" })}
              className={`btnRegister btn-text border-8 px-5 `}
              loading={loading}
              disabled={loading}
              type="primary"
            />
          </Form.Item>
        </Form>
      </div>
      <CustomButton
        text={intl.formatMessage({ id: "export" })}
        onClick={handleOnExport}
        disabled={loadingExport}
        loading={loadingExport}
        type="primary"
        className={"border-8"}
        icon={<FileExcelOutlined />}
      />
      <Table
        searchable
        fuzzySearch
        exportable
        title={() => {
          if (stats) {
            return <ReportSummary data={stats} />;
          }
        }}
        exportableProps={{
          disabled: loading || formattedRestaurants?.length === 0,
          btnProps: {
            icon: <FileExcelOutlined />,
            type: "primary",
            style: { borderRadius: 7, position: "relative", zIndex: -20 },
            id: "exportBTN",
          },
          children: (
            <Text
              className="my-font"
              style={{
                color:
                  formattedRestaurants?.length === 0 ? "#dddddd" : COLORS.white,
              }}
            >
              {intl.formatMessage({ id: "export" })}
            </Text>
          ),
        }}
        pagination={{
          position: ["topLeft"],
          pageSizeOptions: [10, 25, 50, 100, 200, 500],
        }}
        searchableProps={{
          debounce: true,
          fuzzySearch: true,
          inputProps: {
            placeholder: "Search this table",
            prefix: <SearchOutlined />,
            disabled: loading || formattedRestaurants?.length === 0,
          },
        }}
        columns={columns}
        className="light-shadow"
        loading={loading}
        dataSource={formattedRestaurants}
        scroll={{ x: "max-content" }}
        footer={(currentPageData) => {
          DATA.current = currentPageData;
          return null;
        }}
      />
    </div>
  );
}

export default ActivityReports;
