import React, { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Typography,
  Descriptions,
} from "antd";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import CustomButton from "../../../Components/CustomButton";
import EditPaymentTransactionModal from "./EditPaymentTransactionModal";

const {  Text } = Typography;
const PaymentTransactions = ({ restaurant }) => {
  const intl = useIntl();
  const [formattedTransactions, setFormattedTransactions] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null)

  useEffect(() => {
    if (restaurant?.paymentsTransactionsInfo?.length) {
      let formatted = restaurant.paymentsTransactionsInfo.map(
        (payment, i) => {
          return {
            key: payment._id,
            number: i + 1,
            endDate: payment.endDate,
            startDate: payment.startDate,
            amount: payment.amount?.toFixed(2),
            source: payment.source,
            transactionNumber: payment.transactionNumber,
            status: payment.status,
            createdAt: payment.createdAt,
            invoiceUrl: payment?.invoiceUrl,
            _id: payment._id,
          };
        }
      );
      // sort by createdAt
      formatted.sort((a, b) => {
        return dayjs(b.createdAt).diff(dayjs(a.createdAt));
      });
      setFormattedTransactions(formatted);
    }
  }, [restaurant]);


  const updateTransaction = (transaction) => {
    const newTransactions = formattedTransactions.map((t) => {
      if (t._id === transaction._id) {
        return {
          ...t,
          endDate: transaction.endDate,
          startDate: transaction.startDate,
          amount: transaction.amount,
          source: transaction.source,
          transactionNumber: transaction.transactionNumber,
          status: transaction.status,
          createdAt: transaction.createdAt,
          invoiceUrl: transaction.invoiceUrl,
          _id: transaction._id,
        };
      } else {
        return t;
      }
    });
    setFormattedTransactions(newTransactions);
  }
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record) => {
        return <Text>{number}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "pierodTime" }),
      dataIndex: "startDate",
      key: "startDate",
      render: (date, record) => {
        return (
          <Text>
            {dayjs(date).format("DD-MM-YYYY")} -{" "}
            {dayjs(record.endDate).format("DD-MM-YYYY")}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "totalAmount" }),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: intl.formatMessage({ id: "source" }),
      dataIndex: "source",
      key: "source",
    },
    {
      title: intl.formatMessage({ id: "transactionNumber" }),
      dataIndex: "transactionNumber",
      key: "transactionNumber",
    },
    {
      title: intl.formatMessage({ id: "createdAt" }),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => {
        return <Text>{dayjs(date).format("DD-MM-YYYY")}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag color={status === "Success" ? "green" : "red"}>{status}</Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "invoice" }),
      dataIndex: "invoiceUrl",
      key: "invoiceUrl",
      render: (invoiceUrl) => {
        return (
          <a style={{
            color: invoiceUrl ? "#1890ff": "gray",
            textDecoration: invoiceUrl ? "underline" : "none",
            cursor: invoiceUrl ? "pointer" : "not-allowed"
          }} href={invoiceUrl} target='_blank'
            
          >
            {intl.formatMessage({ id: "invoice" })}
          </a>
        );
      },
    },
    {
      // edit the invoice url;
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: 'editInvoiceUrl',
      key: 'editInvoiceUrl',
      render: (editInvoiceUrl, record) => {
        return (
          <CustomButton 
            text={intl.formatMessage({ id: "edit" })}
            onClick={() => {
              setSelectedTransaction(record)
              setShowEditModal(true)
            }}
            type='outline'
            
          />
        );
      },
    }
  ];
  return (
    <div>
      <Table
        columns={columns}
        pagination={{
          hideOnSinglePage: true,
        }}
        className='light-shadow'
        dataSource={formattedTransactions}
        scroll={{ x: 'max-content' }}
      />
      {/* Add Edit model */}
      <EditPaymentTransactionModal 
        show={showEditModal}
        hide={() => {
          setShowEditModal(false)
          setSelectedTransaction(null)
        }}
        paymentTransaction={selectedTransaction}
        updateTransaction={updateTransaction}
        />
      <div className='mt-3'>
        <Descriptions
          className='my-font'
          bordered 
        >
          <Descriptions.Item
            label={intl.formatMessage({ id: "totals" })}
          >
            {restaurant?.paymentsTransactionsInfo?.length &&
              restaurant.paymentsTransactionsInfo.reduce(
                  (acc, curr) => acc + curr.amount,
                  0
                )?.toFixed(2)}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default PaymentTransactions;
