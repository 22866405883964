import React, { useState, useEffect } from "react";
import {
  Modal,
  Select,
  Typography,
  Divider,
  Space,
  Popconfirm,
  Spin,
  Descriptions,
  Table,
} from "antd";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import openNotification from "../../Components/Notifications";
import CustomButton from "../../Components/CustomButton";
import dayjs from "dayjs";
import COLORS from "../../Style/colors";
import { createWinner, getPotionalWinner } from "../../API/fetch";
const { Option } = Select;
const { Title, Text } = Typography;
const WinnerModal = ({ show, hide, competition }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [formattedWinner, setFormattedWinner] = useState([]);
  const [disableForToday, setDisableForToday] = useState(false);
  const [potionalWinners, setPotionalWinners] = useState(null);
  const [tmpWinner, setTmpWinner] = useState(null);
  useEffect(() => {
    if (competition?._id) {
      let formatted = competition?.winners?.map((item) => {
        return {
          //   ...item,
          key: item._id,
          name: item?.customer?.name,
          phone: item?.customer?.phone,
          restaurant: item?.restaurant?.name,
          orderNumber: item?.order?.orderNumber,
          prize: item?.prize,
          winingDate: dayjs(item?.winingDate).format("DD/MM/YYYY"),
        };
      });
      setFormattedWinner(formatted);
      let isDisabled = false;
      formatted?.forEach((one) => {
        let yestaday = dayjs().subtract(1, "day");
        let date = dayjs();
        if (one?.winingDate) {
          date = dayjs(one.winingDate, "DD/MM/YYYY");
        }
        // check if yesterday and date are equal by day;
        if (yestaday.format("DD/MM/YYYY") === date.format("DD/MM/YYYY")) {
          isDisabled = true;
        }
      });
      setDisableForToday(isDisabled);
    }
  }, [competition?._id, competition?.winners?.length]);

  const getTodayInfo = async () => {
    try {
      setLoading(true);
      let date = dayjs().subtract(1, "day").format("DD/MM/YYYY");
      const res = await getPotionalWinner(competition?._id, date);
      if (res?.data?.potionalWinners) {
        let customers = [];
        let customerIds = [];
        let restaurants = [];
        let restaurantIds = [];
        res?.data?.potionalWinners?.forEach((item) => {
          if (!customerIds?.includes(item?.customer?._id)) {
            customerIds.push(item?.customer?._id);
            customers.push(item?.customer);
          }
          if (!restaurantIds?.includes(item?.restaurant?._id)) {
            restaurantIds.push(item?.restaurant?._id);
            restaurants.push(item?.restaurant);
          }
        });
        setPotionalWinners({
          data: res?.data?.potionalWinners,
          customers,
          restaurants,
        });
        openNotification({
          message: "Success",
          description: "Winners has been created successfully",
          type: "success",
          rtl,
        });
      } else {
        openNotification({
          message: "Error",
          description: "Something went wrong",
          type: "error",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      openNotification({
        message: "Error",
        description: "Something went wrong",
        type: "error",
        rtl,
      });
      setLoading(false);
    }
  };
  const selectWinner = () => {
    const randomWinner =
      potionalWinners?.data[
        Math.floor(Math.random() * potionalWinners?.data.length)
      ];
    setTmpWinner(randomWinner);
  };

  const getWinner = async () => {
    try {
      setLoading(true);

      let data = {
        winner: {
          customerId: tmpWinner?.customer?._id,
          restaurantId: tmpWinner?.restaurant?._id,
          prize: competition?.prizeAmount,
          winingDate: dayjs().subtract(1, "day").format("DD/MM/YYYY"),
          status: "pending",
          orderId: tmpWinner?.type === "inHouse" ? tmpWinner?._id : null,
          pickupOrderId: tmpWinner?.type !== "inHouse" ? tmpWinner?._id : null,
          orderType: tmpWinner?.type,
        },
      };

      const res = await createWinner(competition?._id, data);
      if (res?.data?.status === "success") {
        openNotification({
          message: "Success",
          description: "Winner has been created successfully",
          type: "success",
          rtl,
        });
      } else {
        openNotification({
          message: "Error",
          description: "Something went wrong",
          type: "error",
          rtl,
        });
      }
      setLoading(false);
      hide();
    } catch (err) {
      console.log(err.response?.data?.message);
      setLoading(false);
      openNotification({
        message: "Error",
        description: err?.response?.data?.message,
        type: "error",
        rtl,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      render: (text, record, index) => <Text>{index + 1}</Text>,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: intl.formatMessage({ id: "restaurant" }),
      dataIndex: "restaurant",
      key: "restaurant",
    },
    {
      title: intl.formatMessage({ id: "prize" }),
      dataIndex: "prize",
      key: "prize",
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "winingDate",
      key: "winingDate",
    },
    {
      title: intl.formatMessage({ id: "orderNumber" }),
      dataIndex: "orderNumber",
      key: "orderNumber",
    },
  ];

  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {intl.formatMessage({ id: "winners" })}
        </Title>
      }
      centered
      open={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => {
        hide();
      }}
      footer={null}
      width={"90vw"}
    >
      <Spin spinning={loading}>
        <CustomButton
          type='primary'
          text='احصل على بيانات المسابقة لليوم'
          loading={loading}
          disabled={loading}
          onClick={getTodayInfo}
        />
        <CustomButton
          style={{
            backgroundColor: COLORS.success,
            borderColor: COLORS.success,
          }}
          text={<Text style={{ color: COLORS.white }}>اختيار فائز لليوم</Text>}
          loading={loading}
          disabled={loading || !potionalWinners}
          onClick={selectWinner}
          className={"mx-5"}
        />

        <Descriptions
          title={`بيانات السحب ليوم ${dayjs()
            .subtract(1, "day")
            .format("dddd")} ${dayjs()
            .subtract(1, "day")
            .format("DD/MM/YYYY")}`}
          bordered
          style={{
            marginTop: 20,
          }}
        >
          {/* Display count of potional winners */}
          <Descriptions.Item label='عدد الطلبات' span={3}>
            {potionalWinners?.data?.length}
          </Descriptions.Item>
          <Descriptions.Item label='عدد العملاء' span={3}>
            {potionalWinners?.customers?.length}
          </Descriptions.Item>
          <Descriptions.Item label='عدد المطاعم' span={3}>
            {potionalWinners?.restaurants?.length}
          </Descriptions.Item>
        </Descriptions>
        <Divider />

        {tmpWinner ? (
          <>
            <Descriptions
              bordered
              style={{
                marginTop: 10,
              }}
            >
              <Descriptions.Item label='العميل' span={3}>
                <Title
                  level={4}
                  className='my-font'
                  style={{
                    color: COLORS.success,
                  }}
                >
                  🔥🔥{tmpWinner?.customer?.name}
                </Title>
              </Descriptions.Item>
              <Descriptions.Item label='المطعم' span={3}>
                <Title
                  level={4}
                  className='my-font'
                  style={{
                    color: COLORS.success,
                  }}
                >
                  {tmpWinner?.restaurant?.name}
                </Title>
              </Descriptions.Item>
              <Descriptions.Item label='الطلب' span={3}>
                <Title
                  level={4}
                  className='my-font'
                  style={{
                    color: COLORS.success,
                  }}
                >
                  رقم الطلب ، {tmpWinner?.orderNumber} وكان بقيمة{" "}
                  {tmpWinner?.total?.toFixed(2)} ريال
                </Title>
              </Descriptions.Item>
            </Descriptions>
            <Popconfirm
              title={
                <Space
                  style={{
                    maxWidth: 150,
                  }}
                >
                  <Text className='my-font'>
                    هل أنت متأكد من تأكيد بيانات الفائز ، لن نتمكن من إلغاء
                    البيانات نهائياً ، الفائز سيكون ليوم{" "}
                    <Text
                      style={{
                        fontWeight: "bold",
                        color: COLORS.success,
                      }}
                    >
                      {dayjs().subtract(1, "day").format("dddd")}{" "}
                      {dayjs().subtract(1, "day").format("DD/MM/YYYY")}
                    </Text>
                  </Text>
                </Space>
              }
              okText={"تأكيد الفائز"}
              okButtonProps={{
                style: { color: COLORS.success, fontFamily: "Cairo" },
              }}
              okType='text'
              cancelText={intl.formatMessage({ id: "cancel" })}
              cancelButtonProps={{
                style: { border: "none", fontFamily: "Cairo" },
              }}
              onConfirm={getWinner}
              disabled={disableForToday || loading || !tmpWinner}
              loading={loading}
            >
              <CustomButton
                //   type='primary'
                text={
                  <Text
                    style={{
                      color: COLORS.white,
                    }}
                  >
                    تأكيد فائز جديد
                  </Text>
                }
                disabled={loading || disableForToday || !potionalWinners}
                style={{
                  backgroundColor: COLORS.success,
                  borderColor: COLORS.success,
                  marginTop: 20,
                }}
              />
            </Popconfirm>
          </>
        ) : null}

        <Divider />
        <Title level={4}>الفائزين السابقين</Title>
        <Table dataSource={formattedWinner} columns={columns} />
      </Spin>
    </Modal>
  );
};

export default WinnerModal;
