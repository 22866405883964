import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Spin, Form, Input, Select, Button } from "antd";
import { useIntl } from "react-intl";
import { SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import { sendEmails } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import * as XLSX from "xlsx";

const { Text, Title } = Typography;
const MarketingEmails = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formattedUsers, setFormattedUsers] = useState([]);
  const [emails, setEmails] = useState([]);

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record, index) => {
        return <Text>{index + 1}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      width: 160,
      render: (name, record) => {
        return <Text>{name}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "email" }),
      dataIndex: "email",
      key: "email",
      render: (email) => {
        return <Text>{email}</Text>;
      },
    },
  ];

  const handelSendEmails = async (values) => {
    try {
      if (!emails?.length) return;

      setLoading(true);
      const data = {
        emails,
        template: values.template,
        subject: values.subject,
      };
      const res = await sendEmails(data);
      if (res.data.status === "success") {
        form.resetFields();
        setEmails([]);
      }

      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      let formatted = [];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

      dataParse.forEach((arr, index) => {
        if (index === 0) return;
        if (arr[1]) {
          // numbers.push(`966${arr[1]}`);
          formatted.push({
            name: arr[0],
            email: arr[1],
            key: `966${arr[1]}-${index}`,
          });
        }
      });
      setFormattedUsers(formatted);
    };
    reader.readAsBinaryString(f);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const emails = selectedRows.map((row) => ({
        email: row.email,
        name: row.name,
      }));
      setEmails(emails);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: !record.email ? true : false,
        // Column configuration not to be checked
        name: record.email,
      };
    },
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "users" })}
          </Title>
          <Button
            className='upload-wrap mx-5 border-8'
            onClick={() => {
              const a = document.getElementById("my-upload-excel");
              a.click();
            }}
          >
            <input
              style={{ display: "none" }}
              className='file-uploader'
              type='file'
              id='my-upload-excel'
              accept='.xlsx, .xls'
              onChange={onChange}
            />
            <span className='upload-text'>Upload Target emails</span>
          </Button>
        </div>
        <Form
          name='send-emails'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={handelSendEmails}
          initialValues={{
            template: "services",
            subject: "",
          }}
        >
          {() => {
            return (
              <>
                <Form.Item
                  name='subject'
                  label={intl.formatMessage({ id: "subject" })}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input showCount />
                </Form.Item>
                <Form.Item
                  name='template'
                  label={intl.formatMessage({ id: "template" })}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select className='my-font'>
                    <Select.Option value='services'>services</Select.Option>
                    <Select.Option value='marketing'>marketing</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "send" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={
                      loading ||
                      !emails?.length ||
                      !form?.getFieldValue("subject")
                    }
                    type='primary'
                  />
                </Form.Item>
              </>
            );
          }}
        </Form>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedUsers?.length === 0,
            },
          }}
          pagination={false}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedUsers}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      </Spin>
    </div>
  );
};

export default MarketingEmails;
