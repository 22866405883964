import { Collapse, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import OneFoodCard from "./OneFoodCard";

const { Title } = Typography;
const { Panel } = Collapse;
const OneCategoryCard = ({ category, posFoods,updateMenu }) => {
  const { rtl } = useSelector((state) => state.settings);
  return (
    <Collapse
      bordered={false}
      accordion={true}
      className='light-shadow'
      style={{ backgroundColor: "#ffffff", }}
      destroyInactivePanel={false}
    >
      {category?.foods.map((food) => {
        return (
          <Panel
            style={{ marginTop: 10 }}
            header={
              <Title level={5} className='my-font'>
                {rtl ? food.name : food.name_en}
              </Title>
            }
            key={food.foodId}
          >
            <OneFoodCard food={food} posFoods={posFoods} updateMenu={updateMenu} />
          </Panel>
        );
      })}
    </Collapse>
  );
};

export default OneCategoryCard;
