import { Result, Spin, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getIntegration } from "../../API/fetch";
import CustomButton from "../../Components/CustomButton";
import openNotification from "../../Components/Notifications";
import { INTEGRATION_TYPE } from "../../Types";
import Foodics from "./Foodics";
import Marn from "./Marn";

const { Title,  } = Typography;
const Integrations = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  // get the id from params;
  const { id } = useParams();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [restaurantInfo, setRestaurantInfo] = useState(null);
  const [posData, setPosData] = useState(null);

  useEffect(() => {
    if (id) {
      getIntegrationInfo(id);
    } else {
      setError(true);
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    // scroll to top of page;
    window.scrollTo(0, 0);
  }, [])

  const getIntegrationInfo = async (id) => {
    try {
      const res = await getIntegration(id);
      if (!res?.data?.restaurant) {
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
        setError(true);
        setLoading(false);
        return;
      }
      setRestaurantInfo(res.data.restaurant);
      setPosData(res.data.posData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {error ? (
        <Result
          status={"404"}
          title={"404"}
          subTitle={"Sorry, the page you visited does not exist."}
          extra={
            <CustomButton
              className={`btn-text border-8 px-5 pb-5`}
              type='primary'
              onClick={() => navigate(-1)}
              text={intl.formatMessage({ id: "goBack" })}
            />
          }
        />
      ) : (
        <Spin spinning={loading}>
          <div
            style={
              rtl
                ? {
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                  }
                : {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                  }
            }
          >
            <Title
              level={3}
              className='pointer'
              onClick={() => navigate(-1)}
              // style={{ position: "absolute", zIndex: 1 }}
            >
              {rtl ? (
                <IoArrowForwardOutline className='pointer' />
              ) : (
                <IoArrowBackOutline className='pointer' />
              )}
            </Title>
          </div>
          <br />

          <div
          >
            {restaurantInfo?.integration?.posName ===
            INTEGRATION_TYPE.FOODICS ? (
              <Foodics restaurant={restaurantInfo} posData={posData} setRestaurant={setRestaurantInfo} />
            ) : restaurantInfo?.integration?.posName ===
              INTEGRATION_TYPE.MARN ? (
              <Marn restaurant={restaurantInfo} posData={posData}  setRestaurant={setRestaurantInfo}  />
            ) : loading ? null : (
              <Result
                status='404'
                title='404'
                subTitle='Sorry, the page you visited does not exist.'
                extra={
                  <CustomButton
                    className={`btn-text border-8 px-5 pb-5`}
                    type='primary'
                    onClick={() => navigate(-1)}
                    text={intl.formatMessage({ id: "goBack" })}
                  />
                }
              />
            )}
          </div>
        </Spin>
      )}
    </div>
  );
};

export default Integrations;
