import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Tag,
  Typography,
  Spin,
  Form,
  DatePicker,
  Button,
  Row,
  Col,
} from "antd";
import { useIntl } from "react-intl";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import dayjs from "dayjs";
import { getSettlements, updatePaymentSettlements } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import { Table as MYT } from "antd";
import { setSettlementsAction } from "../../redux/reducers/settlementReducer";
import * as XLSX from "xlsx";
import { customerExport } from "../../helpers";
import Papa from "papaparse";
const { RangePicker } = DatePicker;

const { Text, Title } = Typography;
const Settlement = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { settlements } = useSelector((state) => state.settlement);
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formattedPayments, setFormattedPayments] = useState([]);
  const [filterRestaurants, setFilterRestaurants] = useState([]);
  const getSettlementData = async (values) => {
    try {
      setLoading(true);
      let dateStart = dayjs(values.date[0]).format("YYYY-MM-DD");
      let dateEnd = dayjs(values.date[1]).format("YYYY-MM-DD");
      const query = `?dateStart=${dateStart}&dateEnd=${dateEnd}`;
      const res = await getSettlements(query);
      if (res?.data?.status === "success") {
        dispatch(setSettlementsAction(res.data.payments));
        setLoading(false);
        openNotification({
          title: "success",
          description: "payments loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      let ids = [];
      let date = "";
      let number = "";
      let totalAmountBeforeFees = 0;
      let paymentFees = 0;
      let totalAfterFees = 0;
      let ordersWithFees = [];
      let didReachDebits = false;
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      // console.log(dataParse);
      dataParse.forEach((arr) => {
        // console.log(arr);
        arr?.forEach((item) => {
          // get the charge id;
          if (item && item?.includes && item?.includes("chg_")) {
            if (!ids.includes(item.trim())) {
              ids.push(item.trim());
            } else if (didReachDebits) {
              let number = `${arr[arr.length - 1]}`;
              number = number.replace(/,/g, "");
              number = parseFloat(number);
              // check if the orderWithFees array has the order id;
              let oId = item.trim();
              let found = ordersWithFees.find((o) => o.chargeId === oId);
              if (found) {
                found.fees = found.fees + number;
              } else {
                ordersWithFees.push({
                  chargeId: oId,
                  fees: number,
                });
              }
            }
          }
          // get the transaction number
          if (item && item?.includes && item?.includes("iwaiter")) {
            if (item.split(" ")[0] === "iwaiter") {
              number = item.split(" ")[1];
            }
          }
          if (
            item &&
            item?.includes &&
            item?.includes("Net Transaction Debits")
          ) {
            let numberString = `${arr[arr.length - 1]}`;
            numberString = numberString.replace(/,/g, "");
            paymentFees = Math.abs(parseFloat(numberString));
            didReachDebits = true;
          }
          if (
            item &&
            item?.includes &&
            item?.includes("Net Transaction Credits")
          ) {
            let numberString = `${arr[arr.length - 1]}`;
            numberString = numberString.replace(/,/g, "");
            totalAmountBeforeFees = parseFloat(numberString);
          }
          if (item && item?.includes && item?.includes("goSell")) {
            let numberString = `${arr[arr.length - 1]}`;
            numberString = numberString.replace(/,/g, "");
            totalAfterFees = parseFloat(numberString);
          }
        });
        if (arr && arr?.length > 1 && arr?.[1]?.includes("/") && !date) {
          date = arr[1];
        }
      });
      let formattedDate = dayjs(date, "DD/MM/YYYY");
      if (!formattedDate.isValid()) {
        formattedDate = dayjs(date, "DD/MM/YY");
      }
      updatePayments({
        ids,
        transactionDate: formattedDate.toDate(),
        transactionNumber: number,
        totalAmountBeforeFees,
        paymentFees,
        totalAfterFees,
        ordersWithFees,
      });
    };
    reader.readAsBinaryString(f);
  };
  const onChangeTapNew = (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    Papa.parse(f, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // convert all values inside the result.data object to a value without \u0000
        let notPars = results.data.map((one) => {
          let copy = {};
          let keys = Object.keys(one);
          keys.forEach((key) => {
            // remove all /n from the string
            // check if onk[key] is not array;
            if (Array.isArray(one[key])) {
              copy[key] = one[key];
            } else {
              let copyKey = key.replace(/\u0000/g, "")?.replace(/\n/g, "");
              copy[copyKey] = one[key]
                .replace(/\u0000/g, "")
                ?.replace(/\n/g, "");
            }
          });
          return copy;
        });
        let ids = [];
        let date = "";
        let number = "";
        let totalAmountBeforeFees = 0;
        let paymentFees = 0;
        let totalAfterFees = 0;
        let ordersWithFees = [];
        notPars.forEach((all, index) => {
          // console.log(Object.keys(arr), "keys");
          let arr = {};
          let keys = Object.keys(all);
          keys.forEach((key) => {
            // remove all U+FEFF from the key;
            let copyKey = key.replace(/\uFEFF/g, "");
            arr[copyKey] = all[key].replace(/\uFEFF/g, "");
          });

          if (arr["status"] !== "CAPTURED") {
            return;
          }

          let idNumber = arr["payout_id"].split("_")[1];
          if (number === "") {
            number = idNumber;
          }
          if (date === "") {
            date = arr["settlement_date"];
          }

          ids.push(arr["charge_id"]);
          let fees = parseFloat(arr["fee"]);
          let amount = parseFloat(arr["post_amount"]);
          let amountAfterFees = parseFloat(arr["net_amount"]);
          // check if the format is not correct;
          if (arr["customer_phone_code"]?.length != 3) {
            console.log("shift rows");
            fees = parseFloat(arr["fee_vat"]);
            amount = parseFloat(arr["fee"]);
            amountAfterFees = parseFloat(arr["metadata_1"]);
          }

          totalAmountBeforeFees += amount;
          paymentFees += fees;
          totalAfterFees += amountAfterFees;
          ordersWithFees.push({
            chargeId: arr["charge_id"],
            fees: fees,
          });
        });
        let formattedDate = dayjs(date, "DD/MM/YYYY");
        if (!formattedDate.isValid()) {
          formattedDate = dayjs(date, "DD/MM/YY");
        }

        updatePayments({
          ids,
          transactionDate: formattedDate.toDate(),
          transactionNumber: number,
          totalAmountBeforeFees,
          paymentFees,
          totalAfterFees,
          ordersWithFees,
        });
      },
    });
  };
  const onChangeNOON = (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      let ids = [];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      let orderNumberIndex = dataParse?.[0]?.findIndex(
        (item) => item === "Orderreference"
      );
      let amountIndex = dataParse?.[0]?.findIndex(
        (item) => item === "Transactionamount"
      );
      let statusIndex = dataParse?.[0]?.findIndex(
        (item) => item === "Transactiontype"
      );
      dataParse.forEach((arr, index) => {
        if (index > 0) {
          if (arr[amountIndex] > 0 && arr[statusIndex] === "CAPTURE") {
            ids.push(arr[orderNumberIndex]);
          }
        }
      });
      let now = dayjs().format("DD-MM-YYYY");
      let number = `${now}-LENGTH-${ids.length}`;
      let formattedDate = dayjs().toDate();
      // updatePayments(ids, formattedDate, number);
    };
    reader.readAsBinaryString(f);
  };

  const updatePayments = async ({
    ids,
    transactionDate,
    transactionNumber,
    totalAmountBeforeFees,
    paymentFees,
    totalAfterFees,
    ordersWithFees,
  }) => {
    try {
      setLoading(true);
      const data = {
        ids,
        transactionDate,
        transactionNumber,
        totalAmountBeforeFees,
        paymentFees,
        totalAfterFees,
        ordersWithFees,
      };
      const res = await updatePaymentSettlements(data);
      if (res?.data?.status === "success") {
        setLoading(false);
        openNotification({
          title: "success",
          description: "payments updated",
          type: "success",
          rtl: true,
        });
      }
      // console.log(res.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      // dataIndex: "number",
      key: "index",
      width: 80,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "orderDate" }),
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: intl.formatMessage({ id: "restaurantName" }),
      dataIndex: "name",
      key: "name",
      // width: 160,
      render: (name, record) => {
        return <Text>{name ? name : record?.namePickup}</Text>;
      },
      // add filter;
      onFilter: (value, record) => record?.name?.indexOf(value) === 0,
      filters: filterRestaurants,
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "total",
      key: "total",
    },
    {
      title: "iwaiter ID",
      dataIndex: "orderId",
      key: "orderId",
      render: (orderID) => {
        return (
          <Text
            copyable={{
              text: orderID,
            }}
          >
            {orderID === "Reservation"
              ? "Reservation"
              : `${orderID}`?.substring(orderID?.length - 3)}
          </Text>
        );
      },
    },
    {
      title: "payment ID",
      dataIndex: "chargeId",
      key: "chargeId",
      render: (orderID) => {
        return (
          <Text
            copyable={{
              text: orderID,
            }}
          >
            {`${orderID}`?.substring(orderID?.length - 3)}
          </Text>
        );
      },
    },
    {
      title: "Company",
      dataIndex: "paymentCompanyName",
      key: "paymentCompanyName",
      render: (paymentCompanyName) => {
        return <Text>{`${paymentCompanyName}`}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag color={status ? "success" : "red"} className='my-font'>
            {status ? "DONE" : "PENDING"}
          </Tag>
        );
      },
      onFilter: (value, record) => {
        if (value === "Success") {
          return record.status === true;
        } else {
          return record.status != true;
        }
      },
      filters: [
        {
          text: "Success",
          value: "Success",
        },
        {
          text: "Pending",
          value: "Pending",
        },
      ],
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (orderID) => {
        return <Text>{orderID}</Text>;
      },
    },
    {
      title: "T number",
      dataIndex: "transactionNumber",
      key: "transactionNumber",
      render: (orderID) => {
        return <Text>{orderID}</Text>;
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (settlements?.length) {
      let filter = [];
      const formatted = settlements.map((payment) => {
        let name = payment.name || payment.namePickup;
        let j = filter.findIndex((a) => a.text === name);
        if (j === -1) {
          filter.push({
            text: name,
            value: name,
          });
        }
        return {
          key: payment._id,
          name: name,
          orderId: payment.orderId || payment.type,
          paymentMethod: payment.paymentMethod,
          total: payment.totalNumber,
          paymentCompanyName: payment.paymentCompanyName,
          chargeId: payment.chargeId,
          createdAt: dayjs(payment.createdAt.split("T")[0]).format(
            "DD/MM/YYYY"
          ),
          status: payment?.didReceiveSettlement,
          date: payment?.settlementInfo?.transactionDate
            ? dayjs(payment.settlementInfo.transactionDate).format("DD/MM/YYYY")
            : "",
          transactionNumber: payment?.settlementInfo?.transactionNumber,
          paymentObject: { ...payment },
        };
      });
      setFormattedPayments(formatted);
      setFilterRestaurants(filter);
    } else {
      setFormattedPayments([]);
      setFilterRestaurants([]);
    }
    setLoading(false);
  }, [rtl, settlements]);

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "settlement" })}
          </Title>
          <Button
            className='upload-wrap'
            onClick={() => {
              const a = document.getElementById("my-upload");
              a.click();
            }}
          >
            <input
              style={{ display: "none" }}
              className='file-uploader'
              type='file'
              id='my-upload'
              accept='.xlsx, .xls'
              onChange={onChange}
            />
            <span className='upload-text'>Upload TapFile</span>
          </Button>
          <Button
            className='upload-wrap'
            onClick={() => {
              const a = document.getElementById("my-upload-new");
              a.click();
            }}
          >
            <input
              style={{ display: "none" }}
              className='file-uploader'
              type='file'
              id='my-upload-new'
              accept='.csv,'
              onChange={onChangeTapNew}
            />
            <span className='upload-text'>Upload TapFile NEW!!</span>
          </Button>
          <Button
            className='upload-wrap'
            style={{
              backgroundColor: COLORS.primary,
              color: "white",
            }}
            onClick={() => {
              const a = document.getElementById("noon-upload");
              a.click();
            }}
          >
            <input
              style={{ display: "none" }}
              className='file-uploader'
              type='file'
              id='noon-upload'
              accept='.xlsx, .xls'
              onChange={onChangeNOON}
            />
            <span className='upload-text'>Upload NOON</span>
          </Button>
          <Form
            name='payment-data'
            form={form}
            preserve={false}
            className='form-container'
            layout='inline'
            onFinish={getSettlementData}
          >
            <Form.Item
              name='date'
              label={intl.formatMessage({ id: "dateRange" })}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <RangePicker />
            </Form.Item>
            <Form.Item>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "send" })}
                className={`btnRegister btn-text border-8`}
                loading={loading}
                disabled={loading}
                type='primary'
              />
            </Form.Item>
          </Form>
        </div>

        <Table
          searchable
          fuzzySearch
          exportable
          exportableProps={{
            showColumnPicker: true,
            disabled: loading || formattedPayments?.length === 0,
            btnProps: {
              icon: <FileExcelOutlined />,
              type: "primary",
              style: { borderRadius: 7 },
              onClick: () =>
                customerExport({
                  data: formattedPayments,
                  columns,
                  fileName: "Payments Report",
                  loadingSetter: setLoading,
                }),
            },
            children: (
              <Text
                className='my-font'
                style={{
                  color:
                    formattedPayments?.length === 0 ? "#dddddd" : COLORS.white,
                }}
              >
                {intl.formatMessage({ id: "export" })}
              </Text>
            ),
          }}
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedPayments?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedPayments}
          scroll={{ x: "max-content" }}
          summary={(pageData) => {
            let total = 0;
            let totalPaid = 0;
            let remain = 0;
            pageData.forEach(({ paymentObject }) => {
              if (typeof +paymentObject?.totalNumber === "number") {
                if (paymentObject.didReceiveSettlement) {
                  totalPaid += +paymentObject.totalNumber;
                }
                total += +paymentObject?.totalNumber;
              }
            });
            remain = total - totalPaid;
            return (
              <MYT.Summary>
                <MYT.Summary.Row>
                  <MYT.Summary.Cell className='summary-bg-gray' colSpan={2}>
                    {intl.formatMessage({ id: "total" })}
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {total?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell className='summary-bg-gray' colSpan={2}>
                    {intl.formatMessage({ id: "settlAmount" })}
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell
                    colSpan={2}
                    style={{ backgroundColor: "red" }}
                  >
                    <Text type='success'>{totalPaid?.toFixed(2)}</Text>
                  </MYT.Summary.Cell>
                </MYT.Summary.Row>
                <MYT.Summary.Row>
                  <MYT.Summary.Cell className='summary-bg-gray' colSpan={2}>
                    {intl.formatMessage({ id: "remainAmount" })}
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text type='danger'>{remain?.toFixed(2)}</Text>
                  </MYT.Summary.Cell>
                </MYT.Summary.Row>
              </MYT.Summary>
            );
          }}
        />
      </Spin>
    </div>
  );
};

export default Settlement;
