import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Typography, Spin, Form, DatePicker, Space } from "antd";
import { useIntl } from "react-intl";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import {
  getPayments,
  getTotalToNowAPI,
  getAllActiveRestaurants,
} from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { setPaymentsAction } from "../../redux/reducers/restaurantsReducer";
import { Link } from "react-router-dom";
import { Table } from "ant-table-extensions";
import EditPaymentModal from "./Components/EditPaymentModal";
import dayjs from "dayjs";

const { Text, Title } = Typography;
const Payments = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { payments } = useSelector((state) => state.restaurants);
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formattedPayments, setFormattedPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showTotal, setShowTotal] = useState(false);
  const [loadingTotal, setLoadingTotal] = useState(false);
  const [totalTillNow, setTotalTillNow] = useState(0);

  const getPaymentsData = async (values) => {
    try {
      setLoading(true);
      let dateStart;
      let dateEnd;
      // check if values.data is === Sunday;
      if (values.date.day() === 0) {
        // if Sunday then make start date is the previous Thursday && end date is pervious Saturday;
        dateStart = dayjs(values.date).subtract(3, "days").format("YYYY-MM-DD");
        dateEnd = dayjs(values.date).subtract(1, "days").format("YYYY-MM-DD");
      } else if (values.date.day() === 4) {
        // if Thursday then make start date is pervious Sunday && end date is pervious Wednesday;
        dateStart = dayjs(values.date).subtract(4, "days").format("YYYY-MM-DD");
        dateEnd = dayjs(values.date).subtract(1, "days").format("YYYY-MM-DD");
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
        return;
      }
      let size = 500;
      let totalRest = await getAllActiveRestaurants();
      let totalRestaurants = totalRest?.data?.count;
      let totalPages = Math.ceil(totalRestaurants / size);
      let allRestaults = [];
      for (let i = 1; i <= totalPages; i++) {
        const query = `?dateStart=${dateStart}&dateEnd=${dateEnd}&page=${i}&pageSize=${size}`;
        const res = await getPayments(query);
        allRestaults.push(...res?.data?.restaurants);
      }
      if (allRestaults.length === totalRestaurants || true) {
        dispatch(setPaymentsAction(allRestaults));
        setLoading(false);
        openNotification({
          title: "success",
          description: "payments loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record) => {
        return <Link to={`/restaurants/${record.restaurantId}`}>{number}</Link>;
      },
      fixed: "left",
      index: 0,
    },
    {
      title: intl.formatMessage({ id: "restaurantName" }),
      dataIndex: "name",
      key: "name",
      width: 160,
      render: (name, record) => {
        return (
          <Space direction='vertical'>
            <Link
              to={
                record.restaurantId ? `/restaurants/${record.restaurantId}` : ""
              }
            >
              {rtl ? name : record?.name_en}
            </Link>
            <Text type='secondary'>{record?.officialName}</Text>
          </Space>
        );
      },
      fixed: "left",
      index: 1,
    },
    {
      title: intl.formatMessage({ id: "MADATotal" }),
      dataIndex: "MADATotal",
      key: "MADATotal",
      render: (amount) => {
        return <Text>{amount?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "VISATotal" }),
      dataIndex: "VISATotal",
      key: "VISATotal",
      render: (amount) => {
        return <Text>{amount?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "TABBYTotal" }),
      dataIndex: "TABBYTotal",
      key: "TABBYTotal",
      render: (amount) => {
        return <Text>{amount?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "WALLETTotal" }),
      dataIndex: "WALLETTotal",
      key: "WALLETTotal",
      render: (amount) => {
        return <Text>{amount?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "APPTotal" }),
      dataIndex: "APPTotal",
      key: "APPTotal",
      render: (amount) => {
        return <Text>{amount?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "total",
      key: "total",
      render: (amount) => {
        return <Text>{amount?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "MADATransferTotal" }),
      dataIndex: "MADATransferTotal",
      key: "MADATransferTotal",
      render: (amount) => {
        return <Text>{amount?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "VISATransferTotal" }),
      dataIndex: "VISATransferTotal",
      key: "VISATransferTotal",
      render: (amount) => {
        return <Text>{amount?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "TABBYTransferTotal" }),
      dataIndex: "TABBYTransferTotal",
      key: "TABBYTransferTotal",
      render: (amount) => {
        return <Text>{amount?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "deliveryAmountToTransferWithoutVat" }),
      dataIndex: "deliveryAmountToTransferWithoutVat",
      key: "deliveryAmountToTransferWithoutVat",
      render: (amount) => {
        return <Text>{amount?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "refundFees" }),
      dataIndex: "refundFees",
      key: "refundFees",
      render: (amount) => {
        return <Text>{amount?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "VATFees" }),
      dataIndex: "VATFees",
      key: "VATFees",
      render: (VATFees) => {
        return <Text>{VATFees?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "deliveryAmountVat" }),
      dataIndex: "deliveryAmountVat",
      key: "deliveryAmountVat",
      render: (deliveryAmountVat) => {
        return <Text>{deliveryAmountVat?.toFixed(2)}</Text>;
      },
    },

    {
      title: intl.formatMessage({ id: "countOfDeliveryOrders" }),
      dataIndex: "countOfDeliveryOrders",
      key: "countOfDeliveryOrders",
      render: (countOfDeliveryOrders) => {
        return (
          <Text>
            {countOfDeliveryOrders?.toFixed(2)} -{" "}
            <Tag>{(countOfDeliveryOrders * 2)?.toFixed(2)} SA</Tag>
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "totalRestaurantPaidAmount" }),
      dataIndex: "totalRestaurantPaidAmount",
      key: "totalRestaurantPaidAmount",
      render: (totalRestaurantPaidAmount) => {
        return <Text>{totalRestaurantPaidAmount?.toFixed(2)}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "subscriptionUnpaidFees" }),
      dataIndex: "subscriptionUnpaidFees",
      key: "subscriptionUnpaidFees",
    },
    {
      title: intl.formatMessage({ id: "amountToTransfer" }),
      dataIndex: "totalToTransfer",
      key: "totalToTransfer",
      render: (totalToTransfer, record) => {
        return (
          <Text
            copyable={{
              text:
                record?.subscriptionUnpaidFees >= totalToTransfer &&
                totalToTransfer > 0 &&
                record?.subscriptionUnpaidFees > 0
                  ? "0.00"
                  : record?.subscriptionUnpaidFees < totalToTransfer &&
                    totalToTransfer > 0 &&
                    record?.subscriptionUnpaidFees > 0
                  ? (totalToTransfer - record?.subscriptionUnpaidFees)?.toFixed(
                      2
                    )
                  : totalToTransfer?.toFixed(2),
            }}
            type={
              record?.subscriptionUnpaidFees >= totalToTransfer &&
              totalToTransfer > 0
                ? "danger"
                : "primary"
            }
          >
            {totalToTransfer?.toFixed(2)}
            {record?.subscriptionUnpaidFees < totalToTransfer &&
            totalToTransfer > 0 &&
            record?.subscriptionUnpaidFees > 0 ? (
              <Text type='warning'>
                {" "}
                -{" "}
                {(totalToTransfer - record?.subscriptionUnpaidFees)?.toFixed(2)}
              </Text>
            ) : null}
          </Text>
        );
      },
    },

    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag
            color={status === "Success" ? "success" : "red"}
            className='my-font'
          >
            {status}
          </Tag>
        );
      },
      onFilter: (value, record) => {
        if (value === "Success") {
          return record.status === "Success";
        } else {
          return record.status !== "Success";
        }
      },
      filters: [
        {
          text: "Success",
          value: "Success",
        },
        {
          text: "Pending",
          value: "Pending",
        },
      ],
    },

    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "edit",
      key: "edit",
      render: (actions, record) => {
        return (
          <CustomButton
            onClick={() => {
              setSelectedPayment(record?.paymentObject);
              setShowModal(true);
            }}
            type='primary'
            className='border-8'
            icon={<EditOutlined />}
            text={intl.formatMessage({ id: "edit" })}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (payments?.length) {
      const formatted = payments.map((payment, i) => {
        let subscriptionUnpaidFees = 0;
        let totalSubscriptionFees = 0;
        let totalSubscriptionPaidFees = 0;
        if (payment?.serviceCount > 0) {
          const { serviceTotal, servicePaid, serviceRemain } = payment;
          subscriptionUnpaidFees = serviceRemain;
          totalSubscriptionFees = serviceTotal;
          totalSubscriptionPaidFees = servicePaid;
        }
        let WALLETTotal = 0;
        let APPTotal = 0;
        if (
          payment?.walletPaymentInfo?.walletAmountTotalAfterCuttingAllFees > 0
        ) {
          WALLETTotal =
            payment?.walletPaymentInfo?.walletAmountTotalAfterCuttingAllFees;
        }
        if (
          payment?.appPaymentInfo?.appAmountNoWalletTotalAfterCuttingAllFees > 0
        ) {
          APPTotal =
            payment?.appPaymentInfo?.appAmountNoWalletTotalAfterCuttingAllFees;
        }
        let totalToTransfer =
          payment?.MADATransferTotal +
          payment?.VISATransferTotal +
          payment?.TABBYTransferTotal +
          payment?.deliveryAmountToTransferWithoutVat -
          payment?.VATFees -
          payment?.totalRefundFees -
          payment?.deliveryAmountVat +
          WALLETTotal +
          APPTotal;

        return {
          key: payment.restaurantId,
          number: i + 1,
          name: payment.name,
          name_en: payment.name_en,
          amountToTransfer: payment?.amountToTransfer,
          MADATotal: payment?.MADATotal,
          VISATotal: payment?.VISATotal,
          TABBYTotal: payment?.TABBYTotal,
          officialName: payment?.officialName,
          iban: payment?.iban,
          bankId: payment?.bankId,
          // total: payment?.total,
          MADATransferTotal: payment?.MADATransferTotal,
          VISATransferTotal: payment?.VISATransferTotal,
          TABBYTransferTotal: payment.TABBYTransferTotal,
          deliveryAmountToTransferWithoutVat:
            payment?.deliveryAmountToTransferWithoutVat,
          deliveryAmountVat: payment?.deliveryAmountVat,
          totalToTransfer,
          VATFees: payment?.VATFees,
          end: payment?.restaurant?.end,
          start: payment?.restaurant?.start,
          restaurantId: payment?.restaurantId,
          orderCount: payment?.orderCount,
          total: payment?.total,
          status: payment?.status,
          refundFees: payment?.totalRefundFees,
          totalRefundAmount: payment?.totalRefundAmount,
          totalDeliveryAmount: payment?.totalDeliveryAmount,
          countOfDeliveryOrders: payment?.countOfDeliveryOrders,
          totalRestaurantPaidAmount: payment?.totalRestaurantPaidAmount,
          totalDeliveryCost: payment?.totalDeliveryCost,
          iwaiterCommission: payment?.iwaiterCommission,
          subscriptionUnpaidFees,
          totalSubscriptionFees,
          totalSubscriptionPaidFees,
          WALLETTotal,
          APPTotal,
          paymentObject: {
            ...payment,
            totalToTransfer,
            subscriptionUnpaidFees,
            subscriptionUnpaidFees,
            totalSubscriptionFees,
            totalSubscriptionPaidFees,
          },
        };
      });
      let sorted = formatted
        .sort((a, b) => {
          return b.totalToTransfer - a.totalToTransfer;
        })
        .map((item, i) => {
          return {
            ...item,
            number: i + 1,
          };
        });
      setFormattedPayments(sorted);
    }
    setLoading(false);
  }, [rtl, payments]);

  const getTotalToNow = async () => {
    try {
      setLoadingTotal(true);
      const res = await getTotalToNowAPI();
      if (res?.data?.status === "success") {
        setTotalTillNow(res.data.totalAmount);
        setLoadingTotal(false);
      } else {
        setLoadingTotal(false);
      }
    } catch (error) {
      setLoadingTotal(false);
    }
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <EditPaymentModal
          show={showModal}
          hide={() => {
            setShowModal(false);
            setSelectedPayment(null);
          }}
          payment={selectedPayment}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
            onClick={() => {
              getTotalToNow();
            }}
          >
            {intl.formatMessage({ id: "payments" })}
          </Title>
          <Spin spinning={loadingTotal} />
          <Text>{totalTillNow && !loadingTotal ? totalTillNow : ""}</Text>
          <Form
            name='payment-data'
            form={form}
            preserve={false}
            className='form-container'
            layout='inline'
            onFinish={getPaymentsData}
          >
            <Form.Item
              name='date'
              label={intl.formatMessage({ id: "transferTime" })}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                disabledDate={(currentDate) => {
                  // if the day is not Sunday or Thursday, disabled it;
                  if (currentDate.day() !== 0 && currentDate.day() !== 4) {
                    return true;
                  }
                  return false;
                }}
              />
            </Form.Item>
            <Form.Item>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "send" })}
                className={`btnRegister btn-text border-8`}
                loading={loading}
                disabled={loading}
                type='primary'
              />
            </Form.Item>
          </Form>
        </div>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedPayments?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedPayments}
          scroll={{ x: "max-content" }}
        />
      </Spin>
    </div>
  );
};

export default Payments;
