import axios from "axios";
import { api } from "./index";

export const signin = (data) => {
  return api.post(`/sign-in`, data);
};

export const postLogOut = () => {
  return api({
    method: "DELETE",
    url: `/sign-out`,
  });
};
export const getAdminData = () => {
  return api.get(`/admin-data`);
};

export const getRestaurants = () => {
  return api.get(`/admin-restaurants`);
};

export const getActivityReports = (data) => {
  return api.get(
    `/activity-reports?startDate=${data.start}&endDate=${data.end}&pageSize=${data.size}&pageNumber=${data.page}`
  );
};
export const getUsers = () => {
  return api.get(`/admin-users`);
};

export const getSuperUsers = () => {
  return api.get("/admin-supers-users");
};

export const createSuperUser = (data) => {
  return api.post("/create-super-user", data);
};

export const updateSuperUser = (data, id) => {
  return api.patch(`/admin-update-supers-users/${id}`, data);
};

export const deleteSuperUser = (id) => {
  return api.delete(`/admin-delete-supers-users/${id}`);
};

export const getIntegrations = () => {
  return api.get(`/admin-integrations`);
};
export const getRefers = () => {
  return api.get(`/admin-refers`);
};

export const patchRefer = (id, data) => {
  return api.patch(`/refers/${id}`, data);
};

export const postRefer = (data) => {
  return api.post(`/refers`, data);
};

export const sendMessage = (data) => {
  return api.post(`/send-message`, data);
};

export const getPayments = (query) => {
  return api.get(`/restaurants-wallets${query}`, { timeout: 500000 });
};

export const getRestaurant = (id) => {
  return api.get(`/admin-restaurants/${id}`);
};

export const updateRestaurant = (id, data) => {
  return api.patch(`/admin-restaurants/${id}`, data);
};

export const deleteRestaurant = (id) => {
  return api.delete(`/delete-restaurant/${id}`);
};

export const postPrivateWallet = (data) => {
  return api.post(`/create-wallet`, data);
};
export const cancelPrivateWallet = (data) => {
  return api.patch(`/cancel-wallet`, data);
};

export const createDefaultWallet = (data) => {
  return api.patch(`/create-default-wallet`, data);
};

export const getIntegration = (id) => {
  return api.get(`/admin-integrations/${id}`, {
    timeout: 100000,
  });
};

export const addBranch = (id, data) => {
  return api.post(`/admin-restaurants/${id}/branches`, data);
};

export const updateIntegration = (id, data) => {
  return api.patch(`/integrations/${id}`, data);
};

export const updateIntegrationForFood = (id, data) => {
  return api.patch(`/admin-integrations-food/${id}`, data);
};

export const createTablesLikeFoodics = (data) => {
  return api.post(`/create-tables-foodics`, data);
};

export const createFoodicsMenu = (data) => {
  return api.post(`/create-menu-foodics`, data);
};

export const createTransaction = (data) => {
  return api.post(`/paymentTransactions`, data);
};

export const removeIntegration = (id) => {
  return api.delete(`/integrations/${id}`);
};

export const getTotalToNowAPI = () => {
  return api.get(`/admin-total-to-now`);
};

export const getSettlements = (query) => {
  return api.get(`/payment-settlements/${query}`);
};

export const updatePaymentSettlements = (data) => {
  return api.patch(`/update-payment-settlements`, data, {
    timeout: 1000 * 60 * 50,
  });
};

export const getAllEmails = () => {
  return api.get(`/admin-users-emails`);
};

export const sendWhatsAppMessage = (data) => {
  return axios({
    method: "POST",
    // url: "https://wa.iwaiter.club/v1/send-whats",
    url: "http://localhost:3007/v1/send-whats",
    headers: {
      Authorization: `Bearer 9410348A3249B87446B2E0FCD63A8E47`,
    },
    data: {
      message: data.message,
      customerPhone: data.phone,
    },
  });
};

export const getPendingPayments = (query) => {
  return api.get(`/pending-payments/${query}`);
};
export const getSettlementInfoPayments = (query) => {
  return api.get(`/settlementInfo${query}`);
};
export const getSettlementInfoForPayments = (query) => {
  return api.get(`/payment-settlementInfo${query}`, {
    timeout: 1000 * 60 * 10,
  });
};
export const getSettlementByTransactionNumber = (number) => {
  return api.get(`/settlementInfo/${number}`, {
    timeout: 1000 * 60 * 10,
  });
};

export const verifyPayment = (id) => {
  return api.get(`/verify-payment/${id}`);
};
export const verifyPrivatePayment = (id, restaurantId) => {
  return api.get(`/verify-payment/${id}?restaurantId=${restaurantId}`);
};

export const verifyPaymentReservation = (id, reservationId) => {
  return api.get(`/verify-payment/${id}?reservationId=${reservationId}`);
};
export const verifyPrivatePaymentReservation = (
  id,
  restaurantId,
  reservationId
) => {
  return api.get(
    `/verify-payment/${id}?restaurantId=${restaurantId}&reservationId=${reservationId}`
  );
};

export const deleteUserFromDB = (id) => {
  return api.delete(`/admin-users/${id}`);
};

export const sendMessageWithImage = (data) => {
  return axios({
    method: "POST",
    url: "http://localhost:3007/v1/send-report",
    headers: {
      Authorization: `Bearer 9410348A3249B87446B2E0FCD63A8E47`,
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};

// export const getBankList = () => {
//   return api.get(`/my-fatoorah-banks`);
// };
export const getSuppliersPaymentInfo = () => {
  return api.get(`/my-fatoorah-payments`);
};

export const createMyFatoorah = (data) => {
  return api.post("/create-my-fatoorah", data);
};

export const getMyFatoorahAccount = (code) => {
  return api.get(`/my-fatoorah-account/${code}`);
};

export const addService = (data) => {
  return api.post("/services", data);
};

export const getBanks = () => {
  return api.get(`/my-fatoorah-banks`);
};

export const editWalletRestaurant = (data, id) => {
  return api.patch(`/wallets/${id}`, data);
};
export const getAppRestaurants = () => {
  return api.get(`/app-restaurants`);
};

export const editAppRestaurantInfo = (appId, data) => {
  return api.patch(`/app-restaurants/${appId}`, data);
};

export const getUsersApplication = () => {
  return api.get(`/users-application`);
};
export const getUsersApplicationTerms = (data) => {
  return api.post(`/users-application-filter`, data);
};

export const sendApplicationNotification = (data) => {
  return api.post(`/send-application-notification`, data);
};

export const updateService = (id, data) => {
  return api.patch(`/services/${id}`, data);
};

export const addRestaurantToTheApp = (id) => {
  return api.post(`/app-restaurants/${id}`);
};

export const updateBranchLocation = (id, data) => {
  return api.patch(`/branches-location/${id}`, data);
};

export const sendEmails = (data) => {
  return api.post(`/send-emails`, data);
};

export const editRestaurantGeneralInfo = (data) => {
  return api.patch(`/edit-general-info`, data);
};

export const deleteRestaurantMenu = (data) => {
  return api.patch(`/delete-restaurant-menu`, data);
};

export const patchPaymentTransaction = (paymentId, data) => {
  return api.patch(`/paymentTransactions/${paymentId}`, data);
};
export const checkAuth = () => {
  return axios({
    method: "GET",
    url: "https://wa.iwaiter.club/v1/checkauth",
    headers: {
      Authorization: `Bearer 9410348A3249B87446B2E0FCD63A8E47`,
    },
  });
};

export const getWhatsappQr = () => {
  return axios({
    method: "GET",
    url: "https://wa.iwaiter.club/v1/getqr",
    headers: {
      Authorization: `Bearer 9410348A3249B87446B2E0FCD63A8E47`,
    },
    timeout: 1000 * 60 * 10,
  });
};

export const resetWhatsaapQr = () => {
  return axios({
    method: "GET",
    url: "https://wa.iwaiter.club/v1/resetqr",
    headers: {
      Authorization: `Bearer 9410348A3249B87446B2E0FCD63A8E47`,
    },
  });
};

export const getDeliveryData = () => {
  return api.get(`/delivery-data`);
};

export const editEWalletStatus = (userId, data) => {
  return api.patch(`/edit-e-wallet-status/${userId}`, data);
};
export const addUserBalance = (userId, data) => {
  return api.patch(`/add-deposit/${userId}`, data);
};

export const createZohoInvoiceAPI = (transactionId) => {
  return api.post(`/create-zoho-invoice/${transactionId}`);
};
export const getWallets = () => {
  return api.get(`/tap-wallets`);
};

export const getOneWallet = (id) => {
  return api.get(`/tap-wallets/${id}`);
};

export const uploadFileToTap = (data) => {
  return api.post(`/tap-upload-file`, data);
};

export const createTapBusiness = (data) => {
  return api.post(`/tap-create-business`, data);
};

export const changeWallet = (data) => {
  return api.post(`/tap-change-wallet`, data);
};

export const getRestaurantsWithWallets = () => {
  return api.get(`/wallets-admin`);
};

export const getRestaurantBalance = (id) => {
  return api.get(`/wallets-admin/${id}`);
};

export const getAllCompetition = () => {
  return api.get(`/competitions`);
};

export const postCompetition = (data) => {
  return api.post(`/competitions`, data);
};

export const patchCompetition = (id, data) => {
  return api.patch(`/competitions/${id}`, data);
};

export const createWinner = (competitionId, data) => {
  return api.post(`/winners/${competitionId}`, data);
};

export const getPotionalWinner = (competitionId, date) => {
  return api.get(`/potional-winners/${competitionId}?date=${date}`);
};

export const getTipPayments = (query) => {
  return api.get(`/restaurants-tips${query}`, { timeout: 500000 });
};

export const createTipTransaction = (data) => {
  return api.post(`/tipTransactions`, data);
};

export const createMarnMenu = (data) => {
  return api.post(`/create-menu-marn`, data);
};

export const createTablesLikeMarn = (data) => {
  return api.post(`/create-tables-marn`, data);
};

export const uploadToMyFatoorahAPI = (restaurantId, name) => {
  return api.post(
    `/wallets/document/admin/addToMyFatoorah?restaurantId=${restaurantId}&name=${name}`
  );
};

export const getAllActiveRestaurants = () => {
  return api.get(`/active-restaurants`);
};

export const verifyMyFatoorahPayment = (id, restaurantId) => {
  return api.get(
    `/verify-my-fatoorah-payment/${id}?restaurantId=${restaurantId}`
  );
};

export const verifyMyFatoorahPaymentReservation = (
  id,
  restaurantId,
  reservationId
) => {
  return api.get(
    `/verify-my-fatoorah-payment/${id}?restaurantId=${restaurantId}&reservationId=${reservationId}`
  );
};

export const getAllChallenges = () => {
  return api.get(`/challenges-admin`);
};

export const getOneChallenge = (id) => {
  return api.get(`/challenges-admin/${id}`);
};

export const deleteChallenge = (id) => {
  return api.delete(`/challenges-admin/${id}`);
};

export const createChallenges = (data) => {
  return api.post(`/challenges-admin`, data);
};

export const getGameWalletAmount = (query) => {
  return api.get(`/game-wallets${query}`);
};

export const createWalletAmountGamesTransaction = (data) => {
  return api.post(`/walletGameTransaction`, data);
};

export const getOneUsersApplication = (id) => {
  return api.get(`/users-application/${id}`);
};

export const sendNotificationsUsers = (data) => {
  return api.post(`/users-application-notifications`, data);
};

export const getAppGameData = (start, end) => {
  return api.get(`/admin-game-data?startDate=${start}&endDate=${end}`);
};

export const editBranchApi = (id, data) => {
  return api.patch(`/admin-branches/${id}`, data);
};

export const getPaymentDetails = (data) => {
  return api.post(`/settlements-details`, data, {
    timeout: 1000 * 60 * 10,
  });
};
