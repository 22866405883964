import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Spin,
  Form,
  DatePicker,
  Space,
  Tag,
  Descriptions,
} from "antd";
import { useIntl } from "react-intl";
import { FileExcelOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import dayjs from 'dayjs';
import {
  getSettlementByTransactionNumber,
  getSettlementInfoPayments,
} from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import { Table as MYT } from "antd";
import { customerExport, getIwaiterVatAmount } from "../../helpers";
const { RangePicker } = DatePicker;

const { Text, Title } = Typography;
const SettlementInfo = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formattedPayments, setFormattedPayments] = useState([]);
  const [transactionsNumbers, setTransactionsNumbers] = useState([]);
  const [selected, setSelected] = useState("");
  const [selectDate, setSelectDate] = useState("");
  const [transactionData, setTransactionData] = useState({});

  const getTransactionsNumbers = async (values) => {
    try {
      setLoading(true);
      let dateStart = dayjs(values.date[0]).format("YYYY-MM-DD");
      let dateEnd = dayjs(values.date[1]).format("YYYY-MM-DD");
      const query = `?dateStart=${dateStart}&dateEnd=${dateEnd}`;
      const res = await getSettlementInfoPayments(query);
      if (res?.data?.status === "success") {
        let result = res.data.transactionNumbers;
        // sort by date;
        let sorted = result.sort((a, b) => {
          return new Date(a.transactionDate) - new Date(b.transactionDate);
        });
        setTransactionsNumbers(sorted);
        setLoading(false);
        openNotification({
          title: "success",
          description: "payments loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      key: "index",
      width: 80,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "restaurantName" }),
      dataIndex: "name",
      key: "name",
      render: (name, record) => {
        return (
          <Text type={record?.isSubscriptions ? "success" : record?.isTip ? "warning" : ""}>
            {record?.isSubscriptions ? `رسوم اشتراك ${name}` : name}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "officialName" }),
      dataIndex: "officialName",
      key: "officialName",
      render: (name, record) => {
        return <Text>{name}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "originalTotal" }),
      dataIndex: "originalTotal",
      key: "originalTotal",
    },
    {
      title: intl.formatMessage({ id: "tapFees" }),
      dataIndex: "tapFees",
      key: "tapFees",
    },
    {
      title: intl.formatMessage({ id: "refundAmount" }),
      dataIndex: "refundAmount",
      key: "refundAmount",
    },
    {
      title: intl.formatMessage({ id: "refundFees" }),
      dataIndex: "refundFees",
      key: "refundFees",
    },
    // {
    //   title: intl.formatMessage({ id: "iwaierFees" }),
    //   dataIndex: "iwaierFees",
    //   key: "iwaierFees",
    // },
    // {
    //   title: intl.formatMessage({ id: "iwaiterVAT" }),
    //   dataIndex: "iwaiterVAT",
    //   key: "iwaiterVAT",
    // },
    {
      title: intl.formatMessage({ id: "iwaiterFeesWithVat" }),
      dataIndex: "iwaiterFeesWithVat",
      key: "iwaiterFeesWithVat",
    },
    {
      title: intl.formatMessage({ id: "deliveryIwaiterFees" }),
      dataIndex: "deliveryIwaiterFees",
      key: "deliveryIwaiterFees",
    },
    {
      title: intl.formatMessage({ id: "restaurantPaidAmount" }),
      dataIndex: "restaurantPaidAmount",
      key: "restaurantPaidAmount",
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "amountToTransfer",
      key: "amountToTransfer",
      render: (amountToTransfer) => {
        return (
          <Text type={parseFloat(amountToTransfer) > 0 ? 'primary': 'danger'} >
            {amountToTransfer}
          </Text>
        );
      }
    },

    {
      title: intl.formatMessage({ id: "ordersTotal" }),
      dataIndex: "count",
      key: "count",
    },
  ];

  const setFormatPayments = (restaurants, newData) => {
    let  totalAmountBeforeFees = newData?.totalAmountBeforeFees;
    let paymentFees = newData?.paymentFees; 
    let totalAfterFees = newData?.totalAfterFees;
    if(!newData?.totalAmountBeforeFees){
      totalAmountBeforeFees = 0;
      paymentFees = 0;
      totalAfterFees = 0;
    }
    let totalTapFees = 0;
    let totalOriginalTotal = 0;

    const formatted = restaurants.map((restaurant, i) => {
      // subtract iwaiter percentage from total;
      let amountToTransfer = 0;
      if (restaurant.total > 0 && !restaurant.isSubscriptions) {
        let defaultPercentage = 2.8;
        let defaultMadaPercentage = 1.9;
        let defaultTabbyPercentage = 9;
        if (restaurant.percentageForIwaiter) {
          defaultPercentage = restaurant.percentageForIwaiter;
        }
        if (restaurant.percentageForIWaiterMADA) {
          defaultMadaPercentage = restaurant.percentageForIWaiterMADA;
        }
        if (restaurant.percentageForIWaiterTabby) {
          defaultTabbyPercentage = restaurant.percentageForIWaiterTabby;
        }
        let madaAmountToTransfer =
          restaurant.madaTotal -
          (restaurant.madaTotal * defaultMadaPercentage) / 100;
        let visaAmountToTransfer =
          restaurant.visaTotal -
          (restaurant.visaTotal * defaultPercentage) / 100;
        let tabbyAmountToTransfer = 0;
        if (restaurant.tabbyTotal) {
          tabbyAmountToTransfer =
            restaurant.tabbyTotal -
            (restaurant.tabbyTotal * defaultTabbyPercentage) / 100;
        }
        // subtarc default percentage from total
        amountToTransfer =
          madaAmountToTransfer + visaAmountToTransfer + tabbyAmountToTransfer;
        // get the vat;
      } else if (restaurant.isSubscriptions) {
        let vatIs = getIwaiterVatAmount({ amount: restaurant.total });
        amountToTransfer = vatIs + restaurant.total;
      }
     
      let totalFeesWithTap = restaurant?.total - amountToTransfer;
      let vatForTotalFees =  getIwaiterVatAmount({ amount: totalFeesWithTap });
      let totalWithVatAll = totalFeesWithTap + vatForTotalFees;
      let iwaiterFeesIncludingVat = totalWithVatAll - restaurant?.totalFees;
      let withoutVat = iwaiterFeesIncludingVat / 1.15
      let iwaiterVatFees1 = iwaiterFeesIncludingVat - withoutVat;
      let vatFees = 0;
      let iwaiterFees =
        restaurant?.total - amountToTransfer - restaurant?.totalFees;
      let originalTotal = restaurant.total;
      if (dayjs(selectDate).isAfter(dayjs("2023-02-01", "YYYY-MM-DD"))) {
        vatFees = getIwaiterVatAmount({ amount: iwaiterFees });
        amountToTransfer = amountToTransfer - vatFees;
      }
      if (restaurant?.isSubscriptions) {
        let vatIs = getIwaiterVatAmount({ amount: restaurant.total });
        iwaiterFees = 0;
        vatFees = parseFloat("0.00");
        originalTotal = vatIs + restaurant?.total;
      }
      totalTapFees += parseFloat(restaurant?.totalFees?.toFixed(2));
      totalOriginalTotal += parseFloat(originalTotal?.toFixed(2));
      let allTotalFinal = restaurant?.total - restaurant?.totalFees - iwaiterVatFees1 - withoutVat - restaurant?.deliveryIwaiterFees - restaurant?.deliveryRestaurantPaidAmount;
      let iwaiterFeesWithVat = withoutVat + iwaiterVatFees1;
      // calculate the fees after detacting delivery commission and restaurnat paid amount;
      // iwaiterFeesWithVat += restaurant?.deliveryIwaiterFees;
      // iwaiterFeesWithVat += restaurant?.deliveryRestaurantPaidAmount;
      // Start tip calculation;
        if(restaurant?.isTip){
          let tipAmountToTransfer = restaurant?.total;
          let iwaiterSharePrecentage = restaurant?.percentageForIwaiter;
          
          let iwaiterTipFees = (tipAmountToTransfer * iwaiterSharePrecentage) / 100;
          let iwaiterTipVat = iwaiterTipFees * 0.15;
          
          tipAmountToTransfer = tipAmountToTransfer - (iwaiterTipFees + iwaiterTipVat);

          return {
            key: `${restaurant.name}-tip`,
            name: restaurant.name,
            count: restaurant.count,
            amountToTransfer: tipAmountToTransfer?.toFixed(2),
            isSubscriptions: false,
            isTip: true,
            officialName: restaurant.officialName,
            iwaiterVAT: iwaiterTipVat,
            iwaierFees: iwaiterTipFees,
            originalTotal: restaurant?.total?.toFixed(2) || 0,
            tapFees: restaurant?.totalFees?.toFixed(2) || 0,
            refundAmount: restaurant?.refundAmount?.toFixed(2) || 0,
            refundFees: restaurant?.refundFees?.toFixed(2) || 0,
            iwaiterFeesWithVat: iwaiterTipFees + iwaiterTipVat ,
            deliveryCount: restaurant?.deliveryCount,
            deliveryIwaiterFees: restaurant?.deliveryIwaiterFees || 0,
            restaurantPaidAmount: restaurant?.restaurantPaidAmount || 0,
          }
        }
      // End tip calculation;
      return {
        key: `${restaurant.name}-${restaurant.isSubscriptions ? "-s" : ""}`,
        name: restaurant.name,
        count: restaurant.count,
        amountToTransfer:restaurant?.isSubscriptions ? 0 : restaurant?.refundAmount >0 ? (allTotalFinal - (restaurant?.refundAmount + restaurant?.refundFees))?.toFixed(2) : allTotalFinal?.toFixed(2),
        isSubscriptions: restaurant.isSubscriptions,
        officialName: restaurant.officialName,
        iwaiterVAT: restaurant?.isSubscriptions ? 0 : iwaiterVatFees1?.toFixed(2),
        iwaierFees: restaurant?.isSubscriptions ? 0: withoutVat?.toFixed(2),
        originalTotal: originalTotal?.toFixed(2) || 0,
        tapFees: restaurant?.totalFees?.toFixed(2) || 0,
        refundAmount: restaurant?.refundAmount?.toFixed(2) || 0,
        refundFees: restaurant?.refundFees?.toFixed(2) || 0,
        iwaiterFeesWithVat: restaurant?.isSubscriptions ? 0 : iwaiterFeesWithVat?.toFixed(2),
        deliveryCount: restaurant?.deliveryCount,
        deliveryIwaiterFees: restaurant?.deliveryIwaiterFees || 0,
        restaurantPaidAmount: restaurant?.restaurantPaidAmount || 0,
      };
    });
    // check for missing data;
    if (totalOriginalTotal !== totalAmountBeforeFees && newData?.totalAmountBeforeFees) {
      formatted.push({
        key: "UnNamed",
        name: "غير معروف",
        count: 0,
        amountToTransfer: 0,
        isSubscriptions: true,
        officialName: "غير معروف",
        iwaiterVAT: 0,
        iwaierFees: 0,
        originalTotal: (totalAmountBeforeFees - totalOriginalTotal)?.toFixed(2),
        tapFees: (paymentFees - totalTapFees)?.toFixed(2),
      });
    }
    setFormattedPayments(formatted);
  };

  const getSettlementInfo = async (number) => {
    try {
      setLoading(true);
      setTransactionData({})
      const res = await getSettlementByTransactionNumber(number);
      if (res.data.status === "success") {
        setTransactionData(res.data?.overviewData);
        setFormatPayments(res.data.settlementInfo, res.data?.overviewData);
        openNotification({
          title: "success",
          description: "success",
          type: "success",
          rtl: true,
        });
        setLoading(false);
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "settlementInfo" })}
          </Title>

          <Form
            name='payment-data'
            form={form}
            preserve={false}
            className='form-container'
            layout='inline'
            onFinish={getTransactionsNumbers}
          >
            <Form.Item
              name='date'
              label={intl.formatMessage({ id: "dateRange" })}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <RangePicker />
            </Form.Item>
            <Form.Item>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "send" })}
                className={`btnRegister btn-text border-8`}
                loading={loading}
                disabled={loading}
                type='primary'
              />
            </Form.Item>
          </Form>
        </div>
        <Space
          direction='horizontal'
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          className='my-4'
        >
          {transactionsNumbers?.length
            ? transactionsNumbers.map(
                ({ transactionDate, transactionNumber }) => {
                  return (
                    <CustomButton
                      key={transactionNumber}
                      text={
                        <Text
                          style={{
                            color:
                              selected === transactionNumber ? "#fff" : "#000",
                          }}
                        >
                          {transactionNumber}
                          <Tag color={"cyan"} className='mx-3'>
                            {dayjs(transactionDate).format("DD/MM/YYYY")}
                          </Tag>
                        </Text>
                      }
                      className={`btnRegister btn-text border-8`}
                      loading={loading}
                      disabled={loading}
                      type={
                        selected === transactionNumber ? "primary" : "secondary"
                      }
                      onClick={() => {
                        setSelected(transactionNumber);
                        setSelectDate(transactionDate);
                        setFormatPayments([], {});
                        getSettlementInfo(transactionNumber);
                      }}
                    />
                  );
                }
              )
            : null}
        </Space>
        <Space direction='horizontal' size={20}>
          <Descriptions
            title={intl.formatMessage({ id: "settlementInfo" })}
            layout='vertical'
            bordered
            className='my-font'
          >
            <Descriptions.Item
              label={intl.formatMessage({ id: "transactionNumber" })}
            >
              {selected}
            </Descriptions.Item>
            <Descriptions.Item label={intl.formatMessage({ id: "date" })}>
              {dayjs(selectDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "totalAmountBeforeFees" })}
            >
              {transactionData?.totalAmountBeforeFees}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "refundAmount" })}
            >
              {transactionData?.totalRefundAmount}
            </Descriptions.Item>
            
            <Descriptions.Item
              label={intl.formatMessage({ id: "paymentFees" })}
            >
              {transactionData?.paymentFees}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "totalAfterFees" })}
            >
              {transactionData?.totalAfterFees - (transactionData?.totalRefundAmount)}
            </Descriptions.Item>
          </Descriptions>
        </Space>
        <Table
          exportable
          exportableProps={{
            showColumnPicker: true,
            disabled: loading || formattedPayments?.length === 0,
            btnProps: {
              icon: <FileExcelOutlined />,
              type: "primary",
              style: { borderRadius: 7 },
              onClick: () =>
                customerExport({
                  data: formattedPayments,
                  columns,
                  fileName: "Payments Report",
                  loadingSetter: setLoading,
                }),
            },
            children: (
              <Text
                className='my-font'
                style={{
                  color:
                    formattedPayments?.length === 0 ? "#dddddd" : COLORS.white,
                }}
              >
                {intl.formatMessage({ id: "export" })}
              </Text>
            ),
          }}
          pagination={false}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedPayments}
          // scroll={{ x: 'max-content' }}
          summary={(pageData) => {
            let total = 0;
            let totalTapFees = 0;
            let totalOriginalTotal = 0;
            let totalIwaierFees = 0;
            let totalVatFees = 0;
            let totalCount = 0;
            let totalRefundAmount = 0;
            let totalRefundFees = 0;
            let totalIwaierFeesWithVat = 0;
            let totalDeliveryIwaiterFees = 0;
            let totalDeliveryRestaurantPaidAmount = 0;
            pageData.forEach(
              ({
                amountToTransfer,
                tapFees,
                originalTotal,
                iwaierFees,
                iwaiterVAT,
                count,
                refundAmount,
                refundFees,
                iwaiterFeesWithVat,
                deliveryIwaiterFees,
                restaurantPaidAmount,
              }) => {
                  if(parseFloat(amountToTransfer) > 0){
                    total += parseFloat(amountToTransfer);
                  }else {
                    console.log(amountToTransfer)
                  }
                totalTapFees += parseFloat(tapFees);
                totalOriginalTotal += parseFloat(originalTotal);
                totalIwaierFees += parseFloat(iwaierFees);
                totalVatFees += parseFloat(iwaiterVAT);
                totalCount += parseFloat(count);
                if(refundAmount > 0){
                totalRefundAmount += parseFloat(refundAmount);
                }
                if(refundFees > 0){
                  totalRefundFees += parseFloat(refundFees);
                }
                if(iwaiterFeesWithVat){
                  totalIwaierFeesWithVat += parseFloat(iwaiterFeesWithVat);
                }
                if(deliveryIwaiterFees){
                  totalDeliveryIwaiterFees += parseFloat(deliveryIwaiterFees);
                }
                if(restaurantPaidAmount){
                  totalDeliveryRestaurantPaidAmount += parseFloat(restaurantPaidAmount);
                }
              }
            );

            return (
              <MYT.Summary>
                <MYT.Summary.Row>
                  <MYT.Summary.Cell className='summary-bg-gray' colSpan={3}>
                    {intl.formatMessage({ id: "total" })}
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalOriginalTotal?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalTapFees?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalRefundAmount?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalRefundFees?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalIwaierFeesWithVat?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalDeliveryIwaiterFees?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalDeliveryRestaurantPaidAmount?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {total?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalCount?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                </MYT.Summary.Row>
              </MYT.Summary>
            );
          }}
        />
      </Spin>
    </div>
  );
};

export default SettlementInfo;
