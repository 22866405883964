import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Spin,
  Input,
  InputNumber,
} from "antd";
import openNotification from "../../../Components/Notifications";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import { addBranch,  } from "../../../API/fetch";


const AddBranchForm = ({ restaurant, setRestaurant }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      let data = {
        ...values,
      }
      const res = await addBranch(restaurant._id, data);
      if (res?.data?.restaurant) {
        setRestaurant(res.data.restaurant);
        form.resetFields()
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 500);
  }, []);
  return (
    <Spin spinning={loading}>
      <Form
        name='add-branch-form'
        form={form}
        className='form-container'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          name: "",
          name_en: "",
          cityName: "",
          tablesCount: 10,
          startFrom: 1,
        }}
      >
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          {/*Names */}
          <Col md={8} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "nameAr" })}
              className='mb-1'
              name='name'
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <Input className={"input"} />
            </Form.Item>
          </Col>
          <Col md={8} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "nameEn" })}
              className='mb-1'
              name='name_en'
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <Input className={"input"} />
            </Form.Item>
          </Col>
          {/* city */}
          <Col md={8} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "cityName" })}
              className='mb-1'
              name='cityName'
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "errorname" }),
                },
              ]}
            >
              <Input className={"input"} />
            </Form.Item>
          </Col>
        </Row>
        <Row className='mt-4' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          {/* Table  */}
          <Col md={8} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "tablesCount" })}
              className='mb-1'
              name='tablesCount'
              style={{ borderRadius: 20 }}
            >
              <InputNumber min={1} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col md={8} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "startFrom" })}
              className='mb-1'
              name='startFrom'
              style={{ borderRadius: 20 }}
            >
              <InputNumber min={1} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Form.Item>
            <CustomButton
              htmlType='submit'
              className={`btnRegister btn-text border-8`}
              loading={loading}
              type='primary'
              text={intl.formatMessage({ id: "save" })}
              style={{ minWidth: 100 }}
            />
          </Form.Item>
        </Row>
      </Form>
    </Spin>
  );
};

export default AddBranchForm;
