import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Spin,
  Form,
  DatePicker,
  Space,
  Tag,
  Descriptions,
  Checkbox,
  Divider,
} from "antd";
import { useIntl } from "react-intl";
import { FileExcelOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import dayjs from "dayjs";
import {
  getPaymentDetails,
  getSettlementInfoForPayments,
  getSettlementInfoPayments,
} from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import { Table as MYT } from "antd";
import { customerExport, getIwaiterVatAmount } from "../../helpers";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import EditPaymentModal from "./Components/EditPaymentModal";

const { RangePicker } = DatePicker;

const { Text, Title } = Typography;
const NewPayments = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formattedPayments, setFormattedPayments] = useState([]);
  const [transactionData, setTransactionData] = useState({});
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // new state for settlements;
  const [settlements, setSettlements] = useState([]);
  const [selectedSettlements, setSelectedSettlements] = useState([]);

  const getTransactionsNumbers = async (values) => {
    try {
      setLoading(true);
      let dateStart = dayjs(values.date[0]).format("YYYY-MM-DD");
      let dateEnd = dayjs(values.date[1]).format("YYYY-MM-DD");
      const query = `?dateStart=${dateStart}&dateEnd=${dateEnd}&withPaidSettlement=${values.withPaidSettlement}`;
      const res = await getSettlementInfoForPayments(query);
      if (res?.data?.status === "success") {
        setSettlements(res?.data?.settlements);
        setSelectedSettlements([]);
        setLoading(false);
        openNotification({
          title: "success",
          description: "payments loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      key: "index",
      width: 80,
      render: (text, record, index) => index + 1,
      fixed: "left",
      index: 0,
    },
    {
      title: intl.formatMessage({ id: "restaurantName" }),
      dataIndex: "name",
      key: "name",
      render: (name, record) => {
        return (
          <Link
            to={`/restaurants/${record?.restaurantId}`}
            target='_blank'
            type={
              record?.isSubscriptions
                ? "success"
                : record?.isTip
                ? "warning"
                : ""
            }
          >
            {record?.isSubscriptions ? `رسوم اشتراك ${name}` : name}
          </Link>
        );
      },
      fixed: "left",
      index: 1,
    },
    {
      title: intl.formatMessage({ id: "officialName" }),
      dataIndex: "officialName",
      key: "officialName",
      render: (name, record) => {
        return <Text>{name}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "originalTotal" }),
      dataIndex: "originalTotal",
      key: "originalTotal",
      render: (text, record) => text?.toFixed(2),
    },
    {
      title: intl.formatMessage({ id: "tapFees" }),
      dataIndex: "tapFees",
      key: "tapFees",
      render: (text, record) => text?.toFixed(2),
    },
    {
      title: intl.formatMessage({ id: "refundAmount" }),
      dataIndex: "refundAmount",
      key: "refundAmount",
      render: (text, record) => text?.toFixed(2),
    },
    {
      title: intl.formatMessage({ id: "refundFees" }),
      dataIndex: "refundFees",
      key: "refundFees",
      render: (text, record) => text?.toFixed(2),
    },
    // {
    //   title: intl.formatMessage({ id: "iwaierFees" }),
    //   dataIndex: "iwaierFees",
    //   key: "iwaierFees",
    // },
    // {
    //   title: intl.formatMessage({ id: "iwaiterVAT" }),
    //   dataIndex: "iwaiterVAT",
    //   key: "iwaiterVAT",
    // },
    {
      title: intl.formatMessage({ id: "iwaiterFeesWithVat" }),
      dataIndex: "iwaiterFeesWithVat",
      key: "iwaiterFeesWithVat",
      render: (text, record) => text?.toFixed(2),
    },
    {
      title: intl.formatMessage({ id: "deliveryIwaiterFees" }),
      dataIndex: "deliveryIwaiterFees",
      key: "deliveryIwaiterFees",
      render: (text, record) => text?.toFixed(2),
    },
    {
      title: intl.formatMessage({ id: "restaurantPaidAmount" }),
      dataIndex: "restaurantPaidAmount",
      key: "restaurantPaidAmount",
      render: (text, record) => text?.toFixed(2),
    },
    {
      title: intl.formatMessage({ id: "subscriptionUnpaidFees" }),
      dataIndex: "subscriptionUnpaidFees",
      key: "subscriptionUnpaidFees",
      render: (text, record) => text?.toFixed(2),
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "amountToTransfer",
      key: "amountToTransfer",
      render: (totalToTransfer, record) => {
        return (
          <Text
            copyable={{
              text:
                record?.subscriptionUnpaidFees >= totalToTransfer &&
                totalToTransfer > 0 &&
                record?.subscriptionUnpaidFees > 0
                  ? "0.00"
                  : record?.subscriptionUnpaidFees < totalToTransfer &&
                    totalToTransfer > 0 &&
                    record?.subscriptionUnpaidFees > 0
                  ? (totalToTransfer - record?.subscriptionUnpaidFees)?.toFixed(
                      2
                    )
                  : totalToTransfer?.toFixed(2),
            }}
            type={
              record?.subscriptionUnpaidFees >= totalToTransfer &&
              totalToTransfer > 0
                ? "danger"
                : "primary"
            }
          >
            {totalToTransfer?.toFixed(2)}
            {record?.subscriptionUnpaidFees < totalToTransfer &&
            totalToTransfer > 0 &&
            record?.subscriptionUnpaidFees > 0 ? (
              <Text type='warning'>
                {" "}
                -{" "}
                {(totalToTransfer - record?.subscriptionUnpaidFees)?.toFixed(2)}
              </Text>
            ) : null}
          </Text>
        );
      },
    },

    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag
            color={status === "Success" ? "success" : "red"}
            className='my-font'
          >
            {status}
          </Tag>
        );
      },
      onFilter: (value, record) => {
        if (value === "Success") {
          return record.status === "Success";
        } else {
          return record.status !== "Success";
        }
      },
      filters: [
        {
          text: "Success",
          value: "Success",
        },
        {
          text: "Pending",
          value: "Pending",
        },
      ],
    },

    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "edit",
      key: "edit",
      render: (actions, record) => {
        return (
          <CustomButton
            onClick={() => {
              setSelectedPayment(record);
              setShowModal(true);
            }}
            type='primary'
            className='border-8'
            icon={<EditOutlined />}
            text={intl.formatMessage({ id: "edit" })}
          />
        );
      },
    },
  ];

  const getPaymentsForRestaurants = async () => {
    try {
      if (selectedSettlements.length === 0) {
        openNotification({
          title: "error",
          description: "please select a settlement",
          type: "error",
          rtl: true,
        });
        return;
      }
      setLoading(true);
      const data = {
        transactionNumbers: settlements
          .filter((s) => selectedSettlements.includes(s._id))
          .map((s) => s.transactionNumber),
      };
      const res = await getPaymentDetails(data);
      console.log(res.data);
      setTransactionData(res.data?.overviewData);
      let sorted = res.data.settlementInfo.sort((a, b) => {
        // sort by name;
        if (a.name < b.name) {
          return 1;
        }
        if (a.name > b.name) {
          return -1;
        }
      });
      setFormatPayments(
        sorted,
        res.data?.overviewData,
        res?.data?.deptForIwaiter
      );
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
      setLoading(false);
    }
  };

  const setFormatPayments = (restaurants, newData, deptForIwaiter) => {
    let totalAmountBeforeFees = newData?.totalAmountBeforeFees;
    let paymentFees = newData?.paymentFees;
    let totalAfterFees = newData?.totalAfterFees;

    if (!newData?.totalAmountBeforeFees) {
      totalAmountBeforeFees = 0;
      paymentFees = 0;
      totalAfterFees = 0;
    }
    let totalTapFees = 0;
    let totalOriginalTotal = 0;

    const formatted = restaurants.map((restaurant, i) => {
      // subtract iwaiter percentage from total;
      let subscriptionUnpaidFees = 0;
      let totalSubscriptionFees = 0;
      let totalSubscriptionPaidFees = 0;
      let debt = deptForIwaiter.find(
        (d) => d.restaurantId === restaurant.restaurantId
      );
      if (debt?.serviceCount > 0) {
        const { serviceTotal, servicePaid, serviceRemain } = debt;
        subscriptionUnpaidFees = serviceRemain;
        totalSubscriptionFees = serviceTotal;
        totalSubscriptionPaidFees = servicePaid;
      }
      let amountToTransfer = 0;
      if (restaurant.total > 0 && !restaurant.isSubscriptions) {
        let defaultPercentage = 2.8;
        let defaultMadaPercentage = 1.9;
        let defaultTabbyPercentage = 9;
        if (restaurant.percentageForIwaiter) {
          defaultPercentage = restaurant.percentageForIwaiter;
        }
        if (restaurant.percentageForIWaiterMADA) {
          defaultMadaPercentage = restaurant.percentageForIWaiterMADA;
        }
        if (restaurant.percentageForIWaiterTabby) {
          defaultTabbyPercentage = restaurant.percentageForIWaiterTabby;
        }
        let madaAmountToTransfer =
          restaurant.madaTotal -
          (restaurant.madaTotal * defaultMadaPercentage) / 100;
        let visaAmountToTransfer =
          restaurant.visaTotal -
          (restaurant.visaTotal * defaultPercentage) / 100;
        let tabbyAmountToTransfer = 0;
        if (restaurant.tabbyTotal) {
          tabbyAmountToTransfer =
            restaurant.tabbyTotal -
            (restaurant.tabbyTotal * defaultTabbyPercentage) / 100;
        }
        // subtarc default percentage from total
        amountToTransfer =
          madaAmountToTransfer + visaAmountToTransfer + tabbyAmountToTransfer;
        // get the vat;
      } else if (restaurant.isSubscriptions) {
        let vatIs = getIwaiterVatAmount({ amount: restaurant.total });
        amountToTransfer = vatIs + restaurant.total;
      }

      let totalFeesWithTap = restaurant?.total - amountToTransfer;
      let vatForTotalFees = getIwaiterVatAmount({ amount: totalFeesWithTap });
      let totalWithVatAll = totalFeesWithTap + vatForTotalFees;
      let iwaiterFeesIncludingVat = totalWithVatAll - restaurant?.totalFees;
      let withoutVat = iwaiterFeesIncludingVat / 1.15;
      let iwaiterVatFees1 = iwaiterFeesIncludingVat - withoutVat;
      let vatFees = 0;
      let iwaiterFees =
        restaurant?.total - amountToTransfer - restaurant?.totalFees;
      let originalTotal = restaurant.total;
      if (dayjs().isAfter(dayjs("2023-02-01", "YYYY-MM-DD"))) {
        vatFees = getIwaiterVatAmount({ amount: iwaiterFees });
        amountToTransfer = amountToTransfer - vatFees;
      }
      if (restaurant?.isSubscriptions) {
        let vatIs = getIwaiterVatAmount({ amount: restaurant.total });
        iwaiterFees = 0;
        vatFees = parseFloat("0.00");
        originalTotal = vatIs + restaurant?.total;
      }
      totalTapFees += parseFloat(restaurant?.totalFees?.toFixed(2));
      totalOriginalTotal += parseFloat(originalTotal?.toFixed(2));
      // console.log("TT", totalOriginalTotal);
      let allTotalFinal =
        restaurant?.total -
        restaurant?.totalFees -
        iwaiterVatFees1 -
        withoutVat -
        restaurant?.deliveryIwaiterFees -
        restaurant?.deliveryRestaurantPaidAmount;
      let iwaiterFeesWithVat = withoutVat + iwaiterVatFees1;
      // calculate the fees after detacting delivery commission and restaurnat paid amount;
      // iwaiterFeesWithVat += restaurant?.deliveryIwaiterFees;
      // iwaiterFeesWithVat += restaurant?.deliveryRestaurantPaidAmount;
      // Start tip calculation;
      if (restaurant?.isTip) {
        let tipAmountToTransfer = restaurant?.total;
        let iwaiterSharePrecentage = restaurant?.percentageForIwaiter;

        let iwaiterTipFees =
          (tipAmountToTransfer * iwaiterSharePrecentage) / 100;
        let iwaiterTipVat = iwaiterTipFees * 0.15;

        tipAmountToTransfer =
          tipAmountToTransfer - (iwaiterTipFees + iwaiterTipVat);

        let status = "Pending";
        if (newData?.didSendMoney) {
          // check if restaurant id in the list;
          if (newData?.restaurantIds?.includes(restaurant?.restaurantId)) {
            status = "Success";
          }
        }

        return {
          restaurantId: restaurant?.restaurantId,
          key: `${restaurant.name}-tip`,
          name: restaurant.name,
          count: restaurant.count,
          amountToTransfer: tipAmountToTransfer,
          isSubscriptions: false,
          isTip: true,
          officialName: restaurant.officialName,
          iwaiterVAT: iwaiterTipVat,
          iwaierFees: iwaiterTipFees,
          originalTotal: restaurant?.total || 0,
          tapFees: restaurant?.totalFees || 0,
          refundAmount: restaurant?.refundAmount || 0,
          refundFees: restaurant?.refundFees || 0,
          iwaiterFeesWithVat: iwaiterTipFees + iwaiterTipVat,
          deliveryCount: restaurant?.deliveryCount,
          deliveryIwaiterFees: restaurant?.deliveryIwaiterFees || 0,
          deliveryRestaurantPaidAmount:
            restaurant?.deliveryRestaurantPaidAmount || 0,
          subscriptionUnpaidFees: subscriptionUnpaidFees,
          totalSubscriptionFees,
          totalSubscriptionPaidFees,
          status,
          paymentObject: {
            ...restaurant,
          },
        };
      }
      let status = "Pending";
      if (newData?.didSendMoney) {
        // check if restaurant id in the list;
        if (newData?.restaurantIds?.includes(restaurant?.restaurantId)) {
          status = "Success";
        }
      }
      // End tip calculation;
      return {
        restaurantId: restaurant?.restaurantId,
        key: `${restaurant.name}-${restaurant.isSubscriptions ? "-s" : ""}`,
        name: restaurant.name,
        count: restaurant.count,
        amountToTransfer: restaurant?.isSubscriptions
          ? 0
          : restaurant?.refundAmount > 0
          ? allTotalFinal - (restaurant?.refundAmount + restaurant?.refundFees)
          : allTotalFinal,
        isSubscriptions: restaurant.isSubscriptions,
        officialName: restaurant.officialName,
        iwaiterVAT: restaurant?.isSubscriptions ? 0 : iwaiterVatFees1,
        iwaierFees: restaurant?.isSubscriptions ? 0 : withoutVat,
        originalTotal: originalTotal || 0,
        tapFees: restaurant?.totalFees || 0,
        refundAmount: restaurant?.refundAmount || 0,
        refundFees: restaurant?.refundFees || 0,
        iwaiterFeesWithVat: restaurant?.isSubscriptions
          ? 0
          : iwaiterFeesWithVat,
        deliveryCount: restaurant?.deliveryCount,
        deliveryIwaiterFees: restaurant?.deliveryIwaiterFees || 0,
        deliveryRestaurantPaidAmount:
          restaurant?.deliveryRestaurantPaidAmount || 0,
        subscriptionUnpaidFees: subscriptionUnpaidFees,
        totalSubscriptionFees,
        totalSubscriptionPaidFees,
        status,
        paymentObject: {
          ...restaurant,
        },
      };
    });
    // check for missing data;
    if (
      totalOriginalTotal !== totalAmountBeforeFees &&
      newData?.totalAmountBeforeFees
    ) {
      formatted.push({
        key: "UnNamed",
        name: "غير معروف",
        count: 0,
        amountToTransfer: 0,
        isSubscriptions: true,
        officialName: "غير معروف",
        iwaiterVAT: 0,
        iwaierFees: 0,
        originalTotal: totalAmountBeforeFees - totalOriginalTotal,
        tapFees: paymentFees - totalTapFees,
        status: "failed",
      });
    }

    // merge tips with the rest;
    let allTips = formatted.filter((f) => f.isTip);
    let allSubscriptions = formatted.filter((f) => f.isSubscriptions);
    let notSubOrTips = formatted.filter((f) => !f.isSubscriptions && !f.isTip);
    let merged = [...notSubOrTips];
    allTips.forEach((tip) => {
      // check if the restaurant is already in the list;
      let found = merged.find((m) => m.restaurantId === tip.restaurantId);
      if (found) {
        // update total and fees and amount to transfer;
        const {
          count,
          amountToTransfer,
          iwaiterVAT,
          iwaierFees,
          originalTotal,
          tapFees,
          refundAmount,
          refundFees,
          iwaiterFeesWithVat,
          deliveryCount,
          deliveryIwaiterFees,
          deliveryRestaurantPaidAmount,
        } = tip;
        found.count += count;
        found.amountToTransfer += amountToTransfer;
        found.iwaiterVAT += iwaiterVAT;
        found.iwaierFees += iwaierFees;
        found.originalTotal += originalTotal;
        found.tapFees += tapFees;
        found.refundAmount += refundAmount;
        found.refundFees += refundFees;
        found.iwaiterFeesWithVat += iwaiterFeesWithVat;
        found.deliveryCount += deliveryCount;
        found.deliveryIwaiterFees += deliveryIwaiterFees;
        found.deliveryRestaurantPaidAmount += deliveryRestaurantPaidAmount;
      } else {
        merged.push(tip);
      }
    });
    // add subscriptions to the end;
    merged = [...merged, ...allSubscriptions];

    setFormattedPayments(merged);
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <EditPaymentModal
          show={showModal}
          hide={() => {
            setShowModal(false);
            setSelectedPayment(null);
          }}
          payment={selectedPayment}
          transactionNumbers={settlements
            ?.filter((s) => selectedSettlements?.includes(s._id))
            ?.map((s) => s.transactionNumber)}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "settlementInfo" })}
          </Title>
        </div>
        <Form
          name='payment-data'
          form={form}
          preserve={false}
          className='form-container light-shadow'
          layout='vertical'
          onFinish={getTransactionsNumbers}
          initialValues={{
            withPaidSettlement: false,
          }}
          style={{
            backgroundColor: COLORS.white,
            padding: 20,
            borderRadius: 10,
            flexWrap: "wrap",
          }}
        >
          <Form.Item
            name='date'
            label={intl.formatMessage({ id: "dateRange" })}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <RangePicker
              onChange={() => {
                setSelectedSettlements([]);
              }}
            />
          </Form.Item>
          <Form.Item name='withPaidSettlement' valuePropName='checked'>
            <Checkbox className='my-font'>
              {intl.formatMessage({ id: "withPaidSettlement" })}
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <CustomButton
              htmlType='submit'
              text={intl.formatMessage({ id: "send" })}
              className={`btnRegister btn-text border-8`}
              loading={loading}
              disabled={loading}
              type='primary'
            />
          </Form.Item>
        </Form>
        <Divider />
        <Space
          direction='horizontal'
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          className='my-4'
        >
          {settlements?.length
            ? settlements.map(
                ({ transactionDate, transactionNumber, didSendMoney, _id }) => {
                  let selected = selectedSettlements.includes(_id);
                  return (
                    <Space
                      key={_id}
                      direction='horizontal'
                      size={10}
                      style={{
                        backgroundColor: selected ? COLORS.primary : "#ffffff",
                        padding: "10px 15px",
                        borderRadius: 10,
                      }}
                    >
                      <Checkbox
                        checked={selected}
                        onChange={(v) => {
                          if (v.target.checked) {
                            setSelectedSettlements([
                              ...selectedSettlements,
                              _id,
                            ]);
                          } else {
                            setSelectedSettlements(
                              selectedSettlements.filter((s) => s !== _id)
                            );
                          }
                        }}
                      >
                        <Text
                          className='my-font'
                          style={{
                            color: selected ? COLORS.white : COLORS.primary,
                          }}
                        >
                          {transactionNumber}
                        </Text>
                      </Checkbox>
                      <Tag
                        className='my-font'
                        style={{
                          color: selected ? COLORS.white : COLORS.primary,
                        }}
                      >
                        {dayjs(transactionDate).format("DD/MM/YYYY")}
                      </Tag>
                    </Space>
                  );
                }
              )
            : null}
          <CustomButton
            text={intl.formatMessage({ id: "send" })}
            disabled={selectedSettlements.length === 0}
            loading={loading}
            onClick={getPaymentsForRestaurants}
            style={{
              marginTop: 20,
            }}
            type='primary'
          />
        </Space>
        <Space direction='horizontal' size={20}>
          <Descriptions
            title={intl.formatMessage({ id: "settlementInfo" })}
            layout='vertical'
            bordered
            className='my-font'
          >
            <Descriptions.Item
              label={intl.formatMessage({ id: "transactionNumber" })}
            >
              {transactionData?.transactionNumbers?.join(", ")}
            </Descriptions.Item>
            <Descriptions.Item label={intl.formatMessage({ id: "date" })}>
              {transactionData?.transactionDates
                ?.map((a) => dayjs(a).format("DD/MM/YYYY"))
                .join(", ")}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "totalAmountBeforeFees" })}
            >
              {transactionData?.totalAmountBeforeFees}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "refundAmount" })}
            >
              {transactionData?.totalRefundAmount}
            </Descriptions.Item>

            <Descriptions.Item
              label={intl.formatMessage({ id: "paymentFees" })}
            >
              {transactionData?.paymentFees}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "totalAfterFees" })}
            >
              {transactionData?.totalAfterFees -
                transactionData?.totalRefundAmount}
            </Descriptions.Item>
          </Descriptions>
        </Space>
        <Divider />
        <Table
          exportable
          exportableProps={{
            showColumnPicker: true,
            disabled: loading || formattedPayments?.length === 0,
            btnProps: {
              icon: <FileExcelOutlined />,
              type: "primary",
              style: { borderRadius: 7 },
              onClick: () =>
                customerExport({
                  data: formattedPayments,
                  columns,
                  fileName: "Payments Report",
                  loadingSetter: setLoading,
                }),
            },
            children: (
              <Text
                className='my-font'
                style={{
                  color:
                    formattedPayments?.length === 0 ? "#dddddd" : COLORS.white,
                }}
              >
                {intl.formatMessage({ id: "export" })}
              </Text>
            ),
          }}
          pagination={false}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedPayments}
          scroll={{ x: "max-content" }}
          summary={(pageData) => {
            let total = 0;
            let totalTapFees = 0;
            let totalOriginalTotal = 0;
            let totalIwaierFees = 0;
            let totalVatFees = 0;
            let totalCount = 0;
            let totalRefundAmount = 0;
            let totalRefundFees = 0;
            let totalIwaierFeesWithVat = 0;
            let totalDeliveryIwaiterFees = 0;
            let totalDeliveryRestaurantPaidAmount = 0;
            pageData.forEach(
              ({
                amountToTransfer,
                tapFees,
                originalTotal,
                iwaierFees,
                iwaiterVAT,
                count,
                refundAmount,
                refundFees,
                iwaiterFeesWithVat,
                deliveryIwaiterFees,
                deliveryRestaurantPaidAmount,
              }) => {
                if (parseFloat(amountToTransfer) > 0) {
                  total += parseFloat(amountToTransfer);
                } else {
                  // console.log(amountToTransfer, "AA");
                }
                totalTapFees += parseFloat(tapFees);
                totalOriginalTotal += parseFloat(originalTotal);
                totalIwaierFees += parseFloat(iwaierFees);
                totalVatFees += parseFloat(iwaiterVAT);
                totalCount += parseFloat(count);
                if (refundAmount > 0) {
                  totalRefundAmount += parseFloat(refundAmount);
                }
                if (refundFees > 0) {
                  totalRefundFees += parseFloat(refundFees);
                }
                if (iwaiterFeesWithVat) {
                  totalIwaierFeesWithVat += parseFloat(iwaiterFeesWithVat);
                }
                if (deliveryIwaiterFees) {
                  totalDeliveryIwaiterFees += parseFloat(deliveryIwaiterFees);
                }
                if (deliveryRestaurantPaidAmount) {
                  totalDeliveryRestaurantPaidAmount += parseFloat(
                    deliveryRestaurantPaidAmount
                  );
                }
              }
            );

            return (
              <MYT.Summary>
                <MYT.Summary.Row>
                  <MYT.Summary.Cell className='summary-bg-gray' colSpan={3}>
                    {intl.formatMessage({ id: "total" })}
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalOriginalTotal?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalTapFees?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalRefundAmount?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalRefundFees?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>

                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalIwaierFeesWithVat?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalDeliveryIwaiterFees?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>

                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalDeliveryRestaurantPaidAmount?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {total?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                  <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                    <Text style={{ color: COLORS.primary }}>
                      {totalCount?.toFixed(2)}
                    </Text>
                  </MYT.Summary.Cell>
                </MYT.Summary.Row>
              </MYT.Summary>
            );
          }}
        />
      </Spin>
    </div>
  );
};

export default NewPayments;
