import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import openNotification from "../../Components/Notifications";
import {
  Descriptions,
  Divider,
  Spin,
  Typography,
  Popconfirm,
  Result,
  Tag,
  Image,
} from "antd";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import {
  deleteRestaurant,
  deleteRestaurantMenu,
  getRestaurant,
} from "../../API/fetch";
import COLORS from "../../Style/colors";
import EditRestaurantForm from "./Components/EditRestaurantForm";
import PaymentRestaurant from "./Components/PaymentRestaurant";
import CustomButton from "../../Components/CustomButton";
import PaymentTransactions from "./Components/PaymentTransactions";
import FOODICS from "../../assets/images/Companies/foodics.png";
import MARN from "../../assets/images/Companies/marnPOS.png";
import RestaurantInfo from "./Components/RestaurantInfo";
import AddBranchForm from "./Components/AddBranchForm";
import { SettingOutlined } from "@ant-design/icons";
import EditRestaurantService from "./Components/EditRestaurantService";
import PaymentInformation from "./Components/PaymentInformation";
import AppRestaurantInfo from "./Components/AppRestaurantInfo";
import EditGeneralInformation from "./Components/EditGeneralInformation";

const { Text, Title } = Typography;
const OneRestaurant = () => {
  const intl = useIntl();
  // get the params from the url;
  const { id } = useParams();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const [restaurant, setRestaurant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showGeneralInformation, setShowGeneralInformation] = useState(false);

  useEffect(() => {
    getRestaurantInfo(id);
  }, [id, showGeneralInformation]);

  useEffect(() => {
    // scroll to top of page;
    window.scrollTo(0, 0);
  }, []);

  const getRestaurantInfo = async (id) => {
    try {
      const res = await getRestaurant(id);
      if (!res?.data?.restaurant) {
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
        setError(true);
        setLoading(false);
        return;
      }
      setRestaurant(res?.data?.restaurant);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl,
      });
    }
  };

  const deleteRestaurantInfo = async () => {
    try {
      setLoading(true);
      const res = await deleteRestaurant(restaurant._id);
      if (res?.data?.status === "success") {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setLoading(false);
        navigate("/restaurants");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const deleteMenu = async () => {
    try {
      setLoading(true);
      const data = {
        restaurantId: id,
      };
      const res = await deleteRestaurantMenu(data);
      if (res?.data?.status === "success") {
        openNotification({
          title: intl.formatMessage({ id: "menuDeleteSuccessfully" }),
          type: "success",
          rtl,
        });
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {error ? (
        <Result
          status={"404"}
          title={"404"}
          subTitle={"Sorry, the page you visited does not exist."}
          extra={
            <CustomButton
              className={`btn-text border-8 px-5 pb-5`}
              type='primary'
              onClick={() => navigate(-1)}
              text={intl.formatMessage({ id: "goBack" })}
            />
          }
        />
      ) : (
        <Spin spinning={loading}>
          <div
            style={
              rtl
                ? {
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                  }
                : {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                  }
            }
          >
            <Title
              level={3}
              className='pointer'
              onClick={() => navigate(-1)}
              // style={{ position: "absolute", zIndex: 1 }}
            >
              {rtl ? (
                <IoArrowForwardOutline className='pointer' />
              ) : (
                <IoArrowBackOutline className='pointer' />
              )}
            </Title>
          </div>
          <br />

          <div
            style={{
              backgroundColor: COLORS.white,
              marginTop: 10,
              width: "100%",
              minHeight: "70vh",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              padding: 20,
            }}
            className='light-shadow'
          >
            <RestaurantInfo restaurant={restaurant} />
            <div style={{ display: "flex", alignItems: "center>" }}>
              <EditGeneralInformation
                showGeneralInformation={showGeneralInformation}
                setShowGeneralInformation={setShowGeneralInformation}
                restaurant={restaurant}
                loading={loading}
                setRestaurant={setRestaurant}
                setLoading={setLoading}
              />
              <div className='mx-5 mt-4'>
                <Popconfirm
                  title={
                    <Text className='my-font'>
                      {intl.formatMessage({ id: "deleteMenuDescription" })}
                    </Text>
                  }
                  okText={intl.formatMessage({ id: "deleteMenu" })}
                  okButtonProps={{
                    style: { color: COLORS.danger, fontFamily: "Cairo" },
                  }}
                  okType='text'
                  cancelText={intl.formatMessage({ id: "cancel" })}
                  cancelButtonProps={{
                    style: { border: "none", fontFamily: "Cairo" },
                  }}
                  onConfirm={deleteMenu}
                  disabled={loading}
                  loading={loading}
                >
                  <CustomButton
                    text={intl.formatMessage({ id: "deleteMenu" })}
                    type='danger'
                    className={`btn-text border-8 px-5`}
                    disabled={loading}
                  />
                </Popconfirm>
              </div>
              <div className='mx-5 mt-5'>
                <Popconfirm
                  title={
                    <Text className='my-font'>
                      {intl.formatMessage({ id: "confirmDelete" })}
                    </Text>
                  }
                  okText={intl.formatMessage({ id: "delete" })}
                  okButtonProps={{
                    style: { color: COLORS.danger, fontFamily: "Cairo" },
                  }}
                  okType='text'
                  cancelText={intl.formatMessage({ id: "cancel" })}
                  cancelButtonProps={{
                    style: { border: "none", fontFamily: "Cairo" },
                  }}
                  onConfirm={deleteRestaurantInfo}
                  disabled={restaurant?.didPaid || loading}
                  loading={loading}
                >
                  <Text
                    type='danger'
                    className='pointer my-font'
                    disabled={restaurant?.didPaid || loading}
                  >
                    {intl.formatMessage({ id: "delete" })}
                  </Text>
                </Popconfirm>
              </div>
            </div>
            <Divider />
            <Title level={3}>
              {intl.formatMessage({ id: "subscription" })}
            </Title>
            <EditRestaurantService
              restaurant={restaurant}
              setRestaurant={setRestaurant}
            />
            <Divider />
            <Title level={3}>{intl.formatMessage({ id: "edit" })}</Title>
            <EditRestaurantForm
              restaurant={restaurant}
              setRestaurant={setRestaurant}
            />
            <Divider />
            <Title level={3}>{intl.formatMessage({ id: "addBranch" })}</Title>
            <AddBranchForm
              restaurant={restaurant}
              setRestaurant={setRestaurant}
            />
            <Divider />
            <Title level={3}>{intl.formatMessage({ id: "application" })}</Title>
            <AppRestaurantInfo
              restaurant={restaurant}
              setRestaurant={setRestaurant}
              appInfo={restaurant?.appRestaurantInfo}
            />

            <Divider />
          </div>
          <div
            style={{
              backgroundColor: COLORS.white,
              marginTop: 20,
              width: "100%",
              // minHeight: "70vh",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              padding: 20,
            }}
            className='light-shadow'
          >
            <Title level={3}>{intl.formatMessage({ id: "integration" })}</Title>
            <Descriptions column={1} className='my-font' bordered>
              <Descriptions.Item label={intl.formatMessage({ id: "company" })}>
                {restaurant?.integration?.posName === "foodics" ? (
                  <Image src={FOODICS} width={120} />
                ) : restaurant?.integration?.posName === "Marn" ? (
                  <Image src={MARN} width={80} />
                ) : (
                  "N/A"
                )}
              </Descriptions.Item>
              <Descriptions.Item label={intl.formatMessage({ id: "status" })}>
                {restaurant?.integration?.posStatus === "active" ? (
                  <Tag color='success'>{"Active"}</Tag>
                ) : restaurant?.integration?.posStatus === "request" ? (
                  <Tag color='orange'>{"Request"}</Tag>
                ) : (
                  <Tag color='red'>{restaurant?.integration?.posStatus}</Tag>
                )}
              </Descriptions.Item>
            </Descriptions>
            <CustomButton
              className={`btn-text border-8 px-5 pb-5 mt-4`}
              type='primary'
              text={intl.formatMessage({ id: "manage" })}
              onClick={() => navigate(`/integrations/${restaurant?._id}`)}
              icon={<SettingOutlined />}
            />
          </div>
          <div
            style={{
              backgroundColor: COLORS.white,
              marginTop: 20,
              width: "100%",
              // minHeight: "70vh",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              padding: 20,
            }}
            className='light-shadow'
          >
            <Title level={3}>{intl.formatMessage({ id: "payments" })}</Title>
            <PaymentRestaurant
              restaurant={restaurant}
              setRestaurant={setRestaurant}
              loadingData={loading}
            />
            <Divider />
            <PaymentInformation
              restaurant={restaurant}
              setRestaurant={setRestaurant}
            />
            <Divider />
            <Title level={3}>
              {intl.formatMessage({ id: "paymentRecords" })}
            </Title>
            <Text className='my-font'>
              {intl.formatMessage({ id: "totalToNow" })}{" "}
              <Tag color={"green"}>
                {" "}
                {restaurant?.madaAmount +
                  restaurant?.visaAmount +
                  restaurant?.tabbyAmount -
                  restaurant?.vatFees -
                  restaurant?.totalRefund}{" "}
              </Tag>
            </Text>
            <PaymentTransactions restaurant={restaurant} />
          </div>
        </Spin>
      )}
    </div>
  );
};

export default OneRestaurant;
