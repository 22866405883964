import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Typography, Spin, Badge } from "antd";
import { useIntl } from "react-intl";
import {
  SearchOutlined
} from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import { getIntegrations } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { setSerIntegrationsAction } from "../../redux/reducers/restaurantsReducer";
import { MdVerified } from "react-icons/md";
import { Link } from "react-router-dom";
import { Table } from "ant-table-extensions";
import { IoRefreshOutline } from "react-icons/io5";

const { Text, Title } = Typography;
const AllIntegrations = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { integrations } = useSelector((state) => state.restaurants);
  const { rtl } = useSelector((state) => state.settings);

  const [loading, setLoading] = useState(true);
  const [formattedIntegrations, setFormattedIntegrations] = useState([]);

  useEffect(() => {
    if (!integrations?.length) {
      getIntegrationsData();
    } else {
      setLoading(false);
    }
  }, [integrations]);

  const getIntegrationsData = async () => {
    try {
      setLoading(true);
      const res = await getIntegrations();
      console.log(res.data);
      if (res?.data?.integrations) {
        dispatch(setSerIntegrationsAction(res.data.integrations));
        setLoading(false);
        openNotification({
          title: "success",
          description: "integrations loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      render: (number, record) => {
        return (
          <Link to={`/restaurants//${record.restaurantId}`}>{number}</Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "restaurantName" }),
      dataIndex: "restaurantName",
      key: "restaurantName",
      render: (name, record) => {
        return (
          <Link
            to={
              record.restaurantId ? `/integrations/${record.restaurantId}` : ""
            }
          >
            {record.didPaid ? (
              <MdVerified
                style={{ marginLeft: 10 }}
                size={18}
                color={COLORS.primary}
              />
            ) : null}
            {name}
          </Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "company" }),
      dataIndex: "company",
      key: "company",
      render: (company) => {
        return (
          <Tag color={company === "foodics" ? "purple" : "blue"}>{company}</Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "posStatus",
      key: "posStatus",
      render: (status) => {
        return (
          <Badge
            status={
              status === "active"
                ? "success"
                : status === "request"
                ? "processing"
                : "error"
            }
            text={
              status === "active"
                ? "Active"
                : status === "request"
                ? "Request"
                : "Not Active"
            }
          />
        );
      },
    },
    {
      title: intl.formatMessage({ id: "syncMenu" }),
      dataIndex: "syncMenu",
      key: "syncMenu",
      render: (syncMenu) => {
        return (
          <Badge
            status={syncMenu ? "success" : "error"}
            text={syncMenu ? "YES" : "No"}
          />
        );
      },
    },
    {
      title: intl.formatMessage({ id: "syncOrders" }),
      dataIndex: "syncOrders",
      key: "syncOrders",
      render: (syncOrders) => {
        return (
          <Badge
            status={syncOrders ? "success" : "error"}
            text={syncOrders ? "YES" : "No"}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (integrations?.length) {
      const formatted = integrations.map((integration, i) => {
        return {
          key: integration._id,
          number: i + 1,
          _id: integration._id,
          company: integration.posName,
          restaurantId: integration?.restaurantInfo?._id,
          restaurantName: integration?.restaurantInfo?.name,
          syncOrders: integration.syncOrders,
          syncMenu: integration.syncMenu,
          posStatus: integration.posStatus,
          integrationObject: { ...integration },
        };
      });
      setFormattedIntegrations(formatted);
    }
    setLoading(false);
  }, [rtl, integrations]);
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "integration" })}
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getIntegrationsData}
          />
        </div>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table",
              prefix: <SearchOutlined />,
              disabled: loading || formattedIntegrations?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedIntegrations}
          scroll={{ x: 'max-content' }}
        />
      </Spin>
    </div>
  );
};

export default AllIntegrations;
