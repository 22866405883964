import { Popconfirm, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createFoodicsMenu, removeIntegration, updateIntegration } from "../../../API/fetch";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import { hasDuplicates } from "../../../helpers";
import COLORS from "../../../Style/colors";
import BranchesFoodics from "./Components/Branches";
import GeneralInfo from "./Components/GeneralInfo";
import MenuFoodics from "./Components/MenuFoodics";
import TablesFoodics from "./Components/Tables";

const {Text, Title} = Typography;

const Foodics = ({ restaurant, posData, setRestaurant }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false)

  useEffect(() => {
    if(posData?.branches?.message === "Request failed with status code 401"){
      setIsDeleted(true)
    }

  }, [restaurant, posData])

  const updatePOSBranches = async (posBranches) => {
    // check if we have dubelcated branches;
    const localIds = posBranches.map((b) => b.localId);
    if (hasDuplicates(localIds)) {
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: "كل فرع يجب أن يرتبط بفرع واحد 111فقط",
        type: "error",
        rtl,
      });
      return;
    }
    const otherIds = posBranches.map((b) => b.otherId);
    if (hasDuplicates(otherIds)) {
      // openNotification({
      //   title: intl.formatMessage({ id: "error" }),
      //   description: "كل فرع يجب أن يرتبط بفرع واحد فقط",
      //   type: "error",
      //   rtl,
      // });
      // return;
    }
    try {
      const data = {
        integration: {
          posBranches: posBranches,
        },
        type: "posBranches",
      };
      
      setLoading(true);
      const res = await updateIntegration(restaurant.integration._id, data);
      if (res?.data?.integration) {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setRestaurant({
          ...restaurant,
          integration: res.data.integration,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const updateMenu = (food) => {
    let menu = restaurant.menu.map((cat) => {
      if (cat?.categoryId === food?.categoryId) {
        cat.foods = cat.foods?.map((f) => {
          if (f?.foodId === food?._id) {
            return food;
          }
          return f;
        });
      }
      return cat;
    });
    setRestaurant({
      ...restaurant,
      menu,
    });
  };

  const addFoodicsMenu = async () => {
    try {
      setLoading(true);
      const data = {
        restaurantId: restaurant._id,
      }
      const res = await createFoodicsMenu(data)
      if(res.data?.status === 'success'){
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          description: 'حدث الصفحة لمشاهدة التفاصيل',
          rtl,
        });       
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  }

  const updateInformation = async ({ type, value }) => {
    try {
      if (!type) return;
      setLoading(true);
      let sendData = {};
      if (type === "tax") {
        sendData = {
          integration: {
            posTaxInfo: {
              token: restaurant.integration.posToken,
            },
          },
          type: "posTaxInfo",
        };
      } else if (type === "payment") {
        sendData = {
          integration: {
            posPaymentId: { token: restaurant.integration.posToken },
          },
          type: "posPaymentId",
        };
      }else if (type === 'posOtherChargePaymentId'){
        sendData = {
          integration: {
            posOtherChargePaymentId: { token: restaurant.integration.posToken },
          },
          type: "posOtherChargePaymentId",
        };

      } else if (type === "posDelivery") {
        sendData = {
          integration: {
            posDelivery: { token: restaurant.integration.posToken },
          },
          type: "posDelivery",
        };
      } else if (type === "status") {
        sendData = {
          integration: {
            posStatus: value,
          },
          type: "posStatus",
        };
      }

      const res = await updateIntegration(restaurant.integration._id, sendData);
      if (res?.data?.integration) {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setRestaurant({
          ...restaurant,
          integration: res.data.integration,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const deleteIntegration = async () => {
    setLoading(true);
    try {
      const res = await removeIntegration(restaurant.integration?._id);
      if (res?.data?.status === "success") {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        navigate(-1);
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  
  return (
    <div>
      {isDeleted ? <Title level={3}>تم حذف الربط من فودكس</Title> : null}
      <div
        style={{
          backgroundColor: isDeleted ?COLORS.danger : COLORS.white,
          marginTop: 10,
          width: "100%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 20,
        }}
        className='light-shadow'
      >
        <GeneralInfo
          information={posData?.information}
          integration={restaurant?.integration}
        />
        <Space
          direction='horizontal'
          style={{
            width: "100%",
            justifyContent: "space-around",
            marginTop: 20,
            flexWrap: 'wrap'
          }}
        >
          <CustomButton
            type='primary'
            loading={loading}
            disabled={loading}
            onClick={() => updateInformation({ type: "tax", value: "" })}
            text={intl.formatMessage({ id: "grabTax" })}
          />
          <CustomButton
            type='primary'
            loading={loading}
            disabled={loading}
            onClick={() => updateInformation({ type: "payment", value: "" })}
            text={intl.formatMessage({ id: "createPayment" })}
          />
          <CustomButton
            type='primary'
            loading={loading}
            disabled={loading}
            onClick={() => updateInformation({ type: "posOtherChargePaymentId", value: "" })}
            text={intl.formatMessage({ id: "createIwaiterFees" })}
          />
          <CustomButton
            type='primary'
            loading={loading}
            disabled={loading}
            onClick={() => updateInformation({ type: "posDelivery", value: "" })}
            text={intl.formatMessage({ id: "bringDeliveryFees" })}
          />
          {restaurant?.integration?.posStatus !== "deleted" ? (
            <CustomButton
              type='danger'
              loading={loading}
              disabled={loading}
              onClick={() =>
                updateInformation({ type: "status", value: "deleted" })
              }
              text={intl.formatMessage({ id: "deleteToken" })}
            />
          ) : null}
          {restaurant?.integration?.posStatus !== "active" &&
          restaurant?.integration?.posStatus !== "deleted" ? (
            <CustomButton
              type='primary'
              loading={loading}
              disabled={loading}
              onClick={() =>
                updateInformation({ type: "status", value: "active" })
              }
              text={intl.formatMessage({ id: "activate" })}
            />
          ) : null}
          <Popconfirm
            title={
              <Text className='my-font'>
                {intl.formatMessage({ id: "confirmDelete" })}
              </Text>
            }
            okText={intl.formatMessage({ id: "delete" })}
            okButtonProps={{
              style: { color: COLORS.danger, fontFamily: "Cairo" },
            }}
            okType='text'
            cancelText={intl.formatMessage({ id: "cancel" })}
            cancelButtonProps={{
              style: { border: "none", fontFamily: "Cairo" },
            }}
            onConfirm={deleteIntegration}
            disabled={!restaurant?.integration || loading}
            loading={loading}
          >
            <Text
              type='danger'
              className='pointer my-font'
              disabled={!restaurant?.integration || loading}
            >
              {intl.formatMessage({ id: "deleteIntegration" })}
            </Text>
          </Popconfirm>

        </Space>
      </div>
      {(isDeleted || posData?.branches?.message === "Request failed with status code 401") ? null :
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 10,
          width: "100%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 20,
        }}
        className='light-shadow'
      >
        <BranchesFoodics
          linkedBranches={restaurant?.integration?.posBranches}
          posBranches={posData?.branches}
          localBranches={restaurant?.branches}
          updatePOSBranches={updatePOSBranches}
          loading={loading}
          setLoading={setLoading}
        />
      </div>}
      {/* {Loop therow the linked brahces and display the tables for each branch} */}
      {(isDeleted || posData?.branches?.message === "Request failed with status code 401")? null : restaurant?.integration?.posBranches.map((branch) => {
        const localBranch = restaurant?.branches?.find(
          (b) => b?._id === branch?.localId
        );
        const localTables = localBranch.tables;
        const branchName = localBranch.name;
        const localBranchId = localBranch._id;
        const posSections = posData?.branches?.find(
          (b) => b?.foodicsBranchId === branch?.otherId
        )?.foodicsBranchSections;
        const posTables = posSections?.flatMap((s) => {
          return s.tables?.map((table) => {
            return {
              sectionId: s.sectionId,
              sectionName: s.sectionName,
              sectionNameAr: s?.sectionNameAr,
              ...table,
            };
          });
        });
        return (
          <div
            key={branch?.localId}
            style={{
              backgroundColor: COLORS.white,
              marginTop: 10,
              width: "100%",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              padding: 20,
            }}
            className='light-shadow'
          >
            <TablesFoodics
              localTables={localTables}
              posTables={posTables}
              linkedTables={branch?.tables}
              loading={loading}
              branchName={branchName}
              updatePOSBranches={updatePOSBranches}
              linkedBranches={restaurant?.integration?.posBranches}
              localBranchId={localBranchId}
              restaurantId={restaurant._id}
              setRestaurant={setRestaurant}
              restaurant={restaurant}
              setLoading={setLoading}
            />
          </div>
        );
      })}
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 10,
          width: "100%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 20,
        }}
        className='light-shadow'
      >
        <MenuFoodics
          restaurant={restaurant}
          updateMenu={updateMenu}
          posData={posData}
          addFoodicsMenu={addFoodicsMenu}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Foodics;
