import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Typography, Spin, Popconfirm } from "antd";
import { useIntl } from "react-intl";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import { deleteSuperUser, deleteUserFromDB, getSuperUsers, getUsers } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { setSuperUsersAction, setUsersAction } from "../../redux/reducers/restaurantsReducer";
import { MdVerified } from "react-icons/md";
import { Link } from "react-router-dom";
import { Table } from "ant-table-extensions";
import { IoRefreshOutline } from "react-icons/io5";
import SuperUserModal from "./components/SuperUserModal";
import { ExclamationCircleOutlined } from "@ant-design/icons"

const { Text, Title } = Typography;

function SuperUsers() {

  const intl = useIntl();
  const dispatch = useDispatch();
  const { superUsers } = useSelector((state) => state.restaurants);
  const { rtl } = useSelector((state) => state.settings);


  const [loading, setLoading] = useState(true);
  const [showSuperUser, setShowSuperUser] = useState(false)
  const [formattedUsers, setFormattedUsers] = useState([]);
  //added
  const [isEdit, setIsEdit] = useState(false)
  const [selectedUser, setSelectedUser] = useState(false)

  useEffect(() => {
    if (!superUsers?.length) {
      getUsersData();
    } else {
      setLoading(false);
    }
  }, []);

  const getUsersData = async () => {
    try {
      setLoading(true);
      const res = await getSuperUsers();
      if (res?.data?.users) {
        dispatch(setSuperUsersAction(res.data.users));
        setLoading(false);
        openNotification({
          title: "success",
          description: "users loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const deleteUser = async (id) => {
    try {
      setLoading(true);
      const res = await deleteSuperUser(id);
      if (res?.data?.status === "success") {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        getUsersData();
        return;
      }
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });

      setLoading(false);
    } catch (error) {
      console.log("Errror", error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record) => {
        return (
          <Link to={`/restaurants//${record.restaurantId}`}>{number}</Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      render: (name, record) => {
        return (
          <Link
            to={
              record.restaurantId ? `/restaurants/${record.restaurantId}` : ""
            }
          >
            {record.didPaid ? (
              <MdVerified
                style={{ marginLeft: 10 }}
                size={18}
                color={COLORS.primary}
              />
            ) : null}
            {name}
          </Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
      render: (phone) => {
        return (
          <Text
            copyable={{
              // remove the first three digits from phone;
              text: phone ? phone.slice(3) : "",
            }}
          >
            {phone}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "restaurants" }),
      dataIndex: "restaurants",
      key: "restaurants",
      render: (restaurants) => {
        return (
          <div>
            {restaurants.length ?
              <div>
                {restaurants.map(el => <Tag
                  color="success"
                >
                  {rtl ? el?.name : el?.name_en}
                </Tag>)}
              </div> :
              <ExclamationCircleOutlined />
            }

          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (actions) => {
        return (
          <div>
            <Text className='my-font mx-3'
              onClick={() => { setIsEdit(true); setSelectedUser(actions[0]) ; setShowSuperUser(true) }}
            >   {intl.formatMessage({ id: "edit" })}</Text>
            <Popconfirm
              title={
                <Text className='my-font '>
                  {intl.formatMessage({ id: "confirmDelete" })}
                </Text>
              }
              okText={intl.formatMessage({ id: "delete" })}
              okButtonProps={{
                style: { color: COLORS.danger, fontFamily: "Cairo" },
              }}
              okType='text'
              cancelText={intl.formatMessage({ id: "cancel" })}
              cancelButtonProps={{
                style: { border: "none", fontFamily: "Cairo" },
              }}
              onConfirm={() => deleteUser(actions[0]._id)}
              disabled={loading}
              loading={loading}
            >
              <Text
                type='danger'
                className='pointer my-font'
                disabled={loading}
              >
                {intl.formatMessage({ id: "deleteSuperUser" })}
              </Text>
            </Popconfirm>

          </div>
        );
      },
    }
  ];

  useEffect(() => {
    if(!showSuperUser){
      setIsEdit(false); 
      setSelectedUser(null)
    }
  }, [showSuperUser])
  
  useEffect(() => {
    setLoading(true);
    if (superUsers?.length) {
      const formatted = superUsers.map((user, i) => {
        return {
          key: user._id,
          number: i + 1,
          _id: user._id,
          name: user.name,
          phone: user?.phone,
          restaurants: user.restaurants,
          actions: [user]

        };
      });
      setFormattedUsers(formatted);
    }
    setLoading(false);
  }, [rtl, superUsers]);

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "superUsers" })}
          </Title>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CustomButton
              text={intl.formatMessage({ id: "refresh" })}
              type='text'
              icon={<IoRefreshOutline />}
              onClick={getUsersData}
            />
            <SuperUserModal
              showSuperUser={showSuperUser}
              setShowSuperUser={setShowSuperUser}
              setLoading={setLoading}
              getUsersData={getUsersData}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}

            />
          </div>
        </div>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedUsers?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedUsers}
          scroll={{ x: 'max-content' }}
        />
      </Spin>
    </div>
  )
}

export default SuperUsers