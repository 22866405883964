import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import openNotification from "../../Components/Notifications";
import {
  Spin,
  Typography,
  Result,
  Tag,
  Form,
  Row,
  Col,
  Input,
  Select,
  Divider,
  DatePicker,
  Upload,
  Image,
  Space,
  message,
  Button,
  Descriptions,
} from "antd";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import {
  changeWallet,
  createTapBusiness,
  getOneWallet,
  uploadFileToTap,
} from "../../API/fetch";
import COLORS from "../../Style/colors";
import CustomButton from "../../Components/CustomButton";
import phoneCodes from "../Auth/PhoneCods.json";
import { checkIfValidPhone } from "../../helpers";
import apiUrl from "../../API/config";
import { UploadOutlined } from "@ant-design/icons";
import TapInfoUser from "./TapInfoUser";
const { Option } = Select;
const prefixSelector = (
  <Form.Item name='prefix' noStyle>
    <Select
      style={{
        width: 150,
      }}
    >
      {phoneCodes.map((info) => {
        return (
          <Option key={`${info.dial_code}-${info.name}`} value={info.dial_code}>
            {info.dial_code} {info.name}
          </Option>
        );
      })}
    </Select>
  </Form.Item>
);
const WALLET_TYPES = {
  PRIVATE: "PRIVATE",
  PUBLIC: "PUBLIC",
}
const acceptedTypes = ["image/png", "image/jpeg"];
const { Text, Title } = Typography;
const OneWallet = () => {
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  // get the params from the url;
  const { id } = useParams();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const [wallet, setWallet] = useState(null);
  const [restaurant, setRestaurant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showGeneralInformation, setShowGeneralInformation] = useState(false);
  const [logos, setLogos] = useState([]);
  const [tapInfo, setTapInfo] = useState({});

  useEffect(() => {
    getWalletInfo(id);
  }, [id, showGeneralInformation]);
  useEffect(() => {
    // scroll to top of page;
    // window.scrollTo(0, 0);
  }, []);
  const getWalletInfo = async (id) => {
    try {
      const res = await getOneWallet(id);
      if (!res?.data?.wallet) {
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
        setError(true);
        setLoading(false);
        return;
      }
      setWallet(res?.data?.wallet);
      setRestaurant(res?.data?.wallet?.restaurantId);
      setTapInfo(res?.data?.tapInfo);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl,
      });
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const data = {
        tapData: {
          name: {
            en: restaurant?.name_en,
            ar: restaurant?.name,
          },
          type: values?.type,
          entity: {
            legal_name: {
              en: values?.nameEn,
              ar: values?.nameAR,
            },
            is_licensed: true,
            license: {
              type: values?.licenseType,
              number: values?.licenseNumber,
            },
            not_for_profit: false,
            country: "SA",
            tax_number: values?.vatNumber || "",
            documents: [
              {
                type: "Commercial Registration",
                number: values?.numberOfCommercial,
                issuing_country: values?.issuing_country,
                issuing_date: values?.issuing_date.format("YYYY-MM-DD"),
                expiry_date: values?.expiry_date.format("YYYY-MM-DD"),
                files: [wallet?.tapDocs?.commercialFile],
              },
            ],
            bank_account: {
              iban: values?.iban,
            },
            billing_address: {
              recipient_name: values?.recipientName,
              address_1: values?.address1,
              // address_2: values?.address2,
              po_box: values?.poBox,
              district: values?.district,
              city: values?.city,
              state: values?.state,
              zip_code: values?.zipCode,
              country: values?.country,
            },
          },
          contact_person: {
            name: {
              title: "Mr",
              first: values?.firstName,
              middle: values?.middleName,
              last: values?.lastName,
            },
            contact_info: {
              primary: {
                email: values?.email,
                phone: {
                  country_code: values?.prefix?.replace("+", ""),
                  number: values?.phone,
                },
              },
            },
            nationality: "SA",
            date_of_birth: values?.date_of_birth.format("YYYY-MM-DD"),
            is_authorized: true,
            authorization: {
              name: {
                title: "Mr",
                first: values?.firstName,
                middle: values?.middleName,
                last: values?.lastName,
              },
              type: "identity_document",
              issuing_country: values?.issuing_countryId,
              issuing_date: values?.issuing_dateId.format("YYYY-MM-DD"),
              expiry_date: values?.expiry_dateId.format("YYYY-MM-DD"),
              files: [wallet?.tapDocs?.idFile],
            },
            identification: [
              {
                name: {
                  title: "Mr",
                  first: values?.firstName,
                  middle: values?.middleName,
                  last: values?.lastName,
                },
                type: "identity_document",
                number: values?.numberOfId,
                issuing_country: values?.issuing_countryId,
                issuing_date: values?.issuing_dateId.format("YYYY-MM-DD"),
                expiry_date: values?.expiry_dateId.format("YYYY-MM-DD"),
                files: [wallet?.tapDocs?.idFile],
              },
            ],
          },
          brands: [
            {
              name: {
                en: restaurant?.name_en,
                ar: restaurant?.name,
              },
              sector: ["Food"],
              website: `https://app.iwaiter.club/${restaurant?.slug}`,
              // social: [restaurant?.socialMedia?.facebook],
              logo: wallet?.tapDocs?.logoFile,
              content: {
                tag_line: {
                  en: restaurant?.name_en,
                  ar: restaurant?.name,
                },
                about: {
                  en: restaurant?.description_en || "",
                  ar: restaurant?.description || "",
                },
              },
            },
          ],
          post: {
            url: `https://api.iwaiter.club/api/v1/tap-webhook`,
          },
          metadata: {
            restaurantId: restaurant?._id,
          },
        },
        restaurantId: restaurant?._id,
      };
      setLoading(true);
      const res = await createTapBusiness(data);
      console.log(res.data);
      openNotification({
        title: "success",
        description: "Tap business created successfully",
        type: "success",
        rtl: true,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
      setLoading(false);
    }
  };

  const uploadCommercial = async ({ type }) => {
    setLoading(true);
    let url = "";
    if (type === "commercialFile") {
      url = wallet?.CommercialRegister;
    } else if (type === "idFile") {
      url = wallet?.ids;
    } else if (type === "logoFile") {
      url = wallet?.smallLogo;
    }
    try {
      // check if the url ends with jpeg or png, otherwise throw an error;
      if (!url?.endsWith("jpeg") && !url?.endsWith("png")) {
        openNotification({
          title: "error",
          description: "File should be jpeg or png",
          type: "error",
          rtl: true,
        });
        setLoading(false);
        return null;
      }
      const data = {
        url,
        restaurantId: restaurant?._id,
        type,
      };
      const res = await uploadFileToTap(data);
      if (res?.data?.fileId) {
        setLoading(false);
        openNotification({
          title: "success",
          description: "File uploaded successfully",
          type: "success",
          rtl: true,
        });
        return true;
      }
      throw new Error("No file");
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
      return null;
    }
  };

  const beforeUpload = (file) => {
    if (!acceptedTypes.includes(file?.type) || !file) {
      message.error("You can only upload JPEG/PNG file!");
      return false;
    }
    if (file.type?.includes("image")) {
      // make sure the file size is less than 512 kb;
      if (file.size / 1000 > 512) {
        message.error(intl.formatMessage({ id: "limit2Mb" }));
        return false;
      }
    }
    return true;
  };
  const handleChangeImages = ({ fileList, file }) => {
    if (file.status === "uploading") {
      setLoading(true); // show loading;
    } else if (file.status === "done") {
      // upload success;
      setLoading(false); // hide loading;
      if (file.response) {
        if (file?.response) {
          openNotification({
            title: intl.formatMessage({ id: "savedSuccessfully" }),
            type: "success",
            rtl,
          });
        }
      }
    } else if (file.status === "error") {
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
    setLogos([file]);
  };

  const toggleWallet = async ({type}) => {
    setLoading(true);
    try {
      const data = {
        restaurantId: restaurant?._id,
        destinationId: wallet?.tapWalletInfo?.destinationId,
        action: type
      }
      const res = await changeWallet(data)
      if(res?.data?.status === 'success'){
        openNotification({
          title: "success",
          description: "Wallet changed successfully",
          type: "success",
          rtl: true,
        })
        setLoading(false);
        return
      }
      throw new Error('something went wrong')
    }catch(error){
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {error ? (
        <Result
          status={"404"}
          title={"404"}
          subTitle={"Sorry, the page you visited does not exist."}
          extra={
            <CustomButton
              className={`btn-text border-8 px-5 pb-5`}
              type='primary'
              onClick={() => navigate(-1)}
              text={intl.formatMessage({ id: "goBack" })}
            />
          }
        />
      ) : (
        <Spin spinning={loading}>
          <div
            style={
              rtl
                ? {
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                  }
                : {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                  }
            }
          >
            <Title
              level={3}
              className='pointer'
              onClick={() => navigate(-1)}
              // style={{ position: "absolute", zIndex: 1 }}
            >
              {rtl ? (
                <IoArrowForwardOutline className='pointer' />
              ) : (
                <IoArrowBackOutline className='pointer' />
              )}
            </Title>
          </div>
          <br />

          <div
            style={{
              backgroundColor: COLORS.white,
              marginTop: 10,
              width: "100%",
              // minHeight: "70vh",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              padding: 20,
            }}
            className='light-shadow'
          >
            <Title level={4}>
              {rtl ? restaurant?.name : restaurant?.name_en}
            </Title>
            <Tag
              color={
                wallet?.name === "MY_FATOORAH"
                  ? "orange"
                  : restaurant?.destination_id !== "WALLET"
                  ? "green"
                  : "red"
              }
            >
              {wallet?.name === "MY_FATOORAH"
                ? "MY_FATOORAH"
                : restaurant?.destination_id !== "WALLET"
                ? "TAP_MERCHANT"
                : "TAP_GENERAL"}
            </Tag>
            {wallet?.tapWalletInfo?.destinationId ? (
              ""
            ) : (
              <Text type='danger'>
                {intl.formatMessage({ id: "uploadAllImageFirst" })}
              </Text>
            )}
          </div>
          <div
            style={{
              backgroundColor: COLORS.white,
              marginTop: 20,
              width: "100%",
              // minHeight: "70vh",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              padding: 20,
            }}
            className='light-shadow'
          >
            <Title level={4}>{intl.formatMessage({ id: "info" })}</Title>
            {tapInfo?.id ? (
              <TapInfoUser data={tapInfo} wallet={wallet} />
            ) : (
              <Form
                form={form}
                layout='vertical'
                name='register-tap'
                scrollToFirstError
                onFinish={onFinish}
                initialValues={{}}
                disabled={tapInfo?.id}
              >
                {() => {
                  return (
                    <>
                      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='nameAR'
                            label={intl.formatMessage({ id: "legalNameAR" })}
                            rules={[
                              {
                                required: true,
                                message: "Please input your name!",
                              },
                            ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='nameEn'
                            label={intl.formatMessage({ id: "legalNameEN" })}
                            rules={[
                              {
                                required: true,
                                message: "Please input your name!",
                              },
                            ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='type'
                            label={intl.formatMessage({ id: "accountType" })}
                            rules={[
                              {
                                required: true,
                                message: "Please select type!",
                              },
                            ]}
                          >
                            <Select className='my-font' disabled={tapInfo?.id}>
                              <Select.Option value='corp' className='my-font'>
                                {intl.formatMessage({ id: "corp" })}
                              </Select.Option>
                              <Select.Option value='ind' className='my-font'>
                                {intl.formatMessage({ id: "person" })}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          {/* tax_number */}
                          <Form.Item
                            name='vatNumber'
                            label={intl.formatMessage({ id: "vatNumber" })}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please input your tax number!",
                            //   },
                            // ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='licenseType'
                            label={intl.formatMessage({ id: "licenseType" })}
                            rules={[
                              {
                                required: true,
                                message: "Please select type!",
                              },
                            ]}
                          >
                            <Select className='my-font' disabled={tapInfo?.id}>
                              <Select.Option
                                value='Commercial Registration'
                                className='my-font'
                              >
                                {intl.formatMessage({
                                  id: "CommercialRegistration",
                                })}
                              </Select.Option>
                              <Select.Option
                                value='Commercial License'
                                className='my-font'
                              >
                                {intl.formatMessage({
                                  id: "CommercialLicense",
                                })}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='licenseNumber'
                            label={intl.formatMessage({ id: "licenseNumber" })}
                            rules={[
                              {
                                required: true,
                                message: "Please input your license number!",
                              },
                            ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          {/* iban */}
                          <Form.Item
                            label={intl.formatMessage({ id: "iban" })}
                            className='mb-1'
                            name='iban'
                            style={{ borderRadius: 20 }}
                            rules={[
                              {
                                required: true,
                              },
                              {
                                // check if the first two letters are SA
                                pattern: /^SA/,
                                message: 'IBAN must start with "SA"',
                              },
                              {
                                // check if the length is 24
                                pattern: /^.{24}$/,
                                message: "IBAN must be 24 characters long",
                              },
                            ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24} className='mb-3'></Col>
                      </Row>
                      <Divider />
                      <Title level={4}>
                        {intl.formatMessage({ id: "billingAddress" })}
                      </Title>
                      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='recipientName'
                            label={intl.formatMessage({ id: "recipientName" })}
                            rules={[
                              {
                                required: true,
                                message: "Please input your name!",
                              },
                            ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='address1'
                            label={intl.formatMessage({ id: "address1" })}
                            rules={[
                              {
                                required: true,
                                message: "Please input your address!",
                              },
                            ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='poBox'
                            label={intl.formatMessage({ id: "poBox" })}
                            rules={[
                              {
                                required: true,
                                message: "Please input your poBox!",
                              },
                            ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='district'
                            label={intl.formatMessage({ id: "district" })}
                            rules={[
                              {
                                required: true,
                                message: "Please input your district!",
                              },
                            ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='city'
                            label={intl.formatMessage({ id: "city" })}
                            rules={[
                              {
                                required: true,
                                message: "Please input your city!",
                              },
                            ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='state'
                            label={intl.formatMessage({ id: "state" })}
                            rules={[
                              {
                                required: true,
                                message: "Please input your state!",
                              },
                            ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='zipCode'
                            label={intl.formatMessage({ id: "zipCode" })}
                            rules={[
                              {
                                required: true,
                                message: "Please input your zipCode!",
                              },
                            ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='country'
                            label={intl.formatMessage({ id: "country" })}
                          >
                            <Select disabled={tapInfo?.id}>
                              <Select.Option value='SA'>
                                Saudi Arabia
                              </Select.Option>
                              <Select.Option value='KW'>Kuwait</Select.Option>
                              <Select.Option value='BH'>Bahrain</Select.Option>
                              <Select.Option value='AE'>
                                United Arab Emirates
                              </Select.Option>
                              <Select.Option value='QA'>Qatar</Select.Option>
                              <Select.Option value='OM'>Oman</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />

                      <Title level={4}>
                        {intl.formatMessage({ id: "contactPerson" })}
                      </Title>
                      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                        <Col md={8} sm={24} xs={24} className='mb-3'>
                          {/* name */}
                          <Form.Item
                            name='firstName'
                            label={intl.formatMessage({ id: "firstName" })}
                            rules={[
                              {
                                required: true,
                                message: "Please input your name!",
                              },
                            ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24} className='mb-3'>
                          {/* name */}
                          <Form.Item
                            name='middleName'
                            label={intl.formatMessage({ id: "middleName" })}
                            rules={[
                              {
                                required: true,
                                message: "Please input your name!",
                              },
                            ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24} className='mb-3'>
                          {/* name */}
                          <Form.Item
                            name='lastName'
                            label={intl.formatMessage({ id: "lastName" })}
                            rules={[
                              {
                                required: true,
                                message: "Please input your name!",
                              },
                            ]}
                          >
                            <Input disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='phone'
                            label={intl.formatMessage({ id: "phone" })}
                            rules={[
                              {
                                required: true,
                                message: intl.formatMessage({
                                  id: "errorphone",
                                }),
                              },
                              {
                                validator: (_, value) => {
                                  const prefix = form.getFieldValue("prefix");
                                  if (!prefix) {
                                    return Promise.reject(
                                      intl.formatMessage({ id: "errorphone" })
                                    );
                                  }
                                  let valid = checkIfValidPhone(
                                    `${prefix}${value}`
                                  );
                                  if (valid) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      intl.formatMessage({ id: "errorphone" })
                                    );
                                  }
                                },
                              },
                            ]}
                          >
                            <Input
                              disabled={tapInfo?.id}
                              addonBefore={rtl ? null : prefixSelector}
                              addonAfter={rtl ? prefixSelector : null}
                              style={{
                                width: "100%",
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            label={intl.formatMessage({ id: "email" })}
                            className='mb-1'
                            name='email'
                            rules={[
                              {
                                required: true,
                                message: "Error Email",
                              },
                              {
                                type: "email",
                                message: "Error Email",
                              },
                            ]}
                          >
                            <Input className={"input"} disabled={tapInfo?.id} />
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* date_of_birth */}
                      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                        <Col md={8} sm={24} xs={24} className='mb-3'>
                          <Form.Item
                            name='date_of_birth'
                            label={intl.formatMessage({ id: "birthDate" })}
                            rules={[
                              {
                                required: true,
                                message: "Please input your day!",
                              },
                            ]}
                          >
                            <DatePicker
                              style={{
                                width: "100%",
                              }}
                              disabled={tapInfo?.id}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24} className='mb-3'></Col>
                      </Row>
                      <Divider />
                      {wallet?.CommercialRegister ? (
                        <>
                          <Title level={4}>
                            {intl.formatMessage({
                              id: "CommercialRegistration",
                            })}
                          </Title>
                          <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                            <Col md={12} sm={24} xs={24} className='mb-3'>
                              <Form.Item
                                name='issuing_country'
                                label={intl.formatMessage({
                                  id: "issuing_country",
                                })}
                              >
                                <Select disabled={tapInfo?.id}>
                                  <Select.Option value='SA'>
                                    Saudi Arabia
                                  </Select.Option>
                                  <Select.Option value='KW'>
                                    Kuwait
                                  </Select.Option>
                                  <Select.Option value='BH'>
                                    Bahrain
                                  </Select.Option>
                                  <Select.Option value='AE'>
                                    United Arab Emirates
                                  </Select.Option>
                                  <Select.Option value='QA'>
                                    Qatar
                                  </Select.Option>
                                  <Select.Option value='OM'>Oman</Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={12} sm={24} xs={24} className='mb-3'>
                              <Form.Item
                                name='issuing_date'
                                label={intl.formatMessage({
                                  id: "issuing_date",
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your issuing_date!",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{
                                    width: "100%",
                                  }}
                                  disabled={tapInfo?.id}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                            <Col md={12} sm={24} xs={24} className='mb-3'>
                              <Form.Item
                                name='expiry_date'
                                label={intl.formatMessage({
                                  id: "expiry_date",
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your expiry_date!",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{
                                    width: "100%",
                                  }}
                                  disabled={tapInfo?.id}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12} sm={24} xs={24} className='mb-3'>
                              <Form.Item
                                name='numberOfCommercial'
                                label={intl.formatMessage({
                                  id: "numberOfCommercial",
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input your numberOfCommercial!",
                                  },
                                ]}
                              >
                                <Input disabled={tapInfo?.id} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Space direction='horizontal' size={5}>
                              <Image
                                src={wallet?.CommercialRegister}
                                style={{
                                  width: 100,
                                  height: 100,
                                }}
                              />
                              <CustomButton
                                onClick={() =>
                                  uploadCommercial({
                                    type: "commercialFile",
                                  })
                                }
                                text={intl.formatMessage({ id: "uploadToTap" })}
                                type='primary'
                                className='border-8 px-5'
                                loading={loading}
                                disabled={loading || tapInfo?.id}
                              />
                              <Text
                                type={
                                  wallet?.tapDocs?.commercialFile
                                    ? "success"
                                    : "danger"
                                }
                              >
                                {wallet?.tapDocs?.commercialFile || "N/A"}
                              </Text>
                            </Space>
                          </Row>
                        </>
                      ) : (
                        <Title level={4}>
                          {intl.formatMessage({
                            id: "pleaseUploadFromRestaurantView",
                          })}
                        </Title>
                      )}
                      <Divider />
                      {wallet?.ids ? (
                        <>
                          <Title level={4}>
                            {intl.formatMessage({ id: "idsInfo" })}
                          </Title>
                          <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                            <Col md={12} sm={24} xs={24} className='mb-3'>
                              <Form.Item
                                name='issuing_countryId'
                                label={intl.formatMessage({
                                  id: "issuing_country",
                                })}
                              >
                                <Select disabled={tapInfo?.id}>
                                  <Select.Option value='SA'>
                                    Saudi Arabia
                                  </Select.Option>
                                  <Select.Option value='KW'>
                                    Kuwait
                                  </Select.Option>
                                  <Select.Option value='BH'>
                                    Bahrain
                                  </Select.Option>
                                  <Select.Option value='AE'>
                                    United Arab Emirates
                                  </Select.Option>
                                  <Select.Option value='QA'>
                                    Qatar
                                  </Select.Option>
                                  <Select.Option value='OM'>Oman</Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={12} sm={24} xs={24} className='mb-3'>
                              <Form.Item
                                name='issuing_dateId'
                                label={intl.formatMessage({
                                  id: "issuing_date",
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your issuing_date!",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{
                                    width: "100%",
                                  }}
                                  disabled={tapInfo?.id}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                            <Col md={12} sm={24} xs={24} className='mb-3'>
                              <Form.Item
                                name='expiry_dateId'
                                label={intl.formatMessage({
                                  id: "expiry_date",
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your expiry_date!",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{
                                    width: "100%",
                                  }}
                                  disabled={tapInfo?.id}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12} sm={24} xs={24} className='mb-3'>
                              <Form.Item
                                name='numberOfId'
                                label={intl.formatMessage({
                                  id: "numberOfId",
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your numberOfId!",
                                  },
                                ]}
                              >
                                <Input disabled={tapInfo?.id} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Space direction='horizontal' size={5}>
                              <Image
                                src={wallet?.ids}
                                style={{
                                  width: 100,
                                  height: 100,
                                }}
                              />
                              <CustomButton
                                onClick={() =>
                                  uploadCommercial({
                                    type: "idFile",
                                  })
                                }
                                text={intl.formatMessage({ id: "uploadToTap" })}
                                type='primary'
                                className='border-8 px-5'
                                loading={loading}
                                disabled={loading || tapInfo?.id}
                              />
                              <Text
                                type={
                                  wallet?.tapDocs?.idFile ? "success" : "danger"
                                }
                              >
                                {wallet?.tapDocs?.idFile || "N/A"}
                              </Text>
                            </Space>
                          </Row>
                        </>
                      ) : (
                        <Title level={4}>
                          {intl.formatMessage({
                            id: "pleaseUploadFromRestaurantView",
                          })}
                        </Title>
                      )}
                      <Divider />
                      <Title level={4}>
                        {intl.formatMessage({ id: "logoInformation" })}
                      </Title>
                      <Title level={5}>
                        {intl.formatMessage({ id: "oldLogo" })}
                      </Title>
                      <Row>
                        <Space direction='horizontal' size={5}>
                          <Image
                            src={restaurant?.logo?.[0]?.url}
                            style={{
                              width: 100,
                              height: 100,
                            }}
                          />
                          <Text>
                            {intl.formatMessage({
                              id: "logoMustBeLessThan1MB",
                            })}
                          </Text>
                        </Space>
                      </Row>
                      <Divider />
                      <Title level={5}>
                        {intl.formatMessage({ id: "newLogo" })}
                      </Title>
                      <Space direction='horizontal' size={5}>
                        <Image
                          src={wallet?.smallLogo}
                          style={{ width: 50, height: 50 }}
                        />
                        <Upload
                          action={`${apiUrl}/smallRestaurant?restaurantId=${restaurant?._id}&slug=${restaurant?.slug}}`}
                          headers={{ authorization: "Bearer " + user.token }}
                          name='file'
                          fileList={logos}
                          listType='file'
                          onChange={(info) => handleChangeImages(info)}
                          beforeUpload={beforeUpload}
                          multiple={false}
                          maxCount={0}
                          accept='image/jpeg,image/png'
                          disabled={loading || tapInfo?.id}
                        >
                          <Button
                            type='primary'
                            className='border-8 mt-2'
                            icon={<UploadOutlined />}
                            disabled={loading || tapInfo?.id}
                          >
                            {intl.formatMessage({ id: "clickToUpload" })}
                          </Button>
                        </Upload>
                      </Space>
                      <br />

                      <CustomButton
                        onClick={() =>
                          uploadCommercial({
                            type: "logoFile",
                          })
                        }
                        text={intl.formatMessage({ id: "uploadToTap" })}
                        type='primary'
                        className='border-8 px-5 mt-5'
                        loading={loading}
                        disabled={loading || tapInfo?.id}
                      />
                      <Text
                        type={wallet?.tapDocs?.logoFile ? "success" : "danger"}
                      >
                        {wallet?.tapDocs?.logoFile || "N/A"}
                      </Text>
                      <Divider />
                      {/* Submit button */}
                      <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                        <Col md={24} sm={24} xs={24} className='mb-3'>
                          <Form.Item>
                            <CustomButton
                              text={intl.formatMessage({ id: "save" })}
                              type='primary'
                              htmlType='submit'
                              className='border-8 px-5'
                              loading={loading}
                              disabled={
                                loading ||
                                !wallet?.tapDocs?.commercialFile ||
                                !wallet?.tapDocs?.idFile ||
                                !wallet?.tapDocs?.logoFile ||
                                !wallet?.ids ||
                                !wallet?.CommercialRegister ||
                                !restaurant?.logo?.[0]?.url ||
                                !wallet?.smallLogo ||
                                tapInfo?.id
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              </Form>
            )}
          </div>
          <div
            style={{
              backgroundColor: COLORS.white,
              marginTop: 20,
              width: "100%",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              padding: 20,
            }}
            className='light-shadow'
          >
            <Descriptions
              title={intl.formatMessage({ id: "paymentInfo" })}
              bordered
            >
              <Descriptions.Item
                label={intl.formatMessage({ id: "usedWallet" })}
              >
                {wallet?.name === "MY_FATOORAH"
                  ? "MY_FATOORAH"
                  : restaurant?.destination_id === "WALLET"
                  ? intl.formatMessage({ id: "iwaiterWallet" })
                  : intl.formatMessage({ id: "privateWalletTap" })}
              </Descriptions.Item>
            </Descriptions>
            <Space direction='horizontal' size={40} className='mt-5'>
              <CustomButton
                text={intl.formatMessage({ id: "useiwaiterPublicWallet" })}
                type='primary'
                className='border-8 px-5'
                loading={loading}
                disabled={
                  loading ||
                  restaurant?.destination_id === "WALLET" ||
                  wallet?.name === "MY_FATOORAH"
                }
                onClick={() => toggleWallet({type: WALLET_TYPES.PUBLIC})}
              />
              <CustomButton
                text={intl.formatMessage({ id: "useUserWallet" })}
                type='primary'
                className='border-8 px-5'
                loading={loading}
                disabled={
                  loading ||
                  restaurant?.destination_id !== "WALLET" ||
                  wallet?.name === "MY_FATOORAH"
                }
                onClick={() => toggleWallet({type: WALLET_TYPES.PRIVATE})}
              />
            </Space>
          </div>
        </Spin>
      )}
    </div>
  );
};

export default OneWallet;
