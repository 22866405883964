import { Descriptions, Space } from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import CustomButton from "../../../../../Components/CustomButton";
import COLORS from "../../../../../Style/colors";
import OneTableRow from "./OneTableRow";
import { useSelector } from "react-redux";
import openNotification from "../../../../../Components/Notifications";
import {createTablesLikeMarn} from "../../../../../API/fetch";
const TablesMarn = ({
  loading,
  localTables,
  posTables,
  linkedTables,
  updatePOSBranches,
  branchName,
  linkedBranches,
  localBranchId,
  restaurantId,
  setRestaurant,
  restaurant,
  setLoading,
}) => {
  const intl = useIntl();
  const {rtl} =useSelector((state) => state.settings);
  const [linkTableLocal, setLinkTableLocal] = useState(linkedTables);
  useEffect(() => {
    if (linkedTables?.length) {
      setLinkTableLocal(linkedTables);
    }
  }, [linkedTables]);

  const handleUpdateTables = () => {
    // get the branch index from linkedBranch by local branch id;
    let index = linkedBranches?.findIndex((b) => b?.localId === localBranchId);
    if (index === -1) {
      return;
    }
    let newLinkBranches = [...linkedBranches];
    newLinkBranches[index].tables = linkTableLocal;
    updatePOSBranches(newLinkBranches);
  };

  const addMarnTables = async () => {
    const allTables = [];
    console.log(posTables);
    posTables.forEach((table) => {
      allTables.push({
        name: table.marnTableName,
        name_en: table.marnTableName,
        zone: table.marnSectionName,
        owner: localBranchId,
      });
    });
    const data = {
      tables: allTables,
      branchId: localBranchId,
      restaurantId,
      marnTables: posTables,
    };
    try {
      setLoading(true);
      const res = await createTablesLikeMarn(data);
      if (res?.data?.integration) {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setRestaurant({
          ...restaurant,
          integration: res.data.integration,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div>
      <Descriptions
        title={`${intl.formatMessage({ id: "tables" })} - ${branchName}`}
        bordered
        className='my-font'
        style={{
          textAlign: "center",
        }}
        column={1}
      >
        <Descriptions.Item
          label='iwaiter'
          labelStyle={{ backgroundColor: COLORS.gray }}
          contentStyle={{ backgroundColor: COLORS.gray }}
        >
          Marn
        </Descriptions.Item>

        {localTables?.map((table, i) => {
          return (
            <Descriptions.Item
              label={`${table.name} - ${table?.zone}`}
              key={table._id}
            >
              <OneTableRow
                table={table}
                posTables={posTables}
                linkTableLocal={linkTableLocal}
                setLinkTableLocal={setLinkTableLocal}
              />
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      <Space
        direction='horizontal'
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <CustomButton
          text={intl.formatMessage({ id: "save" })}
          className={`btn-text border-8 px-5 pb-5 mt-4`}
          loading={loading}
          disabled={loading}
          type='primary'
          onClick={handleUpdateTables}
        />
        <CustomButton
          text={intl.formatMessage({ id: "deleteAllTablesAndAddFromFoodics" })}
          className={`btn-text border-8 px-5 pb-5 mt-4`}
          loading={loading}
          disabled={
            loading || posTables?.length === 0 || linkedBranches?.length === 0
          }
          type='waring'
          onClick={addMarnTables}
        />
      </Space>
    </div>
  );
};

export default TablesMarn;
