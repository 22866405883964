import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Typography, Spin, Popconfirm, Input } from "antd";
import { useIntl } from "react-intl";
import { SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import { MdVerified } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "ant-table-extensions";
import { IoRefreshOutline } from "react-icons/io5";
// import SuperUserModal from "./components/SuperUserModal";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { loginAdminPayApp, getAllAppRestaurants } from "../../API/payAppFetch";
import {
  setAdminAppUserAction,
  setMyFatoorahBanksAction,
  setPayAppRestaurantsAction,
} from "../../redux/reducers/payAppReducer";
import openNotification from "../../Components/Notifications";
import { BiPlusMedical } from "react-icons/bi";
import AddRestaurant from "./components/AddRestaurant";

const { Text, Title } = Typography;

function PayApp() {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { aminAppUser, payAppRestaurants } = useSelector(
    (state) => state.payApp
  );
  const { rtl } = useSelector((state) => state.settings);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [showSuperUser, setShowSuperUser] = useState(false);
  const [formattedRestaurants, setFormattedRestaurants] = useState([]);
  const [showAdd, setShowAdd] = useState(false);

  //   useEffect(() => {
  //     if (!superUsers?.length) {
  //       getUsersData();
  //     } else {
  //       setLoading(false);
  //     }
  //   }, []);

  useEffect(() => {
    setLoading(true);
    if (payAppRestaurants?.length) {
      const formatted = payAppRestaurants?.map((rest, i) => {
        return {
          ...rest,
          key: rest._id,
          _id: rest._id,
          name: rtl ? rest?.name : rest?.name_en,
          phone: rest?.owner?.phone ?? "",
          integration: rest?.integration?.posName ?? "iwaiter",
        };
      });
      setFormattedRestaurants(formatted);
    }
    setLoading(false);
  }, [rtl, payAppRestaurants]);

  const loginAdmin = async () => {
    try {
      setLoading(true);
      const data = {
        credentials: {
          password: password,
          phone: "966509000036",
        },
      };
      const res = await loginAdminPayApp(data);
      if (res?.data?.user) {
        dispatch(setAdminAppUserAction(res.data.user));
        openNotification({
          type: "success",
          title: "success",
          description: "logged in",
          rtl,
        });
      }
    } catch (error) {
      console.log(error);
      openNotification({
        type: "error",
        title: "error",
        description: "something went wrong",
        rtl,
      });
    } finally {
      setLoading(false);
    }
  };

  const getAllPayAppRestaurants = async () => {
    try {
      setLoading(true);
      const res = await getAllAppRestaurants();
      if (res?.data?.restaurants) {
        dispatch(setPayAppRestaurantsAction(res.data.restaurants));
        dispatch(setMyFatoorahBanksAction(res.data.banks));
        openNotification({
          type: "success",
          title: "success",
          description: "got restaurants",
          rtl,
        });
      }
    } catch (error) {
      console.log(error);
      openNotification({
        type: "error",
        title: "error",
        description: "something went wrong",
        rtl,
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record, index) => {
        return <Link to={`/payApp/${record._id}`}>{index}</Link>;
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      render: (name, record) => {
        return (
          <Link to={record._id ? `/payApp/${record._id}` : ""}>{name}</Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
      render: (phone) => {
        return (
          <Text
            copyable={{
              // remove the first three digits from phone;
              text: phone ? phone.slice(3) : "",
            }}
          >
            {phone}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "integration" }),
      dataIndex: "integration",
      key: "integration",
      filters: [
        {
          text: "iWaiter",
          value: "iWaiter",
        },
        {
          text: "Marn",
          value: "Marn",
        },
        {
          text: "Foodics",
          value: "Foodics",
        },
      ],
      onFilter: (value, record) => record.integration === value,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (actions, record) => {
        return (
          <div>
            <CustomButton
              text={intl.formatMessage({ id: "view" })}
              type='outline'
              onClick={() => {
                navigate(`/payApp/${record._id}`);
              }}
            />
          </div>
        );
      },
    },
  ];

  if (!aminAppUser?.token) {
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 10,
          minHeight: 200,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        className='light-shadow'
      >
        <Title level={4}>{intl.formatMessage({ id: "password" })}</Title>
        <Input.Password
          placeholder={intl.formatMessage({ id: "password" })}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ width: 200, marginTop: 30 }}
        />
        <CustomButton
          text={intl.formatMessage({ id: "signin" })}
          type='primary'
          style={{ marginTop: 30 }}
          onClick={loginAdmin}
          loading={loading}
          disabled={loading || !password}
        />
      </div>
    );
  }

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <AddRestaurant open={showAdd} setOpen={setShowAdd} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "payApp" })}
          </Title>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CustomButton
              text={intl.formatMessage({ id: "refresh" })}
              type='text'
              icon={<IoRefreshOutline />}
              onClick={getAllPayAppRestaurants}
            />
            <CustomButton
              text={intl.formatMessage({ id: "addRestaurant" })}
              type='primary'
              icon={<BiPlusMedical />}
              onClick={() => {
                setShowAdd(true);
              }}
            />
          </div>
        </div>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedRestaurants?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedRestaurants}
          scroll={{ x: "max-content" }}
        />
      </Spin>
    </div>
  );
}

export default PayApp;
