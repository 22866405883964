import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Spin,
  Form,
  Input,
  Tag,
  Row,
  Col,
  Select,
  Divider,
  Upload,
} from "antd";
import { useIntl } from "react-intl";
import { SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import {
  getUsersApplicationTerms,
  sendApplicationNotification,
} from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { setAppUsersAction } from "../../redux/reducers/restaurantsReducer";
import { Table } from "ant-table-extensions";
import { IoRefreshOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

const { Text, Title } = Typography;
const Notification = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [length, setLength] = useState(0);
  const [users, setUsers] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [imageURL, setImageURL] = useState("");

  const getUserApplication = async (values) => {
    try {
      let data = {};
      if (values) {
        data = { ...values };
      } else {
        data = form.getFieldsValue();
      }
      if (data.type === "hasAmountInWallet" && !data.amount) {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "required" }),
          type: "error",
          rtl,
        });
        return;
      }
      if (!data.type) {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "required" }),
          type: "error",
          rtl,
        });
        return;
      }
      setLoading(true);
      const res = await getUsersApplicationTerms(data);
      console.log(res?.data);
      if (res?.data?.length > -1) {
        setLength(res.data.length);
        let formatted = res?.data?.users?.map((user) => {
          return {
            key: user?._id,
            name: user?.name,
            phone: user?.phone,
            ordersCount: user?.orders?.length,
            pickupCount: user?.pickupOrders?.length,
            reservationCount: user?.reservations?.length,
            waitingCount: user?.waiting?.length,
            fcmNotificationToken: user?.fcmNotificationToken,
            eWallet: user?.eWallet,
            userObject: { ...user },
          };
        });
        setUsers(formatted);
        setLoading(false);
        openNotification({
          title: "success",
          description: "users loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const sendNotifications = async (values, callBack) => {
    try {
      setLoading(true);
      const data = {
        ...values,
        tokens,
      };
      if (tokens?.length === 0) {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "selectUsers" }),
          type: "error",
          rtl,
        });
        setLoading(false);
        return;
      }
      const res = await sendApplicationNotification(data);
      if (res.data.status === "success") {
        callBack();
      }

      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const phones = selectedRows.map((row) => row.fcmNotificationToken);
      setTokens(phones);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: !record.fcmNotificationToken ? true : false,
        // Column configuration not to be checked
        name: record.fcmNotificationToken,
      };
    },
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      render: (number, record, index) => {
        return <Text>{index + 1}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: intl.formatMessage({ id: "inHouse" }),
      dataIndex: "ordersCount",
      key: "ordersCount",
      sorter: (a, b) => new Date(a.ordersCount) - new Date(b.ordersCount),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "pickupD" }),
      dataIndex: "pickupCount",
      key: "pickupCount",
      sorter: (a, b) => new Date(a.pickupCount) - new Date(b.pickupCount),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "waiting" }),
      dataIndex: "waitingCount",
      key: "waitingCount",
      sorter: (a, b) => new Date(a.waitingCount) - new Date(b.waitingCount),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "reservations" }),
      dataIndex: "reservationCount",
      key: "reservationCount",
      sorter: (a, b) =>
        new Date(a.reservationCount) - new Date(b.reservationCount),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "view" }),
      dataIndex: "view",
      key: "view",
      render: (text, record) => (
        <Link to={`/users-application/${record?.userObject?._id}`}>
          {intl.formatMessage({ id: "view" })}
        </Link>
      ),
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "users" })}
            <Tag color='green' className='mx-3'>
              {length} users
            </Tag>
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={() => getUserApplication()}
          />
        </div>
        <Form
          name='get-users'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={getUserApplication}
        >
          {() => {
            return (
              <>
                <Row justify='center' align='middle'>
                  <Col md={12} sm={24} xs={24}>
                    {/* show drop doown selector */}
                    <Form.Item
                      label={intl.formatMessage({ id: "type" })}
                      name='type'
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "required" }),
                        },
                      ]}
                    >
                      <Select className='my-font'>
                        <Select.Option value='all'>
                          {intl.formatMessage({ id: "all" })}
                        </Select.Option>
                        <Select.Option value='hasAmountInWallet'>
                          {intl.formatMessage({ id: "hasAmountInWallet" })}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "amount" })}
                      name='amount'
                    >
                      <Input type='number' />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "search" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                  />
                </Form.Item>
              </>
            );
          }}
        </Form>
        <Divider />
        <MySendCom loading={loading} sendNotifications={sendNotifications} />

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || users?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
            pageSizeOptions: [10, 25, 50, 100, 200, 500],
          }}
          columns={columns}
          className='light-shadow pointer'
          loading={loading}
          dataSource={users}
          scroll={{ x: "max-content" }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      </Spin>
    </div>
  );
};

export default Notification;

const MySendCom = ({ loading, sendNotifications }) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const send = () => {
    sendNotifications(form.getFieldsValue(), form.resetFields);
  };
  return (
    <Form
      name='send-notification'
      form={form}
      preserve={false}
      className='form-container'
      layout='vertical'
      onFinish={send}
      initialValues={{
        title: "",
        body: "",
      }}
    >
      {() => {
        return (
          <>
            <Row justify='start' align='middle'>
              <Col md={6} sm={12} xs={24}>
                <Form.Item
                  label={intl.formatMessage({ id: "title" })}
                  name='title'
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "required" }),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row justify='start' align='middle'>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  label={intl.formatMessage({ id: "body" })}
                  name='body'
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "required" }),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row justify='start' align='middle'>
              <Col md={12} sm={24} xs={24}>
                <Upload
                  listType='picture'
                  maxCount={1}
                  beforeUpload={(file) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      // setImageURL(e.target.result);
                      console.log(e.target.result);
                    };
                    reader.readAsDataURL(file);
                    return false;
                  }}
                >
                  <CustomButton
                    text={intl.formatMessage({ id: "upload" })}
                    type='text'
                  />
                </Upload>
              </Col>
            </Row> */}
            <Row justify='flex-start' align='middle'>
              <Form.Item>
                <CustomButton
                  type='primary'
                  htmlType='submit'
                  text={intl.formatMessage({ id: "send" })}
                  className={`btnRegister btn-text border-8`}
                  loading={loading}
                  disabled={loading}
                />
              </Form.Item>
            </Row>
          </>
        );
      }}
    </Form>
  );
};
