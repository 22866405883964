import { createSlice } from "@reduxjs/toolkit";
import { arrAdd, arrUpdateByKeyValue } from "../../../helpers/array";

const initialState = {
  restaurants: [],
  users: [],
  superUsers: [],
  integrations: [],
  refers: [],
  payments: [],
  appRestaurants: [],
  appUsers: [],
  wallets: [],
  tips: [],
  walletAmounts: [],
};

export const restaurantsSlice = createSlice({
  name: "restaurants",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setRestaurants: (state, action) => {
      state.restaurants = action.payload;
    },
    setAppRestaurants: (state, action) => {
      state.appRestaurants = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setSuperUsers: (state, action) => {
      state.superUsers = action.payload;
    },
    serIntegrations: (state, action) => {
      state.integrations = action.payload;
    },
    setRefers: (state, action) => {
      state.refers = action.payload;
    },
    editRefer: (state, action) => {
      state.refers = arrUpdateByKeyValue({
        arr: state.refers,
        key: "_id",
        value: action.payload._id,
        newElement: action.payload,
      });
    },
    addRefer: (state, action) => {
      state.refers = arrAdd({ arr: state.refers, newElement: action.payload });
    },
    setPayments: (state, action) => {
      state.payments = action.payload;
    },
    setTips: (state, action) => {
      state.tips = action.payload;
    },
    editPayment: (state, action) => {
      state.payments = arrUpdateByKeyValue({
        arr: state.payments,
        key: "_id",
        value: action.payload._id,
        newElement: action.payload,
      });
    },
    setAppUsers: (state, action) => {
      state.appUsers = action.payload;
    },
    updateAppUser: (state, action) => {
      state.appUsers = arrUpdateByKeyValue({
        arr: state.appUsers,
        key: "_id",
        value: action.payload._id,
        newElement: action.payload,
      });
    },
    setWallets: (state, action) => {
      state.wallets = action.payload;
    },
    setWalletAmounts: (state, action) => {
      state.walletAmounts = action.payload;
    },
  },
});

export const {
  setRestaurants: setRestaurantsAction,
  setUsers: setUsersAction,
  serIntegrations: setSerIntegrationsAction,
  setRefers: setRefersAction,
  editRefer: editReferAction,
  addRefer: addReferAction,
  setPayments: setPaymentsAction,
  setTips: setTipsAction,
  editPayment: editPaymentAction,
  setAppRestaurants: setAppRestaurantsAction,
  setSuperUsers: setSuperUsersAction,
  setAppUsers: setAppUsersAction,
  updateAppUser: updateAppUserAction,
  setWallets: setWalletsAction,
  setWalletAmounts: setWalletAmountsAction,
} = restaurantsSlice.actions;

export default restaurantsSlice.reducer;
