import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Spin, Tag } from "antd";
import { useIntl } from "react-intl";
import { SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import { getWallets } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import {
  setRestaurantsAction,
  setWalletsAction,
} from "../../redux/reducers/restaurantsReducer";
import { MdVerified } from "react-icons/md";
import { Link } from "react-router-dom";
import { Table } from "ant-table-extensions";
import { IoRefreshOutline } from "react-icons/io5";

const { Text, Title } = Typography;
const TapWallets = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { wallets } = useSelector((state) => state.restaurants);
  const { rtl } = useSelector((state) => state.settings);

  const [loading, setLoading] = useState(true);
  const [formattedWallets, setFormattedWallets] = useState([]);

  useEffect(() => {
    if (!wallets?.length) {
      getWalletData();
    } else {
      setLoading(false);
    }
  }, [wallets]);

  const getWalletData = async () => {
    try {
      setLoading(true);
      const res = await getWallets();
      if (res?.data?.wallets) {
        dispatch(setWalletsAction(res.data.wallets));
        setLoading(false);
        openNotification({
          title: "success",
          description: "wallets loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      render: (number, record, index) => {
        return <Text className='my-font'>{index + 1}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "restaurantName" }),
      dataIndex: "restaurantName",
      key: "restaurantName",
      render: (name, record) => {
        return (
          <Link to={record._id ? `/tap/${record._id}` : ""}>
            {name}
          </Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "wallet" }),
      dataIndex: "type",
      key: "type",
      render: (type) => {
        return (
          <Tag
            color={
              type === "GENERAL"
                ? "red"
                : type === "MY_FATOORAH"
                ? "orange"
                : "green"
            }
          >
            {type === "GENERAL"
              ? "TAP_GENERAL"
              : type === "MY_FATOORAH"
              ? type
              : "TAP_MERCHANT"}
          </Tag>
        );
      },
      filters: [
        {
          text: "TAP_GENERAL",
          value: "GENERAL",
        },
        {
          text: "MY_FATOORAH",
          value: "MY_FATOORAH",
        },
        {
          text: "TAP_MERCHANT",
          value: "MERCHANT",
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
    },
    {
      title: intl.formatMessage({ id: "destination_id" }),
      dataIndex: "destination_id",
      key: "destination_id",
      filters: [
        {
          text: "N/A",
          value: "N/A",
        },
        {
          text: "Active",
          value: "ACTIVE",
        },
      ],
      onFilter: (value, record) => {
        console.log(value,)
        if(value === "ACTIVE") {
          
          return record.destination_id !== "N/A"
        }
        return record.destination_id.indexOf(value) === 0
      },
    },
    
  ];

  useEffect(() => {
    setLoading(true);
    if (wallets?.length) {
      const formatted = wallets.map((wallet, i) => {
        let type = "GENERAL";
        if (wallet?.name === "MY_FATOORAH") {
          type = "MY_FATOORAH";
        } else if (
          wallet?.restaurantId?.destination_id &&
          wallet?.restaurantId?.destination_id !== "WALLET"
        ) {
          type = "MERCHANT";
        }

        return {
          key: wallet._id,
          _id: wallet._id,
          restaurantName: wallet?.restaurantId?.name,
          type: type,
          destination_id: wallet?.tapWalletInfo?.destinationId ? wallet?.tapWalletInfo?.destinationId :  !wallet?.restaurantId?.destination_id || wallet?.restaurantId?.destination_id === "WALLET" ? "N/A" : wallet?.restaurantId?.destination_id,
          walletObject: { ...wallet },
        };
      });
      setFormattedWallets(formatted);
    }
    setLoading(false);
  }, [rtl, wallets]);
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "integration" })}
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getWalletData}
          />
        </div>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table",
              prefix: <SearchOutlined />,
              disabled: loading || formattedWallets?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
            pageSizeOptions: [10, 25, 50, 100, 200, 500],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedWallets}
          scroll={{ x: 'max-content' }}
        />
      </Spin>
    </div>
  );
};

export default TapWallets;
