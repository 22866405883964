import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { deleteChallenge, getOneChallenge } from "../../API/fetch";
import COLORS from "../../Style/colors";
import { Descriptions, Typography, Spin } from "antd";
import { useIntl } from "react-intl";
import CustomButton from "../../Components/CustomButton";

const { Text, Title } = Typography;
function OneGame() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [challenge, setChallenge] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getChallenge();
  }, []);

  const getChallenge = async () => {
    try {
      setLoading(true);
      const res = await getOneChallenge(id);
      console.log(res.data);
      if (res?.data?.challenge) {
        setChallenge(res.data.challenge);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  console.log(challenge);

  const deleteSystemChallenge = async () => {
    try {
      setLoading(true);
      const res = await deleteChallenge(challenge?._id);
      getChallenge();
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div
      style={{
        backgroundColor: COLORS.white,
        marginTop: 10,
        width: "92%",
        minHeight: "70vh",
        marginRight: "auto",
        marginLeft: "auto",
        borderRadius: 10,
        padding: 20,
      }}
      className='light-shadow'
    >
      <Spin spinning={loading}>
        <Descriptions
          title={intl.formatMessage({ id: "gameInfo" })}
          layout='vertical'
          bordered
          className='my-font'
        >
          <Descriptions.Item label={intl.formatMessage({ id: "creator" })}>
            <Link
              to={
                challenge?.creator?.phone.startsWith("111")
                  ? "#"
                  : `/users-application/${challenge?.creator?._id}`
              }
            >
              {challenge?.creator?.phone.startsWith("111")
                ? "System"
                : challenge?.creator?.name}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: "prize" })}>
            {challenge?.prize?.toFixed(2)} SAR
          </Descriptions.Item>
          <Descriptions.Item
            style={{
              backgroundColor:
                challenge?.status === "ended" || challenge?.status === "deleted"
                  ? COLORS.danger
                  : COLORS.green,
              color: COLORS.white,
            }}
            label={intl.formatMessage({ id: "status" })}
          >
            {challenge?.status}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: "winner" })}>
            <Link
              to={
                challenge?.winner?._id
                  ? `/users-application/${challenge?.winner?._id}`
                  : "#"
              }
            >
              {challenge?.winner?.name
                ? challenge?.winner?.name
                : "No Winner Yet"}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: "game" })}>
            {challenge?.gameType}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: "players" })}>
            {challenge?.allPlayers?.map((player) => (
              <div
                key={player._id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Link to={`/users-application/${player._id}`}>
                  {player.name}
                </Link>
              </div>
            ))}
          </Descriptions.Item>
          {challenge?.gameType?.includes("Quiz") ? (
            <Descriptions.Item label={intl.formatMessage({ id: "questions" })}>
              {challenge?.allQuestions?.map((question) => (
                <div
                  key={question._id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text>{question.text}</Text>
                  {/* <Text style={{ marginLeft: 10 }}>{question.answer}</Text> */}
                </div>
              ))}
            </Descriptions.Item>
          ) : null}
        </Descriptions>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <CustomButton
            onClick={deleteSystemChallenge}
            disabled={
              challenge?.status === "deleted" ||
              challenge?.status === "ended" ||
              challenge?.status === "started" ||
              challenge?.status === "accepted" ||
              challenge?.creator?.phone.startsWith("111") === false
            }
            style={{ width: "48%" }}
            text={intl.formatMessage({ id: "delete" })}
            danger
            loading={loading}
          />
          <CustomButton
            onClick={() => navigate(-1)}
            style={{ width: "48%" }}
            text={intl.formatMessage({ id: "back" })}
            type='primary'
          />
        </div>
      </Spin>
    </div>
  );
}

export default OneGame;
