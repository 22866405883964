import { Collapse, Empty, Space, Typography, Progress } from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import OneCategoryCard from "./OneCategoryCard";
import CustomButton from "../../../../../Components/CustomButton";
import COLORS from "../../../../../Style/colors";

const { Title, Text } = Typography;
const { Panel } = Collapse;
const MenuMarn = ({ restaurant, updateMenu, posData, addMarnMenu, loading }) => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [percentage, setPercentage] = useState(0);
  const [posProductsNotLinked, setPosProductsNotLinked] = useState([]);
  const [posProductsLinked, setPosProductsLinked] = useState([])

  useEffect(() => {
    if (restaurant?.menu?.length) {
      // get all foods count;
      let allFoodsCount = 0;
      let linked = [];
      // get all food that have POSIntegration array in it;
      let allFoodsWithIntegration = 0;
      restaurant.menu.forEach((cat) => {
        cat.foods.forEach((food) => {
          allFoodsCount++;
          if (food.POSIntegration?.length) {
            allFoodsWithIntegration++;
            // add the pos product to posProductsLinked;
            const product = posData?.products?.find(p => p.marnId === food.POSIntegration?.[0]?.otherFoodId);
            if(product){
              linked.push(product);
            }
          }
        });
      });
      setPercentage(
        Math.round((allFoodsWithIntegration / allFoodsCount) * 100)
      );
      setPosProductsLinked(linked);
    }
  }, [restaurant]);

  useEffect(() => {
    if(posData?.products?.length){
      let notLinked = [];
      posData.products.forEach(product => {
        if(!posProductsLinked.find(p => p.marnId === product.marnId)){
          notLinked.push(product);
        }
      }
      )
      setPosProductsNotLinked(notLinked);
    }

  } ,[posData, posProductsLinked])

  // console.log(JSON.stringify(posProductsNotLinked));

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Space direction='vertical'>
        <Title level={3}>{intl.formatMessage({ id: "menu" })}</Title>
        <CustomButton
          text={intl.formatMessage({ id: "addMarnMenu" })}
          className={`btn-text border-8 px-5 pb-5 mt-4`}
          style={{
            border: `1px ${COLORS.primary} solid`
          }}
          loading={loading}
          disabled={loading}
          type='danger'
          onClick={addMarnMenu}
        />
      </Space>
      <Progress percent={percentage} />
      {restaurant?.menu?.length ? (
        <div className='mb-3 mt-5'>
          <Collapse
            bordered={false}
            accordion={true}
            className='border-8'
            style={{ backgroundColor: "#ffffff" }}
            destroyInactivePanel={false}
          >
            {restaurant?.menu.map((category) => {
              return (
                <Panel
                  style={{ marginTop: 10 }}
                  header={
                    <Title level={5} className='my-font'>
                      {rtl ? category.name : category.name_en}
                    </Title>
                  }
                  key={category.categoryId}
                >
                  <OneCategoryCard
                    category={category}
                    posFoods={posData?.products}
                    updateMenu={updateMenu}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>
      ) : (
        <div className='mt-4'>
          <Empty
            image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
            imageStyle={{
              height: 250,
            }}
            description={<h2 className='my-font'>No Menu in iwaiter</h2>}
          ></Empty>
        </div>
      )}
    </div>
  );
};

export default MenuMarn;
