import React, { useState, useEffect } from "react";
import COLORS from "../../Style/colors";
import { Table, Typography } from "antd";
import { getAllCompetition } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { IoRefreshOutline } from "react-icons/io5";
import CustomButton from "../../Components/CustomButton";
import EditCompetition from "./EditCompetition";
import { BiPlus } from "react-icons/bi";
import dayjs from "dayjs";
import WinnerModal from "./WinnerModal";

const { Title, Text } = Typography;
function AppMarketing() {
  const intl = useIntl();
  const { restaurants } = useSelector((state) => state.restaurants);

  const [loading, setLoading] = useState(false);
  const [competitions, setCompetitions] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [showWinners, setShowWinners] = useState(false)

  const getCompetition = async () => {
    try {
      setLoading(true);
      const res = await getAllCompetition();
      if (res?.data?.competitions) {
        setCompetitions(res.data.competitions);
        openNotification({
          title: "ناجح",
          description: "تم تحميل البيانات بنجاح",
          type: "success",
          rtl: true,
        });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: "فشل",
        description: "حدث خطأ أثناء تحميل البيانات",
        type: "danger",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'number',
      key: 'number',
      render: (text, record, index) => <Text>{index + 1}</Text>,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: intl.formatMessage({ id: "dateStart" }),
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => <Text>{dayjs(text).format('YYYY-MM-DD')}</Text>,
    },
    {
      title: intl.formatMessage({ id: "dateEnd" }),
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => <Text>{dayjs(text).format('YYYY-MM-DD')}</Text>,
    },
    {
      title: intl.formatMessage({ id: "winners" }),
      dataIndex: "winners",
      key: "winners",
      render: (text, record) => <CustomButton 
        text={`${text.length} ${intl.formatMessage({id: 'winners'})}`}
        type='primary'
        onClick={() => {
          setSelectedCompetition(record);
          setShowWinners(true);
        }}
        
      />,
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        let background = COLORS.success;
        let color = COLORS.white;
        // check if date is less than now;
        let date = record.endDate;
        if (dayjs(date).isBefore(dayjs()) || text === 'disabled') {
          background = COLORS.danger;
        } // check if date is within the next 8 days;
        else if (dayjs(date).isBetween(dayjs(), dayjs().add(8, "days"))) {
          background = COLORS.warning;
        }
        return {
          props: {
            style: { background },
          },
          children: (
            <Text style={{ color }}>{intl.formatMessage({id: text})}</Text>
          ),
        };
      },
    },
    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <CustomButton
          text={intl.formatMessage({ id: "edit" })}
          type='primary'
          onClick={() => {
            setSelectedCompetition(record);
            setShowEdit(true);
          }}
        />
      ),
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {showEdit ? (
        <EditCompetition
          show={showEdit}
          hide={() => {
            setShowEdit(false);
            setSelectedCompetition(null);
          }}
          competition={selectedCompetition}
          restaurants={restaurants}
        />
      ) : null}
      {showWinners ? 
        <WinnerModal
          show={showWinners}
          hide={() => {
            setShowWinners(false);
            setSelectedCompetition(null);
          }}
          competition={selectedCompetition}
        />
      
      : null}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "appMarketing" })}
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getCompetition}
          />
        </div>
        <CustomButton
          text={intl.formatMessage({ id: "add" })}
          icon={<BiPlus />}
          type='primary'
          onClick={() => {
            setShowEdit(true);
            setSelectedCompetition(null);
          }}
        />
      </div>

      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 10,
          width: "92%",
          minHeight: "70vh",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 20,
        }}
        className='light-shadow'
      >
        <Table
          pagination={{
            position: ["topLeft"],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={competitions?.map(a => ({...a, key: a._id}))}
          scroll={{ x: "max-content" }}
        />
      </div>
    </div>
  );
}

export default AppMarketing;
