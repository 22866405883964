export   const getRestaurantStats = (restaurants) => {
    let restaurantWithHighestDineIn = null;
    let highestDineIn = 0;

    let restaurantWithHighestDelivery = null;
    let highestDelivery = 0;

    let restaurantWithHighestPickUp = null;
    let highestPickUp = 0;

    let restaurantWithHighestReservation = null;
    let highestReservation = 0;

    let restaurantWithHighestWaiting = null;
    let highestWaiting = 0;

    // Iterate through the restaurants array to find the highest dineIn and delivery
    for (const restaurant of restaurants) {
      const { name, name_en, dineIn, delivery, waiting, pickUp, reservations } =
        restaurant;

      if (dineIn > highestDineIn) {
        highestDineIn = dineIn;
        restaurantWithHighestDineIn = {
          name: name,
          name_en: name_en,
        };
      }

      if (delivery > highestDelivery) {
        highestDelivery = delivery;
        restaurantWithHighestDelivery = {
          name: name,
          name_en: name_en,
        };
      }

      if (waiting > highestWaiting) {
        highestWaiting = waiting;
        restaurantWithHighestWaiting = {
          name: name,
          name_en: name_en,
        };
      }

      if (pickUp > highestPickUp) {
        highestPickUp = pickUp;
        restaurantWithHighestPickUp = {
          name: name,
          name_en: name_en,
        };
      }

      if (reservations > highestReservation) {
        highestReservation = reservations;
        restaurantWithHighestReservation = {
          name: name,
          name_en: name_en,
        };
      }
    }

    return {
      dineIn: {
        highestDineIn,
        restaurantWithHighestDineIn,
      },
      delivery: {
        highestDelivery,
        restaurantWithHighestDelivery,
      },
      waiting: {
        highestWaiting,
        restaurantWithHighestWaiting,
      },
      pickUp: {
        highestPickUp,
        restaurantWithHighestPickUp,
      },
      reservation: {
        highestReservation,
        restaurantWithHighestReservation,
      },
    };
  };