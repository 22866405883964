import { Descriptions, Result, Space, Typography } from "antd";
import React from "react";
import { useIntl } from "react-intl";

const { Text, Title } = Typography;
const FoodicsModifiers = ({ modifiers }) => {
  const intl = useIntl();
  return (
    <Descriptions
      title='FOODICS DATA'
      bordered
      column={2}
      style={{ marginTop: 25 }}
    >
      {modifiers?.length ? (
        modifiers?.map((modifier) => {
          return (
            <Descriptions.Item
              label={
                <Space direction='vertical'>
                  <Title level={5}>{modifier.foodicsModName}</Title>
                  <Text
                    className='my-font'
                    type={modifier.foodicsMinOptions > 0 ? "danger" : ""}
                  >
                    {intl.formatMessage({ id: "minSelection" })} -{" "}
                    {modifier.foodicsMinOptions}
                  </Text>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "maxSelection" })} -{" "}
                    {modifier.foodicsMaxOptions}
                  </Text>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "freeSelection" })} -{" "}
                    {modifier.foodicsFreeOptions}
                  </Text>
                </Space>
              }
              key={modifier.foodicsModId}
            >
              <ul>
                {modifier.options
                  ?.filter((opt) => {
                    return !modifier?.foodicsExcluded?.includes(opt.id);
                  })
                  ?.map((option) => {
                    return (
                      <li key={option.id}>
                        {option?.name} / SAR {option?.price}
                      </li>
                    );
                  })}
              </ul>
            </Descriptions.Item>
          );
        })
      ) : (
        <Descriptions.Item>
          <Result
            status={"404"}
            title={intl.formatMessage({ id: "noModifiers" })}
            className='my-font'
          />
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

export default FoodicsModifiers;
