import React, { useState, useEffect } from "react";
import { Result, Button } from "antd";
import Login from "./Components/Login";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { changeLanguageAction } from "../../redux/reducers/settingsReducer";
import EnterCode from "./Components/EnterCode";
import { useIntl } from "react-intl";

const Register = () => {
  const [screen, setScreen] = useState("SignIn");
  const intl = useIntl();
  const [formValues, setFormValues] = useState(null);
  const dispatch = useDispatch();
  const { search } = useLocation();

  const { rtl } = useSelector((state) => state.settings);

  useEffect(() => {
    if (search?.includes("lang=")) {
      let isRtl = search.split("=")[1] === "ar";
      if (isRtl && !rtl) {
        dispatch(changeLanguageAction());
      }
    }
  }, [search, dispatch, rtl]);

  return (
    <div>
      {screen === "SignIn" ? (
        <Login
          showSignUp={() => setScreen("SignUp")}
          setShowForgetPassword={() => setScreen("Forget_Password")}
          setShowPinCode={() => setScreen("ShowPinCode")}
        />
      ) : screen === "ShowPinCode" ? (
        <EnterCode />
      ) : (
        <Result
          status='404'
          title='404'
          subTitle={intl.formatMessage({ id: "pageError" })}
          extra={
            <Button type='primary' onClick={() => setScreen("SignIn")}>
              {intl.formatMessage({ id: "goBack" })}
            </Button>
          }
        />
      )}
    </div>
  );
};

export default Register;
