import { Descriptions, Typography, Badge, Avatar } from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import React, { useState, useEffect } from "react";
import { MdVerified } from "react-icons/md";
import { useIntl } from "react-intl";
import COLORS from "../../../Style/colors";
const CLINT_URL = "https://pay.iwaiter.club";
dayjs.extend(isBetween);
const { Text, Title } = Typography;
const RestaurantInfo = ({ restaurant }) => {
  const intl = useIntl();
  return (
    <Descriptions
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title level={4}>{restaurant?.name}</Title>
          <Avatar src={restaurant?.logo ? restaurant?.logo : null} />
        </div>
      }
      className='my-font'
      bordered
      column={{
        xxl: 4,
        xl: 3,
        lg: 2,
        md: 2,
        sm: 1,
        xs: 1,
      }}
    >
      <Descriptions.Item label={intl.formatMessage({ id: "justName" })}>
        {restaurant?.owner?.name}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "phone" })}>
        <Text
          copyable={{
            text: restaurant?.owner?.phone
              ? restaurant?.owner?.phone?.slice(3)
              : "",
          }}
        >
          {restaurant?.owner?.phone}
        </Text>
      </Descriptions.Item>
      <Descriptions.Item
        label={`${intl.formatMessage({
          id: "phone",
        })} - ${intl.formatMessage({ id: "restaurant" })}`}
      >
        <Text
          copyable={{
            text: restaurant?.whatsapp ? restaurant?.whatsapp : "",
          }}
        >
          {restaurant?.whatsapp}
        </Text>
      </Descriptions.Item>

      <Descriptions.Item label={intl.formatMessage({ id: "email" })}>
        {restaurant?.email}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "branches" })}>
        {restaurant?.branches?.length}
      </Descriptions.Item>

      <Descriptions.Item label={intl.formatMessage({ id: "cityName" })}>
        {restaurant?.branches?.map((b) => b?.cityName || "N/A")?.join(", ")}
      </Descriptions.Item>

      <Descriptions.Item label={"1st Table"}>
        <a
          href={`${CLINT_URL}/${restaurant?.slug}/${restaurant?.branches?.[0]?._id}/${restaurant?.tables?.[0]?._id}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {intl.formatMessage({ id: "link" })}
        </a>
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "tablesCount" })}>
        {restaurant?.tables?.length}
      </Descriptions.Item>
      <Descriptions.Item
        label={`${intl.formatMessage({
          id: "ordersTotal",
        })}`}
      >
        {restaurant?.orderCount}
      </Descriptions.Item>
      <Descriptions.Item
        label={`${intl.formatMessage({
          id: "amount",
        })}`}
      >
        {restaurant?.orderAmount?.toFixed(2)}
      </Descriptions.Item>

      <Descriptions.Item
        label={intl.formatMessage({ id: "percentageForIwaiter" })}
      >
        {restaurant?.percentageForIwaiterVISA}
      </Descriptions.Item>
      <Descriptions.Item
        label={intl.formatMessage({ id: "percentageForIWaiterMADA" })}
      >
        {restaurant?.percentageForIWaiterMADA}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default RestaurantInfo;
