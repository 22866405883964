import { Descriptions } from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import CustomButton from "../../../../../../Components/CustomButton";
import COLORS from "../../../../../../Style/colors";
import OneBranchRow from "./OneBranchRow";
const BranchesFoodics = ({
  localBranches,
  linkedBranches,
  posBranches,
  updatePOSBranches,
  loading,
}) => {
  const intl = useIntl();
  const [linkBranchedLocal, setLinkBranchedLocal] = useState(linkedBranches);
  useEffect(() => {
    if (linkedBranches?.length) {
      setLinkBranchedLocal(linkedBranches);
    }
  }, [linkedBranches]);
  return (
    <div>
      <Descriptions
        title={intl.formatMessage({ id: "branches" })}
        bordered
        className='my-font'
        style={{
          textAlign: "center",
        }}
        column={1}
      >
        <Descriptions.Item
          label='iwaiter'
          labelStyle={{ backgroundColor: COLORS.gray }}
          contentStyle={{ backgroundColor: COLORS.gray }}
        >
          Foodics
        </Descriptions.Item>

        {localBranches.map((branch, i) => {
          return (
            <Descriptions.Item label={branch.name} key={branch._id}>
              <OneBranchRow
                branch={branch}
                posBranches={posBranches}
                linkedBranches={linkBranchedLocal}
                setLinkBranchedLocal={setLinkBranchedLocal}
              />
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      <CustomButton
        text={intl.formatMessage({ id: "save" })}
        className={`btn-text border-8 px-5 pb-5 mt-4`}
        loading={loading}
        disabled={loading}
        type='primary'
        onClick={() => updatePOSBranches(linkBranchedLocal)}
      />
    </div>
  );
};

export default BranchesFoodics;
