import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Typography, Divider, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import openNotification from "../../../Components/Notifications";
import CustomButton from "../../../Components/CustomButton";
import dayjs from "dayjs";
import { createTransaction } from "../../../API/fetch";
const { Option } = Select;
const { Title, Text } = Typography;

export const TransactionsTypes = [
  {
    name: "Bank Al Riyadh",
    value: "Bank-AlRiyadh",
  },
  {
    name: "Bank AlBilad",
    value: "Bank-Albilad",
  },
  {
    name: "STC Pay",
    value: "STC-PAY",
  },
];

const EditPaymentModal = ({ show, hide, payment }) => {
  const intl = useIntl();

  const { rtl } = useSelector((state) => state.settings);
  // const [isNew, setIsNew] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 300);
  }, [payment, show, hide]);

  const addTransaction = async (values) => {
    try {
      setLoading(true);

      const data = {
        paymentTransaction: {
          restaurantId: payment?.restaurantId,
          startDate: payment?.start,
          endDate: payment?.end,
          source: values.source,
          transactionNumber: values.transactionNumber,
          amount:
            payment?.MADATransferTotal +
            payment?.VISATransferTotal +
            payment?.TABBYTransferTotal +
            payment?.deliveryAmountToTransferWithoutVat -
            payment?.VATFees -
            payment?.totalRefundFees -
            payment?.deliveryAmountVat,
          profit:
            payment?.total -
            (payment?.MADATransferTotal +
              payment?.VISATransferTotal +
              payment?.TABBYTransferTotal) +
            payment?.iwaiterCommission,
          status: "Success",
          MADATotal: payment?.MADATotal,
          TABBYTotal: payment?.TABBYTotal,
          VISATotal: payment?.VISATotal,
          MADATransferTotal: payment?.MADATransferTotal,
          TABBYTransferTotal: payment?.TABBYTransferTotal,
          VISATransferTotal: payment?.VISATransferTotal,
          total:
            payment?.MADATransferTotal +
            payment?.VISATransferTotal +
            payment?.TABBYTransferTotal,
          totalToTransfer:
            payment?.MADATransferTotal +
            payment?.VISATransferTotal +
            payment?.TABBYTransferTotal +
            payment?.deliveryAmountToTransferWithoutVat -
            payment?.VATFees -
            payment?.totalRefundFees -
            payment?.deliveryAmountVat,
          VATFees: payment?.VATFees,
          refundFees: payment?.totalRefundFees,
          totalRefundAmount: payment?.totalRefundAmount,
          refundFeesWithoutVAT: payment?.totalRefundFees,
          deliveryTotal: payment?.totalDeliveryAmount,
          deliveryTotalToTransfer:
            payment?.deliveryAmountToTransferWithoutVat -
            payment?.deliveryAmountVat,
          restaurantPaidForDelivery: payment?.totalRestaurantPaidAmount,
          deliveryAmountVat: payment?.deliveryAmountVat,
          deliveryCountOrders: payment?.iwaiterCommission / 2,
          subscriptionUnpaidFees: payment?.subscriptionUnpaidFees,
          totalSubscriptionFees: payment?.totalSubscriptionFees,
          totalSubscriptionPaidFees: payment?.totalSubscriptionPaidFees,
        },
      };
      const res = await createTransaction(data);
      if (res?.data?.transaction) {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setLoading(false);
        hide();
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    } catch (error) {
      console.log(error?.response?.data);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {payment?.name}
        </Title>
      }
      centered
      open={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => {
        hide();
      }}
      footer={null}
    >
      <Form
        name='addTransaction'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={addTransaction}
        initialValues={{
          name: payment?.name,
          // source: payment?.source,
          transactionNumber: payment?.transactions?.[0]?.transactionNumber,
          source:
            payment?.transactions?.[0]?.source || TransactionsTypes[0].value,
        }}
      >
        {() => {
          return (
            <>
              <Form.Item
                label={intl.formatMessage({ id: "transactionNumber" })}
                className='mb-1'
                name='transactionNumber'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Input
                  disabled={payment?.transactions?.[0]?.transactionNumber}
                  className={"input"}
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "transferType" })}
                className='mb-1'
                name='source'
              >
                <Select
                  className='my-font'
                  disabled={payment?.transactions?.[0]?.transactionNumber}
                >
                  {TransactionsTypes.map((item) => {
                    return (
                      <Option
                        className='my-font'
                        key={item.value}
                        value={item.value}
                      >
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Title className='mt-2' level={5}>
                {intl.formatMessage({ id: "amount" })} :{" "}
                {payment?.MADATransferTotal +
                  payment?.VISATransferTotal +
                  payment?.TABBYTransferTotal -
                  payment?.VATFees}{" "}
                SAR
              </Title>
              <Title className='mt-2' level={5}>
                {intl.formatMessage({ id: "ordersTotal" })} :{" "}
                {payment?.orderCount}
              </Title>
              <Text className='my-font'>
                {intl.formatMessage({ id: "forOrdersBetween" })}
                <Text type='secondary' className='mx-3'>
                  {dayjs(payment?.start)?.format("DD/MM/YYYY")}
                </Text>
                -
                <Text type='secondary' className='mx-3'>
                  {dayjs(payment?.end)?.format("DD/MM/YYYY")}
                </Text>
              </Text>

              <Divider />
              <Space direction='horizontal'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={payment?.transactions?.length || loading}
                    type='primary'
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    type='text'
                    text={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                    onClick={() => {
                      hide();
                    }}
                  />
                </Form.Item>
              </Space>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default EditPaymentModal;
