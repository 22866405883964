import { Descriptions } from "antd";
import React from "react";
import { useIntl } from "react-intl";

function TapInfoUser({ data, wallet }) {
  const intl = useIntl();
  return (
    <Descriptions bordered>
      <Descriptions.Item label={intl.formatMessage({ id: "justName" })}>
        {data?.user?.name?.title}. {data?.user?.name?.first}{" "}
        {data?.user?.name?.middle} {data?.user?.name?.last}{" "}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "phone" })}>
        {data?.user?.contact_info?.primary?.phone?.number}{" "}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "email" })}>
        {data?.user?.contact_info?.primary?.email}{" "}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "type" })}>
        {data?.type}{" "}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "website" })}>
        {data?.brands?.[0]?.website}{" "}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "restaurant" })}>
        {data?.name?.ar}{" - "}{data?.name?.en}
      </Descriptions.Item>
      <Descriptions.Item label={'Business id'}>
        {data?.id}{" "}
      </Descriptions.Item>
      <Descriptions.Item label={'destination id'}>
        {wallet?.tapWalletInfo?.destinationId}{" "}
      </Descriptions.Item>
      
    </Descriptions>
  );
}

export default TapInfoUser;
