import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  challenges: [],
  appUsers: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setChallenges: (state, action) => {
      state.challenges = action.payload;
    },
    setAppUsers: (state, action) => {
      state.appUsers = action.payload;
    },
  },
});

export const {
  setChallenges: setChallengesAction,
  setAppUsers: setAppUsersAction,
} = appSlice.actions;

export default appSlice.reducer;
