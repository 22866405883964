import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { message } from "antd";
import Register from "./Views/Auth/index";
import Sidebar from "./Components/Sidebar";
import PrivateRoute from "./Components/PrivateRoute";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
// import { logOutAction } from "./redux/reducers/authReducer";
import Home from "./Views/Home";
import Users from "./Views/Users";
import Restaurants from "./Views/Restaurants";
import OneRestaurant from "./Views/OneRestaurant";
import Integrations from "./Views/Integrations";
import Refer from "./Views/Refer";
import SMS from "./Views/SMS";
import Payments from "./Views/Payments";
import Delivery from "./Views/Delivery";
import AllIntegrations from "./Views/AllIntegrations";
import Settlement from "./Views/Settlement";
import AllEmails from "./Views/AllEmails";
import PendingPayments from "./Views/PendingPayments";
import SettlementInfo from "./Views/SettlementInfo";
import MyFoatoorah from "./Views/MyFoatoorah";
import Application from "./Views/Application";
import UserApplication from "./Views/UserApplication";
import Marketing from "./Views/Marketing";
import MarketingEmails from "./Views/Marketing/MarketingEmail";
import SuperUser from "./Views/SuperUsers";
import SettingWhatsapp from "./Views/SettingWhatsapp";
import OneUserApplication from "./Views/UserApplication/Components/OneUserApplication";
import ActivityReports from "./Views/ActivityReports";
import TapWallets from "./Views/TapWallets";
import OneWallet from "./Views/OneWallet";
import AccountStatement from "./Views/AccountStatement";
import OneRestaurantAccount from "./Views/AccountStatement/OneRestaurantAccount";
import AppMarketing from "./Views/AppMarketing";
import Tips from "./Views/Tips";
import GameMarketing from "./Views/GameMarketing";
import OneGame from "./Views/GameMarketing/OneGame";
import AddChallenges from "./Views/GameMarketing/AddChallenges";
import GamesWallet from "./Views/GamesWallet";
import Notification from "./Views/Notification";
import NewPayments from "./Views/NewPayments";
import PayApp from "./Views/PayApp";
import OneRestaurantPayApp from "./Views/PayApp/components/OneRestaurantPayApp";

const AllRoutes = () => {
  const intl = useIntl();
  const { isOffline } = useSelector((state) => state.settings);

  useEffect(() => {
    if (isOffline) {
      message.error(intl.formatMessage({ id: "pleaseCheckInternet" }), 0);
    } else {
      message.destroy();
    }
  }, [isOffline]);

  return (
    <Routes>
      <Route
        path='/'
        element={
          <PrivateRoute>
            <Sidebar component={<Home />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/delivery-requests'
        element={
          <PrivateRoute>
            <Sidebar component={<Delivery />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/payApp'
        element={
          <PrivateRoute>
            <Sidebar component={<PayApp />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/payApp/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<OneRestaurantPayApp />} />
          </PrivateRoute>
        }
      />

      <Route
        path='/payments'
        element={
          <PrivateRoute>
            <Sidebar component={<Payments />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/tips'
        element={
          <PrivateRoute>
            <Sidebar component={<Tips />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/sms'
        element={
          <PrivateRoute>
            <Sidebar component={<SMS />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/app-games'
        element={
          <PrivateRoute>
            <Sidebar component={<GameMarketing />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/app-games/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<OneGame />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/addChallenges'
        element={
          <PrivateRoute>
            <Sidebar component={<AddChallenges />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/games-wallet'
        element={
          <PrivateRoute>
            <Sidebar component={<GamesWallet />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/marketing'
        element={
          <PrivateRoute>
            <Sidebar component={<Marketing />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/marketing-emails'
        element={
          <PrivateRoute>
            <Sidebar component={<MarketingEmails />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/settlement'
        element={
          <PrivateRoute>
            <Sidebar component={<Settlement />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/accountStatement'
        element={
          <PrivateRoute>
            <Sidebar component={<AccountStatement />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/accountStatement/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<OneRestaurantAccount />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/settlementInfo'
        element={
          <PrivateRoute>
            <Sidebar component={<SettlementInfo />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/application'
        element={
          <PrivateRoute>
            <Sidebar component={<Application />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/users-application'
        element={
          <PrivateRoute>
            <Sidebar component={<UserApplication />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/notifications'
        element={
          <PrivateRoute>
            <Sidebar component={<Notification />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/newPayments'
        element={
          <PrivateRoute>
            <Sidebar component={<NewPayments />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/users-application/:userId'
        element={
          <PrivateRoute>
            <Sidebar component={<OneUserApplication />} />
          </PrivateRoute>
        }
      />

      <Route
        path='/refer'
        element={
          <PrivateRoute>
            <Sidebar component={<Refer />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/allEmails'
        element={
          <PrivateRoute>
            <Sidebar component={<AllEmails />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/pendingPayments'
        element={
          <PrivateRoute>
            <Sidebar component={<PendingPayments />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/users'
        element={
          <PrivateRoute>
            <Sidebar component={<Users />} />
          </PrivateRoute>
        }
      />

      <Route
        path='/super-users'
        element={
          <PrivateRoute>
            <Sidebar component={<SuperUser />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/whatsapp-setting'
        element={
          <PrivateRoute>
            <Sidebar component={<SettingWhatsapp />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/restaurants'
        element={
          <PrivateRoute>
            <Sidebar component={<Restaurants />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/restaurants/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<OneRestaurant />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/myFatoorah'
        element={
          <PrivateRoute>
            <Sidebar component={<MyFoatoorah />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/integrations/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<Integrations />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/integrations'
        element={
          <PrivateRoute>
            <Sidebar component={<AllIntegrations />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/tap'
        element={
          <PrivateRoute>
            <Sidebar component={<TapWallets />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/tap/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<OneWallet />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/activityReports'
        element={
          <PrivateRoute>
            <Sidebar component={<ActivityReports />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/app-marketing'
        element={
          <PrivateRoute>
            <Sidebar component={<AppMarketing />} />
          </PrivateRoute>
        }
      />

      <Route path='*' element={<Register />} />
    </Routes>
  );
};

export default AllRoutes;
