import React from "react";
import { Spin, Image } from "antd";
import LOGO from "../assets/images/main-logo.png";
import { useIntl } from "react-intl";
import { Typography } from "antd";
import COLORS from '../Style/colors'
const { Title } = Typography;
const LoadingScreen = () => {
  const intl = useIntl();
  return (
    <div
      style={{
        minWidth: "100vw",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Image src={LOGO} preview={false} />
      <Title style={{color: COLORS.primary}}>{intl.formatMessage({ id: "loadingData" })} ...</Title>
      <Spin size='large' />
    </div>
  );
};

export default LoadingScreen;
