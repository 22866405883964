import { Tag } from "antd";
import React, {useState} from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import MapModal from "./MapModal";

const BranchTag = ({ branch }) => {
  const { rtl } = useSelector((state) => state.settings);
  const [showSelectMap, setShowSelectMap] = useState(false);
  const [newLocation, setNewLocation] = useState(null);
  useEffect(() =>{
    if(branch?.location){
        // extranct lat and lng from location.coordinates;
        const [lng, lat] = branch.location.coordinates;
        setNewLocation({lat, lng});
    }
  }, [branch])
  return (
    <>
     <MapModal
        show={showSelectMap}
        hide={() => setShowSelectMap(false)}
        location={newLocation}
        setLocation={setNewLocation}
        branchId={branch?._id}
        city={branch?.cityName}
      />
    <Tag 
    onClick={() => setShowSelectMap(true)}
    className="pointer"
    color={branch?.location ? "green" : "red"}>
      {rtl ? branch?.name : branch?.name_en}
    </Tag>
    </>
  );
};

export default BranchTag;
