import dayjs from "dayjs";
export const formatServices = (services) => {
    const sorted = [...services].sort((a, b) => {
     // form last end to first end
      // sort by serviceEnd
      const dateA = dayjs(a?.serviceEnd);
      const dateB = dayjs(b?.serviceEnd);
      return dateB - dateA;
    });

    //format the sorted Services
    const existingServices = [];
    const format = sorted?.map((sort) => {
      const newServices = [];
      sort?.services?.map((service) => {
        if (!existingServices?.includes(service)) {
          existingServices?.push(service);
          newServices?.push(service);
        }
      });
      return {
        ...sort,
        services: newServices,
      };
    });

    return format?.filter((form) => form?.services?.length > 0);
  };