import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
// import { getAddressText } from "../../../API/fetch";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Modal, Select } from "antd";
import openNotification from "../../../Components/Notifications";
import { updateBranchLocation } from "../../../API/fetch";
import citiesKSA from "../../../helpers/cities.json";

const containerStyle = {
  width: "90%",
  height: "40vh",
};
const { Option } = Select;

function MapModal({
  location,
  setLocation,
  // setCityName,
  show,
  hide,
  disabled = false,
  branchId,
  city,
}) {
  const intl = useIntl();
  const searchBoxRef = useRef(null);
  const { rtl } = useSelector((state) => state.settings);
  // const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState({
    lat: location && location.lat ? location.lat : 24.761654,
    lng: location && location.lng ? location.lng : 46.657367,
  });
  const [cityName, setCityName] = useState(city);
  useEffect(() => {
    if (city) {
      setCityName(city);
    }
  }, [city]);

  useEffect(() => {
    if (location && location.lat && location.lng) {
      setCenter({
        lat: location.lat,
        lng: location.lng,
      });
    }
  }, [location]);

  return (
    <Modal
      title={intl.formatMessage({ id: "location" })}
      centered
      open={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => hide()}
      disabled={disabled}
      okText={intl.formatMessage({ id: disabled ? "back" : "save" })}
      cancelText={intl.formatMessage({ id: "cancel" })}
      onOk={async () => {
        if (disabled) {
          hide();
          return;
        }
        if (!cityName || !cityName?.includes("-")) {
          openNotification({
            title: "اختر مدينة",
            type: "error",
            rtl,
          });
          return;
        }
        // setLoading(true);
        const { lat, lng } = center;
        try {
          setLocation({ ...location, lat, lng });
          // convert lat and lng to cords;
          const cords = [lng, lat];
          const data = {
            location: {
              type: "Point",
              coordinates: cords,
            },
            lat: lat,
            lng: lng,
            cityName: cityName,
          };
          const res = await updateBranchLocation(branchId, data);
          if (res?.data?.status === "success") {
            openNotification({
              title: intl.formatMessage({ id: "savedSuccessfully" }),
              type: "success",
              rtl,
            });
            hide();
          }
          // hide();
        } catch (error) {
          console.log(error);
        }
      }}
    >
      {/* Add select city name */}
      <span>{intl.formatMessage({ id: "choose" })}</span>
      <br />
      <Select
        style={{
          width: 180,
          marginBottom: 10,
        }}
        showSearch
        filterOption={(input, option) => (option?.value ?? "").includes(input)}
        value={cityName}
        onChange={(value) => {
          setCityName(value);
        }}
        required
      >
        {citiesKSA.map((info) => {
          return (
            <Option
              key={info?.city_id}
              value={`${info?.name_en} - ${info?.name_ar}`}
            >
              {info.name_en} - {info.name_ar}
            </Option>
          );
        })}
      </Select>
      <LoadScript
        googleMapsApiKey='AIzaSyDR59W-TaZEyeJY7aNcXZfULvShQO3RzM0'
        language={rtl ? "ar" : "en"}
        libraries={["places"]}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          clickableIcons={false}
          center={center}
          zoom={18}
          onClick={(e) => {
            if (disabled) return;
            setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          }}
        >
          <StandaloneSearchBox
            onLoad={(ref) => (searchBoxRef.current = ref)}
            onPlacesChanged={() => {
              if (searchBoxRef.current) {
                const places = searchBoxRef.current.getPlaces();
                if (places.length === 0) {
                  return;
                }
                let lat = places[0].geometry.location.lat();
                let lng = places[0].geometry.location.lng();
                setCenter({ lat, lng });
              }
            }}
          >
            <input
              type='text'
              placeholder='Search for an address'
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                marginTop: 2,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px",
              }}
            />
          </StandaloneSearchBox>
          <Marker position={{ lat: center.lat, lng: center.lng }}></Marker>
        </GoogleMap>
      </LoadScript>
    </Modal>
  );
}

export default MapModal;
