import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Spin,
  Form,
  Input,
  Select,
  Tag,
  Upload,
  Button,
} from "antd";
import { useIntl } from "react-intl";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import { sendMessage, sendMessageWithImage } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import * as XLSX from "xlsx";

const { Dragger } = Upload;

const { Text, Title } = Typography;
const Marketing = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formattedUsers, setFormattedUsers] = useState([]);
  const [phones, setPhones] = useState([]);
  const [file, setFile] = useState(null);

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record, index) => {
        return <Text>{index + 1}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      width: 160,
      render: (name, record) => {
        return <Text>{name}</Text>;
      },
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
      render: (phone) => {
        return <Text>{phone}</Text>;
      },
    },
  ];

  const dummyRequest = (props) => {
    setTimeout(() => {
      props.onSuccess("ok");
      setFile(props.file);
    }, 0);
  };
  const sendSMS = async (values) => {
    try {
      if (!phones?.length) return;
      if (file && values.senderName === "Whatsapp") {
        return sendWithImage(values, file);
      }

      setLoading(true);
      const data = {
        phones,
        message: values.message,
        senderName: values.senderName,
      };
      const res = await sendMessage(data);
      if (res.data.status === "success") {
        form.resetFields();
        setPhones([]);
      }

      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const sendWithImage = async (values, file) => {
    try {
      // send the image with the message;
      if (!phones?.length) return;
      // loop throw the phones and send the message;
      setLoading(true);
      for (let i = 0; i < phones.length; i++) {
        const phone = phones[i];
        // create form Data for the file;
        const formData = new FormData();
        formData.append("userPhone", phone);
        formData.append("message", values.message);
        formData.append("file", file);
        const res = await sendMessageWithImage(formData);
        openNotification({
          title: `تم إرسال الرسالة رقم ${i + 1} بنجاح`,
          type: "success",
          rtl,
        });
        // await for one seconde;
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      form.resetFields();
      setPhones([]);

      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      let formatted = [];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      // console.log(dataParse);
      // format should be like this ==> 966509111136
      dataParse.forEach((arr, index) => {
        if (index === 0) return;
        if (arr[1] && `${arr[1]}`?.length === 9) {
          // numbers.push(`966${arr[1]}`);
          formatted.push({
            name: arr[0],
            phone: `966${arr[1]}`,
            key: `966${arr[1]}-${index}`,
          });
        }else if(arr[1] && `${arr[1]}`?.length === 12){
          formatted.push({
            name: arr[0],
            phone: `${arr[1]}`,
            key: `${arr[1]}-${index}`,
          });
        }else if (arr[1] && `${arr[1]}`?.length === 10){
          const phone = `${arr[1]}`.slice(1);
          formatted.push({
            name: arr[0],
            phone: `966${phone}`,
            key: `966${arr[1]}-${index}`,
          });
        }else {
          console.log(arr);
        }
      });
      setFormattedUsers(formatted);
    };
    reader.readAsBinaryString(f);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const phones = selectedRows.map((row) => row.phone);
      setPhones(phones);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: !record.phone ? true : false,
        // Column configuration not to be checked
        name: record.phone,
      };
    },
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "users" })}
          </Title>
          <Button
            className='upload-wrap mx-5 border-8'
            onClick={() => {
              const a = document.getElementById("my-upload-excel");
              a.click();
            }}
          >
            <input
              style={{ display: "none" }}
              className='file-uploader'
              type='file'
              id='my-upload-excel'
              accept='.xlsx, .xls'
              onChange={onChange}
            />
            <span className='upload-text'>Upload Target phones</span>
          </Button>
        </div>
        <Form
          name='send-sms'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={sendSMS}
          initialValues={{
            senderName: "Whatsapp",
          }}
        >
          {() => {
            return (
              <>
                <Form.Item
                  name='message'
                  label={intl.formatMessage({ id: "message" })}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea showCount rows={4} />
                </Form.Item>
                <Form.Item
                  name='senderName'
                  label={intl.formatMessage({ id: "senderName" })}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select className='my-font'>
                    <Select.Option value='iWaiter-AD'>iWaiter-AD</Select.Option>
                    <Select.Option value='iWaiter.net'>
                      iWaiter.net
                    </Select.Option>
                    <Select.Option value='Whatsapp'>Whatsapp</Select.Option>
                  </Select>
                </Form.Item>
                {/* Add File upload input image */}
                {form?.getFieldValue("senderName") === "Whatsapp" ? (
                  <Dragger
                    accept='image/jpg,image/jpeg,image/png,application/pdf'
                    // action='/upload.do'
                    listType='picture-card'
                    customRequest={dummyRequest}
                    maxCount={1}
                  >
                    <div>
                      <PlusOutlined />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        تحميل
                      </div>
                    </div>
                  </Dragger>
                ) : null}
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "send" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={
                      loading ||
                      !phones?.length ||
                      !form?.getFieldValue("message")
                    }
                    type='primary'
                  />
                </Form.Item>
              </>
            );
          }}
        </Form>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedUsers?.length === 0,
            },
          }}
          pagination={false}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedUsers}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      </Spin>
    </div>
  );
};

export default Marketing;
