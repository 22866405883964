import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Typography, Spin } from "antd";
import { useIntl } from "react-intl";
import {
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { getRefers } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { setRefersAction } from "../../redux/reducers/restaurantsReducer";
import { Table } from "ant-table-extensions";
import { IoRefreshOutline } from "react-icons/io5";
import EditReferModal from "./EditReferModal";

dayjs.extend(isBetween);
const { Text, Title } = Typography;
const Refer = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { refers } = useSelector((state) => state.restaurants);
  const { rtl } = useSelector((state) => state.settings);
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true);
  const [formattedRefers, setFormattedRefers] = useState([]);
  const [selectedRefer, setSelectedRefer] = useState(null)

  useEffect(() => {
    if (!refers?.length) {
      getRefersData();
    } else {
      setLoading(false);
    }
  }, [refers]);

  const getRefersData = async () => {
    try {
      setLoading(true);
      const res = await getRefers();
      if (res?.data?.refers) {
        dispatch(setRefersAction(res.data.refers));
        setLoading(false);
        openNotification({
          title: "success",
          description: "refers loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 50,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "referCode" }),
      dataIndex: "code",
      key: "code",
    },
    {
      title: intl.formatMessage({ id: "numberOfFreeWeeks" }),
      dataIndex: "numberOfFreeWeeks",
      key: "numberOfFreeWeeks",
    },
    {
      title: intl.formatMessage({ id: "totalUsed" }),
      dataIndex: "totalUsed",
      key: "totalUsed",
    },
    {
      title: intl.formatMessage({ id: "percentage" }),
      dataIndex: "percentage",
      key: "percentage",
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag color={status === "Active" ? "green" : "red"}>{status}</Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "startDate" }),
      dataIndex: "startDate",
      key: "startDate",
      render: (dateString, record) => {
        return <Text>{dayjs(dateString).format("DD/MM/YYYY")}</Text>;
      },
      sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
      showSorterTooltip: false,
    },

    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "edit",
      key: "edit",
      render: (actions, record) => {
        return (
          <CustomButton 
            onClick={() => {
              setSelectedRefer(record.referObject)
              setShowModal(true)
            }
            }
            text={intl.formatMessage({ id: "edit"})}
          />
        );
      }
    },
    {
      title: intl.formatMessage({ id: "endDate" }),
      dataIndex: "endDate",
      key: "endDate",
      fixed: "right",
      width: 140,
      style: {
        backgroundColor: "red",
      },
      render: (dateString, record) => {
        let background = COLORS.success;
        let color = COLORS.white;
        // check if date is less than now;
        let date = record.endDate;
        if (dayjs(date).isBefore(dayjs())) {
          background = COLORS.danger;
        } // check if date is within the next 7 days;
        else if (dayjs(date).isBetween(dayjs(), dayjs().add(7, "days"))) {
          background = COLORS.warning;
        }
        return {
          props: {
            style: { background },
          },
          children: (
            <Text style={{ color }}>{dayjs(date).format("DD/MM/YYYY")}</Text>
          ),
        };
      },
      sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
      showSorterTooltip: false,
    },
   
  ];

  useEffect(() => {
    setLoading(true);
    if (refers?.length) {
      const formatted = refers.map((refer, i) => {
        return {
          key: refer._id,
          number: i + 1,
          _id: refer._id,
          name: refer.name,
          code: refer?.code,
          numberOfFreeWeeks: refer?.numberOfFreeWeeks,
          totalUsed: refer?.totalUsed,
          percentage: refer?.percentage,
          status: refer?.status,
          endDate: refer?.endDate,
          startDate: refer?.startDate,
          referObject: { ...refer },
        };
      });
      setFormattedRefers(formatted);
    }
    setLoading(false);
  }, [rtl, refers]);
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <EditReferModal 
        show={showModal}
        hide={() => {
          setShowModal(false)
          setSelectedRefer(null)
        }}
        refer={selectedRefer}
      />
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
           <CustomButton
            text={intl.formatMessage({ id: "addCoupon" })}
            type='primary'
            icon={<PlusCircleOutlined />}
            onClick={() => {
              setShowModal(true)
            }}
          />
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "refer" })}
          </Title>
         
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getRefersData}
          />
        </div>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedRefers?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedRefers}
          scroll={{ x: 'max-content' }}
        />
      </Spin>
    </div>
  );
};

export default Refer;
