import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  aminAppUser: null,
  payAppRestaurants: [],
  myFatoorahBanks: [],
};

export const payAppSlice = createSlice({
  name: "payApp",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAdminAppUser: (state, action) => {
      state.aminAppUser = action.payload;
    },
    setPayAppRestaurants: (state, action) => {
      state.payAppRestaurants = action.payload;
    },
    addPayAppRestaurant: (state, action) => {
      state.payAppRestaurants.push(action.payload);
    },
    setMyFatoorahBanks: (state, action) => {
      state.myFatoorahBanks = action.payload;
    },
  },
});

export const {
  setAdminAppUser: setAdminAppUserAction,
  setPayAppRestaurants: setPayAppRestaurantsAction,
  addPayAppRestaurant: addPayAppRestaurantAction,
  setMyFatoorahBanks: setMyFatoorahBanksAction,
} = payAppSlice.actions;

export default payAppSlice.reducer;
