import React, { useState, useEffect } from "react";
import { Descriptions, Select, Tag, Typography } from "antd";
import { useIntl } from "react-intl";

const { Text } = Typography;
const OneBranchRow = ({
  posBranches,
  branch,
  linkedBranches,
  setLinkBranchedLocal,
}) => {
  const intl = useIntl();
  const [foodicsId, setFoodicsId] = useState("");
  useEffect(() => {
    if (linkedBranches?.length && !foodicsId) {
      let a = branch?.posBranchId;
      if (!a) {
        a = "NON";
      }
      setFoodicsId(a);
    }
  }, [linkedBranches, branch, posBranches]);

  const selectBranch = (id) => {
    // console.log(linkedBranches);
    // setFoodicsId(id);
    if (!id) return;
    if (id === "NON") {
      let newLinkedBranches = linkedBranches.map((b) => {
        if (b._id === branch._id) {
          return {
            ...b,
            posBranchId: "",
            posBranchName: "",
          };
        }
        return b;
      });
      setLinkBranchedLocal(newLinkedBranches);
      setFoodicsId(id);
      return;
    }
    // // check if the link branch does exist in the array;
    let index = linkedBranches?.findIndex((b) => b._id === branch._id);
    if (index === -1) {
      throw new Error("branch not found");
    } else {
      const otherBranchInfo = posBranches?.find(
        (t) => t?.foodicsBranchId === id
      );
      let newLinkedBranches = [...linkedBranches];
      newLinkedBranches[index].posBranchId = id;
      newLinkedBranches[index].posBranchName =
        otherBranchInfo.foodicsBranchName;
      setLinkBranchedLocal(newLinkedBranches);
      setFoodicsId(id);
    }
  };
  return (
    <>
      <Select
        className={"my-font"}
        style={{
          width: "100%",
        }}
        value={foodicsId}
        onChange={selectBranch}
      >
        <Select.Option
          value={"NON"}
          style={{
            width: "100%",
          }}
        >
          <span className='my-font'>
            {intl.formatMessage({ id: "choose" })}
          </span>
        </Select.Option>
        {posBranches?.map((posBranch, i) => {
          return (
            <Select.Option
              style={{
                width: "100%",
              }}
              className='my-font'
              value={posBranch.foodicsBranchId}
              key={posBranch.foodicsBranchId}
            >
              {posBranch.foodicsBranchName}
            </Select.Option>
          );
        })}
      </Select>
      <Text type='secondary'>
        Tables in Foodics :{" "}
        <Tag color='blue'>
          {posBranches
            ?.find((t) => t?.foodicsBranchId === foodicsId)
            ?.foodicsBranchSections?.reduce((acc, curr) => {
              return acc + curr.tables.length;
            }, 0)}{" "}
          {intl.formatMessage({ id: "tables" })}
        </Tag>
      </Text>
    </>
  );
};

export default OneBranchRow;
