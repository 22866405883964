import { Descriptions, Result, Select, Space, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import LinkedOption from "./LinkedOption";

const { Text, Title } = Typography;
const LinkedModifiers = ({
  marnModifiers,
  localFood,
  linkedArray,
  setLinkedArray,
}) => {
  const intl = useIntl();

  useEffect(() => {
    if (localFood?.modifiers?.length) {
      let initals = localFood.modifiers.map((m) => {
        return {
          _id: m._id,
          name: m.name,
          POSIntegration: m.POSIntegration?.find(
            (intg) => intg?.name === "Marn"
          ),
          options: m.options?.map((opt) => {
            return {
              ...opt,
              POSIntegration: opt.POSIntegration?.find(
                (intg) => intg?.name === "Marn"
              ),
            };
          }),
        };
      });
      setLinkedArray(initals);
    }
  }, [localFood]);
  return (
    <Descriptions
      title='IWAITER DATA'
      bordered
      column={1}
      style={{ marginTop: 25 }}
    >
      {localFood?.modifiers?.length ? (
        localFood?.modifiers?.map((modifier) => {
          return (
            <Descriptions.Item
              key={modifier._id}
              label={
                <Space direction='vertical'>
                  <Title level={5}>{modifier?.privateName}</Title>
                  <SelectMarnModifier
                    localModId={modifier._id}
                    marnModifiers={marnModifiers}
                    linkedArray={linkedArray}
                    setLinkedArray={setLinkedArray}
                  />
                  <Text
                    className='my-font'
                    type={modifier.minSelection > 0 ? "danger" : ""}
                  >
                    {intl.formatMessage({ id: "minSelection" })} -{" "}
                    {modifier.minSelection}
                  </Text>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "maxSelection" })} -{" "}
                    {modifier.maxSelection}
                  </Text>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "freeSelection" })} -{" "}
                    {modifier.numberOfFreeChoices}
                  </Text>
                </Space>
              }
            >
              {modifier.options?.map((option) => {
                return (
                  <LinkedOption
                    key={option._id}
                    localOption={option}
                    marnModifiers={marnModifiers}
                    localModifier={modifier}
                    localModId={modifier._id}
                    linkedArray={linkedArray}
                    setLinkedArray={setLinkedArray}
                  />
                );
              })}
            </Descriptions.Item>
          );
        })
      ) : (
        <Descriptions.Item>
          <Result
            status={"404"}
            title={intl.formatMessage({ id: "noModifiers" })}
            className='my-font'
          />
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

export default LinkedModifiers;

const SelectMarnModifier = ({
  localModId,
  marnModifiers,
  linkedArray,
  setLinkedArray,
}) => {
  const intl = useIntl();
  const [marnId, setMarnId] = useState("");

  useEffect(() => {
    if (linkedArray?.length) {
      const marnMod = linkedArray.find((mod) => mod._id === localModId);
      setMarnId(marnMod?.POSIntegration?.otherModifierId);
    }
  }, [localModId, linkedArray]);

  const onChange = (id) => {
    if (!id) return;
    // check if id === 'NON;
    if (id === "NON") {
      const newLinkedArray = linkedArray.map((mod) => {
        if (mod._id === localModId) {
          mod.POSIntegration = null;
          mod.options = mod.options.map((option) => {
            option.POSIntegration = [];
            return option;
          });
        }
        return mod;
      });
      setLinkedArray(newLinkedArray);
      return;
    }
    // get the full object from marnModifiers;
    const marnMod = marnModifiers.find((mod) => `${mod.marnModifierId}` === id);
    // update the linked array where is the _id === localModId;
    // set the full marn object in POSIntegration;

    const newLinkedArray = linkedArray.map((mod) => {
      if (mod._id === localModId) {
        mod.POSIntegration = {
          name: "Marn",
          otherMaxSelection: marnMod.marnMaxOptions,
          otherModifierId: marnMod.marnModifierId,
          otherMinSelection: marnMod.marnMinOptions,
        };
        mod.options = mod.options.map((option) => {
          option.POSIntegration = [];
          return option;
        });
      }
      return mod;
    });
    setLinkedArray(newLinkedArray);
  };

  return (
    <>
      <Text className='my-font'>
        {intl.formatMessage({ id: "modifierNameInMarn" })}
      </Text>
      <Select
        className={"my-font"}
        style={{
          width: "80%",
        }}
        value={marnId}
        onChange={onChange}
      >
        <Select.Option
          value={"NON"}
          style={{
            width: "100%",
          }}
        >
          <span className='my-font'>choose</span>
        </Select.Option>
        {marnModifiers?.map((marnModi) => {
          return (
            <Select.Option
              key={`${marnModi?.marnModifierId}`}
              value={`${marnModi?.marnModifierId}`}
              style={{
                width: "100%",
              }}
              className='my-font'
            >
              {marnModi?.marnModifierName} - {marnModi?.marnModifierNameAr}
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
};
