import React, { useCallback, useEffect, useRef, useState } from "react";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import messages from "./messages";
import { useDispatch, useSelector } from "react-redux";
import {
  sefFullWidthAction,
  setIsOfflineAction,
} from "./redux/reducers/settingsReducer";
import { BrowserRouter as Router } from "react-router-dom";
import AllRoutes from "./AllRoutes";
import { getAdminData, getRestaurants } from "./API/fetch";
import openNotification from "./Components/Notifications";

import LoadingScreen from "./Components/LoadingScreen";

import { loginAction } from "./redux/reducers/authReducer";
import { setRestaurantsAction } from "./redux/reducers/restaurantsReducer";

const App = () => {
  const { rtl, local, fullWidth } = useSelector((state) => state.settings);
  const { waiter, userHasLevel, isLoggedIn, user } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      console.log("e ===>", e.message);
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  // handle resize the screen;
  const handleResizeScreen = useCallback(() => {
    if (fullWidth !== window.innerWidth) {
      let isBigScreen = window.innerWidth >= 600 ? true : false;
      dispatch(
        sefFullWidthAction({ isBigScreen, fullWidth: window.innerWidth })
      );
    }
  }, [dispatch, fullWidth]);
  // handle internet connections;
  const handleInternetConnection = useCallback(
    (value) => {
      dispatch(setIsOfflineAction(value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isLoggedIn && userHasLevel) {
      getInitialData();
    } else {
      setLoadingData(false);
    }
  }, [isLoggedIn, userHasLevel]);

  const getInitialData = async () => {
    try {
      const res = await getRestaurants();
      if (res?.data?.restaurants) {
        dispatch(setRestaurantsAction(res.data.restaurants));
      }
      setLoadingData(false);
    } catch (error) {
      console.log("error", error);
      setLoadingData(false);

      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  // listeners for internet and resize screen;
  useEffect(() => {
    if (navigator && navigator.onLine === false) {
      handleInternetConnection(true);
    }
    window.addEventListener("online", () => handleInternetConnection(false));
    window.addEventListener("offline", () => handleInternetConnection(true));
    window.addEventListener("resize", handleResizeScreen);
    return () => {
      window.removeEventListener("online", () =>
        handleInternetConnection(false)
      );
      window.removeEventListener("offline", () =>
        handleInternetConnection(true)
      );
      window.addEventListener("resize", handleResizeScreen);
    };
  }, [handleInternetConnection, handleResizeScreen]);
  return (
    <ConfigProvider
      theme={{ token: { colorPrimary: "#0a2240", colorError: "#FC4C3A" } }}
      direction={rtl ? "rtl" : "ltr"}
    >
      <IntlProvider locale={local} messages={messages[local]}>
        <Router>{loadingData ? <LoadingScreen /> : <AllRoutes />}</Router>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default App;
