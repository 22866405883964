import {
  Col,
  DatePicker,
  Descriptions,
  Form,
  InputNumber,
  Row,
  Select,
  Spin,
  Tag,
  Typography
} from "antd";
import dayjs from 'dayjs';
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { addService } from "../../../API/fetch";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import COLORS from "../../../Style/colors";
import {
  SERVICES_PRICE,
  SERVICES_TYPES,
  servicesPaymentTypes,
} from "../../../Types";
import EditServiceModal from "./EditServiceModal";

const { Title } = Typography;

const EditRestaurantService = ({ restaurant, setRestaurant }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showEditServiceModal, setShowEditServiceModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const onFinish = async (values) => {
    let end = dayjs(values.serviceEnd).endOf("day").toDate();
    let start = dayjs(values.serviceStart).startOf("day").toDate();

    const services = SERVICES_TYPES[values.service];
    let service = {
      serviceType: values.service,
      services,
      serviceStart: start,
      serviceEnd: end,
      addByAdmin: true,
      restaurantId: restaurant._id,
      owner: restaurant.userInfo?._id,
      price: values.price,
      discountAmount: values.discountAmount,
      amountPaid: values.amountPaid,
      paymentType: values?.paymentType,
    };
    
    try {
      setLoading(true);
      // call the api to add the service
      const res = await addService(service);
      if (res?.data?.restaurant) {
        setRestaurant(res.data.restaurant);
        openNotification({
          title: "success",
          description: "added successfully",
          type: "success",
          rtl: true,
        });
        form.resetFields();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      openNotification({
        type: "error",
        message: intl.formatMessage({ id: "error" }),
        rtl: true,
      });
    }
  };
  return (
    <Spin spinning={loading}>
      <EditServiceModal
        show={showEditServiceModal}
        hide={() => setShowEditServiceModal(false)}
        service={selectedService}
        setRestaurant={setRestaurant}
      />
      <Descriptions className='my-font' bordered column={2}>
        <Descriptions.Item label={intl.formatMessage({ id: "service" })}>
          {intl.formatMessage({ id: "dateStart" })}
        </Descriptions.Item>
        <Descriptions.Item
          label={intl.formatMessage({ id: "dateEnd" })}
          labelStyle={{ backgroundColor: COLORS.white }}
        >
          {intl.formatMessage({ id: "amountPaid" })}
        </Descriptions.Item>
        {restaurant?.services?.length
          ? restaurant?.services?.map((service) => {
              let isBeforeNow = dayjs(service.serviceEnd).isBefore(dayjs());
              let isPaymentFromOrder = service?.paymentType === "FROM_ORDERS";
              return (
                <React.Fragment key={service?._id}>
                  <Descriptions.Item
                  
                    label={
                      <>
                        <Tag>{service?.services?.join(" - ")}</Tag>
                        <Tag
                          onClick={() => {
                            setShowEditServiceModal(true);
                            setSelectedService(service);
                          }}
                          color='red'
                          className='pointer'
                        >
                          {intl.formatMessage({ id: "edit" })}
                        </Tag>
                        {isPaymentFromOrder ? (
                          <Tag color="red">{intl.formatMessage({id: service?.paymentType})} - {service?.price} SAR</Tag>
                        ): null}
                      </>
                    }
                  >
                    {dayjs(service?.serviceStart)?.format("YYYY-MM-DD")}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{
                      color: isBeforeNow ? "red" : "green",
                      backgroundColor: "white",
                    }}
                    label={dayjs(service?.serviceEnd)?.format("YYYY-MM-DD")}
                  >
                    {service?.amountPaid}
                  </Descriptions.Item>
                </React.Fragment>
              );
            })
          : null}
      </Descriptions>
      <Title className='mt-5' level={5}>
        {intl.formatMessage({ id: "addNewService" })}
      </Title>
      <Form
        name='edit-rest-form'
        form={form}
        className='form-container'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          service: "",
          serviceStart: dayjs(),
          serviceEnd: dayjs(),
          amountPaid: 0,
          discountAmount: 0,
          paymentType: "UP_FRONT",
        }}
      >
        {() => {
          return (
            <>
              <Row className='mt-4' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* iwaiter percentage */}

                {/* total amount */}
                <Col md={8} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "service" })}
                    className='mb-1'
                    name='service'
                    style={{ borderRadius: 20 }}
                    rules={[
                      {
                        required: true,
                        message: "REQUIRED",
                      },
                    ]}
                  >
                    <Select
                      onChange={(value) => {
                        // get the period of the service from data start and date end in months;
                        // get the price of the service;
                        let period = dayjs(
                          form.getFieldValue("serviceEnd")
                        ).diff(
                          dayjs(form.getFieldValue("serviceStart")),
                          "months"
                        );
                        if (period <= 0) {
                          period = 1;
                        }
                        let monthPrice = (
                          SERVICES_PRICE[value] * 1.15
                        )?.toFixed(2);
                        let total = parseFloat(monthPrice) * period;
                        form.setFieldsValue({
                          price: total?.toFixed(2),
                        });
                      }}
                      className='my-font'
                    >
                      {Object.keys(SERVICES_TYPES).map((service) => {
                        return (
                          <Select.Option key={service} value={service}>
                            {service}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={8} sm={24} xs={24}>
                  <Form.Item
                    name={"serviceStart"}
                    type='date'
                    label={intl.formatMessage({ id: "dateStart" })}
                    className='mb-1'
                    rules={[
                      {
                        required: true,
                        message: "REQUIRED",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      // disabled date before today;
                      disabledDate={(current) =>
                        current && current < dayjs().subtract(1, "days")
                      }
                      onChange={(date, dateString) => {
                        form.setFieldsValue({
                          serviceEnd: dayjs(dateString).add(7, "days"),
                        });
                        let period = dayjs(
                          form.getFieldValue("serviceEnd")
                        ).diff(
                          dayjs(form.getFieldValue("serviceStart")),
                          "months"
                        );
                        if (period <= 0) {
                          period = 1;
                        }
                        let monthPrice = (
                          SERVICES_PRICE[form.getFieldValue("service")] * 1.15
                        )?.toFixed(2);
                        let total = parseFloat(monthPrice) * period;
                        form.setFieldsValue({
                          price: total?.toFixed(2),
                        });
                      }}
                      className='my-font'
                      format='YYYY-MM-DD'
                    />
                  </Form.Item>
                </Col>
                <Col md={8} sm={24} xs={24}>
                  <Form.Item
                    name={"serviceEnd"}
                    type='date'
                    label={intl.formatMessage({ id: "dateEnd" })}
                    className='mb-1'
                    rules={[
                      {
                        required: true,
                        message: "REQUIRED",
                      },
                    ]}
                    dependencies={["serviceStart"]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={(current) => {
                        // make sure the end date is after the start date
                        // and make sure the end date is after today;
                        return (
                          current &&
                          (current < dayjs().subtract(1, "days") ||
                            current < form.getFieldValue("serviceStart"))
                        );
                      }}
                      className='my-font'
                      format='YYYY-MM-DD'
                      onChange={() => {
                        let period = dayjs(
                          form.getFieldValue("serviceEnd")
                        ).diff(
                          dayjs(form.getFieldValue("serviceStart")),
                          "months"
                        );
                        console.log("period in End", period);
                        if (period <= 0) {
                          period = 1;
                        }
                        let monthPrice = (
                          SERVICES_PRICE[form.getFieldValue("service")] * 1.15
                        )?.toFixed(2);
                        let total = parseFloat(monthPrice) * period;
                        form.setFieldsValue({
                          price: total?.toFixed(2),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='mt-4' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={8} sm={24} xs={24}>
                  {/* add select payment types; */}
                  <Form.Item
                    name={"paymentType"}
                    label={intl.formatMessage({ id: "paymentType" })}
                    className='mb-1'
                    rules={[
                      {
                        required: true,
                        message: "REQUIRED",
                      },
                    ]}
                  >
                    <Select className='my-font'>
                      {servicesPaymentTypes.map((type) => {
                        return (
                          <Select.Option key={type} value={type} className="my-font">
                            {intl.formatMessage({id: type})}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={8} sm={24} xs={24}>
                  <Form.Item
                    name={"amountPaid"}
                    label={intl.formatMessage({ id: "amountPaid" })}
                    className='mb-1'
                    rules={[
                      {
                        required: true,
                        message: "REQUIRED",
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} min={0} />
                  </Form.Item>
                </Col>
                <Col md={8} sm={24} xs={24}>
                  <Form.Item
                    name={"discountAmount"}
                    label={intl.formatMessage({ id: "discountAmount" })}
                    className='mb-1'
                    rules={[
                      {
                        required: true,
                        message: "REQUIRED",
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} min={0} />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='mt-4' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                <Col md={8} sm={24} xs={24}>
                  <Form.Item
                    name={"price"}
                    label={intl.formatMessage({ id: "originalPrice" })}
                    className='mb-1'
                    dependencies={["service"]}
                  >
                    <InputNumber style={{ width: "100%" }} disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='mt-4'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    type='primary'
                    text={intl.formatMessage({ id: "add" })}
                    style={{ minWidth: 100 }}
                  />
                </Form.Item>
              </Row>
            </>
          );
        }}
      </Form>
    </Spin>
  );
};

export default EditRestaurantService;
