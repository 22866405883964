import {
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import CustomButton from "../../Components/CustomButton";
import { IoRefreshOutline } from "react-icons/io5";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import openNotification from "../../Components/Notifications";
import { useSelector } from "react-redux";
import { getAppGameData } from "../../API/fetch";
import COLORS from "../../Style/colors";
import { Table } from "ant-table-extensions";
import { cutText } from "../../helpers";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
export const DELIVERY_ORDERS_STATUS = {
  PENDING: "pending",
  OPEN: "open",
  IN_KITCHEN: "inKitchen",
  READY: "ready",
  IN_DELIVERY: "inDelivery",
  CLOSE: "close",
  REJECTED: "rejected",
  TIMEOUT: "timeout",
  ALL: "all",
};
export const TAG_COLORS_BY_STATUS_ALL_TYPES = {
  open: "green",
  inKitchen: "orange",
  ready: "cyan",
  inDelivery: "purple",
  inService: "orange",
  served: "cyan",
  pending: "orange",
  rejected: "red",
  timeout: "red",
};
const Home = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const DATA = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [filterData, setFilterData] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    let s = dayjs().format("YYYY-MM-DD");
    let e = dayjs().add(1, "day").format("YYYY-MM-DD");
    getData(s, e);
  }, []);

  const getData = async (start, end) => {
    try {
      setLoading(true);
      const res = await getAppGameData(start, end);
      if (res?.data) {
        setData(res.data);
        formatColumns(res?.data);
        openNotification({
          title: intl.formatMessage({ id: "success" }),
          message: intl.formatMessage({ id: "successfetch" }),
          type: "success",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        message: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const formatColumns = (info) => {
    const formatted = info?.challengesData?.map((item) => {
      return {
        key: item._id,
        _id: item._id,
        name: item.name,
        phone: item.phone,
        challengesTimed: item?.challengesCount,
        allChallengesCount: item?.allChallenges,
        prizesTimed: item?.prizesCount,
        allPrizesCount: item?.allPrizes,
      };
    });
    setFilterData(formatted);
  };

  const onFinish = async (values) => {
    const [start, end] = values.date;
    const startDate = dayjs(start).startOf("day").format("YYYY-MM-DD");
    const endDate = dayjs(end).endOf("day").format("YYYY-MM-DD");
    getData(startDate, endDate);
  };
  const columns = [
    {
      title: "#",
      dataIndex: "_id",
      key: "_id",
      render: (text, record, index) => <Text>{index + 1}</Text>,
    },
    {
      title: intl.formatMessage({ id: "customer" }),
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <Link to={`/users-application/${record._id}`} target='_blank'>
            <Text
              style={{
                color: COLORS.blue,
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              {cutText(text, 20)}
            </Text>
          </Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "عدد التحديات في التاريخ المحدد",
      dataIndex: "challengesTimed",
      key: "challengesTimed",
    },
    {
      title: "عدد التحديات الكلي",
      dataIndex: "allChallengesCount",
      key: "allChallengesCount",
    },
    {
      title: "عدد الجوائز في التاريخ المحدد",
      dataIndex: "prizesTimed",
      key: "prizesTimed",
    },
    {
      title: "عدد الجوائز الكلي",
      dataIndex: "allPrizesCount",
      key: "allPrizesCount",
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "info" })}
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={() => {
              let s = dayjs().format("YYYY-MM-DD");
              let e = dayjs().add(1, "day").format("YYYY-MM-DD");
              getData(s, e);
            }}
          />
        </div>
        <Form
          name='games-form'
          form={form}
          className='form-container'
          layout='inline'
          onFinish={onFinish}
          initialValues={{
            date: [dayjs(), dayjs()],
          }}
        >
          <Form.Item
            rules={[{ required: true, message: "Please select Date" }]}
            name='date'
            label={intl.formatMessage({ id: "date" })}
          >
            <RangePicker />
          </Form.Item>
          <Form.Item>
            <CustomButton
              text={intl.formatMessage({ id: "search" })}
              htmlType='submit'
              type='primary'
              loading={loading}
              disabled={loading}
            />
          </Form.Item>
        </Form>
        <Divider />

        <Row
          style={{
            marginBottom: 20,
            justifyContent: "space-between",
          }}
          gutter={[16, 16]}
        >
          <Col
            span={11}
            style={{
              backgroundColor: "white",
              padding: "10px 20px",
              borderRadius: 10,
              minHeight: 100,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
            className='light-shadow'
          >
            <Title level={5}>عدد طلبات التطبيق</Title>
            <Tag
              color='blue'
              style={{
                padding: "5px 10px",
                fontSize: 16,
                fontWeight: "bold",
                borderRadius: 10,
              }}
            >
              {data?.ordersCount}
            </Tag>
          </Col>
          <Col
            span={11}
            style={{
              backgroundColor: "white",
              padding: "10px 20px",
              borderRadius: 10,
              minHeight: 100,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
            className='light-shadow'
          >
            <Title level={5}>عدد التحديات الملعوبة </Title>
            <Tag
              color='orange'
              style={{
                padding: "5px 10px",
                fontSize: 16,
                fontWeight: "bold",
                borderRadius: 10,
              }}
            >
              {data?.allChallengesCount}
            </Tag>
          </Col>
          <Col
            span={11}
            style={{
              backgroundColor: "white",
              padding: "10px 20px",
              borderRadius: 10,
              minHeight: 100,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
            className='light-shadow'
          >
            <Title level={5}>عدد الجوائز </Title>
            <Tag
              color='green'
              style={{
                padding: "5px 10px",
                fontSize: 16,
                fontWeight: "bold",
                borderRadius: 10,
              }}
            >
              {data?.allPrizesCount}
            </Tag>
          </Col>
          <Col
            span={11}
            style={{
              backgroundColor: "white",
              padding: "10px 20px",
              borderRadius: 10,
              minHeight: 100,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
            className='light-shadow'
          >
            <Title level={5}>قيمة الجوائز من اي ويتر</Title>
            <Tag
              color='green'
              style={{
                padding: "5px 10px",
                fontSize: 16,
                fontWeight: "bold",
                borderRadius: 10,
              }}
            >
              {data?.allPrizesAmount} ريال
            </Tag>
          </Col>
        </Row>
        <div
          style={{
            backgroundbackgroundColor: "white",
            padding: "20px 5px",
            borderRadius: 10,
          }}
          className='light-shadow'
        >
          <Table
            searchable
            fuzzySearch
            searchableProps={{
              debounce: true,
              fuzzySearch: true,
              inputProps: {
                placeholder: "Search this table",
                prefix: <SearchOutlined />,
                disabled: loading || filterData?.length === 0,
              },
            }}
            pagination={{
              position: ["bottomLeft"],
              pageSizeOptions: [50, 100, 200, 500],
              pageSize: 50,
            }}
            columns={columns}
            loading={loading}
            dataSource={filterData}
            scroll={{ x: "max-content" }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default Home;
