import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Spin,
  Input,
  Typography,
  Select,
  Popconfirm,
  Tag,
} from "antd";
import openNotification from "../../../Components/Notifications";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import {
  cancelPrivateWallet,
  createDefaultWallet,
  postPrivateWallet,
} from "../../../API/fetch";
import { WALLET_TYPE } from "../../../Types";
import COLORS from "../../../Style/colors";

const { Text } = Typography;

const PaymentRestaurant = ({ restaurant, setRestaurant, loadingData }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (values.type === WALLET_TYPE.IWAITER_WALLET) {
        await postDefaultWallet({ type: "TAP" });
      } else if (values.type === WALLET_TYPE.IWAITER_WALLET_NOON) {
        await postDefaultWallet({ type: "NOON" });
      } else if (values.type === WALLET_TYPE.IWAITER_WALLET_MY_FATOORAH) {
        postDefaultWallet({
          type: "MY_FATOORAH",
          otherId: restaurant?.destination_code,
        });
      } else {
        let privateW = await addPrivateWallet(values);
        if (!privateW) {
          setLoading(false);
          return;
        }
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const addPrivateWallet = async (values) => {
    try {
      if (
        !values.pup_key ||
        !values.pup_key.trim() ||
        !values.pri_key ||
        !values.pri_key.trim()
      ) {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: "نرجو اضافة معلومات المحفظة الخاصة",
          type: "error",
          rtl,
        });
        return null;
      }
      setLoading(true);
      const data = {
        wallet: {
          name: "TAB",
          provider: "TAB",
          providerId: values.providerId.trim(),
          pup_key: values.pup_key.trim(),
          pri_key: values.pri_key.trim(),
          restaurantId: restaurant._id,
          type: values.type,
          status: "Active",
        },
      };
      const res = await postPrivateWallet(data);
      if (res?.data?.restaurant) {
        setRestaurant(res.data.restaurant);
      }
      return true;
    } catch (error) {
      setLoading(false);
    }
  };
  const cancelPrivateWalletAction = async () => {
    try {
      setLoading(true);
      const data = {
        wallet: {
          restaurantId: restaurant._id,
        },
      };
      const res = await cancelPrivateWallet(data);
      if (res?.data?.restaurant) {
        setRestaurant(res.data.restaurant);
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  const postDefaultWallet = async ({ type, otherId }) => {
    try {
      const data = {
        wallet: {
          restaurantId: restaurant._id,
        },
        type,
      };
      if (otherId) {
        data.otherId = otherId;
      }
      const res = await createDefaultWallet(data);
      if (res?.data?.restaurant) {
        setRestaurant(res.data.restaurant);
      }
      return true;
    } catch (error) {
      setLoading(false);
      openNotification({
        type: "error",
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        rtl,
      });
    }
  };

  useEffect(() => {
    if (!loadingData) {
      setTimeout(() => {
        form.resetFields();
      }, 1000);
    }
  }, [loadingData]);

  // console.log(restaurant?.wallet?.provider);
  return (
    <Spin spinning={loading}>
      <Form
        name='payment-form'
        form={form}
        className='form-container'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          type: restaurant?.wallet?.type
            ? restaurant?.wallet?.provider?.includes("NOON")
              ? WALLET_TYPE.IWAITER_WALLET_NOON
              : restaurant?.wallet?.provider?.includes("MY_FATOORAH")
              ? WALLET_TYPE.IWAITER_WALLET_MY_FATOORAH
              : WALLET_TYPE.IWAITER_WALLET
            : "",
          pup_key: restaurant?.wallet?.pup_key,
          pri_key: restaurant?.wallet?.pri_key,
          providerId: restaurant?.wallet?.providerId,
          status: restaurant?.wallet?.status,
        }}
      >
        {() => {
          return (
            <>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Date and payment */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "wallet" })}
                    className='mb-1'
                    style={{ borderRadius: 20 }}
                    name='type'
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select className='my-font'>
                      <Select.Option
                        className='my-font'
                        value={WALLET_TYPE.IWAITER_WALLET}
                      >
                        {intl.formatMessage({ id: "iwaiterWallet" })}{" "}
                        {!restaurant?.destination_id ||
                        restaurant?.destination_id === "WALLET" ? (
                          ""
                        ) : (
                          <Tag color='green'>
                            {intl.formatMessage({ id: "private" })}
                          </Tag>
                        )}
                      </Select.Option>
                      <Select.Option
                        className='my-font'
                        value={WALLET_TYPE.IWAITER_WALLET_NOON}
                      >
                        {intl.formatMessage({ id: "iwaiterWalletNOON" })}
                      </Select.Option>

                      <Select.Option
                        disabled={!restaurant?.destination_code}
                        className='my-font'
                        value={WALLET_TYPE.IWAITER_WALLET_MY_FATOORAH}
                      >
                        {intl.formatMessage({ id: "privateWalletMyFatoorah" })}{" "}
                        <Tag
                          color='blue'
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                        >
                          {" "}
                          {restaurant?.destination_code}
                        </Tag>
                      </Select.Option>

                      <Select.Option
                        className='my-font'
                        value={WALLET_TYPE.PRIVATE_WALLET_TAP}
                      >
                        {intl.formatMessage({ id: "privateWalletTap" })}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={"pup_key"}
                    name='pup_key'
                    style={{ borderRadius: 20 }}
                  >
                    <Input
                      type='password'
                      disabled={
                        form.getFieldValue("type") ===
                        WALLET_TYPE.IWAITER_WALLET
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='mt-4' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* iwaiter percentage */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={"pri_key"}
                    name='pri_key'
                    style={{ borderRadius: 20 }}
                  >
                    <Input
                      type='password'
                      disabled={
                        form.getFieldValue("type") ===
                          WALLET_TYPE.IWAITER_WALLET ||
                        form.getFieldValue("type") ===
                          WALLET_TYPE.IWAITER_WALLET_NOON
                      }
                    />
                  </Form.Item>
                </Col>
                {/* total amount */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={"marchant_id"}
                    name='providerId'
                    style={{ borderRadius: 20 }}
                  >
                    <Input
                      type='password'
                      disabled={
                        form.getFieldValue("type") ===
                          WALLET_TYPE.IWAITER_WALLET ||
                        form.getFieldValue("type") ===
                          WALLET_TYPE.IWAITER_WALLET_NOON
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className='mt-4'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    type='primary'
                    text={intl.formatMessage({ id: "save" })}
                    style={{ minWidth: 100 }}
                  />
                </Form.Item>
              </Row>
            </>
          );
        }}
      </Form>
      <Popconfirm
        title={
          <Text className='my-font'>
            {intl.formatMessage({ id: "confirmDelete" })}
          </Text>
        }
        okText={intl.formatMessage({ id: "delete" })}
        okButtonProps={{
          style: { color: COLORS.danger, fontFamily: "Cairo" },
        }}
        okType='text'
        cancelText={intl.formatMessage({ id: "cancel" })}
        cancelButtonProps={{
          style: { border: "none", fontFamily: "Cairo" },
        }}
        onConfirm={cancelPrivateWalletAction}
        disabled={!restaurant?.wallet || loading}
        loading={loading}
      >
        <Text
          type='danger'
          className='pointer my-font'
          disabled={!restaurant?.wallet || loading}
        >
          {intl.formatMessage({ id: "delete" })}
        </Text>
      </Popconfirm>
    </Spin>
  );
};

export default PaymentRestaurant;
