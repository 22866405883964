import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  editBranchApi,
  editRestaurantGeneralInfo,
  getRestaurant,
} from "../../../API/fetch";
import CustomButton from "../../../Components/CustomButton";
import phoneCodes from "../../../helpers/phoneCodes.json";
import citiesKSA from "../../../helpers/cities.json";
import { useSelector } from "react-redux";
import { checkIfValidPhone } from "../../../helpers";
import openNotification from "../../../Components/Notifications";

const { Option } = Select;
const { Text } = Typography;

const prefixSelector = (
  <Form.Item name='prefix' noStyle>
    <Select
      style={{
        width: 150,
      }}
    >
      {phoneCodes.map((info) => {
        return (
          <Option key={`${info.dial_code}-${info.name}`} value={info.dial_code}>
            {info.dial_code} {info.name}
          </Option>
        );
      })}
    </Select>
  </Form.Item>
);

function EditGeneralInformation({
  showGeneralInformation,
  setShowGeneralInformation,
  restaurant,
  loading,
  setLoading,
  setRestaurant,
}) {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const fullPhone = `${values.prefix.substring(1)}${values.phone}`;
      const oldPhone = restaurant?.userInfo?.phone;
      const data = {
        restaurantId: restaurant?._id,
        email: values?.email,
        phone: fullPhone,
        branches: values?.branches,
        userId: restaurant?.userInfo?._id,
        userName: values?.name,
        isPhoneUpdated: fullPhone === oldPhone ? false : true,
      };
      const res = await editRestaurantGeneralInfo(data);
      openNotification({
        title: "success",
        description: "updated successfully",
        type: "success",
        rtl: true,
      });
      setShowGeneralInformation(false);
    } catch (error) {
      if (error.response.data.error === "PhoneExists") {
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "PhoneExists" }),
          type: "error",
          rtl: true,
        });
        setLoading(false);
        return;
      }
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl: true,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 500);
  }, [restaurant]);
  return (
    <div>
      <CustomButton
        className={`btn-text border-8 px-5 pb-5 mt-4`}
        type='primary'
        text={intl.formatMessage({ id: "edit" })}
        onClick={() => setShowGeneralInformation(true)}
      />
      {showGeneralInformation ? (
        <Modal
          title={intl.formatMessage({ id: "EditGeneralInformation" })}
          open={showGeneralInformation}
          destroyOnClose={true}
          // mask={true}
          maskClosable={true}
          onCancel={() => {
            setShowGeneralInformation(false);
          }}
          onOk={() => console.log("confirm")}
          cancelText={intl.formatMessage({ id: "cancel" })}
          okText={intl.formatMessage({ id: "save" })}
          footer={null}
        >
          <Form
            name='Add-reservation'
            form={form}
            preserve={false}
            className='form-container'
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
              name: restaurant?.userInfo?.name,
              phone: restaurant?.userInfo?.phone.substring(3),
              prefix: phoneCodes.find(
                (c) =>
                  c?.dial_code.substring(1) ===
                  restaurant?.userInfo?.phone?.substring(0, 3)
              )?.dial_code,
              email: restaurant?.email,
              branches: restaurant?.branches,
            }}
          >
            {() => (
              <>
                <Row type='flex' justify='space-between'>
                  <Col md={24} sm={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "justName" })}
                      className='mb-1'
                      name='name'
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorname" }),
                        },
                      ]}
                    >
                      <Input className={"input"} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row type='flex' justify='space-between'>
                  <Col md={24} sm={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "email" })}
                      className='mb-1'
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorname" }),
                        },
                      ]}
                    >
                      <Input className={"input"} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row type='flex' justify='space-between'>
                  <Col md={24} sm={24}>
                    <Form.Item
                      name='phone'
                      label={intl.formatMessage({ id: "phone" })}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorphone" }),
                        },
                        {
                          validator: (_, value) => {
                            const prefix = form.getFieldValue("prefix");
                            if (!prefix) {
                              return Promise.reject(
                                intl.formatMessage({ id: "errorphone" })
                              );
                            }
                            let valid = checkIfValidPhone(`${prefix}${value}`);
                            if (valid) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                intl.formatMessage({ id: "errorphone" })
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Input
                        addonBefore={rtl ? null : prefixSelector}
                        addonAfter={rtl ? prefixSelector : null}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider orientation={rtl ? "left" : "right"}>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "branches" })}
                  </Text>
                </Divider>
                <Row type='flex' justify='space-between'>
                  <Col sm={24}>
                    <Form.List name='branches'>
                      {(fields) =>
                        fields.map((field) => (
                          <Row
                            key={field.key}
                            type='flex'
                            justify='space-between'
                          >
                            <Col sm={12}>
                              <Form.Item name={[field.name, "name"]}>
                                {form.getFieldValue([
                                  "branches",
                                  field.name,
                                  "name",
                                ])}
                              </Form.Item>
                            </Col>
                            <Col sm={11}>
                              <Form.Item name={[field.name, "cityName"]}>
                                <Select
                                  style={{
                                    width: 180,
                                  }}
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.value ?? "").includes(input)
                                  }
                                >
                                  {citiesKSA.map((info) => {
                                    return (
                                      <Option
                                        key={info?.city_id}
                                        value={`${info?.name_en} - ${info?.name_ar}`}
                                      >
                                        {info.name_en} - {info.name_ar}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        ))
                      }
                    </Form.List>
                  </Col>
                </Row>

                <Divider />
                <Space direction='horizontal'>
                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "save" })}
                      className={`btnRegister btn-text border-8`}
                      loading={loading}
                      disabled={loading}
                      type='primary'
                    />
                  </Form.Item>

                  <Form.Item>
                    <CustomButton
                      type='text'
                      text={intl.formatMessage({ id: "cancel" })}
                      disabled={loading}
                      onClick={() => {
                        setShowGeneralInformation(false);
                      }}
                    />
                  </Form.Item>
                </Space>
              </>
            )}
          </Form>
          <Divider />
          <Divider orientation={rtl ? "left" : "right"}>
            <Text className='my-font'>
              {intl.formatMessage({ id: "edit" })}
            </Text>
          </Divider>
          {restaurant?.branches?.map((branch, index) => {
            return (
              <BranchRow
                key={branch?._id}
                restaurant={restaurant}
                loading={loading}
                index={index}
                branch={branch}
                setRestaurant={setRestaurant}
                hide={() => setShowGeneralInformation(false)}
              />
            );
          })}
        </Modal>
      ) : null}
    </div>
  );
}

export default EditGeneralInformation;

const BranchRow = ({
  branch,
  loading,
  restaurant,
  index,
  setRestaurant,
  hide,
}) => {
  const [loadingHere, setLoadingHere] = useState(false);
  const [isActiveWithTips, setIsActiveWithTips] = useState(false);
  const [isActiveWithoutTips, setIsActiveWithoutTips] = useState(false);
  const intl = useIntl();
  useEffect(() => {
    if (
      branch?.messageSubscription?.activeServices?.includes("FEEDBACK_TIPS")
    ) {
      setIsActiveWithTips(true);
    }
    if (branch?.messageSubscription?.activeServices?.includes("FEEDBACK")) {
      setIsActiveWithoutTips(true);
    }
  }, [branch]);
  const deleteBranch = async () => {
    try {
      setLoadingHere(true);
      const data = {
        type: "DELETE_BRANCH",
        data: {},
        restaurantId: restaurant?._id,
      };
      const res = await editBranchApi(branch?._id, data);
      if (res?.data?.restaurant) {
        setRestaurant(res.data.restaurant);
        openNotification({
          title: intl.formatMessage({ id: "success" }),
          description: intl.formatMessage({ id: "deletedSuccessfully" }),
          type: "success",
          rtl: true,
        });
        hide();
      }

      setLoadingHere(false);
    } catch (error) {
      setLoadingHere(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl: true,
      });
    }
  };

  const editBranch = async () => {
    try {
      setLoadingHere(true);
      const data = {
        type: "MESSAGE_SUBSCRIPTION",
        data: {
          activeServices: isActiveWithTips
            ? ["FEEDBACK_TIPS"]
            : isActiveWithoutTips
            ? ["FEEDBACK"]
            : [],
        },
        restaurantId: restaurant?._id,
      };
      const res = await editBranchApi(branch?._id, data);
      if (res?.data?.restaurant) {
        setRestaurant(res.data.restaurant);
        openNotification({
          title: intl.formatMessage({ id: "success" }),
          description: intl.formatMessage({ id: "updatedSuccessfully" }),
          type: "success",
          rtl: true,
        });
        hide();
      }
      setLoadingHere(false);
    } catch (error) {
      setLoadingHere(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl: true,
      });
    }
  };
  return (
    <Row
      style={{
        borderBottom:
          index === restaurant?.branches?.length - 1
            ? "none"
            : "2px solid #f0f0f0",
        padding: "10px 0",
      }}
      key={branch?._id}
      type='flex'
      justify='space-between'
    >
      <Text>{branch?.name}</Text>

      <div
        style={{
          backgroundColor: "#f0f0f0",
          flex: 1,
          marginRight: 10,
          marginLeft: 10,
          borderRadius: 5,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          paddingRight: 10,
          paddingLeft: 10,
          flexDirection: "column",
        }}
      >
        <Checkbox
          checked={isActiveWithTips}
          onChange={(e) => {
            if (e.target.checked) {
              setIsActiveWithTips(e.target.checked);
              setIsActiveWithoutTips(!e.target.checked);
            } else {
              setIsActiveWithTips(e.target.checked);
            }
          }}
        >
          {intl.formatMessage({ id: "sendMessageFeedbackWithTips" })}
        </Checkbox>
        <Checkbox
          checked={isActiveWithoutTips}
          onChange={(e) => {
            if (e.target.checked) {
              setIsActiveWithoutTips(e.target.checked);
              setIsActiveWithTips(!e.target.checked);
            } else {
              setIsActiveWithoutTips(e.target.checked);
            }
          }}
        >
          {intl.formatMessage({ id: "sendMessageFeedbackNoTips" })}
        </Checkbox>
        {/* <Checkbox
            checked={
              branch?.messageSubscription?.activeServices?.includes(
                "NEW_CUSTOMER_THANKS"
              )
                ? true
                : false
            }
          >
            {intl.formatMessage({ id: "sendMessageNewCustomerThanks" })}
          </Checkbox> */}
        <CustomButton
          text={intl.formatMessage({ id: "save" })}
          disabled={loading || loadingHere}
          loading={loadingHere}
          onClick={editBranch}
        />
      </div>

      <Popconfirm
        onCancel={() => console.log("cancel")}
        onConfirm={deleteBranch}
        title={intl.formatMessage({ id: "confirmDelete" })}
        okText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "cancel" })}
        disabled={loading || loadingHere || restaurant?.branches?.length <= 1}
        loading={loadingHere}
      >
        <CustomButton
          danger
          text={intl.formatMessage({ id: "delete" })}
          onClick={() => {
            // console.log("edit");
          }}
          disabled={loading || loadingHere || restaurant?.branches?.length <= 1}
          loading={loadingHere}
        />
      </Popconfirm>
    </Row>
  );
};
