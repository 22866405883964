import { Modal, Typography, Form, Space, Input, Divider, Select } from "antd";
import React, { useState } from "react";
import dayjs from "dayjs";
import { TransactionsTypes } from "../../Payments/Components/EditPaymentModal";
import CustomButton from "../../../Components/CustomButton";
import { useIntl } from "react-intl";
import openNotification from "../../../Components/Notifications";
import {
  createZohoInvoiceAPI,
  patchPaymentTransaction,
} from "../../../API/fetch";
import { useEffect } from "react";
const { Title, Text } = Typography;
const { Option } = Select;
const EditPaymentTransactionModal = ({
  show,
  hide,
  paymentTransaction,
  updateTransaction,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 300);
  }, [paymentTransaction, show, hide]);

  const editTransaction = async (values) => {
    try {
      setLoading(true);
      const data = {
        paymentTransaction: {
          restaurantId: paymentTransaction?.restaurantId,
          transactionNumber: values.transactionNumber,
          source: values.source,
          invoiceUrl: values.invoiceUrl,
        },
      };
      const res = await patchPaymentTransaction(paymentTransaction?._id, data);
      if (res?.data?.status === "success") {
        openNotification({
          type: "success",
          description: intl.formatMessage({ id: "success" }),
          rtl: true,
        });
        updateTransaction(res?.data?.transaction);
        setLoading(false);
        hide();
      } else {
        openNotification({
          type: "error",
          description: intl.formatMessage({ id: "error" }),
          rtl: true,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        type: "error",
        description: intl.formatMessage({ id: "error" }),
        rtl: true,
      });
    }
  };

  const createZohoInvoice = async () => {
    try {
      const res = await createZohoInvoiceAPI(paymentTransaction?._id);
      if (res?.data?.status === "success") {
        openNotification({
          type: "success",
          description: intl.formatMessage({ id: "success" }),
          rtl: true,
        });
        updateTransaction(res?.data?.transaction);
        setLoading(false);
        hide();
      } else {
        openNotification({
          type: "error",
          description: intl.formatMessage({ id: "error" }),
          rtl: true,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        type: "error",
        description: intl.formatMessage({ id: "error" }),
        rtl: true,
      });
    }
  };
  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {paymentTransaction?.transactionNumber}
        </Title>
      }
      centered
      open={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => {
        hide();
      }}
      footer={null}
    >
      <Form
        name='editTransaction'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={editTransaction}
        initialValues={{
          transactionNumber: paymentTransaction?.transactionNumber,
          source: paymentTransaction?.source,
          invoiceUrl: paymentTransaction?.invoiceUrl,
        }}
      >
        {() => {
          return (
            <>
              <Form.Item
                label={intl.formatMessage({ id: "transactionNumber" })}
                className='mb-1'
                name='transactionNumber'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Input className={"input"} />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "transferType" })}
                className='mb-1'
                name='source'
              >
                <Select className='my-font'>
                  {TransactionsTypes.map((item) => {
                    return (
                      <Option
                        className='my-font'
                        key={item.value}
                        value={item.value}
                      >
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "invoiceUrl" })}
                className='mb-1'
                name='invoiceUrl'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "invoiceUrl" }),
                  },
                ]}
              >
                <Input className={"input"} />
              </Form.Item>
              <Title className='mt-2' level={5}>
                {intl.formatMessage({ id: "amount" })} :{" "}
                {paymentTransaction?.amountToTransfer} SAR
              </Title>
              <Title className='mt-2' level={5}>
                {intl.formatMessage({ id: "ordersTotal" })} :{" "}
                {paymentTransaction?.orderCount}
              </Title>
              <Text className='my-font'>
                {intl.formatMessage({ id: "forOrdersBetween" })}
                <Text type='secondary' className='mx-3'>
                  {dayjs(paymentTransaction?.start)?.format("DD/MM/YYYY")}
                </Text>
                -
                <Text type='secondary' className='mx-3'>
                  {dayjs(paymentTransaction?.end)?.format("DD/MM/YYYY")}
                </Text>
              </Text>

              <Divider />
              <Space direction='horizontal'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading}
                    type='primary'
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    type='text'
                    text={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                    onClick={() => {
                      hide();
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <CustomButton
                    type='danger'
                    text={intl.formatMessage({ id: "createZohoInvoice" })}
                    disabled={loading || paymentTransaction?.invoiceUrl}
                    loading={loading}
                    onClick={createZohoInvoice}
                  />
                </Form.Item>
              </Space>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default EditPaymentTransactionModal;
