import {
  Modal,
  Typography,
  Form,
  Row,
  Col,
  Select,
  InputNumber,
  Input,
  Switch,
  Space,
  Descriptions,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { createMyFatoorah, getMyFatoorahAccount } from "../../../API/fetch";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import COLORS from "../../../Style/colors";

const { Title, Text } = Typography;

function EditPaymentInfoModal({ show, hide, paymentInfo, bankList }) {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const { restaurants } = useSelector((state) => state.restaurants);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState({});
  const [deposits, setDeposits] = useState([]);
  const [restaurantsWithIds, setRestaurantsWithIds] = useState([]);

  const editMyFatoorah = async (values) => {
    try {
      setLoading(true);
      const data = {
        myFatoorahInfo: {
          ...values,
          DepositTerms: "Daily",
        },
        restaurantId: values.restaurant,
      };
      if (paymentInfo?.SupplierCode) {
        data.myFatoorahInfo.SupplierCode = paymentInfo.SupplierCode;
      }
      const res = await createMyFatoorah(data);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl: true,
      });
      setLoading(false);
      hide();
    } catch (error) {
      console.log("Error in edit my fatoorah", error);
      openNotification({
        title: "Error",
        description: "Error in edit my fatoorah",
        type: "error",
        rtl,
      });
      setLoading(false);
    }
  };

  const getAccountDetails = async (value) => {
    setLoading(true);
    try {
      const res = await getMyFatoorahAccount(paymentInfo?.SupplierCode);
      setAccount(res.data?.account);
      setDeposits(res.data?.deposits);
      setLoading(false);
    } catch (error) {
      console.log("Error in get account details", error);
      openNotification({
        title: "Error",
        description: "Error in get account details",
        type: "error",
        rtl,
      });
      setLoading(false);
    }
  };
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "transferTime" }),
      dataIndex: "DepositDate",
      key: "DepositDate",
    },
    {
      title: intl.formatMessage({ id: "transferNumber" }),
      dataIndex: "DepositReference",
      key: "DepositReference",
    },
    {
      title: intl.formatMessage({ id: "bankName" }),
      dataIndex: "BankName",
      key: "BankName",
    },
    {
      title: intl.formatMessage({ id: "amountPaid" }),
      dataIndex: "TotalValue",
      key: "TotalValue",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
      if (restaurants?.length) {
        setRestaurantsWithIds(
          restaurants.map((item) => ({
            name: item.name,
            value: item._id,
          }))
        );
      }
    }, 300);
  }, [paymentInfo, show, hide]);
  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {paymentInfo?._id ? "Edit Payment Info" : "Add Payment Info"}
        </Title>
      }
      centered
      open={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      width={"90vw"}
      onCancel={() => {
        setAccount({});
        setDeposits([]);
        hide();
      }}
      footer={null}
    >
      <Form
        name='edit-add-my-fatoorah'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={editMyFatoorah}
        initialValues={{
          restaurant: paymentInfo?.restaurantId || "",
          SupplierName: paymentInfo?.SupplierName || "",
          Mobile: paymentInfo?.Mobile || "",
          Email: paymentInfo?.Email || "",
          CommissionValue: paymentInfo?.CommissionValue || 0,
          CommissionPercentage: paymentInfo?.CommissionPercentage || 0,

          BankId: paymentInfo?.BankId ? parseInt(paymentInfo?.BankId) : 0,
          BankAccountHolderName: paymentInfo?.BankAccountHolderName || "",
          BankAccount: paymentInfo?.BankAccount || "",
          Iban: paymentInfo?.Iban || "",
          IsActive: paymentInfo?.IsActive ? paymentInfo.IsActive : false,
        }}
      >
        {() => {
          return (
            <>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Date and payment */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "restaurant" })}
                    className='mb-1'
                    name='restaurant'
                    style={{ borderRadius: 20 }}
                    rules={
                      paymentInfo?.SupplierCode
                        ? []
                        : [
                            {
                              required: true,
                            },
                          ]
                    }
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) => {
                        let ids = restaurantsWithIds
                          .filter((item) =>
                            item.name
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          )
                          .map((item) => item.value);
                        return ids.includes(option.value);
                      }}
                      disabled={paymentInfo?.SupplierCode}
                      onChange={(e) => {
                        const selected = restaurants.find((r) => r._id === e);
                        const { name, email, userInfo } = selected;
                        let phone = "";
                        if (userInfo?.phone) {
                          phone = userInfo?.phone;
                        } else if (selected?.phone) {
                          phone = selected?.phone;
                        }
                        // const { phone } = userInfo;
                        form.setFieldsValue({
                          SupplierName: name,
                          Mobile: phone,
                          Email: email,
                        });
                      }}
                      className='my-font'
                    >
                      {restaurants?.map((type) => (
                        <Select.Option
                          className='my-font'
                          key={type._id}
                          value={type._id}
                        >
                          {type?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name='BankId'
                    label={"Bank Id"}
                    style={{ borderRadius: 20 }}
                    rules={
                      paymentInfo?.SupplierCode
                        ? []
                        : [
                            {
                              required: true,
                            },
                          ]
                    }
                  >
                    <Select
                      disabled={paymentInfo?.SupplierCode}
                      className='my-font'
                    >
                      {bankList?.map((bank) => (
                        <Select.Option
                          className='my-font'
                          key={bank.Value}
                          value={bank.Value}
                        >
                          {bank?.Text}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Date and payment */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={"اسم المطعم"}
                    className='mb-1'
                    name='SupplierName'
                    style={{ borderRadius: 20 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={"الايميل"}
                    className='mb-1'
                    name='Email'
                    style={{ borderRadius: 20 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Date and payment */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={"الهاتف 966xxx"}
                    className='mb-1'
                    name='Mobile'
                    style={{ borderRadius: 20 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  {/* <Form.Item
                    label={"الايميل"}
                    className='mb-1'
                    name='Email'
                    style={{ borderRadius: 20 }}
                  >
                    <Input />
                  </Form.Item> */}
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Date and payment */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={"المبلغ المستقطع ريال"}
                    className='mb-1'
                    name='CommissionValue'
                    style={{ borderRadius: 20 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} min={0} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={"النسبة المستقطعة ٪"}
                    className='mb-1'
                    name='CommissionPercentage'
                    style={{ borderRadius: 20 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      step={0.01}
                      style={{ width: "100%" }}
                      min={0}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Date and payment */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={"اسم الحساب البنكي"}
                    className='mb-1'
                    name='BankAccountHolderName'
                    style={{ borderRadius: 20 }}
                    rules={
                      paymentInfo?.SupplierCode
                        ? []
                        : [
                            {
                              required: true,
                            },
                          ]
                    }
                  >
                    <Input disabled={paymentInfo?.SupplierCode} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={"رقم الحساب البنكي"}
                    className='mb-1'
                    name='BankAccount'
                    style={{ borderRadius: 20 }}
                    rules={
                      paymentInfo?.SupplierCode
                        ? []
                        : [
                            {
                              required: true,
                            },
                          ]
                    }
                  >
                    <Input disabled={paymentInfo?.SupplierCode} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                {/* Date and payment */}
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={"الايبان"}
                    className='mb-1'
                    name='Iban'
                    style={{ borderRadius: 20 }}
                    rules={
                      paymentInfo?.SupplierCode
                        ? []
                        : [
                            {
                              required: true,
                            },
                          ]
                    }
                  >
                    <Input disabled={paymentInfo?.SupplierCode} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={"الحالة"}
                    className='mb-1'
                    name='IsActive'
                    style={{ borderRadius: 20 }}
                    valuePropName='checked'
                  >
                    <Switch disabled={paymentInfo?.SupplierCode} />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='mt-4'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading || paymentInfo?.SupplierCode}
                    type='primary'
                    text={intl.formatMessage({ id: "save" })}
                    style={{ minWidth: 100 }}
                  />
                </Form.Item>
              </Row>
            </>
          );
        }}
      </Form>
      <Space>
        <CustomButton
          onClick={getAccountDetails}
          className={`btnRegister btn-text border-8`}
          loading={loading}
          type='primary'
          text={intl.formatMessage({ id: "checkAccount" })}
          style={{ minWidth: 100 }}
        />
      </Space>
      {Object.keys(account)?.length ? (
        <Descriptions
          title={intl.formatMessage({ id: "info" })}
          bordered
          className='my-font'
          style={{
            textAlign: "center",
          }}
          column={1}
        >
          <Descriptions.Item
            labelStyle={{ backgroundColor: COLORS.gray }}
            contentStyle={{ backgroundColor: COLORS.white }}
            label='Status'
          >
            {account?.IsActive ? "Active" : "Not Active"}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ backgroundColor: COLORS.gray }}
            contentStyle={{ backgroundColor: COLORS.white }}
            label='Approved'
          >
            {account?.IsApproved ? "Approved" : "Not Approved"}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ backgroundColor: COLORS.gray }}
            contentStyle={{ backgroundColor: COLORS.white }}
            label='Total Awaiting Balance'
          >
            {account?.TotalAwaitingBalance}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ backgroundColor: COLORS.gray }}
            contentStyle={{ backgroundColor: COLORS.white }}
            label='Total Awaiting To Transfer'
          >
            {account?.TotalAwaitingToTransfer}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ backgroundColor: COLORS.gray }}
            contentStyle={{ backgroundColor: COLORS.white }}
            label='Total Deposited Amount'
          >
            {account?.TotalDepositedAmount}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ backgroundColor: COLORS.gray }}
            contentStyle={{ backgroundColor: COLORS.white }}
            label='Total Number Of Transactions'
          >
            {account?.TotalNumberOfTransactions}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ backgroundColor: COLORS.gray }}
            contentStyle={{ backgroundColor: COLORS.white }}
            label='Total Supplier Invoice Share'
          >
            {account?.TotalSupplierInvoiceShare}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ backgroundColor: COLORS.gray }}
            contentStyle={{ backgroundColor: COLORS.white }}
            label='Total Value Of Transactions'
          >
            {account?.TotalValueOfTransactions}
          </Descriptions.Item>
        </Descriptions>
      ) : null}
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 40,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 15,
          textAlign: "center",
        }}
        className='light-shadow'
      >
        <Title level={5}>{intl.formatMessage({ id: "settlements" })}</Title>
        {deposits?.length ? (
          <Table
            scroll={{ x: "max-content" }}
            pagination={{ hideOnSinglePage: true }}
            columns={columns}
            dataSource={deposits}
          />
        ) : (
          <Text>{intl.formatMessage({ id: "noData" })}</Text>
        )}
      </div>
    </Modal>
  );
}

export default EditPaymentInfoModal;
