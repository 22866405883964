import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Spin,
  Input,
  InputNumber,
  Select,
  Typography,
  Tag,
} from "antd";
import openNotification from "../../../Components/Notifications";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import { addBranch, editAppRestaurantInfo } from "../../../API/fetch";
import { RESTAURANT_TYPE } from "../../../Types";

const { Text } = Typography;
const AppRestaurantInfo = ({ restaurant, setRestaurant, appInfo }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      let data = {
        ...values,
      };
      setLoading(true);
      if (values?.restaurantType?.length) {
        data.restaurantType = values.restaurantType.map((item) => item);
      }

      const res = await editAppRestaurantInfo(appInfo._id, data);
      const newRest = {
        ...restaurant,
        appRestaurantInfo: {
          ...restaurant.appRestaurantInfo,
          ...res.data.appRestaurantInfo,
        },
      };
      setRestaurant(newRest);
      form.resetFields();

      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 1000);
  }, []);
  return (
    <Spin spinning={loading}>
      <Form
        name='app-info-form'
        form={form}
        className='form-container'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          allowedServices: appInfo?.allowedServices,
          pickupStatus: appInfo?.pickupStatus,
          reservationStatus: appInfo?.reservationStatus,
          status: appInfo?.status,
          waitingStatus: appInfo?.waitingStatus,
          restaurantType: appInfo?.restaurantType,
        }}
      >
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
          {/*Names */}
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "allowedServices" })}
              className='mb-1'
              name='allowedServices'
            >
              <Select className='my-font' mode='multiple'>
                <Select.Option value='Pickup'>Pickup</Select.Option>
                <Select.Option value='Delivery'>Delivery</Select.Option>
                <Select.Option value='Waiting'>Waiting</Select.Option>
                <Select.Option value='Reservation'>Reservation</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={intl.formatMessage({ id: "restaurantType" })}
              className='mb-1'
              name='restaurantType'
            >
              <Select className='my-font' mode='multiple'>
                {Object.values(RESTAURANT_TYPE).map((type) => {
                  return (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row
          className='mt-4'
          justify='space-a'
          gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
        >
          {/* Table  */}
          <Col span={12} className="mt-3">
            <Text>
              Pickup{" : "}
              {restaurant?.branches?.map((branch) => {
                return (
                  <Tag
                    color={
                      branch?.appPickupStatus === "Active" ? "green" : "red"
                    }
                    key={branch._id}
                  >
                    {branch?.appPickupStatus} - {branch?.name}
                  </Tag>
                );
              })}
            </Text>
          </Col>
          <Col span={12} className="mt-3">
            <Text>
              Delivery{" : "}
              {restaurant?.branches?.map((branch) => {
                return (
                  <Tag
                    color={
                      branch?.appDeliveryStatus === "Active" ? "green" : "red"
                    }
                    key={branch._id}
                  >
                    {branch?.appDeliveryStatus} - {branch?.name}
                  </Tag>
                );
              })}
            </Text>
          </Col>
          <Col span={12} className="mt-3">
            <Text>
              Waiting{" : "}
              {restaurant?.branches?.map((branch) => {
                return (
                  <Tag
                    color={
                      branch?.appWaitingStatus === "Active" ? "green" : "red"
                    }
                    key={branch._id}
                  >
                    {branch?.appWaitingStatus} - {branch?.name}
                  </Tag>
                );
              })}
            </Text>
          </Col>
          <Col span={12} className="mt-3">
            <Text>
              Reservation{" : "}
              {restaurant?.branches?.map((branch) => {
                return (
                  <Tag
                    color={
                      branch?.appReservationStatus === "Active"
                        ? "green"
                        : "red"
                    }
                    key={branch._id}
                  >
                    {branch?.appReservationStatus} - {branch?.name}
                  </Tag>
                );
              })}
            </Text>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Form.Item>
            <CustomButton
              htmlType='submit'
              className={`btnRegister btn-text border-8`}
              loading={loading}
              type='primary'
              text={intl.formatMessage({ id: "save" })}
              style={{ minWidth: 100 }}
            />
          </Form.Item>
        </Row>
      </Form>
    </Spin>
  );
};

export default AppRestaurantInfo;
