const messages = {
  ar: {
    home: "الرئيسية",
    tables: "الطاولات",
    oppLanguage: "English",
    arabic: "العربية",
    english: "الإنجليزية",
    signout: "تسجيل الخروج",
    settings: "الإعدادات",
    signin: "تسجيل الدخول",
    signup: "إنشاء حساب",
    name: "ادخل اسمك",
    phone: "رقم الجوال",
    phone05: "5xxxxxxxx",
    emailPlaceholder: "test@gmail.com",
    email: "البريد الالكتروني",
    error: "خطأ",
    errorname: "يجب كتابة الاسم",
    errorphone: "5xxxxxxxx",
    erroremail: "تأكد من البريد الالكتروني",
    password: "الرقم السري",
    errorpassword: "الرقم السري يحب أن يكون أكثر من ٦ خانات",
    errorfetch: "خطأ في الاتصال, فضلاً أعد المحاولة لاحقاً",
    restaurantName: "اسم المطعم",
    nameAr: "الاسم بالعربي",
    nameEn: "الاسم بالإنجليزي",
    choose: "اختر",
    restaurant: "مطعم",
    delete: "حذف",
    status: "الحالة",
    edit: "تعديل",
    inHouse: "محلي",
    takeAway: "سفري",
    confirmDelete: "هل أنت متأكد من حذف هذا السجل ؟",
    cancel: "إلغاء",
    save: "حفظ",
    maxSelection: "الحد الأعلى من الخيارات",
    minSelection: "الحد الأدنى من الخيارات",
    code: "كود لوحة التحكم",
    errorCode: "الكود غير صحيح",
    enter: "ادخل",
    branches: "الفروع",
    waiters: "النوادل",
    payments: "الحسابات",
    subscription: "الاشتراك",
    addBranch: "أضف فرع",
    cityName: "اسم المدينة",
    tablesCount: "عدد الطاولات",
    startFrom: "البداية من",
    justName: "الاسم",
    confirmDeleteReferTitle: "هل أنت متأكد من حذف هذه الكود ؟",
    forgetPassword: "نسيت كلمة المرور",
    addCoupon: "أضف كوبون",
    justCode: "الكود",
    totalUsed: "عدد مرات الاستخدام",
    freeSelection: "عدد الاختيارات المجانية",
    disabled: "معطل",
    savedSuccessfully: "تم حفظ التغييرات بنجاح",
    Active: "نشطة",
    noModifiers: "لا يوجد أي إضافة",
    manage: "إدارة",
    foodicsInfo: "معلومات فودكس",
    marnInfo: "معلومات الربط مع نظام مرن",
    subscriptionEndDate: "نهاية الاشتراك",
    loadingData: "جاري تحميل البيانات",
    JUST_MENU: "المنيو",
    paymentRecords: "سجل المدفوعات",
    transferTime: "تاريخ الحوالة",
    forOrdersBetween: " للطلبات بين التواريخ",
    restaurants: "المطاعم",
    users: "المستخدمين",
    end: "ينتهي الاشتراك في ",
    noUser: "لا يوجد يوزر لهذا المطعم",
    ordersTotal: "عدد الطلبات",
    totalVisits: "عدد الزيارات",
    ordersTotalAmount: "الدخل",
    totals: "الإجمالي",
    paidInRestaurant: "الدفع في المطعم",
    paidOnline: "الدفع اونلاين",
    amount: "المبلغ",
    totalRevenue: "إجمالي الدخل",
    cash: "كاش",
    online: "اونلاين",
    info: "معلومات",
    orderType: "نوع الطلب",
    dateStart: "تاريخ البداية",
    dateEnd: "تاريخ النهاية",
    search: "بحث",
    ourShare: "حصتنا",
    thereShare: "حصتهم",
    subType: "الباقة",
    user: "العميل",
    gold: "الذهبية",
    silver: "الفضية",
    basic: "العادية",
    update: "تحديث",
    free: "المجانية",
    requests: "الطلبات",
    integration: "الربط",
    select: "اختيار",
    deliveryRequests: "طلبات التوصيل",
    delivery: " التوصيل",
    posRequsets: "طلبات ربط النظام",
    request: "طلب جديد",
    foodicsMenu: "منيو فودكس",
    modifier: "النوع",
    readyForDeploy: "جاهز للربط",
    ready: "مربوط",
    active: "فعال",
    grabTax: "جلب الضريبة",
    bringDeliveryFees: "جلب بيانات التوصيل",
    createPayment: "إنشاء وسيلة دفع",
    refer: "الكوبونات",
    addRefer: "أضف كوبون",
    couponName: "اسم الكوبون , هذا الاسم سيكون مرجع لك",
    couponCode: "كوبون كود , هذا الكود الذي سيكتبه العميل",
    startDate: "تاريخ بداية الكود",
    endDate: "تاريخ نهاية الكود",
    percentage: "النسبة الخصم",
    numberOfFreeWeeks: "عدد الأسابيع المجانية",
    editRefer: "تعديل الكوبون",
    stop: "إيقاف",
    activate: "تفعيل",
    referCode: "كود التسجيل",
    menu: "المنيو",
    deleted: "محذوف",
    tapPayments: "اشتراك تاب",
    sms: "الرسائل النصية",
    paymentsTransactions: "سجل المدفوعات",
    paidStatus: "حالة الدفع",
    linkTable: "رابط للطاولة",
    percentageForIwaiter: "نسبة آي ويتر فيزا",
    percentageForIWaiterMADA: "نسبة آي ويتر مدى",
    percentageForIWaiterTabby: "نسبة آي ويتر تابي",
    totalAmount: "المبلغ المدفوع",
    iwaiterWallet: "محفظة اي ويتر العامة",
    privateWalletTap: "محفظة خاصة بالعميل من تاب",
    wallet: "المحفظة",
    pierodTime: "المدة المحول عنها",
    source: "الطريقة",
    transactionNumber: "رقم الحوالة",
    createdAt: "تاريخ الحوالة",
    company: "الشركة",
    foodicsFood: "اسم الاكلة في فودكس",
    modifierNameInFoodics: "اسم الاضافة في فودكس",
    marnFood: "اسم الاكلة في مرن",
    modifierNameInMarn: "اسم الاضافة في مرن",
    language: "اللغة",
    country: "الدولة",
    numberOfCashiers: "عدد الكاشيرات",
    numberOfBranches: "عدد الفروع",
    numberOfWaiters: "عدد الويترز",
    createdTime: "تاريخ الإنشاء",
    totalOrders: "عدد الطلبات الكلية",
    sentSuccessful: "عدد الطلبات المرسلة بنجاح",
    successfulRate: "نسبة النجاح",
    refresh: "تحديث",
    syncOrders: "مزامنة الطلبات",
    syncMenu: "مزامنة المنيو",
    deleteToken: "حذف التوكن",
    deleteAllTablesAndAddFromFoodics:
      "حذف كل الطاولات الحالية واضافتها من فودكس",
    addFoodicsMenu: "اضف منيو فودكس",
    message: "الرسالة",
    senderName: "اسم المرسل",
    send: "إرسال",
    amountToTransfer: "المبلغ المطلوب للتحويل",
    transferType: "طريقة التحويل",
    addTransaction: "إضافة حوالة",
    deleteIntegration: "حذف الربط",
    "PAY AS YOU GO": "PAY AS YOU GO",
    lessThanWeek: "أقل من أسبوع",
    expired: "منتهي",
    GOLD: "الذهبية",
    MENU: "المنيو",
    STANDER: "العادية",
    SILVER: "الفضية",
    "N/A": "N/A",
    settlement: "التسوية",
    dateRange: "التواريخ",
    uploadFile: "رفع ملف تاب",
    total: "الإجمالي",
    settlAmount: "المبلغ الواصل",
    remainAmount: "المبلغ المتبقي",
    date: "التاريخ",
    orderDate: "تاريخ الطلب",
    export: "تصدير",
    iwaiterWalletNOON: "محفظة اي ويتر مع نون",
    sendSubscriptionEnd: "أرسل تذكير بانتهاء الاشتراك",
    pendingPayments: "مدفوعات معلقة",
    settlementInfo: "معلومات التسوية",
    paid: "تم الدفع",
    notPaid: "لم يتم الدفع",
    startSubscriptionDate: "بداية الاشتراك",
    createIwaiterFees: "جلب بيانات الرسوم الأخرى",
    totalToNow: "مجموع المديونية",
    application: "التطبيق",
    pickupStatus: "حالة الاستلام",
    pickup: "الاستلام",
    waitingStatus: "حالة الانتظار",
    waiting: "الانتظار",
    reservationStatus: "حالة الحجز",
    allowedServices: "الخدمات المسموحة",
    images: "الصور",
    restaurantType: "نوع المطعم",
    saveSort: "حفظ الترتيب",
    sort: "الترتيب",
    usersApplication: "عملاء التطبيق",
    deviceType: "نوع الجهاز",
    title: "العنوان",
    body: "المحتوى",
    add: "إضافة",
    privateWalletMyFatoorah: "محفظة ماي فاتورة",
    checkAccount: "التحقق من الحساب",
    service: "الخدمة",
    services: "الخدمات",
    addNewService: "إضافة خدمة جديدة",
    discountAmount: "الخصم",
    amountPaid: "المبلغ المدفوع",
    originalPrice: "السعر الأساسي",
    paymentInformation: "بيانات الدفع",
    chooseBank: "اختر البنك",
    bankAccountName: "اسم الحساب البنكي باللغة الانجليزية",
    bankAccountNameTool:
      "اسم الحساب البنكي باللغة الانجليزية ، كما هو مسجل في شهادة الايبان",
    bankAccountNumber: "رقم الحساب البنكي",
    clickToUpload: "اضغط لرفع الملف",
    files: "الملفات",
    activatePayment: "تفعيل الدفع",
    CommercialRegister: "السجل التجاري",
    CommercialLicense: "رخصة البلدية",
    ArticlesOfAssociation: "عقد التأسيس - في حال كون المنشأة شركة-",
    ids: "صورة الهوية",
    BankAccountLetter: "شهادة الايبان باللغة الإنجليزية",
    switchToNewPayment:
      "الآن تستطيع الانتقال إلى بوابة الدفع الجديدة من اي ويتر، حيث ستكون نسبة المدفوعات الالكترونية هي 1.9% لكل عملية تتم عن طريق مدى ، و 2.4% لكل عملية تتم عن طريق فيزا وماستر كارد ، يمكنك تفعيل هذه الاتفاقية بتعبئة النموذج في أسفل هذه الصفحة.",
    newDeal: "إتفاقية جديدة",
    youCanUploadFilesAfterSavePayment:
      "تستطيع رفع الملفات بعد تفعيل الدفع وتقديم البيانات البنكية",
    iban: "الايبان",
    userName: "اسم المستخدم",
    settlements: "التسويات",
    nextDateForTransaction: "تاريخ الحوالة القادم",
    transferNumber: "رقم الحوالة",
    transferTime: "تاريخ الحوالة",
    noData: "لا يوجد بيانات",
    bankName: "اسم البنك",
    MADATotal: "إجمالي مدى",
    VISATotal: "إجمالي فيزا",
    MADATransferTotal: "إجمالي مدى للتحويل",
    VISATransferTotal: "إجمالي فيزا للتحويل",
    officialName: "الاسم الرسمي",
    inApp: "مضاف للتطبيق",
    location: "الموقع",
    change: "تغيير",
    Suspended: "معلق",
    marketing: "التسويق",
    marketingEmail: "إرسال بريد",
    template: "القالب",
    subject: "الموضوع",
    EditGeneralInformation: "تعديل المعلومات الاساسية",
    invoice: "فاتورة",
    invoiceUrl: "رابط الفاتورة",
    PhoneExists: "رقم الهاتف موجود في مطعم آخر",
    deleteMenu: "حذف المنيو",
    deleteMenuDescription: "هل أنت متأكد من حذف منيو هذا السجل ؟",
    menuDeleteSuccessfully: "تم حذف المنيو بنجاح",
    VATFees: "رسوم الضريبة",
    tapAndIwaiterFees: "رسوم اي ويتر وتاب",
    originalTotal: "المجموع الأساسي",
    superUsers: "المستخدمين المتميزين",
    addSuperUser: "اضف مستخدم متميز",
    numberOfRestaurants: "عدد المطاعم",
    superUserName: "اسم المستخدم المتميز",
    superUserRestaurants: " مطاعم المستخدم المتميز",
    selectRestaurants: "اخترالمطاعم",
    deleteSuperUser: "حذف المستخدم",
    phonealreadyused: "رقم الهاتف مستخدم من قبل",
    menuDeleteSuccessfully: "تم حذف المنيو بنجاح",
    whatsappSetting: "اعدادات الواتساب",
    menuDeleteSuccessfully: "تم حذف المنيو بنجاح",
    totalAmountBeforeFees: "المجموع قبل رسوم الدفع",
    paymentFees: "رسوم الدفع",
    totalAfterFees: "المجموع بعد رسوم الدفع",
    tapFees: "رسوم تاب",
    iwaierFees: "رسوم اي وايتر",
    iwaierFeesWithVat: "رسوم اي وايتر مع الضريبة",
    iwaiterVAT: "ضريبة اي وايتر",
    isOnlineActive: "الدفع الالكتروني",
    cities: "المدن",
    isActive: "مفعل",
    notActive: "غير مفعل",
    dine_in: "طلبات داخلية",
    All: "الكل",
    enableIn: "فعال في",
    disabledIn: "غير مفعل في",
    refundFees: "رسوم الاسترجاع المخصومة من العميل تمثل 2.5% من المجموع",
    deliveryAmountToTransferWithoutVat: "مبلغ التوصيل للتحويل بدون الضريبة",
    deliveryAmountVat: "ضريبة مبلغ التوصيل",
    countOfDeliveryOrders: "عدد طلبات التوصيل",
    totalRestaurantPaidAmount: "نصيب المطعم للتوصيل",
    waiting: " الانتظار",
    reservations: "الحجوزات",
    pickupD: "الاستلام والتوصيل",
    pending: "قيد الانتظار",
    inProgress: "قيد التحضير",
    delivered: "تم التوصيل",
    other: "أخرى",
    success: "تم بنجاح",
    open: "طلب جديد",
    closed: "مغلق",
    inKitchen: "في المطبخ",
    inService: "في الخدمة",
    rejected: "مرفوض",
    timeout: "انتهى الوقت",
    inDelivery: "In delivery",
    ready: "جاهزة",
    close: "مغلق",
    deliveryInformation: "معلومات التوصيل",
    customer: "العميل",
    successfetch: "تم الاتصال بنجاح",
    orderNumber: "رقم الطلب",
    deposits: "الإيداعات",
    withdrawals: "السحوبات",
    balance: "الرصيد",
    addBalance: "إضافة رصيد",
    paymentMethod: "طريقة الدفع",
    back: "رجوع",

    activityReports: "تقرير نشاط العملاء",
    reservations: "الحجوزات",
    newClient: "عميل جديد",
    TABBYTotal: "إجمالي تابي",
    TABBYTransferTotal: "إجمالي تابي للتحويل",
    refundAmount: "مبلغ الاسترجاع",
    iwaiterFeesWithVat: "إجمالي رسوم اي ويتر",
    deliveryCount: "عدد طلبات التوصيل",
    tapWallets: "محافظ تاب",
    destination_id: "destination_id",
    legalNameAR: "الاسم الرسمي بالعربي",
    legalNameEN: "الاسم الرسمي بالإنجليزي",
    corp: "تجاري",
    person: "شخصي",
    accountType: "نوع الحساب",
    CommercialLicense: "رخصة تجارية",
    licenseType: "نوع الرخصة",
    CommercialRegistration: "السجل التجاري",
    licenseNumber: "رقم الرخصة أو السجل التجاري",
    vatNumber: "رقم الضريبة",
    deliveryIwaiterFees: "رسوم اي ويتر للتوصيل",
    restaurantPaidAmount: "المبلغ الذي تحمله المطعم من قيمة التوصيل",
    FROM_ORDERS: "من قيمة الطلبات",
    createZohoInvoice: "إنشاء فاتورة ZOHO",

    UP_FRONT: "مقدماً",
    OTHER_PAYMENT: "أخرى",
    paymentType: "طريقة الدفع",
    canEditPaymentType: "يستطيع التحكم بالدفع الكاش والالكتروني",
    subscriptionUnpaidFees: "رسوم الاشتراك المتبقية",
    personName: "اسم الشخص",
    zipCode: "الرمز البريدي",
    city: "المدينة",
    state: "المنطقة",
    poBox: "صندوق البريد",
    district: "الحي",
    recipientName: "اسم الشخص",
    address1: "العنوان 1",
    billingAddress: "عنوان الفاتورة",
    contactPerson: "الشخص المسؤول",
    lastName: "الاسم الأخير",
    firstName: "الاسم الأول",
    middleName: "الاسم الأوسط",
    birthDate: "تاريخ الميلاد",
    issuing_country: "بلد الإصدار",
    issuing_date: "تاريخ الإصدار",
    expiry_date: "تاريخ الانتهاء",
    numberOfCommercial: "رقم السجل التجاري",
    pleaseUploadFromRestaurantView:
      "من فضلك قم برفع السجل التجاري والهوية من صفحة المطعم",
    idsInfo: "معلومات الهوية",
    pleaseCheckInternet: "ارجو التاكد من اتصال الانترنت",
    logoInformation: "معلومات الشعار",
    logoMustBeLessThan1MB: "يجب أن يكون حجم الشعار أقل من 512 كيلوبايت",
    oldLogo: "الشعار القديم",
    newLogo: "الشعار الجديد",
    uploadToTap: "رفع الى تاب",
    numberOfId: "رقم الهوية",
    uploadAllImageFirst: "من فضلك قم برفع جميع الصور أولاً",
    usedWallet: "المحفظة المستخدمة",
    paymentInfo: "معلومات الدفع",
    useiwaiterPublicWallet: "استخدم محفظة اي ويتر العامة",
    useUserWallet: "استخدم المحفظة الخاصة بالعميل",
    private: "خاصة",
    website: "الموقع الالكتروني",
    type: "النوع",
    accountStatement: "كشوف الحساب",
    walletBalance: "رصيد المحفظة",
    view: "عرض",
    totalRefund: "إجمالي الاسترجاعات",
    count: "العدد",
    deliveryFees: "رسوم التوصيل",
    orderCount: "عدد الطلبات",
    tapSettlement: "معلومات تسوية تاب",
    iwaiterSettlements: "معلومات تسوية اي ويتر",
    totalTransferAmount: "إجمالي المبلغ المحول",
    RESERVATION: "الحجوزات",
    ORDER_B: "الطلبات بلس",
    ORDER_A: "الطلبات",
    WAITING: "الانتظار",
    PICKUP: "طلبات الاستلام",
    BASIC: "العادية",
    appMarketing: "مسابقات التطبيق",
    addCompetition: "إضافة مسابقة",
    countOfWinners: "عدد الفائزين",
    timeToWin: "وقت السحوبات يومياً",
    winners: "الفائزين",
    conditions: "الشروط ",
    prizeType: "نوع الجائزة",
    prizeAmount: "قيمة الجائزة",
    prize: "الجائزة",
    tip: "إكرامية",
    tipsRecords: "سجلات الإكرامية",
    tips: "الإكراميات",
    iwaiterTipPercentage: "نسبة اي ويتر من الاكراميات",
    DoesTipActive: "الاكراميات مفعلة",
    addMarnMenu: "اضف منيو مرن",
    paymentCompanyName: "بوابة الدفع",
    appGames: "العاب التطبيق",
    creator: "المنشئ",
    winner: "الفائز",
    players: "اللاعبين",
    allPrizes: "جميع الجوائز",
    systemPrize: "جوائز النظام",
    gameInfo: "معلومات اللعبة",
    game: "اللعبة",
    questions: "الأسئلة",
    numberOfChallenges: "عدد التحديات",
    maxAmount: "الحد الأقصى لجميع الجوائز",
    challengesType: "نوع التحدي",
    both: "كلاهما",
    XO: "XO",
    Quiz: "أسئلة",
    mix: "مشكل",
    required: "مطلوب",
    isWithApp: "مشترك في تطبيق اي ويتر للألعاب",
    appPercentage: "عمولة اي ويتر من التطبيق",
    WALLETTotal: "إجمالي المحفظة",
    APPTotal: "إجمالي التطبيق",
    gamesWallet: "محفظة الالعاب",
    notifications: "الإشعارات",
    imageURL: "رابط الصورة",
    challenges: "التحديات",
    prizes: "الجوائز",
    runningType: "نوع التوزيع",
    daily: "يومي",
    weekly: "أسبوعي",
    monthly: "شهري",
    all: "الكل",
    hasAmountInWallet: "لديه أكثر من او يساوي في المحفظة",
    yes: "نعم",
    no: "لا",
    sendMessageFeedbackWithTips: "إرسال رسالة بالتقييم مع إكرامية",
    sendMessageFeedbackNoTips: "إرسال رسالة بالتقييم بدون إكرامية",
    newPayments: "المدفوعات الجديدة",
    withPaidSettlement: "مع التسويات المدفوعة",
    payApp: "تطبيق الدفع",
    addRestaurant: "إضافة مطعم",
    link: "الرابط",
    create: "إنشاء",
  },

  en: {
    create: "Create",
    link: "Link",
    addRestaurant: "Add restaurant",
    payApp: "Pay app",
    withPaidSettlement: "With paid settlements",
    newPayments: "New Payments",
    yes: "Yes",
    sendMessageFeedbackWithTips: "Send message feedback with tips",
    sendMessageFeedbackNoTips: "Send message feedback no tips",
    no: "No",
    mix: "Mix",
    all: "All",
    hasAmountInWallet: "Has amount in wallet",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    runningType: "Running type",
    challenges: "Challenges",
    prizes: "Prizes",
    imageURL: "Image URL",
    notifications: "Notifications",
    gamesWallet: "Games wallet",
    WALLETTotal: "Total wallet",
    APPTotal: "App payments",
    isWithApp: "Is with app iwaiter games",
    appPercentage: "App percentage commission",
    required: "Required",
    numberOfChallenges: "Number of challenges",
    maxAmount: "Max amount of all prizes",
    challengesType: "Challenges type",
    both: "Both",
    XO: "XO",
    Quiz: "Quiz",
    prizeAmount: "Prize amount",
    questions: "Questions",
    game: "Game",
    gameInfo: "Game information",
    allPrizes: "All prizes",
    systemPrize: "System prizes",
    winner: "Winner",
    players: "Players",
    creator: "Creator",
    appGames: "App games",
    paymentCompanyName: "Payment company name",
    addMarnMenu: "Add marn menu",
    iwaiterTipPercentage: "Iwaiter tip percentage",
    DoesTipActive: "Does tip active",
    tips: "Tips",
    tipsRecords: "Tips Records",
    tip: "Tip",
    prize: "Prize",
    prizeType: "Prize type",
    timeToWin: "Time to win",
    conditions: "Conditions",
    winners: "Winners",
    addCompetition: "Add competition",
    countOfWinners: "Count of winners",
    firstName: "First name",
    appMarketing: "App marketing",
    iwaiterSettlements: "Iwaiter settlements",
    totalTransferAmount: "Total transfer amount",
    tapSettlement: "Tap settlement information",
    orderCount: "Order count",
    deliveryFees: "Delivery fees",
    count: "Count",
    totalRefund: "Total refund",
    view: "View",
    walletBalance: "Wallet balance",
    accountStatement: "Accounts statement",
    type: "Type",
    website: "Website",
    private: "Private",
    useiwaiterPublicWallet: "Use iwaiter public wallet",
    useUserWallet: "Use user wallet",
    paymentInfo: "Payment information",
    usedWallet: "Used wallet",
    uploadAllImageFirst: "Please upload all images first",
    numberOfId: "ID number",
    uploadToTap: "Upload to Tap",
    oldLogo: "Old logo",
    newLogo: "New logo",
    logoMustBeLessThan1MB: "Logo must be less than 512 KB",
    logoInformation: "Logo information",
    pleaseCheckInternet: "please Check Internet",
    idsInfo: "IDs information",
    pleaseUploadFromRestaurantView:
      "Please upload commercial register and id from restaurant view",
    numberOfCommercial: "Commercial number",
    expiry_date: "Expiry date",
    issuing_date: "Issuing date",
    issuing_country: "Issuing country",
    birthDate: "Birth date",
    middleName: "Middle name",
    lastName: "Last name",
    billingAddress: "Billing Address",
    contactPerson: "Contact Person",
    personName: "Person name",
    zipCode: "Zip code",
    city: "City",
    state: "State",
    poBox: "PO Box",
    district: "District",
    recipientName: "Recipent name",
    address1: "Address 1",
    CommercialLicense: "Commercial License",
    vatNumber: "VAT Number",
    licenseNumber: "License number",
    licenseType: "License Type",
    CommercialRegistration: "Commercial Registration",
    corp: "Corp",
    person: "Person",
    accountType: "Account type",
    ordersTotal: "Total orders",
    legalNameAR: "Legal name AR",
    legalNameEN: "Legal name EN",
    destination_id: "destination_id",
    tapWallets: "Tap wallets",
    FROM_ORDERS: "From orders",
    createZohoInvoice: "Create Zoho invoice",
    subscriptionUnpaidFees: "Subscription unpaid fees",
    canEditPaymentType: "Can edit payment type",
    paymentType: "Payment type",
    UP_FRONT: "Up front",
    OTHER_PAYMENT: "Other payment",
    ordersTotal: "Total orders",
    deliveryIwaiterFees: "Delivery iwaiter fees",
    restaurantPaidAmount: "Restaurant paid amount delivery",
    deliveryCount: "Delivery count",
    iwaiterFeesWithVat: "Total iwaiter fees",
    countOfDeliveryOrders: "Count of delivery orders",
    totalRestaurantPaidAmount: "Resturant paid delivery",
    deliveryAmountToTransferWithoutVat:
      "Delivery amount to transfer without VAT",
    deliveryAmountVat: "Delivery amount VAT",
    waiting: "Waiting",
    pickupD: "Pickup & Delivery",
    reservations: "Reservations",
    invoiceUrl: "Invoice URL",
    invoice: "Invoice",
    refundAmount: "Refund amount",
    TABBYTransferTotal: "TABBY Transfer Total",
    TABBYTotal: "TABBY Total",
    back: "Back",
    paymentMethod: "Payment method",
    addBalance: "Add balance",
    deposits: "Deposits",
    withdrawals: "Withdrawals",
    balance: "Balance",
    orderNumber: "Order number",
    successfetch: "Success",
    deliveryInformation: "Delivery information",
    custoemr: "Customer",
    ready: "Ready",
    open: "New order",
    closed: "Closed",
    close: "Closed",
    inKitchen: "In kitchen",
    inService: "In service",
    rejected: "Rejected",
    timeout: "Timeout",
    inDelivery: "In delivery",
    success: "Success",
    pending: "Pending",
    inProgress: "In progress",
    delivered: "Delivered",
    other: "Other",
    waiting: "Waiting",
    pickupD: "Pickup & Delivery",
    reservations: "Reservations",
    countOfDeliveryOrders: "Count of delivery orders",
    totalRestaurantPaidAmount: "Resturant paid delivery",
    deliveryAmountToTransferWithoutVat:
      "Delivery amount to transfer without VAT",
    deliveryAmountVat: "Delivery amount VAT",
    refundFees: "Refund fees",
    whatsappSetting: "Whatsapp settings",
    iwaiterVAT: "iwaite VAT",
    iwaierFeesWithVat: "iwaite fees with VAT",
    iwaierFees: "iwaite fees",
    tapFees: "Tap fees",
    totalAmountBeforeFees: "Total amount before payment fees",
    paymentFees: "Payment fees",
    totalAfterFees: "Total after payment fees",
    originalTotal: "Original total",
    tapAndIwaiterFees: "iwaite & Tap fees",

    phonealreadyused: "Phone already used",
    VATFees: "VAT Fees",
    subject: "Subject",
    template: "Template",
    marketingEmail: "Send email",
    marketing: "Marketing",
    Suspended: "Suspended",
    change: "Change",
    location: "Locations",
    inApp: "Added to the application",
    officialName: "Official name",
    MADATotal: "MADA Total",
    VISATotal: "VISA Total",
    MADATransferTotal: "MADA Transfer Total",
    VISATransferTotal: "VISA Transfer Total",
    bankName: "Bank Name",
    noData: "No Data",
    transferTime: "Transfer Time",
    forOrdersBetween: "For orders between dates",
    transferNumber: "Transfer Number",
    yourBalance: "Your Balance",
    settlements: "Settlement",
    userName: "User name",
    iban: "IBAN",
    youCanUploadFilesAfterSavePayment:
      "You can upload files after activate payment and submit bank information",
    newDeal: "New Deal",
    switchToNewPayment:
      "Now you can switch to the new payment gateway from iwaiter, where the percentage of online payments is 1.9% for each transaction made through Mada, and 2.4% for each transaction made through Visa and MasterCard, you can activate this agreement by filling out the form below this page.",
    CommercialRegister: "Commercial Register",
    CommercialLicense: "Commercial License",
    ArticlesOfAssociation:
      "Articles Of Association - in case the register is a company -",
    ids: "Copy of ID",
    BankAccountLetter: "Bank Account Letter in English",
    files: "Files",
    activatePayment: "Activate Payment",
    clickToUpload: "Click to upload",
    bankAccountName: "Bank account name in English",
    bankAccountNameTool:
      "Bank account name in English as registered in the Iban certificate",
    bankAccountNumber: "Bank account number",
    chooseBank: "Choose Bank",
    paymentInformation: "Payment Information",
    onlinePayment: "Online Payment",
    originalPrice: "Original price",
    discountAmount: "Discount amount",
    amountPaid: "Amount paid",
    addNewService: "Add new service",
    service: "Service",
    services: "Services",
    checkAccount: "Check Account",
    privateWalletMyFatoorah: "My Fatoorah Wallet",
    add: "Add",
    deviceType: "Device type",
    title: "Title",
    body: "Body",
    usersApplication: "Users application",
    sort: "Sort",
    saveSort: "Save sort",
    totalToNow: "Total to now",
    restaurantType: "Restaurant Type",
    pickupStatus: "Pickup status",
    pickup: "Pickup",
    waitingStatus: "Waiting status",
    waiting: "waiting",
    reservationStatus: "Reservation status",
    allowedServices: "Allowed services",
    images: "Images",
    application: "Application",
    createIwaiterFees: "Create iwaiter fees",
    startSubscriptionDate: "Start subscription",
    paid: "Paid",
    notPaid: "Not paid",
    settlementInfo: "Settlement info",
    pendingPayments: "Pending payments",
    sendSubscriptionEnd: "Send reminder about subscription end",
    iwaiterWalletNOON: "Iwaiter wallet with NOON",
    export: "Export",
    orderDate: "Order date",
    date: "Date",
    total: "Total",
    settlAmount: "Settled amount",
    remainAmount: "Remaining amount",
    uploadFile: "Upload TAP file",
    dateRange: "Date range",
    settlement: "Settlement",
    "N/A": "N/A",
    GOLD: "GOLD",
    MENU: "MENU",
    STANDER: "STANDER",
    SILVER: "SILVER",
    lessThanWeek: "Less than a week",
    expired: "Expired",
    "PAY AS YOU GO": "PAY AS YOU GO",
    deleteIntegration: "Delete integration",
    addTransaction: "Add transaction",
    transferType: "Transfer type",
    amountToTransfer: "Amount to transfer",
    messages: "Message",
    senderName: "Sender name",
    send: "Send",
    addFoodicsMenu: "Add Foodics Menu",
    deleteAllTablesAndAddFromFoodics: "Delete all tables and add from Foodics",
    deleteToken: "Delete token",
    syncOrders: "Sync orders",
    syncMenu: "Sync menu",
    refresh: "Refresh",
    totalOrders: "Total orders",
    sentSuccessful: "Total sent successful orders",
    successfulRate: "Successful rate",
    createdTime: "Created time",
    numberOfCashiers: "Number of cashiers",
    numberOfBranches: "Number of branches",
    numberOfWaiters: "Number of waiters",
    country: "Country",
    language: "Language",
    foodicsFood: "Food name in Foodics",
    modifierNameInFoodics: "Modifier name in Foodics",
    marnFood: "Food name in Marn",
    modifierNameInMarn: "Modifier name in Marn",
    company: "Company",
    createdAt: "Created at",
    transactionNumber: "Transaction number",
    source: "Source",
    pierodTime: "Pierod time",
    wallet: "Wallet",
    iwaiterWallet: "iwaiter public wallet",
    privateWalletTap: "private wallet from tap",
    percentageForIwaiter: "iwaiters percentage VISA",
    percentageForIWaiterMADA: "iwaiters percentage MADA",
    percentageForIWaiterTabby: "iwaiters percentage Tabby",
    totalAmount: "Paid amount",
    linkTable: "Table link",
    paidStatus: "Payment status",
    paymentsTransactions: "Payments transactions",
    sms: "SMS",
    tapPayments: "Tap Payments",
    deleted: "deleted",
    menu: "menu",
    referCode: "Refer code",
    activate: "Activate",
    stop: "Stop",
    editRefer: "Edit Refer",
    numberOfFreeWeeks: "number Of Free Weeks",
    percentage: "Discount percentage",
    couponName: "Coupon name, this name for your reference",
    couponCode: "Coupon code, this code the customer will type.",
    startDate: "Coupon start date",
    endDate: "Coupon end date",
    addRefer: "Add refer code",
    refer: "Coupons",
    createPayment: "Create payment method",
    bringDeliveryFees: "Bring Delivery Fees",
    grabTax: "Grab Tax",
    readyForDeploy: "ready For integrate",
    ready: "Ready",
    active: "Active",
    modifier: "Modifier",
    foodicsMenu: "Foodics Menu",
    request: "New request",
    deliveryRequests: "Delivery requests",
    delivery: "Delivery",
    posRequsets: "POS requests",
    select: "Select",
    integration: "Integration",
    requests: "requests",
    free: "Free",
    update: "Update",
    gold: "Glod",
    silver: "Silver",
    basic: "Basic",
    user: "User",
    subType: "subscription type",
    ourShare: "Our share",
    thereShare: "there share",
    orderType: "Order type",
    search: "Search",
    info: "Information",
    dateEnd: "End date",
    dateStart: "Start date",
    cash: "Cash",
    online: "Online",
    totalRevenue: "Total Revenue",
    amount: "Amount",
    paidInRestaurant: "paid in Restaurant",
    paidOnline: "Paid online",
    totals: "Total",
    ordersTotalAmount: "Revenue",
    totalVisits: "Total visits",
    restaurants: "Restaurants",
    end: "Subscription end on ",
    noUser: "No user with this restaurnat",
    users: "Users",

    transferTime: "Transfer Time",
    forOrdersBetween: "For orders between dates",

    paymentRecords: "Payment Records",

    JUST_MENU: "Menu",

    BASIC: "Standard",
    STANDER: "Standard",
    SILVER: "Silver",
    GOLD: "Gold",
    MENU: "Menu",
    "N/A": "N/A",

    loadingData: "Loading data",
    subscriptionEndDate: "subscription end",

    marnInfo: "Marn information",

    foodicsInfo: "Foodics Information",
    manage: "Manage",

    noModifiers: "No modifiers",

    Active: "Active",

    savedSuccessfully: "change has been saved successfully",

    disabled: "disabled",

    freeSelection: "Number of free choices",

    goBack: "Back",

    totalUsed: "Total used",
    justCode: "Code",
    addCoupon: "Add coupon",

    home: "Home",
    tables: "Tables",
    oppLanguage: "عربي",
    settings: "Settings",
    signout: "Sign out",
    signin: "Sign in",
    signup: "Sign up",
    name: "Name",
    phone: "Mobile Number",
    phone05: "5xxxxxxxx",
    email: "Email",
    error: "Error",
    errorname: "Name is required",
    errorphone: "5xxxxxxxx",
    erroremail: "Please check your email",
    password: "Password",
    errorpassword: "Minimum length is 6 characters",
    errorfetch: "There was an error in the connection please try again.",
    restaurantName: "Restaurant name",
    nameAr: "Name in Arabic",
    nameEn: "Name in English",

    choose: "Choose",
    restaurant: "Restaurant",

    delete: "Delete",

    status: "Status",
    edit: "Edit",
    inHouse: "Dine in",
    takeAway: "Take away",
    confirmDelete: "Are you sure you want to delete this record ?",
    cancel: "Cancel",

    save: "Save",

    maxSelection: "Maximum choices",
    minSelection: "Minimum choices",

    code: "Enter your dashboard pin code",
    errorCode: "Code is not correct",
    enter: "Enter",

    branches: "Branches",
    waiters: "Waiters",

    payments: "Payments",
    subscription: "Subscription",

    addBranch: "Add Branch",
    cityName: "City name",
    tablesCount: "Tables count",
    startFrom: "Start from",
    justName: "Name",
    confirmDeleteReferTitle: "Are you sure you want to Delete this code ?",
    forgetPassword: "Forget password",
    EditGeneralInformation: "Edit general information",
    PhoneExists: "This phone number already exists in other restaurant",
    deleteMenuDescription:
      "Are you sure you want to delete the menu of this restaurant ?",
    deleteMenu: "Delete menu",
    menuDeleteSuccessfully: "Menu deleted successfully",

    superUsers: "Super users",
    addSuperUser: "Add new super user",
    numberOfRestaurants: "Number of restaurants",
    superUserName: "Name of super user",
    superUserRestaurants: "Super user Restaurants",
    selectRestaurants: "Select restaurants",
    deleteSuperUser: "Delete user",
    isOnlineActive: "Online payment",
    cities: "Cities",
    isActive: "enabled",
    notActive: "Not enabled",
    dine_in: "Dine in",
    All: "All",
    enableIn: "Enabled in",
    disabledIn: "Disabled in",
    cities: "Cities",
    isActive: "enabled",
    notActive: "Not enabled",
    dine_in: "Dine in",
    All: "All",
    enableIn: "Enabled in",
    disabledIn: "Disabled in",
    activityReports: "Activity reports",
    newClient: "New Client",
    reservations: "Reservations",
    RESERVATION: "Reservations",
    ORDER_B: "Orders Plus",
    ORDER_A: "Orders",
    WAITING: "Waiting",
    PICKUP: "Pickup",
  },
};

export default messages;
