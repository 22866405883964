import { SearchOutlined } from "@ant-design/icons";
import { Spin, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import CustomButton from "../../Components/CustomButton";
import { Table } from "ant-table-extensions";
import EditPaymentInfoModal from "./Components/EditPaymentInfoModal";
import { getBankList, getSuppliersPaymentInfo } from "../../API/fetch";
import Link from "antd/es/typography/Link";

const { Title } = Typography;
const MyFoatoorah = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [formattedData, setFormattedData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedPaymentInfo, setSelectedPaymentInfo] = useState(null);
  const [bankList, setBankList] = useState([]);
  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    try {
      setLoading(true);
      const res = await getSuppliersPaymentInfo();
      // const res = await getBankList();
      let data = res?.data?.suppliers?.map((s) => {
        return {
          ...s,
          key: s.SupplierCode,
          restaurantName: s?.restaurant?.name,
          restaurantId: s?.restaurant?._id,
        };
      });
      setFormattedData(data);
      setBankList(res?.data?.banks);
      setLoading(false);
    } catch (error) {
      console.log("Error in get inital data", error);
    }
  };
  const column = [
    {
      title: "#",
      render: (_, __, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "restaurantName",
      key: "restaurantName",
      render: (text, record) => (
        <Link target="_blank" href={`/restaurants/${record.restaurantId}`}>{text}</Link>
      ),
    },
    {
      title: "Supplier Name",
      dataIndex: "SupplierName",
      key: "SupplierName",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "Mobile",
      key: "Mobile",
    },
    {
      title: intl.formatMessage({ id: "email" }),
      dataIndex: "Email",
      key: "Email",
    },
    // {
    //   title: "اسم الحساب البنكي",
    //   dataIndex: "BankAccountHolderName",
    //   key: "BankAccountHolderName",
    // },
    {
      title: "المبلغ المستقطع",
      dataIndex: "CommissionValue",
      key: "CommissionValue",
    },
    {
      title: "النسبة ٪",
      dataIndex: "CommissionPercentage",
      key: "CommissionPercentage",
    },
    {
      title: "Action",
      render: (text, record) => (
        <CustomButton
          onClick={() => {
            setSelectedPaymentInfo(record);
            setShowModal(true);
          }}
          text={intl.formatMessage({ id: "edit" })}
          type='primary'
          icon={<SearchOutlined />}
        />
      ),
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <EditPaymentInfoModal
          show={showModal}
          hide={() => {
            setShowModal(false);
            setSelectedPaymentInfo(null);
          }}
          paymentInfo={selectedPaymentInfo}
          bankList={bankList}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            My Fatoorah accounts
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "add" })}
            className={`btnRegister btn-text border-8`}
            loading={loading}
            disabled={loading}
            type='primary'
            onClick={() => setShowModal(true)}
          />
        </div>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table",
              prefix: <SearchOutlined />,
              disabled: loading || formattedData?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          columns={column}
          className='light-shadow'
          loading={loading}
          dataSource={formattedData}
          scroll={{ x: "max-content" }}
        />
      </Spin>
    </div>
  );
};

export default MyFoatoorah;
