import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Spin, Tag } from "antd";
import { useIntl } from "react-intl";
import { SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import { getAppRestaurants, addRestaurantToTheApp, editAppRestaurantInfo } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { setAppRestaurantsAction } from "../../redux/reducers/restaurantsReducer";
import { MdVerified } from "react-icons/md";
import { Link } from "react-router-dom";
import { Table } from "ant-table-extensions";
import { IoRefreshOutline } from "react-icons/io5";
import BranchTag from "./Components/BranchTag";

const { Title } = Typography;

function Application() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { appRestaurants } = useSelector((state) => state.restaurants);
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(true);
  const [formattedRestaurants, setFormattedRestaurants] = useState([]);

  useEffect(() => {
    if (!appRestaurants?.length) {
      getRestaurant();
    } else {
      setLoading(false);
    }
  }, [appRestaurants]);

  const getRestaurant = async () => {
    try {
      console.log("Start");
      setLoading(true);
      const res = await getAppRestaurants();
      if (res?.data?.appRestaurants) {
        dispatch(setAppRestaurantsAction(res.data.appRestaurants));
        setLoading(false);
        openNotification({
          title: "success",
          description: "restaurants loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const addToTheApp = async (id) => {
    try {
      setLoading(true);
      const res = await addRestaurantToTheApp(id);
      if (res?.data?.status === "success") {
        openNotification({
          title: "success",
          description: "restaurant added to the app",
          type: "success",
          rtl,
        });
        getRestaurant();
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
      console.log(error);
    }
  };

  const changeRestaurantStatus = async ({ currentStatus, appRestaurantId }) => {
    try {
      setLoading(true);
      let status = 'Suspended';
      if(currentStatus === 'Suspended') {
        status = 'Active';
      }
      const data = {
        status: status,
      };
      const res = await editAppRestaurantInfo(appRestaurantId, data);
      if (res.data?.appRestaurantInfo?._id) {
        openNotification({
          title: "success",
          description: "restaurant status changed",
          type: "success",
          rtl,
        });
       return getRestaurant();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (appRestaurants?.length) {
      const formatted = appRestaurants.map((restaurant, i) => {
        let inApp = false;
        if (restaurant?.appRestaurantInfo?._id) {
          inApp = true;
        }
        let services = [];
        if (restaurant?.services?.length) {
          // filter services where serviceEnd is bigger than now;
          const filteredServices = restaurant.services.filter((service) => {
            return new Date(service.serviceEnd) > new Date();
          });
          filteredServices?.forEach((service) => {
            service?.services?.forEach((s) => {
              if (services?.indexOf(s) === -1) {
                services.push(s);
              }
            });
          });
        }
        return {
          key: restaurant?._id,
          // number: i + 1,
          appRestaurantId: restaurant?.appRestaurantInfo?._id,
          inApp,
          name: rtl ? restaurant?.name : restaurant?.name_en,
          restaurantId: restaurant?._id,
          order: restaurant?.appRestaurantInfo?.order || i + 1,
          status: restaurant?.appRestaurantInfo?.status,
          allowedServices: restaurant?.appRestaurantInfo?.allowedServices,
          branches: restaurant?.branches,
          services,
        };
      });
      setFormattedRestaurants(formatted);
    }
    setLoading(false);
  }, [rtl, appRestaurants]);

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (_, record, index) => {
        return (
          <Link to={`/restaurants/${record.restaurantId}`}>{index + 1}</Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      width: 160,
      render: (name, record) => {
        // get active
        return (
          <>
            <Link to={`/restaurants/${record.restaurantId}`}>{name}</Link>
            <div>
              {record?.services?.map((s) => (
                <Tag style={{ fontSize: 10 }} key={s}>
                  {s}
                </Tag>
              ))}
            </div>
          </>
        );
      },
      onFilter: (value, record) => {
        return record?.didPaid === value;
      },
      filters: [
        { text: intl.formatMessage({ id: "paid" }), value: true },
        { text: intl.formatMessage({ id: "notPaid" }), value: false },
      ],
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Tag color={status === "Active" ? "green" : "red"}>{status}</Tag>
            <CustomButton
              text={intl.formatMessage({ id: "change" })}
              onClick={() => {
                changeRestaurantStatus({
                  currentStatus: status,
                  appRestaurantId: record.appRestaurantId,
                });
              }}
              className='mt-2 border-8'
              type={status === "Active" ? "danger" : "primary"}
            />
          </div>
        );
      },

      filters: [
        { text: "Active", value: "Active" },
        { text: "Busy", value: "Busy" },
        { text: "Suspended", value: "Suspended" },
        { text: "Deleted", value: "Deleted" },
      ],
      onFilter: (value, record) => {
        return record?.status === value;
      },
    },
    {
      title: intl.formatMessage({ id: "inApp" }),
      dataIndex: "inApp",
      key: "inApp",
      render: (inApp, record) => {
        return (
          <CustomButton
            text={intl.formatMessage({ id: inApp ? "active" : "add" })}
            disabled={inApp}
            type='primary'
            onClick={() => addToTheApp(record?.restaurantId)}
            loading={loading}
          />
        );
      },

      filters: [
        { text: "Active", value: true },
        { text: "Not Active", value: false },
      ],
      onFilter: (value, record) => {
        return record?.inApp === value;
      },
    },
    // {
    //   title: intl.formatMessage({ id: "sort" }),
    //   dataIndex: "order",
    //   key: "order",
    //   render: (order, record) => {
    //     return (
    //       <ChangeAppSort order={order} appId={record.appRestaurantId} />
    //     );
    //   },
    // },

    {
      title: intl.formatMessage({ id: "allowedServices" }),
      dataIndex: "allowedServices",
      key: "allowedServices",
      render: (allowedServices) => {
        return allowedServices?.map((service) => {
          return (
            <Tag color='green' key={service}>
              {service}
            </Tag>
          );
        });
      },
      //   Pickup Reservation Waiting
      filters: [
        { text: "Pickup", value: "Pickup" },
        {
          text: "Reservation",
          value: "Reservation",
        },
        { text: "Waiting", value: "Waiting" },
      ],
      onFilter: (value, record) => {
        return record?.allowedServices.includes(value);
      },
    },
    {
      title: intl.formatMessage({ id: "branches" }),
      dataIndex: "branches",
      key: "branches",
      render: (branches) => {
        return branches?.map((branch) => {
          return <BranchTag branch={branch} key={branch?._id} />;
        });
      },
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "restaurants" })}
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getRestaurant}
          />
        </div>
        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedRestaurants?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
            defaultPageSize: 50,
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedRestaurants}
          scroll={{ x: 'max-content' }}
        />
      </Spin>
    </div>
  );
}

export default Application;
