import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Typography, Spin } from "antd";
import { useIntl } from "react-intl";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import { getAllEmails } from "../../API/fetch";
import openNotification from "../../Components/Notifications";

import { Table } from "ant-table-extensions";
import { IoRefreshOutline } from "react-icons/io5";
import { customerExport } from "../../helpers";

const { Text, Title } = Typography;
const AllEmails = () => {
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [formattedEmails, setFormattedEmails] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await getAllEmails();
      if (res?.data?.emails) {
        formatedData(res.data.emails);
        setLoading(false);
        openNotification({
          title: "success",
          description: "restaurants loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      width: 160,
    },
    {
      title: intl.formatMessage({ id: "email" }),
      dataIndex: "email",
      width: 150,
      key: "email",
      render: (email) => {
        return <Text copyable>{email}</Text>;
      },
    },
  ];

  const formatedData = (emails) => {
    if (emails?.length) {
      const formatted = emails.map((email) => {
        return {
          key: email._id,
          _id: email._id,
          name: email.name,
          email: email.email,
          emailObject: { ...email },
        };
      });
      setFormattedEmails(formatted);
    }
    setLoading(false);
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "email" })}
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getData}
          />
        </div>
        <Table
          searchable
          fuzzySearch
          exportable
          exportableProps={{
            showColumnPicker: true,
            disabled: loading || formattedEmails?.length === 0,
            btnProps: {
              icon: <FileExcelOutlined />,
              type: "primary",
              style: { borderRadius: 7 },
              onClick: () =>
              customerExport({
                data: formattedEmails,
                columns,
                fileName: "Emails Report",
                loadingSetter: setLoading,
              }),
            },
            children: (
              <Text
                className='my-font'
                style={{
                  color:
                    formattedEmails?.length === 0 ? "#dddddd" : COLORS.white,
                }}
              >
                {intl.formatMessage({ id: "export" })}
              </Text>
            ),
          }}
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table",
              prefix: <SearchOutlined />,
              disabled: loading || formattedEmails?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedEmails}
          scroll={{ x: 'max-content' }}
        />
      </Spin>
    </div>
  );
};

export default AllEmails;
