import React, { useState, useEffect } from "react";
import {
  Typography,
  Divider,
  Space,
  Spin,
  Descriptions
} from "antd";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import openNotification from "../../Components/Notifications";
import CustomButton from "../../Components/CustomButton";
import dayjs from "dayjs";
import { getRestaurantBalance } from "../../API/fetch";
import { Table } from "ant-table-extensions";
import { SearchOutlined } from "@ant-design/icons";
import COLORS from "../../Style/colors";
import { Table as MYT } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const { Title, Text } = Typography;

const OneRestaurantAccount = ({}) => {
  const intl = useIntl();
  const { id } = useParams();
  const navigate = useNavigate();
  // get state from navigation;
  const { state } = useLocation();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(true);
  const [balanceData, setBalanceData] = useState([]);
  const [transactionNumbers, setTransactionNumbers] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [balanceSummary, setBalanceSummary] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [transactionsSummary, setTransactionsSummary] = useState(null);
  const [tableType, settableType] = useState("TAB");
  useEffect(() => {
    if (id && balanceData?.length === 0) {
      getBallanceInfo();
    } else {
      console.log("no id");
    }
  }, []);
  const getBallanceInfo = async () => {
    try {
      const res = await getRestaurantBalance(id);
      if (res?.data?.balanceData?.length) {
        let data = [...res.data.balanceData];
        let transactionNumbers1 = [];
        let totalAll = 0;
        let paymentGetawayFeesAll = 0;
        let totalRefundAll = 0;
        let deliveryFeesAll = 0;
        let countAll = 0;
        let filters = [];
        data.forEach((item) => {
          if (!transactionNumbers1.includes(item.transactionNumber)) {
            transactionNumbers1.push(item.transactionNumber);
            filters.push({
              text: item.transactionNumber,
              value: item.transactionNumber,
            });
          }
          // setup balance summary;
          if (parseFloat(item?.total)) totalAll += parseFloat(item?.total);
          if (parseFloat(item?.paymentGetawayFees))
            paymentGetawayFeesAll += parseFloat(item?.paymentGetawayFees);
          if (parseFloat(item?.totalRefund))
            totalRefundAll += parseFloat(item?.totalRefund);
          if (parseFloat(item?.deliveryFees))
            deliveryFeesAll += parseFloat(item?.deliveryFees);
          if (parseFloat(item?.count)) countAll += parseFloat(item?.count);
        });
        setBalanceSummary({
          totalAll,
          paymentGetawayFeesAll,
          totalRefundAll,
          deliveryFeesAll,
          countAll,
        });
        setTransactionNumbers(filters);
        // sort by date;
        data
          .sort(
            (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
          )
          .map((a) => {
            return {
              ...a,
              key: `${a.transactionNumber}-${a.transactionDate}`,
            };
          });
        setBalanceData(data);
      }
      if (res?.data?.iwaiterTransactions?.length) {
        setTransactions(res.data.iwaiterTransactions);
        let allTotalTransfer = 0;
        res.data.iwaiterTransactions.forEach((payment) => {
          allTotalTransfer = payment?.amount - payment?.amountPaidForServices;
        });
        setTransactionsSummary({
          totalTransfer: allTotalTransfer,
        });
      }
      openNotification({
        title: "success",
        description: "balance data loaded",
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: rtl,
      });
    }
  };
  //   DATE, Transaction Number, Total, paymentGetawayFees, totalRefund, count of orders;
  const columns = [
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (text) => <Text>{dayjs(text).format("DD-MM-YYYY hh:mm A")}</Text>,
      //   sort by data;
      sorter: (a, b) =>
        new Date(a.transactionDate) - new Date(b.transactionDate),
    },
    {
      title: intl.formatMessage({ id: "transactionNumber" }),
      dataIndex: "transactionNumber",
      key: "transactionNumber",
      onFilter: (value, record) => {
        return record?.transactionNumber === value;
      },
      filters: transactionNumbers,
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "total",
      key: "total",
      render: (text) => <Text>{text?.toFixed(2)}</Text>,
    },
    {
      title: intl.formatMessage({ id: "tapFees" }),
      dataIndex: "paymentGetawayFees",
      key: "paymentGetawayFees",
      render: (text) => <Text>{text?.toFixed(2)}</Text>,
    },
    {
      title: intl.formatMessage({ id: "totalRefund" }),
      dataIndex: "totalRefund",
      key: "totalRefund",
      render: (text) => <Text>{text?.toFixed(2)}</Text>,
    },
    {
      title: intl.formatMessage({ id: "deliveryFees" }),
      dataIndex: "deliveryFees",
      key: "deliveryFees",
      render: (text) => <Text>{text?.toFixed(2)}</Text>,
    },
    {
      title: intl.formatMessage({ id: "count" }),
      dataIndex: "count",
      key: "count",
    },
  ];
  const columns2 = [
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <Text>{dayjs(text).format("DD-MM-YYYY")}</Text>,
      //   sort by data;
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: intl.formatMessage({ id: "transactionNumber" }),
      dataIndex: "transactionNumber",
      key: "transactionNumber",
    },
    {
      title: intl.formatMessage({ id: "amount" }),
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <Text>{(text - record?.amountPaidForServices)?.toFixed(2)}</Text>
      ),
    },
  ];
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <CustomButton
            onClick={() => navigate(-1)}
            text={intl.formatMessage({ id: "back" })}
            className={"mx-3 border-8 px-3"}
          />
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {state?.restaurant?.restaurantName}
          </Title>
        </div>
        <div>
          <Descriptions
            title={intl.formatMessage({ id: "tapSettlement" })}
            layout='vertical'
            bordered
            className='my-font'
          >
            <Descriptions.Item label={intl.formatMessage({ id: "total" })}>
              {balanceSummary?.totalAll?.toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item label={intl.formatMessage({ id: "tapFees" })}>
              <Text>{balanceSummary?.paymentGetawayFeesAll?.toFixed(2)}</Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "totalRefund" })}
            >
              {balanceSummary?.totalRefundAll?.toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: "deliveryFees" })}
            >
              <Text> {balanceSummary?.deliveryFeesAll?.toFixed(2)} </Text>
            </Descriptions.Item>
            <Descriptions.Item label={intl.formatMessage({ id: "orderCount" })}>
              <Text> {balanceSummary?.countAll} </Text>
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions
            title={intl.formatMessage({ id: "iwaiterSettlements" })}
            layout='vertical'
            bordered
            className='my-font'
          >
            <Descriptions.Item
              label={intl.formatMessage({ id: "totalTransferAmount" })}
            >
              {transactionsSummary?.totalTransfer?.toFixed(2)}
            </Descriptions.Item>
            {/* <Descriptions.Item label={intl.formatMessage({ id: "tapFees" })}>
              <Text>{balanceSummary?.paymentGetawayFeesAll?.toFixed(2)}</Text>
            </Descriptions.Item> */}
          </Descriptions>
        </div>
        <Space className='my-3' size={5}>
          <CustomButton
            text={intl.formatMessage({ id: "tapSettlement" })}
            onClick={() => {
              setShowTable(t => tableType === "TAB" && t  ? false : true)
              settableType("TAB")
            }}
            className={`border-8 px-3 ${
              tableType === "TAB" ? "bg-primary text-white" : ""
            }`}
          />
          <CustomButton
            text={intl.formatMessage({ id: "iwaiterSettlements" })}
            onClick={() => {
              setShowTable(t => tableType === "IWAITER" && t ? false : true)
              settableType("IWAITER")
            }}
            className={`border-8 px-3 ${
              tableType === "IWAITER" ? "bg-primary text-white" : ""
            }`}
          />
        </Space>
        {showTable ? (
          <Table
            searchable
            fuzzySearch
            searchableProps={{
              debounce: true,
              fuzzySearch: true,
              inputProps: {
                placeholder: "Search this table...",
                prefix: <SearchOutlined />,
                disabled: loading || balanceData?.length === 0,
              },
            }}
            exportable
            pagination={{
              position: ["topLeft"],
              pageSizeOptions: [10, 25, 50, 100, 200, 500],
              pageSize: 500,
            }}
            columns={tableType === 'TAB' ? columns : columns2}
            // className='light-shadow'
            loading={loading}
            dataSource={tableType === 'TAB' ? balanceData: transactions}
            scroll={{ x: 'max-content' }}
            summary={(pageData) => {
              let totalAll = 0;
              let paymentGetawayFeesAll = 0;
              let totalRefundAll = 0;
              let deliveryFeesAll = 0;
              let countAll = 0;
              pageData.forEach(
                ({
                  total,
                  paymentGetawayFees,
                  totalRefund,
                  deliveryFees,
                  count,
                }) => {
                  if (parseFloat(total)) totalAll += parseFloat(total);
                  if (parseFloat(paymentGetawayFees))
                    paymentGetawayFeesAll += parseFloat(paymentGetawayFees);
                  if (parseFloat(totalRefund))
                    totalRefundAll += parseFloat(totalRefund);
                  if (parseFloat(deliveryFees))
                    deliveryFeesAll += parseFloat(deliveryFees);
                  if (parseFloat(count)) countAll += parseFloat(count);
                }
              );

              return (
                <MYT.Summary>
                  <MYT.Summary.Row>
                    <MYT.Summary.Cell className='summary-bg-gray' colSpan={2}>
                      {intl.formatMessage({ id: "total" })}
                    </MYT.Summary.Cell>
                    <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                      <Text style={{ color: COLORS.primary }}>
                        {totalAll?.toFixed(2)}
                      </Text>
                    </MYT.Summary.Cell>
                    <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                      <Text style={{ color: COLORS.primary }}>
                        {paymentGetawayFeesAll?.toFixed(2)}
                      </Text>
                    </MYT.Summary.Cell>
                    <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                      <Text style={{ color: COLORS.primary }}>
                        {totalRefundAll?.toFixed(2)}
                      </Text>
                    </MYT.Summary.Cell>
                    <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                      <Text style={{ color: COLORS.primary }}>
                        {deliveryFeesAll?.toFixed(2)}
                      </Text>
                    </MYT.Summary.Cell>

                    <MYT.Summary.Cell style={{ backgroundColor: "red" }}>
                      <Text style={{ color: COLORS.primary }}>
                        {countAll?.toFixed(2)}
                      </Text>
                    </MYT.Summary.Cell>
                  </MYT.Summary.Row>
                </MYT.Summary>
              );
            }}
          />
        ) : null}
      </Spin>
    </div>
  );
};

export default OneRestaurantAccount;
