import { Popconfirm, Space, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeIntegration, updateIntegration, createMarnMenu } from "../../../API/fetch";
import CustomButton from "../../../Components/CustomButton";
import openNotification from "../../../Components/Notifications";
import { hasDuplicates } from "../../../helpers";
import COLORS from "../../../Style/colors";
import BranchesMarn from "./Components/BranchesMarn";
import GeneralInfo from "./Components/GeneralInfo";
import MenuMarn from "./Components/MenuMarn";
import TablesMarn from "./Components/TablesMarn";

const { Text } = Typography;

const Marn = ({ restaurant, posData, setRestaurant }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);

  const updatePOSBranches = async (posBranches) => {
    // check if we have dubelcated branches;
    const localIds = posBranches.map((b) => b.localId);
    if (hasDuplicates(localIds)) {
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: "كل فرع يجب أن يرتبط بفرع واحد فقط",
        type: "error",
        rtl,
      });
      return;
    }
    const otherIds = posBranches.map((b) => b.otherId);
    if (hasDuplicates(otherIds)) {
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: "كل فرع يجب أن يرتبط بفرع واحد فقط",
        type: "error",
        rtl,
      });
      return;
    }
    try {
      const data = {
        integration: {
          posBranches: posBranches,
        },
        type: "posBranches",
      };
      setLoading(true);
      const res = await updateIntegration(restaurant.integration._id, data);
      if (res?.data?.integration) {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setRestaurant({
          ...restaurant,
          integration: res.data.integration,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const updateMenu = (food) => {
    let menu = restaurant.menu.map((cat) => {
      if (cat?.categoryId === food?.categoryId) {
        cat.foods = cat.foods?.map((f) => {
          if (f?.foodId === food?._id) {
            return food;
          }
          return f;
        });
      }
      return cat;
    });
    setRestaurant({
      ...restaurant,
      menu,
    });
  };

  const updateInformation = async ({ type }) => {
    try {
      if (!type) return;
      setLoading(true);
      let sendData = {};
      if (type === "tax") {
        sendData = {
          integration: {
            posTaxInfo: {
              token: restaurant.integration.posToken,
              restaurantId: restaurant?.integration.restaurantId,
              brandKey: restaurant?.integration.restaurantOtherId,
              locationKey: restaurant?.integration.posBranches[0].otherId,
            },
          },
          type: "posTaxInfo",
          company: "marn",
        };
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }

      const res = await updateIntegration(restaurant.integration._id, sendData);
      if (res?.data?.integration) {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setRestaurant({
          ...restaurant,
          integration: res.data.integration,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const deleteIntegration = async () => {
    setLoading(true);
    try {
      const res = await removeIntegration(restaurant.integration?._id);
      if (res?.data?.status === "success") {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        navigate(-1);
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const addMarnMenu = async () => {
    try {
      setLoading(true);
      const data = {
        restaurantId: restaurant._id,
      }
      const res = await createMarnMenu(data)
      if(res.data?.status === 'success'){
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          description: 'حدث الصفحة لمشاهدة التفاصيل',
          rtl,
        });       
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  }

  return (
    <div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 10,
          width: "100%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 20,
        }}
        className='light-shadow'
      >
        <GeneralInfo information={posData?.information} />
        <Space
          direction='horizontal'
          style={{
            width: "100%",
            justifyContent: "space-around",
            marginTop: 20,
          }}
        >
          <CustomButton
            type='primary'
            loading={loading}
            disabled={loading}
            onClick={() => updateInformation({ type: "tax", value: "" })}
            text={intl.formatMessage({ id: "grabTax" })}
          />
          <Popconfirm
            title={
              <Text className='my-font'>
                {intl.formatMessage({ id: "confirmDelete" })}
              </Text>
            }
            okText={intl.formatMessage({ id: "delete" })}
            okButtonProps={{
              style: { color: COLORS.danger, fontFamily: "Cairo" },
            }}
            okType='text'
            cancelText={intl.formatMessage({ id: "cancel" })}
            cancelButtonProps={{
              style: { border: "none", fontFamily: "Cairo" },
            }}
            onConfirm={deleteIntegration}
            disabled={!restaurant?.integration || loading}
            loading={loading}
          >
            <Text
              type='danger'
              className='pointer my-font'
              disabled={!restaurant?.integration || loading}
            >
              {intl.formatMessage({ id: "deleteIntegration" })}
            </Text>
          </Popconfirm>
        </Space>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 10,
          width: "100%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 20,
        }}
        className='light-shadow'
      >
        <BranchesMarn
          linkedBranches={restaurant?.integration?.posBranches}
          posBranches={posData?.branches}
          localBranches={restaurant?.branches}
          updatePOSBranches={updatePOSBranches}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
      {/* {Loop therow the linked brahces and display the tables for each branch} */}
      {restaurant?.integration?.posBranches.map((branch) => {
        const localBranch = restaurant?.branches?.find(
          (b) => b._id === branch.localId
        );
        const localTables = localBranch.tables;
        const branchName = localBranch.name;
        const localBranchId = localBranch._id;
        const posTables = posData?.branches?.find(
          (b) => b.marnLocationKey === branch.otherId
        )?.tables;
        return (
          <div
            key={branch?.localId}
            style={{
              backgroundColor: COLORS.white,
              marginTop: 10,
              width: "100%",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              padding: 20,
            }}
            className='light-shadow'
          >
            <TablesMarn
              localTables={localTables}
              posTables={posTables}
              linkedTables={branch?.tables}
              loading={loading}
              branchName={branchName}
              updatePOSBranches={updatePOSBranches}
              linkedBranches={restaurant?.integration?.posBranches}
              localBranchId={localBranchId}
              restaurantId={restaurant._id}
              setRestaurant={setRestaurant}
              restaurant={restaurant}
              setLoading={setLoading}
            />
          </div>
        );
      })}
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 10,
          width: "100%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 20,
        }}
        className='light-shadow'
      >
        <MenuMarn
          restaurant={restaurant}
          updateMenu={updateMenu}
          posData={posData}
          addMarnMenu={addMarnMenu}
          loading={loading}

        />
      </div>
    </div>
  );
};

export default Marn;
