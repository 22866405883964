export const DINE_IN_ORDERS_STATUS = {
    ALL: 'all',
    OPEN: "open",
    IN_SERVER: "inService",
    SERVED: "served",
    CLOSE: "close",
};
export const PICKUP_ORDERS_STATUS = {
    ALL: 'all',
    OPEN: "open",
    IN_KITCHEN: "inKitchen",
    READY: "ready",
    CLOSE: "close",
};
export const DELIVERY_ORDERS_STATUS = {
    ALL: 'all',
    OPEN: "open",
    IN_KITCHEN: "inKitchen",
    READY: "ready",
    IN_DELIVERY: "inDelivery",
    CLOSE: "close",
};

export const ORDER_TYPES = {
    DELIVERY: 'delivery',
    PICKUP: 'pickup',
    DINE_IN: 'inHouse',
};

export const TAG_COLORS_BY_STATUS = {
    "open": 'green',
    "inService": 'orange',
     "served": 'cyan'
}
export const TAG_COLORS_BY_STATUS_PICKUP = {
    "open": 'green',
    "inKitchen": 'orange',
     "ready": 'cyan'
}
export const TAG_COLORS_BY_STATUS_DELIVERY = {
    "open": 'green',
    "inKitchen": 'orange',
     "ready": 'cyan',
     'inDelivery': 'purple'
}
export const TAG_COLORS_BY_PAYMENT_STATUS = {
    "paid": 'green',
    "notPaid": 'red',
    "inPayment": 'red',
    "failed": 'red'
}
export const TAG_COLORS_BY_USER_TYPE = {
    "customer": 'gray',
    "vip": 'blue',
    "v-vip": 'green',
}

export const PROMOTIONS_TYPES = {
    FIXED_PRICE: 'FIXED_PRICE',
    FIXED_DISCOUNT: 'FIXED_DISCOUNT',
    PERCENTAGE_DISCOUNT: 'PERCENTAGE_DISCOUNT',
    FIXED_INCREASE: 'FIXED_INCREASE',
    PERCENTAGE_INCREASE: 'PERCENTAGE_INCREASE'
}
export const TABLE_SHAPES = {
    CIRCLE: 'CIRCLE',
    SQUARE: 'SQUARE',
    RECTANGLE: 'RECTANGLE',
}

export const WAITING_LIST_USER_STATUS = {
    ACTIVE: 'Active',
    SERVED: 'served',
    DELETED: 'deleted',
}

export const ANSWER_TYPES = {
    stars: 'stars',
    faces: 'faces',
    text: 'text',
}

export const TAG_COLOR_BY_USER_TYPE = {
    waiting: 'green',
    pickup: 'orange',
    delivery: 'purple',
    inHouse: 'blue',
    booking: 'yellow'
}
export const TAG_COLOR_BY_SUBSCRIPTION_TYPES = {
    MENU: 'red',
    STANDER: 'orange',
    SILVER: 'blue',
    GOLD: 'green',
    'PAY AS YOU GO': 'green',
}


export const SUBSCRIPTION_TYPES = {
    '0': 'MENU',
    '1': 'STANDER',
    '2': 'SILVER',
    '3': 'GOLD',
    '4': 'PAY AS YOU GO',
}


export const FoodAllergyTYPES = {
    CORN: 'CORN',
    EGG: 'EGG',
    FISH: 'FISH',
    GLUTEN: 'GLUTEN',
    MILK: 'MILK',
    NUTS: 'NUTS',
    GMO: 'GMO',
    PEANUTS: 'PEANUTS',
    SHELLFISH: 'SHELLFISH',
    SOYA: 'SOYA',
    SULPHITES: 'SULPHITES',
    VEGAN: 'VEGAN',

}

export const WALLET_TYPE ={
    IWAITER_WALLET: 'IWAITER_WALLET',
    IWAITER_WALLET_NOON: 'IWAITER_WALLET_NOON',
    PRIVATE_WALLET_TAP: 'PRIVATE_WALLET_TAP',
    IWAITER_WALLET_MY_FATOORAH: 'IWAITER_WALLET_MY_FATOORAH',
}

export const INTEGRATION_TYPE = {
    FOODICS: 'foodics',
    MARN: 'Marn'
}

export const SERVICES_TYPES = {
    RESERVATION: ['RESERVATION'], 
    WAITING: ['WAITING'],
    ORDER_A: ['ORDER_A'],
    ORDER_B: ['ORDER_B'],
    PICKUP: ['PICKUP'],
    SILVER: [ 'WAITING', 'ORDER_B', 'PICKUP'], 
    GOLD: [ 'RESERVATION', 'ORDER_B', 'PICKUP', 'WAITING'],
    BASIC: [ 'ORDER_A',],
}
export const SERVICES_PRICE = {
    RESERVATION: 249,
    WAITING: 99,
    ORDER_A: 199,
    ORDER_B: 249,
    PICKUP: 99,
    SILVER: 290,
    GOLD: 458,
    BASIC: 166,
}


export const RESTAURANT_TYPE = {
    FINE_DINING: 'Fine Dining',
    CASUAL_DINING: 'Casual Dining',
    FAST_CASUAL: 'Fast Casual',
    FAST_FOOD: 'Fast Food',
    CAFE: 'Cafe',
    BAKERY: 'Bakery',
    DESSERT: 'Dessert',
    ICE_CREAM: 'Ice Cream',
    CATERING: 'Catering',
    FOOD_TRUCK: 'Food Truck',
    FOOD_COURT: 'Food Court',
    OTHER: 'Other',
}

export const PAYONLINEIN = {
    ALL :"ALL",
    DINE_IN :"DINEIN", 
    PICKUP :"PICKUP", 
    DELIVERY :"DELIVERY",
    NONE:"NONE" 
    
}

export const servicesPaymentTypes =  [
    "FROM_ORDERS", 
    "UP_FRONT", 
    "OTHER_PAYMENT"
];
