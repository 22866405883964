import {
  Col,
  Descriptions,
  Divider,
  Input,
  InputNumber,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../Components/CustomButton";
import {
  addUserBalance,
  editEWalletStatus,
  getOneUsersApplication,
  sendNotificationsUsers,
} from "../../../API/fetch";
import { updateAppUserAction } from "../../../redux/reducers/restaurantsReducer";
import openNotification from "../../../Components/Notifications";
import dayjs from "dayjs";
import { ArrowRightOutlined } from "@ant-design/icons";
import COLORS from "../../../Style/colors";
const { Title, Text } = Typography;
const OneUserApplication = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { appUsers, restaurants } = useSelector((state) => state.restaurants);
  // get the last param of the url
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [newBalance, setNewBalance] = useState(0);
  const [notificationObj, setNotificationObj] = useState(null);
  useEffect(() => {
    getUser(userId);
  }, [userId]);

  const getUser = async (id) => {
    try {
      setLoading(true);
      const res = await getOneUsersApplication(id);
      setUser(res?.data.user);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const toggleStatus = async () => {
    try {
      setLoading(true);
      let status = "Active";
      if (user?.eWallet?.status === "Active") {
        status = "disabled";
      }
      const data = {
        status,
      };
      const res = await editEWalletStatus(user?._id, data);
      dispatch(updateAppUserAction(res?.data.user));
      setUser(res?.data.user);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "success" }),
        message: intl.formatMessage({ id: "successfetch" }),
        type: "success",
        rtl,
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  const addBalance = async () => {
    try {
      if (newBalance === 0) return;
      setLoading(true);
      const data = {
        deposit: {
          balance: newBalance,
          amount: newBalance,
          status: "Active",
          paymentMethod: "system",
          paymentType: "system",
          paymentStatus: "success",
          source: "admin",
        },
      };
      const res = await addUserBalance(user?._id, data);
      dispatch(updateAppUserAction(res?.data.user));
      setUser(res?.data.user);
      setNewBalance(0);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "success" }),
        message: intl.formatMessage({ id: "successfetch" }),
        type: "success",
        rtl,
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const getRestaurantName = (id) => {
    let restaurant = restaurants?.find((restaurant) => restaurant._id === id);
    if (rtl) return restaurant?.name;
    return restaurant?.name_en;
  };

  const sendNotificationToUser = async () => {
    try {
      setLoading(true);
      const data = {
        title: notificationObj?.title,
        message: notificationObj?.message,
        imageURL: notificationObj?.imageURL,
        usersIds: [user?._id],
      };
      const res = await sendNotificationsUsers(data);
      console.log(res.data);
      openNotification({
        title: "Success",
        description: "Notification sent successfully",
        type: "success",
        rtl,
      });
      setNotificationObj(null);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "Error",
        description: "Error sending notification",
        type: "error",
        rtl,
      });
    }
  };
  console.log(user);
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {/* Add back button */}
      <CustomButton
        type='secondary'
        text={intl.formatMessage({ id: "back" })}
        onClick={() => navigate(-1)}
        icon={<ArrowRightOutlined />}
        style={{
          marginBottom: 20,
          borderRadius: 10,
        }}
      />
      <div
        style={{
          backgroundColor: "white",
          padding: "15px 15px",
          borderRadius: 10,
        }}
        className='light-shadow'
      >
        <Spin spinning={loading}>
          <Space direction='vertical' size={0}>
            <Title level={5} className='my-font'>
              {user?.name}
            </Title>
            <Text
              copyable={{
                text: `0${user?.phone?.slice(3)}`,
              }}
              className='my-font'
            >
              0{user?.phone?.slice(3)}
            </Text>
          </Space>

          <div
            className='mt-2'
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Space direction='horizontal' size={"small"}>
              <Text className='my-font'>
                {intl.formatMessage({ id: "wallet" })} :{" "}
              </Text>
              <Tag
                className='my-font mx-3'
                color={user?.eWallet?.status === "Active" ? "green" : "red"}
              >
                {user?.eWallet?.status === "Active"
                  ? intl.formatMessage({ id: "Active" })
                  : intl.formatMessage({ id: "disabled" })}
              </Tag>
            </Space>
            <CustomButton
              type={user?.eWallet?.status === "Active" ? "danger" : "primary"}
              text={intl.formatMessage({
                id:
                  user?.eWallet?.status === "Active" ? "disabled" : "activate",
              })}
              className={"btn-text border-8"}
              loading={loading}
              disabled={loading}
              onClick={toggleStatus}
            />
          </div>
          <Space direction='horizontal' size={"large"}>
            <Tag className='my-font'>
              {intl.formatMessage({ id: "inHouse" })} :{" "}
              <Text strong> {user?.orders?.length}</Text>
            </Tag>
            <Tag className='my-font'>
              {intl.formatMessage({ id: "pickupD" })} :{" "}
              <Text strong> {user?.pickupOrders?.length}</Text>
            </Tag>
            <Tag className='my-font'>
              {intl.formatMessage({ id: "reservations" })} :{" "}
              <Text strong> {user?.reservations?.length}</Text>
            </Tag>
            <Tag className='my-font'>
              {intl.formatMessage({ id: "waiting" })} :{" "}
              <Text strong> {user?.waiting?.length}</Text>
            </Tag>
          </Space>
          <Divider />
          <br />
          <Row
            style={{
              justifyContent: "space-between",
            }}
          >
            <Col
              span={11}
              style={{
                backgroundColor: COLORS.gray,
                padding: "10px 15px",
                borderRadius: 10,
              }}
            >
              <Text>{intl.formatMessage({ id: "challenges" })}</Text>
              <br />
              <Tag color='orange'>{user?.challenges?.length} تحديات</Tag>
              <div>
                {user?.challenges?.map((challenge) => (
                  <Tag color='gray' style={{ marginTop: 7 }}>
                    {dayjs(challenge?.createdAt).format("YYYY/MM/DD")}
                  </Tag>
                ))}
              </div>
            </Col>
            <Col
              span={11}
              style={{
                backgroundColor: COLORS.gray,
                padding: "10px 15px",
                borderRadius: 10,
              }}
            >
              <Text>{intl.formatMessage({ id: "prizes" })}</Text>
              <br />
              <Tag color='green'>
                {user?.prizes?.length} جائزة بقيمة{" "}
                {user?.prizes?.reduce(
                  (acc, curr) => acc + (curr?.amount || 0),
                  0
                )}{" "}
                ريال
              </Tag>
              <div>
                {user?.prizes?.map((challenge) => (
                  <Tag color='gray' style={{ marginTop: 7 }}>
                    {dayjs(challenge?.createdAt).format("YYYY/MM/DD")}
                  </Tag>
                ))}
              </div>
            </Col>
          </Row>

          {user?.fcmNotificationToken ? (
            <div>
              <Divider />
              <Title level={5} className='my-font'>
                {intl.formatMessage({ id: "notifications" })}
              </Title>
              <Space direction='vertical' size={"middle"}>
                <Space direction='horizontal' size={"small"}>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "title" })} :{" "}
                  </Text>
                  <Input
                    value={notificationObj?.title}
                    onChange={(e) => {
                      // check if the length of the is less than 50
                      if (e.target.value.length <= 50) {
                        setNotificationObj({
                          ...notificationObj,
                          title: e.target.value,
                        });
                      }
                    }}
                  />
                </Space>
                <Space
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  direction='horizontal'
                  size={"small"}
                >
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "message" })} :{" "}
                  </Text>
                  <Input.TextArea
                    value={notificationObj?.message}
                    onChange={(e) => {
                      // check if the length of the is less than 50
                      if (e.target.value.length <= 120) {
                        setNotificationObj({
                          ...notificationObj,
                          message: e.target.value,
                        });
                      }
                    }}
                  />
                </Space>
                <Space direction='horizontal' size={"small"}>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "imageURL" })} :{" "}
                  </Text>
                  <Input
                    value={notificationObj?.imageURL}
                    onChange={(e) => {
                      // check if the length of the is less than 50
                      if (e.target.value.length <= 50) {
                        setNotificationObj({
                          ...notificationObj,
                          imageURL: e.target.value,
                        });
                      }
                    }}
                  />
                </Space>
                <CustomButton
                  text={intl.formatMessage({ id: "send" })}
                  type={"primary"}
                  loading={loading}
                  disabled={
                    loading ||
                    !notificationObj?.title ||
                    !notificationObj?.message
                  }
                  onClick={sendNotificationToUser}
                />
              </Space>
            </div>
          ) : null}
          {user?.eWallet?._id ? (
            <div>
              <Divider />
              <Title level={5} className='my-font'>
                {intl.formatMessage({ id: "wallet" })}
              </Title>
              <Row
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Col
                  span={10}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 10,
                    flexDirection: "row",
                    padding: "10px 15px 15px 15px",
                  }}
                  className='light-shadow'
                >
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "balance" })} :{" "}
                  </Title>
                  <Text className='my-font mx-2'>
                    {user?.eWallet?.balance?.toFixed(2)} SAR
                  </Text>
                </Col>
                {/* display an input so we can add balance to eWallet */}
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 10,
                    flexDirection: "row",
                    padding: "10px 15px 15px 15px",
                  }}
                  className='light-shadow'
                >
                  <Title level={5} className='my-font'>
                    {intl.formatMessage({ id: "addBalance" })}
                  </Title>
                  <InputNumber
                    className='my-font'
                    style={{
                      marginRight: 10,
                      marginLeft: 10,
                      flex: 1,
                      borderRadius: 10,
                    }}
                    placeholder={intl.formatMessage({ id: "addBalance" })}
                    min={0}
                    max={100}
                    value={newBalance}
                    onChange={(e) => setNewBalance(e)}
                  />
                  <CustomButton
                    type={"primary"}
                    text={intl.formatMessage({ id: "addBalance" })}
                    className={"btn-text border-8"}
                    loading={loading}
                    disabled={loading}
                    onClick={addBalance}
                  />
                </Col>
              </Row>
            </div>
          ) : null}
          {/* Display all the deposits & withdrawals for the wallet  */}
          {user?.eWallet?.deposits?.length ? (
            <div>
              <Divider />
              <Title level={5} className='my-font'>
                {intl.formatMessage({ id: "deposits" })}
              </Title>
              {/* Display a list of all diposites */}
              <Descriptions bordered>
                {user?.eWallet?.deposits?.map((deposit) => (
                  <Descriptions.Item
                    label={
                      <Space direction='vertical' size={"small"}>
                        <Text>
                          {dayjs(deposit.paymentDate).format("YYYY/MM/DD")}
                        </Text>
                        <Tag className='my-font'>{deposit?.paymentStatus}</Tag>
                      </Space>
                    }
                    span={3}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Space direction='vertical' size={"0"}>
                        <Text
                          style={{
                            marginBottom: 0,
                          }}
                          className='my-font'
                        >
                          {intl.formatMessage({ id: "amount" })}
                        </Text>
                        <Tag
                          style={{
                            marginTop: 0,
                            borderRadius: 5,
                          }}
                          className='my-font'
                        >
                          {deposit?.amount}
                        </Tag>
                      </Space>
                      <Space direction='vertical' size={"0"}>
                        <Text
                          style={{
                            marginBottom: 0,
                          }}
                          className='my-font'
                        >
                          {intl.formatMessage({ id: "paymentMethod" })}
                        </Text>
                        <Tag
                          style={{
                            marginTop: 0,
                            borderRadius: 5,
                          }}
                          className='my-font'
                        >
                          {deposit?.paymentMethod}
                        </Tag>
                      </Space>
                      <Space direction='vertical' size={"0"}>
                        <Text
                          style={{
                            marginBottom: 0,
                          }}
                          className='my-font'
                        >
                          {intl.formatMessage({ id: "source" })}
                        </Text>
                        <Tag
                          style={{
                            marginTop: 0,
                            borderRadius: 5,
                          }}
                          className='my-font'
                        >
                          {deposit?.source}
                        </Tag>
                      </Space>
                    </div>
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </div>
          ) : null}
          {user?.eWallet?.withdrawals?.length ? (
            <div>
              <Divider />
              <Title level={5} className='my-font'>
                {intl.formatMessage({ id: "withdrawals" })}
              </Title>
              {/* Display a list of all diposites */}
              <Descriptions bordered>
                {user?.eWallet?.withdrawals?.map((withdrawals) => (
                  <Descriptions.Item
                    label={
                      <Space direction='vertical' size={"small"}>
                        <Text>
                          {dayjs(withdrawals.paymentDate).format("YYYY/MM/DD")}
                        </Text>
                        <Tag className='my-font'>{withdrawals?.status}</Tag>
                      </Space>
                    }
                    span={3}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Space direction='vertical' size={"0"}>
                        <Text
                          style={{
                            marginBottom: 0,
                          }}
                          className='my-font'
                        >
                          {intl.formatMessage({ id: "amount" })}
                        </Text>
                        <Tag
                          style={{
                            marginTop: 0,
                            borderRadius: 5,
                          }}
                          className='my-font'
                        >
                          {withdrawals?.amount}
                        </Tag>
                      </Space>
                      <Space direction='vertical' size={"0"}>
                        <Text
                          style={{
                            marginBottom: 0,
                          }}
                          className='my-font'
                        >
                          {intl.formatMessage({ id: "paymentMethod" })}
                        </Text>
                        <Tag
                          style={{
                            marginTop: 0,
                            borderRadius: 5,
                          }}
                          className='my-font'
                        >
                          {withdrawals?.paymentMethod}
                        </Tag>
                      </Space>
                      <Space direction='vertical' size={"0"}>
                        <Text
                          style={{
                            marginBottom: 0,
                          }}
                          className='my-font'
                        >
                          {intl.formatMessage({ id: "restaurant" })}
                        </Text>
                        <Tag
                          style={{
                            marginTop: 0,
                            borderRadius: 5,
                          }}
                          className='my-font'
                        >
                          {getRestaurantName(withdrawals?.restaurantId)}
                        </Tag>
                      </Space>
                    </div>
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </div>
          ) : null}
        </Spin>
      </div>
    </div>
  );
};

export default OneUserApplication;
