import React from "react";
import { Descriptions, Badge, Typography } from "antd";
import { useIntl } from "react-intl";

const { Title, Text } = Typography;
const GeneralInfo = ({ information, integration }) => {
  const intl = useIntl();
  return (
    <Descriptions
      title={intl.formatMessage({ id: "foodicsInfo" })}
      layout='vertical'
      bordered
      className='my-font'
    >
      <Descriptions.Item label={intl.formatMessage({ id: "justName" })}>
        {information?.user?.name}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "email" })}>
        <Text copyable>{information?.user?.email}</Text>
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "language" })}>
        {information?.user?.lang}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "phone" })}>
        <Text copyable> {information?.user?.number} </Text> -{" "}
        <Text copyable>{information?.user?.number}</Text>
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "country" })}>
        {information?.business?.country}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "email" })}>
        <Text copyable>{information?.business?.email}</Text>
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "restaurantName" })}>
        {information?.business?.name}
      </Descriptions.Item>

      <Descriptions.Item label={intl.formatMessage({ id: "numberOfCashiers" })}>
        {information?.business?.numberOfCashiers}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "numberOfBranches" })}>
        {information?.business?.numberOfBranches}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "numberOfWaiters" })}>
        {information?.business?.numberOfWaiters}
      </Descriptions.Item>
      <Descriptions.Item label={intl.formatMessage({ id: "createdTime" })}>
        {information?.business?.createdAt}
      </Descriptions.Item>
      <Descriptions.Item label='Foodics Pay'>
        <Badge
          status={information?.business?.hasFoodicsPay ? "success" : "error"}
          text={information?.business?.hasFoodicsPay ? "YES" : "NO"}
        />
      </Descriptions.Item>
      <Descriptions.Item label='BLOCKED'>
        <Badge
          status={information?.business?.isBlocked ? "success" : "error"}
          text={information?.business?.isBlocked ? "YES" : "NO"}
        />
      </Descriptions.Item>
    </Descriptions>
  );
};

export default GeneralInfo;
