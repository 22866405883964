import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import settingsReducer from "./settingsReducer";
import authReducer from "./authReducer";
import restaurantsReducer from "./restaurantsReducer";
import settlementReducer from "./settlementReducer";
import appReducer from "./appReducer";
import payAppReducer from "./payAppReducer";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["settings", "auth", "restaurants", "settlement", "app", "payApp"],
  transforms: [], // TODO: secure some data?
};
const reducers = persistCombineReducers(persistConfig, {
  settings: settingsReducer,
  auth: authReducer,
  restaurants: restaurantsReducer,
  settlement: settlementReducer,
  app: appReducer,
  payApp: payAppReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/logOut") {
    // check for action type
    state = {}; // reset state
    storage.removeItem("persist:root");
  }
  return reducers(state, action);
};

export default rootReducer;
