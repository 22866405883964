import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Spin, Form, Input, Select, Tag, Upload } from "antd";
import { useIntl } from "react-intl";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import COLORS from "../../Style/colors";
import { getUsers, sendMessage, sendMessageWithImage } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { setUsersAction } from "../../redux/reducers/restaurantsReducer";
import { MdVerified } from "react-icons/md";
import { Link } from "react-router-dom";
import { Table } from "ant-table-extensions";
import { IoRefreshOutline } from "react-icons/io5";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);
const { Dragger } = Upload;

const { Text, Title } = Typography;
const SMS = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.restaurants);
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [formattedUsers, setFormattedUsers] = useState([]);
  const [phones, setPhones] = useState([]);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (!users?.length) {
      getUsersData();
    } else {
      setLoading(false);
    }
  }, [users]);

  const getUsersData = async () => {
    try {
      setLoading(true);
      const res = await getUsers();
      if (res?.data?.users) {
        dispatch(setUsersAction(res.data.users));
        setLoading(false);
        openNotification({
          title: "success",
          description: "users loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record, index) => {
        return (
          <Link to={`/restaurants//${record.restaurantId}`}>{index + 1}</Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      width: 160,
      render: (name, record) => {
        return (
          <Link
            to={
              record.restaurantId ? `/restaurants/${record.restaurantId}` : ""
            }
          >
            {record.didPaid ? (
              <MdVerified
                style={{ marginLeft: 10 }}
                size={18}
                color={COLORS.primary}
              />
            ) : null}
            {name}
          </Link>
        );
      },
      onFilter: (value, record) => {
        return record?.didPaid === value;
      },
      filters: [
        { text: intl.formatMessage({ id: "paid" }), value: true },
        { text: intl.formatMessage({ id: "notPaid" }), value: false },
      ],
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      width: 150,
      key: "phone",
      render: (phone) => {
        return (
          <Text
            copyable={{
              // remove the first three digits from phone;
              text: phone ? phone.slice(3) : "",
            }}
          >
            {phone}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "restaurantName" }),
      dataIndex: "restaurantName",
      key: "restaurantName",
      render: (name) => {
        return (
          <Text type={name !== "N/A" ? "" : "danger"} className='my-font'>
            {name}
          </Text>
        );
      },
      onFilter: (value, record) => {
        if (value === "N/A") {
          return record.restaurantName === "N/A";
        } else {
          return record.restaurantName !== "N/A";
        }
      },
      filters: [
        {
          text: "N/A",
          value: "N/A",
        },
        {
          text: "Other",
          value: "Other",
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "integration" }),
      dataIndex: "integration",
      key: "integration",
      render: (company) => {
        return (
          <Tag
            color={
              company === "foodics"
                ? "purple"
                : company === "Marn"
                ? "blue"
                : ""
            }
          >
            {company}
          </Tag>
        );
      },
      onFilter: (value, record) => {
        return record?.integration === value;
      },
      filters: [
        { text: "Foodics", value: "foodics" },
        { text: "Marn", value: "Marn" },
        { text: "No integration", value: "NO_INTEGRATION" },
      ],
    },
    {
      title: intl.formatMessage({ id: "subscriptionEndDate" }),
      dataIndex: "endSubscription",
      key: "endSubscription",
      style: {
        backgroundColor: "red",
      },
      render: (dateString, record) => {
        let background = COLORS.success;
        let color = COLORS.white;
        // check if date is less than now;
        let date = record.endSubscriptionDate;
        if (dayjs(date).isBefore(dayjs())) {
          background = COLORS.danger;
        } // check if date is within the next 8 days;
        else if (dayjs(date).isBetween(dayjs(), dayjs().add(8, "days"))) {
          background = COLORS.warning;
        }
        return {
          props: {
            style: { background },
          },
          children: (
            <Text style={{ color }}>{dayjs(date).format("DD/MM/YYYY")}</Text>
          ),
        };
      },
      sorter: (a, b) =>
        new Date(a.endSubscriptionDate) - new Date(b.endSubscriptionDate),
      showSorterTooltip: false,
      onFilter: (value, record) => {
        let date = record.endSubscriptionDate;
        if (value === "active") {
          return dayjs(date).isAfter(dayjs().add(8, "days"));
        } else if (value === "lessThanWeek") {
          return dayjs(date).isBetween(dayjs(), dayjs().add(8, "days"));
        } else if (value === "expired") {
          return dayjs(date).isBefore(dayjs());
        }

        return false;
      },
      filters: [
        {
          text: intl.formatMessage({ id: "active" }),
          value: "active",
        },
        {
          text: intl.formatMessage({ id: "lessThanWeek" }),
          value: "lessThanWeek",
        },
        {
          text: intl.formatMessage({ id: "expired" }),
          value: "expired",
        },
      ],
    },
  ];
  useEffect(() => {
    setLoading(true);
    if (users?.length) {
      const formatted = users.map((user, i) => {
        let selectedService = {};
        let didPaid = user?.restaurant?.didPaid;
       user?.services.forEach((service) => {
          if(service?.amountPaid > 0){
            didPaid = true;
          }
          if (!selectedService?.serviceEnd) {
            selectedService = service;
          } else {
            // check if the current service end date is greater than the selected service end date;
            if (
              dayjs(service.serviceEnd).isAfter(
                dayjs(selectedService.serviceEnd)
              )
            ) {
              selectedService = service;
            }
          }
        });
        return {
          key: user._id,
          number: i + 1,
          _id: user._id,
          name: user.name,
          phone: user?.phone,
          didPaid:didPaid,
          integration: user?.integration || "NO_INTEGRATION",
          restaurantId: user?.restaurant?._id,
          // endSubscriptionDate: dayjs(user?.subscription?.subscriptionEnd),
          restaurantName: user?.restaurant?.name
            ? user?.restaurant?.name
            : "N/A",
            selectedService: selectedService,
          endSubscription: dayjs(selectedService?.serviceEnd).format(
            "DD-MM-YYYY"
          ),
          endSubscriptionDate: dayjs(selectedService?.serviceEnd),

          userObject: { ...user },
        };
      });
      setFormattedUsers(formatted);
    }
    setLoading(false);
  }, [rtl, users]);

  const dummyRequest = (props) => {
    // console.log(props);
    setTimeout(() => {
      props.onSuccess("ok");
      setFile(props.file);
    }, 0);
  };
  const sendSMS = async (values) => {
    try {
      if (!phones?.length) return;
      if (file && values.senderName === 'Whatsapp') {
        return sendWithImage(values, file);
      }

      setLoading(true);
      const data = {
        phones,
        message: values.message,
        senderName: values.senderName,
      };
      const res = await sendMessage(data);
      if (res.data.status === "success") {
        form.resetFields();
        setPhones([]);
      }

      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const sendWithImage = async (values, file) => {
    try {
      // send the image with the message;
      if (!phones?.length) return;
      // loop throw the phones and send the message;
      setLoading(true);
      for(let i = 0; i < phones.length; i++) {
        const phone = phones[i];
        // create form Data for the file;
        const formData = new FormData();
        formData.append("userPhone", phone);
        formData.append("message", values.message);
        formData.append("file", file);
        const res = await sendMessageWithImage(formData);
        openNotification({
          title: `تم إرسال الرسالة رقم ${i + 1} بنجاح`,
          type: 'success',
          rtl,
        });
      }
        form.resetFields();
        setPhones([]);
      
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "savedSuccessfully" }),
        type: "success",
        rtl,
      });
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setPhones(selectedRows.map((user) => user.phone));
    },
    getCheckboxProps: (record) => ({
      disabled: !record.phone ? true : false,
      // Column configuration not to be checked
      name: record.phone,
    }),
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "users" })}
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getUsersData}
          />
        </div>
        <Form
          name='send-sms'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={sendSMS}
          initialValues={{
            senderName: "Whatsapp",
          }}
        >
          {() => {
            return (
              <>
                <Form.Item
                  name='message'
                  label={intl.formatMessage({ id: "message" })}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea showCount rows={4} />
                </Form.Item>
                <Form.Item
                  name='senderName'
                  label={intl.formatMessage({ id: "senderName" })}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select className='my-font'>
                    <Select.Option value='iWaiter-AD'>iWaiter-AD</Select.Option>
                    <Select.Option value='iWaiter.net'>
                      iWaiter.net
                    </Select.Option>
                    <Select.Option value='Whatsapp'>Whatsapp</Select.Option>
                  </Select>
                </Form.Item>
                {/* Add File upload input image */}
                {form?.getFieldValue("senderName") === "Whatsapp" ? (
                  <Dragger
                    accept='image/jpg,image/jpeg,image/png,application/pdf'
                    // action='/upload.do'
                    listType='picture-card'
                    customRequest={dummyRequest}
                    maxCount={1}
                  >
                    <div>
                      <PlusOutlined />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        تحميل
                      </div>
                    </div>
                  </Dragger>
                ) : null}
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "send" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={loading || !phones?.length}
                    type='primary'
                  />
                </Form.Item>
              </>
            );
          }}
        </Form>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedUsers?.length === 0,
            },
          }}
          pagination={false}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedUsers}
          scroll={{ x: 'max-content' }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      </Spin>
    </div>
  );
};

export default SMS;
