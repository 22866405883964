import {  Select, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const {  Text } = Typography;

const LinkedOption = ({
  localOption,
  foodicsModifiers,
  localModifier,
  localModId,
  linkedArray,
  setLinkedArray
}) => {
  const [options, setOptions] = useState([]);
  const [foodicsId, setFoodicsId] = useState("");
  const { rtl } = useSelector((state) => state.settings);

  useEffect(() => {
    // get foodics modifier id from the link array; otherModifierId
    const linkedMod = linkedArray.find((mod) => mod._id === localModId);
    // get otherModifierId from linkedMod;
    const otherModifierId = linkedMod?.POSIntegration?.otherModifierId;
    // get the full object from foodicsModifiers;
    const foodicsMod = foodicsModifiers?.find(
      (mod) => mod?.foodicsModId === otherModifierId
    );
    setOptions(foodicsMod?.options ?? []);
    // id , name, nameAr, price, taxId,

    // check the value selected from linkedArray options where option id === localOption._id;
    const selectedOption = linkedMod?.options?.find(
      (option) => option?._id === localOption?._id
    );
    // get the POSIntegration from selectedOption;
    const POSIntegration = selectedOption?.POSIntegration;
    // get the foodicsId from POSIntegration;
    //     otherOptionId
    // otherTaxOptionId
    setFoodicsId(POSIntegration?.otherOptionId ?? "NON");
  }, [localModId, linkedArray]);

  const onChange = (id) => {
    // get foodics modifier id from the link array; otherModifierId
    const linkedMod = linkedArray.find((mod) => mod._id === localModId);
    // get otherModifierId from linkedMod;
    const otherModifierId = linkedMod?.POSIntegration?.otherModifierId;
    // get the full object from foodicsModifiers;
    const foodicsMod = foodicsModifiers?.find(
      (mod) => mod?.foodicsModId === otherModifierId
    );
    // get foodics full option object from foodicsMod;
    const foodicsOption = foodicsMod?.options?.find(
        (option) => option?.id === id
        );
    // check the value selected from linkedArray options where option id === localOption._id;
    const selectedOption = linkedMod?.options?.find(
      (option) => option?._id === localOption?._id
    );
    let newPOSIntegrationOption = {
        ...selectedOption,
        name: "foodics",
        otherOptionId: id,
        otherTaxOptionId: foodicsOption?.taxId
    }
    // create a new Linked modifier object;
    const newLinkedModifier = {
        ...linkedMod,
        options: linkedMod.options.map((option) => {
            if (option._id === localOption._id) {
                option.POSIntegration = newPOSIntegrationOption;
            }
            return option;
        })
    }
    // update the inked array;
    const newLinkedArray = linkedArray.map((mod) => {
        if (mod._id === localModId) {
            return newLinkedModifier;
        }
        return mod;
    }
    );
    setLinkedArray(newLinkedArray);
    
  };
  return (
    <div>
      <Text className='my-font'>
        {rtl ? localOption.name : localOption.name_en} - {localOption?.price}{" "}
        SAR
      </Text>
      <Select
        className={"my-font"}
        style={{
          width: "100%",
        }}
        value={foodicsId}
        onChange={onChange}
      >
        <Select.Option
          value={"NON"}
          style={{
            width: "100%",
          }}
        >
          <span className='my-font'>choose</span>
        </Select.Option>
        {options?.map((foodicsOption) => {
          return (
            <Select.Option
              key={foodicsOption?.id}
              value={foodicsOption?.id}
              style={{
                width: "100%",
              }}
              className='my-font'
            >
              {foodicsOption?.name} - {foodicsOption?.nameAr} -{" "}
              {foodicsOption?.price} SAR
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default LinkedOption;
