import React, { useState } from "react";
import COLORS from "../../Style/colors";
import { Typography, Spin, Form, Input, Select, Divider } from "antd";
import { useIntl } from "react-intl";
import CustomButton from "../../Components/CustomButton";
import { createChallenges } from "../../API/fetch";
import { useNavigate } from "react-router-dom";
import openNotification from "../../Components/Notifications";
const { Text } = Typography;
const { Option } = Select;
function AddChallenges() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      const data = {
        numberOfChallenges: parseInt(values.numberOfChallenges),
        maxAmount: parseInt(values.maxAmount),
        challengesType: values.challengesType,
      };
      setLoading(true);
      const res = await createChallenges(data);
      openNotification({
        title: "success",
        description: "challenges data crate successfully",
        type: "success",
        rtl: true,
      });
      setLoading(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
      alert("error", JSON.stringify(error));
      setLoading(false);
    }
  };

  const getFormattedInfo = () => {
    let number = form.getFieldValue("numberOfChallenges");
    let maxAmount = form.getFieldValue("maxAmount");
    let total = (maxAmount / number)?.toFixed(2);
    // if (!number || !maxAmount) return "";
    // let total = parent(maxAmount) / parent(number);
    return `سيتم توزيع ${number} تحدي بقيمة ${maxAmount} \n إذا قمنا بتوزيع الجوائز بالتساوي ستكون الجائزة ${total} ريال لكل تحدي`;
  };
  return (
    <div
      style={{
        backgroundColor: COLORS.white,
        marginTop: 10,
        width: "92%",
        minHeight: "70vh",
        marginRight: "auto",
        marginLeft: "auto",
        borderRadius: 10,
        padding: 20,
      }}
      className='light-shadow'
    >
      <Spin spinning={loading}>
        <Form
          name='send-emails'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            numberOfChallenges: 1,
            maxAmount: 100,
            challengesType: "mix",
          }}
        >
          {() => {
            return (
              <>
                {/* number  */}
                <Form.Item
                  name='numberOfChallenges'
                  label={intl.formatMessage({ id: "numberOfChallenges" })}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "required" }),
                    },
                  ]}
                >
                  <Input
                    type='number'
                    onChange={(e) => {
                      // min number is 1;
                      if (parseInt(e.target.value) < 1) {
                        form.setFieldsValue({ numberOfChallenges: 1 });
                      }
                    }}
                  />
                </Form.Item>
                {/* max amount */}
                <Form.Item
                  name='maxAmount'
                  label={intl.formatMessage({ id: "maxAmount" })}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "required" }),
                    },
                  ]}
                >
                  <Input
                    type='number'
                    onChange={(e) => {
                      // min number is 1;
                      if (parseInt(e.target.value) < 1) {
                        form.setFieldsValue({ maxAmount: 1 });
                      }
                    }}
                  />
                </Form.Item>
                {/* type */}
                <Form.Item
                  name='challengesType'
                  label={intl.formatMessage({ id: "challengesType" })}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "required" }),
                    },
                  ]}
                >
                  <Select className='my-font'>
                    <Option value='mix'>
                      {intl.formatMessage({ id: "mix" })}
                    </Option>
                    <Option value='XO'>
                      {intl.formatMessage({ id: "XO" })}
                    </Option>
                    <Option value='Quiz'>
                      {intl.formatMessage({ id: "Quiz" })}
                    </Option>
                  </Select>
                </Form.Item>
                <Divider />
                <div>
                  <Text className='my-font'>
                    {intl.formatMessage({ id: "info" })}
                  </Text>
                  <br />

                  <Text className='my-font'>{getFormattedInfo()}</Text>
                </div>
                <Divider />
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={
                      loading ||
                      parseInt(form?.getFieldValue("numberOfChallenges")) <=
                        0 ||
                      parseInt(form?.getFieldValue("maxAmount")) <= 0
                    }
                    type='primary'
                  />
                </Form.Item>
              </>
            );
          }}
        </Form>
      </Spin>
    </div>
  );
}

export default AddChallenges;
